<template>
  <div>
    <v-dialog
      v-model="collaboratorDialog"
      persistent
      min-width="600px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Collaborate</span>
        </v-card-title>
        <v-card-text>
          <div
            style="display: flex; flexFlow: column; justifyContent: space-around; alignItems: center; height: 117px;"
            v-if="loading"
          >
            <Loading :loadingText="loadingText" />
          </div>
          <div v-else>
            <div style="maxWidth:60vw;margin:0 auto;">
              <v-row>
                <v-col cols="4">
                  <v-card
                    class="pa-6"
                    style="backgroundColor:rgba(109, 142, 164, 0.1)"
                  >
                    <h4 class="collabHStyle">Available Collaborators</h4>
                    <v-text-field
                      label="Search"
                      type="text"
                      prepend-inner-icon="mdi-account-search"
                      @input="
                        val =>
                          searchCollaboratorsHandler(
                            val,
                            'originalUnSelectedCollaboratorsArr',
                            'unSelectedCollaboratorsArr'
                          )
                      "
                    />
                    <v-checkbox
                      v-for="collab in unSelectedCollaboratorsArr"
                      :key="`${collab.user_id}_${collab.user_full_name}`"
                      v-model="unSelectedCollaboratorsChecklist"
                      :label="collab.user_full_name"
                      :value="collab.user_id"
                      style="marginTop:0;paddingTop:0;"
                    />
                  </v-card>
                </v-col>

                <v-col cols="4">
                  <div
                    style="display:flex;flexFlow:column;alignItems:center;marginTop:50vh;transform:translate(0,-50%)"
                  >
                    <div>
                      <v-btn
                        fab
                        small
                        :disabled="unSelectedCollaboratorsChecklist.length < 1"
                        @click="addCollaboratorHandler"
                      >
                        <v-icon>mdi-chevron-double-right</v-icon>
                      </v-btn>
                    </div>

                    <div>
                      <v-btn
                        class="mt-4"
                        fab
                        small
                        :disabled="selectedCollaboratorsChecklist.length < 1"
                        @click="removeCollaboratorHandler"
                      >
                        <v-icon>mdi-chevron-double-left</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>

                <v-col cols="4">
                  <v-card
                    class="pa-6"
                    style="backgroundColor:rgba(109, 142, 164, 0.1)"
                  >
                    <h4 class="collabHStyle">Already Collaborating</h4>
                    <v-text-field
                      label="Search"
                      type="text"
                      prepend-inner-icon="mdi-account-search"
                      @input="
                        val =>
                          searchCollaboratorsHandler(
                            val,
                            'originalSelectedCollaboratorsArr',
                            'selectedCollaboratorsArr'
                          )
                      "
                    />
                    <v-checkbox
                      v-for="collab in selectedCollaboratorsArr"
                      :key="`${collab.user_id}_${collab.user_full_name}`"
                      v-model="selectedCollaboratorsChecklist"
                      :label="collab.user_full_name"
                      :value="collab.user_id"
                      style="marginTop:0;paddingTop:0;"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-if="!loading"
            @click="
              $emit('closeCollaborateDialog');
              collaboratorDialog = false;
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "./misc/Loading.vue";

export default {
  name: "Collaborator",
  props: [
    "assetID",
    "inspectionID",
    "assetName",
    "openCollaboratorDialog",
    "type"
  ],
  components: {
    Loading
  },
  data: () => ({
    collaboratorDialog: false,
    loading: true,
    checkboxList: [true, false],
    loadingText: "Fetching Collaborators!",
    selectedCollaboratorsArr: [],
    originalSelectedCollaboratorsArr: [],
    unSelectedCollaboratorsArr: [],
    originalUnSelectedCollaboratorsArr: [],
    selectedCollaboratorsChecklist: [],
    unSelectedCollaboratorsChecklist: []
  }),
  methods: {
    searchCollaboratorsHandler(val, arrName, arr2Name) {
      let arrToReturn = [];
      this[arrName].map(el => {
        if (el.user_full_name.toLowerCase().includes(val.toLowerCase())) {
          arrToReturn.push(el);
        }
      });
      this[arr2Name] = arrToReturn;
    },
    async addCollaboratorHandler() {
      this.loading = true;
      this.loadingText = "Adding Collaborators!";
      let res;
      for (let i of this.unSelectedCollaboratorsChecklist) {
        let uploadObj = new FormData();
        if (this.type == "asset") {
          uploadObj.append("asset_id", this.assetID);
        } else {
          uploadObj.append("inspection_id", this.inspectionID);
        }
        uploadObj.append("user_id", i);
        res = await this.$http.post(
          `/collab/${
            this.type == "asset"
              ? `add_asset_collab/${this.assetID}`
              : `add_inspection_collab/${this.inspectionID}/`
          }`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
      }
      this.assignProperCollaborate(res.data.collaborators);
      this.unSelectedCollaboratorsChecklist = [];
      this.loading = false;
    },
    async removeCollaboratorHandler() {
      this.loading = true;
      this.loadingText = "Removing Collaborators!";
      let res;
      for (let i of this.selectedCollaboratorsChecklist) {
        res = await this.$http.delete(
          `/collab/${
            this.type == "asset"
              ? `del_asset_collab/${this.assetID}`
              : `del_inspection_collab/${this.inspectionID}`
          }/${i}`,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
      }
      this.assignProperCollaborate(res.data.collaborators);
      this.selectedCollaboratorsChecklist = [];
      this.loading = false;
    },
    assignProperCollaborate(arr) {
      let selected = [],
        unSelected = [];
      arr.forEach(item => {
        if (item.shared) {
          selected.push(item);
        } else {
          unSelected.push(item);
        }
      });

      this.selectedCollaboratorsArr = selected;
      this.originalSelectedCollaboratorsArr = selected;

      this.unSelectedCollaboratorsArr = unSelected;
      this.originalUnSelectedCollaboratorsArr = unSelected;
    }
  },
  async mounted() {
    this.collaboratorDialog = this.openCollaboratorDialog;
    let res = await this.$http.get(
      `/collab/get_collabrators_list/?${
        this.type == "asset"
          ? `asset_id=${this.assetID}`
          : `inspection_id=${this.inspectionID}`
      }`,
      {
        headers: {
          Authorization: "token " + localStorage.getItem("auth_token")
        }
      }
    );
    this.assignProperCollaborate(res.data.collaborators);
    this.loading = false;
  }
};
</script>

<style scoped>
.collabHStyle {
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 10px;
}
</style>

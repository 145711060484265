<template>
  <l-map
    :center="[30.3753, 69.3451]"
    :zoom="11"
    ref="map"
    gestureHandling
    :options="mapOptions"
  >
    <l-control-layers position="topright"></l-control-layers>
    <l-tile-layer
      v-for="tileProvider in tileProviders"
      :key="tileProvider.name"
      :name="tileProvider.name"
      :visible="tileProvider.visible"
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
      :subdomains="tileProvider.subdomains"
      layer-type="base"
    />
    <l-geo-json :geojson="geoJSONData" ref="geoJsonLayer"></l-geo-json>
  </l-map>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { GestureHandling } from "leaflet-gesture-handling";

export default {
  name: "FlightListMap",
  props: ["geoJSONData"],
  data: () => ({
    mapOptions: {
      gestureHandling: true
    },
    tileProviders: [
      {
        name: "OpenStreetMap",
        visible: false,
        attribution:
          "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      },
      // {
      //   name: "Toner",
      //   visible: false,
      //   url: "//stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png",
      //   attribution:
      //     'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      // },
      // {
      //   name: "Satellite",
      //   visible: false,
      //   url:
      //     "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      //   attribution:
      //     "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
      // },
      // {
      //   name: "googleSat",
      //   visible: false,
      //   url: "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      //   subdomains: ["mt0", "mt1", "mt2", "mt3"]
      // },
      {
        name: "googleStreets",
        visible: false,
        url: "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      },
      {
        name: "googleHybrid",
        visible: true,
        url: "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      }
      // {
      //   name: "googleTerrain",
      //   visible: false,
      //   url: "http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
      //   subdomains: ["mt0", "mt1", "mt2", "mt3"]
      // }
    ]
  }),
  methods: {
    invalidatorTester() {
      this.$refs.map.mapObject.invalidateSize();
      this.$refs.map.fitBounds(this.$refs.geoJsonLayer.getBounds());
    }
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(this.$refs.geoJsonLayer.getGeoJSONData());
      this.$refs.map.fitBounds(this.$refs.geoJsonLayer.getBounds());
      //  const mapDiv = document.getElementsByClassName("vue2leaflet-map");
      // const resizeObserver = new ResizeObserver(() => {
      //   console.log("I am inside")

      // });
      // resizeObserver.observe(mapDiv);
      window.addEventListener("resize", this.invalidatorTester);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.invalidatorTester);
  }
};
</script>

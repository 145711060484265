<template>
  <v-dialog v-model="modal" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Adding Observation on Image</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="name"
                label="Name"
                :rules="NameRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                label="Description"
                :rules="DescriptionRules"
                rows="3"
                v-model="description"
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="annotationType"
                :items="annotationTypeItems"
                item-text="type"
                item-value="value"
                label="Annotation Type *"
              ></v-select>
            </v-col>

            <v-col cols="12" v-if="annotationType == 'other'">
              <v-text-field
                v-model="annotationTypeName"
                label="Enter Other Type Name"
                :rules="annotationTypeNameRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeCameraCreateObservationDialog()"
          >Cancel</v-btn
        >
        <v-btn color="blue darken-1" text @click="saveNewObservation()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AnnotatorSaveDialog",
  props: ["saveModal", "editing", "namer", "descriptionr", "categoryr"],
  data() {
    return {
      modal: false,
      name: null,
      description: null,
      NameRules: [v => !!v || "Name is required"],
      DescriptionRules: [v => !!v || "Description is required"],
      annotationTypeName: null,
      annotationTypeNameRules: [v => !!v || "Type is required"],
      annotationType: { type: "Concrete Crack", value: "Concrete Crack" },
      annotationTypeItems: [
        { type: "Concrete Crack", value: "Concrete Crack" },
        { type: "Other", value: "other" }
      ]
    };
  },
  watch: {
    saveModal(newValue) {
      this.modal = newValue;
      if (newValue) {
        if (this.editing) {
          this.name = this.namer;
          this.description = this.descriptionr;
          if (this.categoryr != "Concrete Crack") {
            this.annotationTypeName = this.categoryr;
            this.annotationType = "other";
          } else {
            this.annotationType = this.categoryr;
          }
        }
      }
    }
  },
  methods: {
    resetForm() {
      this.annotationType = { type: "Concrete Crack", value: "Concrete Crack" };
      this.name = null;
      this.description = null;
      this.annotationTypeName = null;
    },

    closeCameraCreateObservationDialog() {
      this.$emit("cancel");

      this.resetForm();
    },

    saveNewObservation() {
      let annotationType = this.annotationType.value
        ? this.annotationType.value
        : this.annotationType;

      if (this.name && this.description) {
        this.$emit("save", {
          name: this.name,
          description: this.description,
          ...(annotationType == "other"
            ? { annotationType: this.annotationTypeName }
            : { annotationType }),
          ...(this.editing && { type: "editingAPI" })
        });
      }

      this.resetForm();
    }
  }
};
</script>

<template>
  <div>
    <div class="uploader mt-3" v-if="uploadingProgress">
      <div>
        <v-progress-linear v-model="uploadProgressNumber" height="40">
          <strong>{{ uploadProgressNumber }}%</strong>
        </v-progress-linear>
        <span class="progressLoadingDetail">
          {{ uploadProgressStatement }}
        </span>
      </div>
    </div>
    <div v-else>
      <div class="uploadMainDivStyle">
        <div class="dropper mt-3">
          <input
            type="file"
            class="dropperInput"
            multiple
            @change="fileChangeHandler"
          />
          <span class="dropperSpan">Drop Files Here!</span>
          <table class="dropperTable" v-if="uploadFilesList.length">
            <thead>
              <tr>
                <th>Sr#</th>
                <th>Thumb</th>
                <th>Name</th>
                <th>Size</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, ind) in uploadFilesList" :key="item.name">
                <td>{{ ind + 1 }}</td>
                <td v-if="item.imagePresent">
                  <img class="uploadTableImage" :src="item.fileOriginPath" />
                </td>
                <td v-else>No Image</td>
                <td>{{ item.name }}</td>
                <td>{{ item.fileSize }}</td>
                <td style="textAlign:center">
                  <v-btn fab small @click="removeUploadFileHandler(ind)">
                    <v-icon dark color="red">mdi-cancel</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <v-btn
        block
        color="success"
        class="mt-4"
        @click="uploadFilesHandler"
        :disabled="uploadFilesList.length < 1"
        >Upload</v-btn
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PilotUpload",
  data() {
    return {
      uploadFilesList: [],
      uploadingProgress: false,
      uploadProgressStatement: "Upload files",
      uploadProgressNumber: 0
    };
  },
  props: ["assetID"],
  methods: {
    niceBytes(x) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    },
    fileChangeHandler(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        e.target.files[i].fileOriginPath = URL.createObjectURL(
          e.target.files[i]
        );
        if (e.target.files[i].type.split("/").shift() == "image") {
          e.target.files[i].imagePresent = true;
        } else {
          e.target.files[i].imagePresent = false;
        }
        e.target.files[i].fileSize = this.niceBytes(e.target.files[i].size);
      }
      this.uploadFilesList.push(...e.target.files);
    },
    removeUploadFileHandler(ind) {
      let duplicateArray = [...this.uploadFilesList];
      duplicateArray.splice(ind, 1);
      this.uploadFilesList = duplicateArray;
    },
    uploadFilesHandler() {
      this.uploadingProgress = true;
      let arrLength = this.uploadFilesList.length;
      for (let [ind, i] of this.uploadFilesList.entries()) {
        let uploadObj = new FormData();
        uploadObj.append("filename", i.name);

        this.$http
          .post("/profile/get-pilot-file-upload-object/", uploadObj, {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          })
          .then(async resIns => {
            try {
              let config = {
                onUploadProgress: progressEvent => {
                  var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  this.uploadProgressNumber = percentCompleted;

                  this.uploadProgressStatement = `Uploading File ${ind +
                    1}/${arrLength}`;
                  if (percentCompleted == 100 && ind == arrLength - 1) {
                    this.$emit("uploadCompleted", true);
                    this.uploadingProgress = false;
                    this.uploadProgressNumber = 0;
                    this.uploadProgressStatement = "Uploading File";
                    this.uploadFilesList = [];
                  }
                },
                headers: {
                  "Content-Type": i.type
                }
              };
              await axios.put(resIns.data.presigned_url, i, config);
            } catch (err) {
              console.log(
                "Some error has occured while uploading the files",
                err
              );
              this.$emit("uploadCompleted", false);
            }
          })
          .catch(errIns => {
            console.log("some error occured in inside", errIns.response.data);
            this.$emit("uploadCompleted", false);
          });
      }
    }
  }
};
</script>

<style scoped>
.uploader {
  min-height: 30vh;
  border: 2px dashed white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dropper {
  min-height: 30vh;
  border: 2px dashed white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s;
}

.dropper:hover {
  background-color: #eeeeee42;
}

.dropperInput {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.dropperSpan {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  position: absolute;
  pointer-events: none;
}

.dropperTable {
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  align-self: flex-start;
}

.dropperTable thead tr {
  background-color: grey;
}

.dropperTable thead th {
  position: sticky;
  top: 0;
  background-color: grey;
  z-index: 1000;
}

.dropperTable td {
  padding: 0.75rem;
  border-bottom: 1px dashed grey;
}

.uploadTableImage {
  max-width: 4em;
  max-height: 4em;
  vertical-align: middle;
  border-style: none;
}

.progressLoadingDetail {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  display: inline-block;
  margin-top: 10px;
}

.uploadMainDivStyle {
  position: relative;
  max-height: 450px;
  overflow-y: scroll;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","min-width":"600px","transition":"dialog-top-transition"},model:{value:(_vm.collaboratorDialog),callback:function ($$v) {_vm.collaboratorDialog=$$v},expression:"collaboratorDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Collaborate")])]),_c('v-card-text',[(_vm.loading)?_c('div',{staticStyle:{"display":"flex","flexFlow":"column","justifyContent":"space-around","alignItems":"center","height":"117px"}},[_c('Loading',{attrs:{"loadingText":_vm.loadingText}})],1):_c('div',[_c('div',{staticStyle:{"maxWidth":"60vw","margin":"0 auto"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"pa-6",staticStyle:{"backgroundColor":"rgba(109, 142, 164, 0.1)"}},[_c('h4',{staticClass:"collabHStyle"},[_vm._v("Available Collaborators")]),_c('v-text-field',{attrs:{"label":"Search","type":"text","prepend-inner-icon":"mdi-account-search"},on:{"input":function (val) { return _vm.searchCollaboratorsHandler(
                          val,
                          'originalUnSelectedCollaboratorsArr',
                          'unSelectedCollaboratorsArr'
                        ); }}}),_vm._l((_vm.unSelectedCollaboratorsArr),function(collab){return _c('v-checkbox',{key:((collab.user_id) + "_" + (collab.user_full_name)),staticStyle:{"marginTop":"0","paddingTop":"0"},attrs:{"label":collab.user_full_name,"value":collab.user_id},model:{value:(_vm.unSelectedCollaboratorsChecklist),callback:function ($$v) {_vm.unSelectedCollaboratorsChecklist=$$v},expression:"unSelectedCollaboratorsChecklist"}})})],2)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticStyle:{"display":"flex","flexFlow":"column","alignItems":"center","marginTop":"50vh","transform":"translate(0,-50%)"}},[_c('div',[_c('v-btn',{attrs:{"fab":"","small":"","disabled":_vm.unSelectedCollaboratorsChecklist.length < 1},on:{"click":_vm.addCollaboratorHandler}},[_c('v-icon',[_vm._v("mdi-chevron-double-right")])],1)],1),_c('div',[_c('v-btn',{staticClass:"mt-4",attrs:{"fab":"","small":"","disabled":_vm.selectedCollaboratorsChecklist.length < 1},on:{"click":_vm.removeCollaboratorHandler}},[_c('v-icon',[_vm._v("mdi-chevron-double-left")])],1)],1)])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"pa-6",staticStyle:{"backgroundColor":"rgba(109, 142, 164, 0.1)"}},[_c('h4',{staticClass:"collabHStyle"},[_vm._v("Already Collaborating")]),_c('v-text-field',{attrs:{"label":"Search","type":"text","prepend-inner-icon":"mdi-account-search"},on:{"input":function (val) { return _vm.searchCollaboratorsHandler(
                          val,
                          'originalSelectedCollaboratorsArr',
                          'selectedCollaboratorsArr'
                        ); }}}),_vm._l((_vm.selectedCollaboratorsArr),function(collab){return _c('v-checkbox',{key:((collab.user_id) + "_" + (collab.user_full_name)),staticStyle:{"marginTop":"0","paddingTop":"0"},attrs:{"label":collab.user_full_name,"value":collab.user_id},model:{value:(_vm.selectedCollaboratorsChecklist),callback:function ($$v) {_vm.selectedCollaboratorsChecklist=$$v},expression:"selectedCollaboratorsChecklist"}})})],2)],1)],1)],1)])]),_c('v-card-actions',[_c('v-spacer'),(!_vm.loading)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$emit('closeCollaborateDialog');
            _vm.collaboratorDialog = false;}}},[_vm._v(" Close ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
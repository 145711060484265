var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',_vm._l((_vm.galleryDataReal),function(image,index){return _c('v-col',{key:index,staticClass:"d-flex child-flex",attrs:{"cols":_vm.cols}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"grey lighten-2",class:{ overlay: hover },staticStyle:{"cursor":"pointer"},attrs:{"src":image.thumb,"lazy-src":image.thumb,"aspect-ratio":"1","elevation":hover ? 16 : 2,"gradient":hover
              ? 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'
              : ''},on:{"click":function($event){return _vm.openGallery(index)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[(image.loading)?_c('div',{staticClass:"separateLoadingDivStyle"},[_c('Loading',{attrs:{"loadingText":"Deleting!"}})],1):_c('div',{staticClass:"deleteArea"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function (e) { return _vm.annotatorDialogOpener(
                    e,
                    image.src,
                    image.id,
                    image.file_name
                  ); }}},[_c('v-icon',{attrs:{"dark":"","color":"white"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error darken-3"},on:{"click":function (e) { return _vm.deleteClickHandler(e, image.id, index); }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)],1)])]}}],null,true)})],1)}),1),_c('LightBox',{ref:"lightbox",attrs:{"media":_vm.galleryDataReal,"show-caption":true,"show-light-box":false}}),_c('v-snackbar',{attrs:{"right":"","color":_vm.success ? 'green' : 'red'},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")]),_c('AnnotatorDialog',{attrs:{"imageName":_vm.imageName,"imageID":_vm.imageID,"imageURL":_vm.imageURL,"dialogOpen":_vm.dialogValue},on:{"dialogClose":function($event){_vm.dialogValue = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
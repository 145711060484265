<template>
  <div style="marginTop:10px;">
    <h1
      id="servicelevel"
      style="fontFamily:Quicksand;fontSize:50px;textAlign:center"
    >
      Price Calculator
    </h1>
    <v-container fluid>
      <v-row>
        <v-col xl="3" lg="4">
          <div style="position:sticky;top:30px;">
            <v-card class="pa-6">
              <scrollactive class="my-nav" @itemchanged="onItemChanged">
                <ul>
                  <li>
                    <a
                      href="#servicelevel"
                      id="firstDiv"
                      class="scrollactive-item"
                    >
                      <span class="number">01</span>
                      <span>Service Level</span>
                    </a>
                  </li>

                  <li>
                    <a href="#buildingcategory" class="scrollactive-item">
                      <span class="number">02</span>
                      <span>Building Category</span>
                    </a>
                  </li>

                  <li>
                    <a href="#typeof3dmodel" class="scrollactive-item">
                      <span class="number">03</span>
                      <span>Type of 3D Model</span>
                    </a>
                  </li>

                  <li>
                    <a href="#qualityof3dmodel" class="scrollactive-item">
                      <span class="number">04</span>
                      <span>Quality of 3D Model</span>
                    </a>
                  </li>

                  <li>
                    <a href="#drawTheArea" class="scrollactive-item">
                      <span class="number">05</span>
                      <span>Draw the Area</span>
                    </a>
                  </li>
                </ul>
              </scrollactive>
            </v-card>
          </div>
        </v-col>
        <v-col xl="9" lg="8">
          <div
            class="contentDiv"
            :style="mainActive == 1 && 'border:3px solid #ff6600'"
          >
            <v-card style="borderRadius: 20px;" class="pa-8">
              <h3 class="hStyle">Service Level</h3>

              <v-radio-group v-model="serviceLevel">
                <v-row>
                  <v-col
                    cols="4"
                    v-for="item in quoteData.service_levels"
                    :key="item.id"
                  >
                    <div class="radioDiv" @click="serviceLevel = item.id">
                      <div class="flexStyle">
                        <v-radio :value="item.id" />
                        <i :class="item.icon"></i>
                      </div>
                      <div class="divHead">
                        <div>{{ item.name }}</div>
                        <p>{{ item.description }}</p>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-card>
          </div>

          <div
            class="contentDiv"
            id="buildingcategory"
            :style="mainActive == 2 && 'border:3px solid #ff6600'"
          >
            <v-card style="borderRadius: 20px;" class="pa-8">
              <h3 class="hStyle">Building Category</h3>
              <v-row>
                <v-col
                  cols="3"
                  v-for="item in quoteData.categories"
                  :key="item.id"
                >
                  <div
                    @click="buildingCategoryActive = item.id"
                    class="buildingDiv"
                    :style="
                      buildingCategoryActive == item.id &&
                        'border:1px solid #ff6600'
                    "
                  >
                    <img :src="`/icons/${item.icon}`" />
                    <span>{{ item.name }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>

          <div
            class="contentDiv"
            id="typeof3dmodel"
            :style="mainActive == 3 && 'border:3px solid #ff6600'"
          >
            <v-card style="borderRadius: 20px;" class="pa-8">
              <h3 class="hStyle">Type of 3D Model</h3>
              <v-radio-group v-model="type3DModel">
                <v-row>
                  <v-col
                    cols="4"
                    v-for="item in quoteData.types_of_3d_model"
                    :key="item.id"
                  >
                    <div class="radioDiv" @click="type3DModel = item.id">
                      <div class="flexStyle">
                        <v-radio :value="item.id" />
                        <div class="modelLabel">
                          <img :src="`/icons/${item.icon.split('+')[0]}`" />
                          <img
                            style="width:32px;height:32px;"
                            :src="`/icons/${item.icon.split('+')[1]}`"
                          />
                        </div>
                      </div>
                      <div class="divHead">
                        <div>{{ item.name }}</div>
                        <p>
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-card>
          </div>

          <div
            class="contentDiv"
            id="qualityof3dmodel"
            :style="mainActive == 4 && 'border:3px solid #ff6600'"
          >
            <v-card style="borderRadius: 20px;" class="pa-8">
              <h3 class="hStyle">Quality of 3D Model</h3>
              <v-radio-group v-model="quality3DModel">
                <v-row>
                  <v-col
                    cols="4"
                    v-for="item in quoteData.qualities"
                    :key="item.id"
                  >
                    <div class="radioDiv" @click="quality3DModel = item.id">
                      <div class="flexStyle">
                        <v-radio :value="item.id" />
                        <img style="width:65px;height:auto;" :src="item.icon" />
                      </div>
                      <div class="divHead">
                        <div>{{ item.name }}</div>
                        <p>
                          {{ item.description }}
                        </p>
                        <!-- <p>
                          {{item.description}} <br /><strong
                            >2cm</strong
                          >
                        </p> -->
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-card>
          </div>

          <div
            class="contentDiv"
            id="drawTheArea"
            :style="mainActive == 5 && 'border:3px solid #ff6600'"
          >
            <v-card style="borderRadius: 20px;" class="pa-8">
              <h3 class="hStyle">Draw the Area</h3>
              <custom-leaflet-map-draw
                @draw="mapDrawn"
                @delete="mapDeleted"
                compX="52.0116"
                compY="4.3571"
                zoomLevel="18"
                :layerType="true"
                ref="leafletMapDrawComp"
              />
            </v-card>
          </div>

          <v-card style="borderRadius: 20px;" class="pa-8">
            <v-text-field
              label="Email"
              type="email"
              prepend-inner-icon="mdi-mail"
              :rules="rules.email"
              v-model="email"
            />
            <v-text-field
              label="Phone"
              prepend-inner-icon="mdi-phone"
              :rules="rules.phone"
              v-model="phone"
            />

            <RequiredFields :message="errorMessage" v-if="errorMessage" />

            <v-btn
              :loading="quoteLoading"
              @click="getQuoteHandler"
              class="mt-4"
              block
              rounded
              color="primary"
              >Request a Quote
            </v-btn>
          </v-card>
          <v-snackbar
            v-model="snackbarValue"
            right
            :color="snackbarSuccess ? 'green' : 'red'"
          >
            {{ snackbarMessage }}
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CustomLeafletMapDraw from "@/components/CustomLeafletMapDraw";
import RequiredFields from "../components/misc/RequiredFields";

export default {
  name: "GetaQuote",
  components: {
    CustomLeafletMapDraw,
    RequiredFields
  },
  data() {
    return {
      serviceLevel: 1,
      buildingCategoryActive: 1,
      type3DModel: 1,
      quality3DModel: 3,
      flightGeoJSON: {},
      email: "",
      phone: "",
      rules: {
        email: [
          v => !!v || "E-mail is required",
          v => /.+@.+/.test(v) || "E-mail must be valid"
        ],
        phone: [v => !!v || "Phone is required"]
      },
      mainActive: 1,
      quoteData: {},
      quoteLoading: false,
      errorMessage: undefined,
      snackbarValue: false,
      snackbarMessage: "Quote has been requested successfully!",
      snackbarSuccess: true
    };
  },
  methods: {
    mapDrawn(flightGeoJSON) {
      this.flightGeoJSON = flightGeoJSON.features[0].geometry;
    },
    mapDeleted() {
      this.flightGeoJSON = {};
    },
    // eslint-disable-next-line no-unused-vars
    onItemChanged(_, currentItem, it) {
      this.mainActive = Number(
        currentItem.getElementsByClassName("number")[0].innerText
      );
    },
    async getQuoteHandler() {
      var re = /\S+@\S+\.\S+/;

      if (!re.test(this.email)) {
        this.errorMessage = "Valid Email is required!";
        return;
      } else if (!this.phone) {
        this.errorMessage = "Phone is required!";
        return;
      } else if (Object.keys(this.flightGeoJSON).length < 1) {
        this.errorMessage = "Drawing on map is required";
        return;
      } else {
        this.errorMessage = undefined;
      }

      try {
        this.quoteLoading = true;
        let uploadArguments = new FormData();

        uploadArguments.append(
          "geojson_string",
          JSON.stringify(this.flightGeoJSON)
        );
        uploadArguments.append("email", this.email);
        uploadArguments.append("phone", this.phone);
        uploadArguments.append("service_level", this.serviceLevel);
        uploadArguments.append("category", this.buildingCategoryActive);
        uploadArguments.append("type_of_3d_model", this.type3DModel);
        uploadArguments.append("quality", this.quality3DModel);

        await this.$http.post("/create-quote", uploadArguments);
        this.quoteLoading = false;
        this.snackbarValue = true;
        this.snackbarMessage = "Quote has been requested successfully!";
        this.snackbarSuccess = true;
        this.serviceLevel = 1;
        this.buildingCategoryActive = 1;
        this.type3DModel = 1;
        this.quality3DModel = 3;
        this.flightGeoJSON = {};
        this.email = "";
        this.phone = "";
        document.getElementById("firstDiv").click();
        this.$refs.leafletMapDrawComp.drawnItems.clearLayers();
        this.$refs.leafletMapDrawComp.drawControlEdit.remove();
        this.$refs.leafletMapDrawComp.drawControlFull.addTo(
          this.$refs.leafletMapDrawComp.map
        );
      } catch (err) {
        console.log(err);
        this.quoteLoading = false;
        this.snackbarValue = true;
        this.snackbarMessage = "Something went wrong! Please Try Again";
        this.snackbarSuccess = false;
      }
    }
  },
  async mounted() {
    try {
      let res = await this.$http.get("/get-quote-options");
      this.quoteData = res.data;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
}

ul li {
  margin-bottom: 25px;
  font-size: 23px;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
}

ul li a {
  display: flex;
  align-items: center;
  color: #ffffff;
}

ul li a span.number {
  height: 40px;
  width: 40px;
  font-size: 16px;
  background-color: rgba(109, 142, 164, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  line-height: 19px;
  color: #6d8ea4;
}

ul li a.is-active {
  color: #ff6600;
}

ul li a.is-active span.number {
  color: #ff6600;
  background-color: #ff660061;
}

.contentDiv {
  border: 1px solid #d9e5ee;
  border-radius: 20px;
  margin-top: 25px;
}

.contentDiv .hStyle {
  font-size: 30px;
  font-family: "Quicksand", sans-serif;
  margin-bottom: 15px;
}

.contentDiv .radioDiv {
  padding: 20px 20px 20px 20px;
  border: 0.5px solid #a6bbca;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  height: 100%;
}

.flexStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconSize {
  font-size: 40px;
}

.divHead {
  font-family: "Quicksand", sans-serif;
}

.divHead div {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.divHead p {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 17px;
}

.buildingDiv {
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 132px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(109, 142, 164, 0.1);
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  padding: 8px;
}

.buildingDiv img {
  width: 60px;
  height: 60px;
}

.buildingDiv span {
  margin-top: 10px;
}

.modelLabel {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 80px;
}
</style>

<template>
  <v-app>
    <v-snackbar v-model="success" color="success" top right
      >Upload Complete</v-snackbar
    >
    <v-container>
      <h1>testing component</h1>
      <v-row justify="center">
        <v-col cols="6">
          <v-autocomplete
            label="Select Asset"
            v-model="selected_asset"
            :items="assets"
            item-text="name"
            item-value="pk"
          ></v-autocomplete>

          <form
            action="/file-upload"
            class="dropzone mt-3 mb-3"
            style="color: black"
          >
            <div class="fallback">
              <input name="file" type="file" multiple />
            </div>
          </form>
          <v-btn color="primary" block @click="upload" v-if="!loading"
            >Upload</v-btn
          >
          <v-progress-linear
            v-model="total_progress"
            height="36"
            rounded
            striped
            v-if="loading"
          >
            <strong>{{ Math.ceil(total_progress) }}%</strong>
          </v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
// import vueDropzone from '../components/vue-dropzone/vue-dropzone'
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import EXIF from "exif-js";

export default {
  mounted() {
    this.$http
      .get("/assets", {
        headers: {
          Authorization: "token " + localStorage.getItem("auth_token")
        }
      })
      .then(res => {
        this.assets = res.data;
      });
    this.initDropzone();
  },
  components: {
    // vueDropzone
  },
  data: () => {
    return {
      url: "https://aer-2-dev.s3.amazonaws.com/",
      signatures: {},
      assets: [],
      selected_asset: null,
      inspection: null,
      drz: null,
      loading: false,
      success: false,
      total_progress: 0
    };
  },
  methods: {
    createInspection: async function() {
      let res = await this.$http.post(
        "/inspections/",
        {
          asset: this.selected_asset,
          date: new Date().toISOString().slice(0, 10)
        },
        {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        }
      );
      this.inspection = res.data;
      return this.inspection;
    },
    initDropzone() {
      let vm = this;
      this.drz = new Dropzone(".dropzone", {
        url: this.url,
        maxFilesize: 10240,
        timeout: 3600000,
        acceptedFiles: "image/jpeg,image/png,image/tiff",
        autoQueue: false,
        parallelUploads: 1000,

        // eslint-disable-next-line no-unused-vars
        params: function(file, xhr) {
          file = file[0];
          console.log(file);
          console.log(vm.signatures[file.name]);
          return vm.signatures[file.name];
        },
        accept: function(file, done) {
          if (vm.loading) {
            done("cant add files while uploading in progress");
          } else {
            console.log("----file: ", file);
            EXIF.getData(file, function() {
              // eslint-disable-next-line
                                let result = EXIF.pretty(this)
              console.log("---exif: ", this);
            });
            done();
          }
        },
        init: function() {
          // eslint-disable-next-line
          this.on("totaluploadprogress",function (uploadProgress,totalBytes,totalBytesSent) {
            vm.total_progress = uploadProgress;
            if (uploadProgress >= 100) {
              vm.success = true;
              vm.loading = false;
              setTimeout(function() {
                vm.$router.push({ name: "Home" });
              }, 2000);
            }
          });
        }
      });
    },
    async upload() {
      this.loading = true;
      await this.createInspection();
      let files = this.drz.getAcceptedFiles();
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let formData = new FormData();
        formData.append("inspection_id", this.inspection.pk);
        formData.append("filename", file.name);
        let res = await this.$http.post(
          "/inspections/get-upload-object",
          formData
        );
        this.signatures[file.name] = res.data.signature;
      }
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        this.drz.enqueueFile(file);
      }
    }
  }
  // raw, processed, att
};
</script>

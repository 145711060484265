<template>
  <div style="marginTop:100px">
    <ErrorComp errorMessage="The url does not exist" />
  </div>
</template>

<script>
import ErrorComp from "../components/misc/ErrorComp";

export default {
  name: "NotFound",
  components: {
    ErrorComp
  },
  metaInfo() {
    return {
      title: this.$store.state.app_title,
      titleTemplate: "%s | NotFound"
    };
  }
};
</script>

<template>
  <v-dialog v-model="modal" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ playerFileName }}</span>
      </v-card-title>

      <v-container>
        <div style="display:flex;alignItems:center;justifyContent:center">
          <video-player
            class="video-player-box"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
          >
          </video-player>
        </div>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import "./video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  name: "VideoPlayerDialog",
  props: [
    "openVideoModal",
    "videoModalData",
    "playerFileName",
    "playerOptions"
  ],
  components: {
    videoPlayer
  },
  data() {
    return {
      modal: false
      // playerOptions: {
      //     language: 'en',
      //     playbackRates: [0.7, 1.0, 1.5, 2.0],
      //     sources: [{
      //       // type: "video/mp4",
      //       src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
      //     }],
      //     poster: "https://backend.buynbulq.com/images/5e988fd024aa9a0007de9d4b-wholesale-maybelline-new-overstock-cosmetic-lots-14.jpeg",
      //   }
    };
  },
  watch: {
    openVideoModal(newValue) {
      this.modal = newValue;
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    }
  },
  methods: {
    closeDialog() {
      this.player.pause();
      // this.player.currentTime(0);
      this.$emit("cancel");
    }
  }
};
</script>

<template>
  <div fluid>
    <v-snackbar v-model="success" color="success" top right>
      Success
    </v-snackbar>
    <v-snackbar v-model="error" color="error" top right :timeout="10000"
      >{{ error_msg }}
    </v-snackbar>
    <!-- <v-dialog
      v-model="assetCreateDialog"
      persistent
      max-width="600px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Create Asset</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name*"
                  prepend-inner-icon="mdi-pencil"
                  v-model="newAssetData.name"
                  :rules="rulesNewAssetData.name"
                  :error-messages="errorsNewAssetData.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Description*"
                  prepend-inner-icon="mdi-pencil"
                  v-model="newAssetData.description"
                  :rules="rulesNewAssetData.description"
                  :error-messages="errorsNewAssetData.description"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <gmap-autocomplete
                  ref="address_autocomplete"
                  class="introInput"
                  placeholder=""
                  @place_changed="setNewAssetDataAddress"
                >
                  <template v-slot:input="slotProps">
                    <v-text-field
                      v-model="newAssetData.address.formatted_address"
                      prepend-inner-icon="mdi-map"
                      :label="newAssetAddressLabel"
                      ref="input"
                      v-on:listeners="slotProps.listeners"
                      v-on:attrs="slotProps.attrs"
                      :placeholder="'Address'"
                      :rules="rulesNewAssetData.address"
                      :error-messages="errorsNewAssetData.address"
                    ></v-text-field>
                  </template>
                </gmap-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="assetCreateDialog = false">
            Close
          </v-btn>
          <v-btn color="primary" text @click="createAsset" :loading="loading">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <CreateAssetDialog
      :openCreateDialog="assetCreateDialog"
      @dialogClose="assetCreateDialog = false"
      @dialogAssetCreationComplete="assetCreationComplete"
      v-if="assetCreateDialog"
    />

    <ShareComponent
      :openShareDialog="assetShareDialog"
      :assetID="shareAssetID"
      :assetName="shareAssetName"
      type="asset"
      @closeShareDialog="assetShareDialog = false"
      v-if="assetShareDialog"
    />

    <Collaborator
      :openCollaboratorDialog="assetCollaboratorDialog"
      :assetID="shareAssetID"
      type="asset"
      @closeCollaborateDialog="assetCollaboratorDialog = false"
      v-if="assetCollaboratorDialog"
    />

    <v-dialog
      v-model="assetEditDialog"
      persistent
      max-width="600px"
      transition="dialog-top-transition"
    >
      <v-form ref="editAssetForm">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Asset</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Name*"
                    prepend-inner-icon="mdi-pencil"
                    v-model="editAssetData.name"
                    :rules="rulesEditAssetData.name"
                    :error-messages="errorsEditAssetData.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Description*"
                    prepend-inner-icon="mdi-pencil"
                    v-model="editAssetData.description"
                    :rules="rulesEditAssetData.description"
                    :error-messages="errorsEditAssetData.description"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <gmap-autocomplete
                    ref="address_autocomplete"
                    class="introInput"
                    placeholder=""
                    @place_changed="setEditAssetDataAddress"
                  >
                    <template v-slot:input="slotProps">
                      <v-text-field
                        v-model="editAssetData.address.formatted_address"
                        prepend-inner-icon="mdi-map"
                        :label="editAssetAddressLabel"
                        ref="input"
                        v-on:listeners="slotProps.listeners"
                        v-on:attrs="slotProps.attrs"
                        :placeholder="'Address'"
                        :rules="rulesEditAssetData.address"
                        :error-messages="errorsEditAssetData.address"
                      ></v-text-field>
                    </template>
                  </gmap-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                $refs.editAssetForm.reset();
                assetEditDialog = false;
              "
            >
              Close
            </v-btn>
            <v-btn color="primary" text @click="editAsset" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model="assetDeleteDialog"
      persistent
      max-width="600px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Delete Asset</span>
        </v-card-title>
        <v-card-text>
          All the surveys, upload and data associated to this asset will be
          deleted. Do you wish to proceed?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="assetDeleteDialog = false"
            v-if="!deleteLoading"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteAsset"
            :loading="deleteLoading"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row no-gutters>
      <v-col xl="3" lg="4" style="height: 100vh; overflow:auto; ">
        <v-container fluid>
          <v-row>
            <v-col>
              <h1>Assets</h1>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="mr-4" align-self="center" cols="3">
              <v-btn
                class="mr-5"
                rounded
                small
                color="primary"
                @click="assetCreateDialog = true"
                >New Asset
              </v-btn>
            </v-col>
          </v-row>

          <v-text-field
            label="Search"
            rounded
            outlined
            dense
            @input="searchHandler"
          ></v-text-field>

          <v-card
            class="mb-2"
            v-for="asset in assets"
            :key="asset.id"
            @click="
              $router.push(
                `/app/asset-details/${asset.properties.name}/timeline/${asset.id}`
              )
            "
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="headline">
                  {{ asset.properties.name }}
                </v-card-title>

                <v-card-subtitle>
                  {{ asset.properties.description }}
                </v-card-subtitle>

                <v-card-subtitle class="topPadding">
                  {{ asset.properties.address }}
                </v-card-subtitle>
              </div>

              <div>
                <v-avatar class="" size="130" tile>
                  <v-img
                    :src="'https://cdn.vuetifyjs.com/images/cards/foster.jpg'"
                  ></v-img>
                </v-avatar>
              </div>
            </div>
            <v-card-actions>
              <v-btn
                class="ml-2 mt-5"
                icon
                @click.stop="selectAssetToEdit(asset)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="ml-2 mt-5"
                icon
                color="error darken-3"
                @click.stop="
                  currentDeleteAsset = asset;
                  assetDeleteDialog = true;
                "
                v-if="asset.properties.can_add_collaborators"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <div style="marginLeft:auto;">
                <v-tooltip bottom v-if="asset.properties.can_add_collaborators">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2 mt-5"
                      icon
                      @click.stop="
                        shareAssetID = asset.id;
                        assetCollaboratorDialog = true;
                      "
                    >
                      <v-icon>mdi-account-multiple-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Collaborate</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ml-1 mt-5"
                      icon
                      @click.stop="
                        shareAssetID = asset.id;
                        shareAssetName = asset.properties.name;
                        assetShareDialog = true;
                      "
                    >
                      <v-icon>mdi-share</v-icon>
                    </v-btn>
                  </template>
                  <span>Share</span>
                </v-tooltip>
              </div>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-col>
      <v-col>
        <l-map
          :center="[30.3753, 69.3451]"
          :zoom="2"
          style="height: 100vh; z-index: 12"
          @update:bounds="updateBounds"
          ref="map"
        >
          <l-control-layers position="topright"></l-control-layers>
          <!-- <l-tile-layer
            :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"
            :attribution="
              '&copy; <a href=\'http://osm.org/copyright\'>OpenStreetMap</a> contributors'
            "
          ></l-tile-layer>

          <l-tile-layer
            :url="'//stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png'"
            :attribution="
              '&copy; <a href=\'http://osm.org/copyright\'>OpenStreetMap</a> contributors'
            "
          ></l-tile-layer> -->
          <l-tile-layer
            v-for="tileProvider in tileProviders"
            :key="tileProvider.name"
            :name="tileProvider.name"
            :visible="tileProvider.visible"
            :url="tileProvider.url"
            :attribution="tileProvider.attribution"
            :subdomains="tileProvider.subdomains"
            layer-type="base"
          />

          <l-geo-json
            v-if="assetsGeoJson"
            :geojson="assetsGeoJson"
            ref="geoJsonLayer"
            :options="geoMapOptions()"
          ></l-geo-json>
        </l-map>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import CreateAssetDialog from "../components/asset/CreateAssetDialog";
import ShareComponent from "../components/ShareComponent";
import Collaborator from "../components/Collaborator";

export default {
  name: "Home",
  components: {
    CreateAssetDialog,
    ShareComponent,
    Collaborator
  },
  metaInfo() {
    return {
      title: this.$store.state.app_title,
      titleTemplate: "%s | Home"
    };
  },

  data: () => ({
    center: latLng(33.41322, 73),
    bounds: null,
    shareAssetID: undefined,
    shareAssetName: undefined,
    assetsGeoJson: false,
    originalAssetsGeoJson: undefined,
    assetsData: [],
    assetCreateDialog: false,
    assetShareDialog: false,
    assetCollaboratorDialog: false,
    newAssetData: {
      description: "",
      name: "",
      address: {
        formatted_address: ""
      }
    },
    errorsNewAssetData: {
      address: "",
      description: "",
      name: ""
    },
    assetToEdit: null,
    assetEditDialog: false,
    assetDeleteDialog: false,
    currentDeleteAsset: null,
    deleteLoading: false,
    editAssetData: {
      id: "",
      description: "",
      name: "",
      address: {
        formatted_address: ""
      }
    },
    editAddressChanged: false,
    errorsEditAssetData: {
      address: "",
      description: "",
      name: ""
    },
    loading: false,
    success: false,
    error: false,
    error_msg: "",
    tileProviders: [
      {
        name: "OpenStreetMap",
        visible: false,
        attribution:
          "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      },
      // {
      //   name: "Toner",
      //   visible: false,
      //   url: "//stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png",
      //   attribution:
      //     'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      // },
      // {
      //   name: "Satellite",
      //   visible: false,
      //   url:
      //     "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      //   attribution:
      //     "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
      // },
      // {
      //   name: "googleSat",
      //   visible: false,
      //   url: "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      //   subdomains: ["mt0", "mt1", "mt2", "mt3"]
      // },
      {
        name: "googleStreets",
        visible: true,
        url: "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      },
      {
        name: "googleHybrid",
        visible: false,
        url: "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      }
      // {
      //   name: "googleTerrain",
      //   visible: false,
      //   url: "http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
      //   subdomains: ["mt0", "mt1", "mt2", "mt3"]
      // }
    ],
    geoMapOptions: function() {
      var self = this;
      return {
        onEachFeature: function(feature, layer) {
          // let popup_content = `<b>Asset Name:</b> ${feature.properties.name}<br>`;
          let popup_content = `<b>${feature.properties.name}</b>`;
          layer.bindPopup(popup_content);
          let foundIndex;
          layer.on("click", function(e) {
            foundIndex = self.assetsData.findIndex(item => {
              return (
                item.geometry.coordinates[1] == e.latlng.lat &&
                item.geometry.coordinates[0] == e.latlng.lng
              );
            });
            // console.log("found index is",foundIndex);
            if (foundIndex != -1) {
              self.$router.push(
                `/app/asset-details/${self.assetsData[foundIndex].properties.name}/timeline/${self.assetsData[foundIndex].id}`
              );
            }
          });
          layer.on("mouseover", function() {
            layer.openPopup();
          });
          layer.on("mouseout", function() {
            layer.closePopup();
          });
        }
      };
    }
  }),
  methods: {
    setNewAssetDataAddress: function(address) {
      this.newAssetData.address = address;
    },
    searchHandler(val) {
      let duplicateArr = [];
      this.originalAssetsGeoJson.forEach(assetGeoJson => {
        let assetProperties = assetGeoJson.properties;
        if (
          assetProperties.name.toLowerCase().includes(val.toLowerCase()) ||
          assetProperties.description
            .toLowerCase()
            .includes(val.toLowerCase()) ||
          assetProperties.address.toLowerCase().includes(val.toLowerCase())
        ) {
          duplicateArr.push(assetGeoJson);
        }
      });
      this.assetsGeoJson.features = duplicateArr;
    },
    setEditAssetDataAddress: function(address) {
      // console.log(address.geometry.location.lat());
      // console.log(address.geometry.location.lng());
      this.editAssetData.address = address;
      this.editAddressChanged = true;
    },
    // createAsset() {

    //   let asset = {
    //     name: this.newAssetData.name,
    //     description: this.newAssetData.description,
    //     address: this.newAssetData.address.formatted_address,
    //     latitude: this.newAssetData.address.geometry.location.lat(),
    //     longitude: this.newAssetData.address.geometry.location.lng()
    //   };
    //   // console.log("----save asset-----", asset);
    //   this.$http
    //     .post("/assets/", asset, {
    //       headers: {
    //         Authorization: "token " + localStorage.getItem("auth_token")
    //       }
    //     })
    //     .then(res => {
    //       this.getAssets();
    //       this.loading = false;
    //       console.log("asset creation complete", res.data);
    //       this.success = true;
    //       this.assetCreateDialog = false;
    //     })
    //     .catch(err => {
    //       // console.log(err.response.data);
    //       this.error = true;
    //       this.loading = false;

    //       this.error_msg = err.response.data[Object.keys(err.response.data)][0];
    //       this.errorsNewAssetData = err.response.data;
    //     });
    // },
    assetCreationComplete() {
      this.getAssets();
      this.success = true;
      this.assetCreateDialog = false;
    },
    selectAssetToEdit(asset) {
      this.assetToEdit = asset;
      console.log(this.assetToEdit);
      this.editAssetData = {
        id: asset.id,
        description: asset.properties.description,
        name: asset.properties.name,
        address: {
          formatted_address: asset.properties.address
        }
      };
      this.assetEditDialog = true;
      this.editAddressChanged = false;
    },
    editAsset() {
      let asset = {
        id: this.editAssetData.id,
        name: this.editAssetData.name,
        description: this.editAssetData.description,
        address: this.editAssetData.address.formatted_address
      };
      if (this.editAddressChanged === true) {
        asset.latitude = this.editAssetData.address.geometry.location.lat();
        asset.longitude = this.editAssetData.address.geometry.location.lng();
      } else {
        console.log(this.assetToEdit.geometry.coordinates[1]);
        asset.latitude = this.assetToEdit.geometry.coordinates[1];
        asset.longitude = this.assetToEdit.geometry.coordinates[0];
      }
      console.log("----save edit asset-----", asset);
      this.$http
        .put("/assets/" + asset.id, asset, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(res => {
          this.getAssets();
          this.loading = false;
          console.log("asset creation complete", res.data);
          this.success = true;
          this.$refs.editAssetForm.reset();
          this.assetEditDialog = false;
        })
        .catch(err => {
          console.log(err.response.data);
          this.error = true;
          this.loading = false;

          this.error_msg = err.response.data[Object.keys(err.response.data)][0];
          this.errorsEditAssetData = err.response.data;
        });
    },
    deleteAsset() {
      this.deleteLoading = true;
      this.$http
        .delete("/assets/" + this.currentDeleteAsset.id, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(() => {
          this.getAssets();
          this.currentDeleteAsset = null;
          this.assetDeleteDialog = false;
          this.deleteLoading = false;
          this.success = true;
        })
        .catch(() => {
          this.deleteLoading = false;
          this.error = true;
          this.error_msg = "Something went wrong";
        });
    },
    async getAssets() {
      let res = await this.$http.get("/assets/", {
        headers: {
          Authorization: "token " + localStorage.getItem("auth_token")
        }
      });

      this.assetsGeoJson = res.data;
      this.originalAssetsGeoJson = [...res.data.features];
      this.assetsData = res.data.features;

      if (res.data.features.length > 0) {
        this.$nextTick(() => {
          // console.log(this.$refs.geoJsonLayer.getGeoJSONData());
          this.$refs.map.fitBounds(this.$refs.geoJsonLayer.getBounds());
        });
      }
    },
    updateBounds(bounds) {
      // console.log("----bounds---: ", bounds);
      this.bounds = bounds;
    }
  },
  computed: {
    newAssetAddressLabel: function() {
      if (this.newAssetData.address.formatted_address === "") {
        return "";
      } else {
        return "Address";
      }
    },
    rulesNewAssetData: function() {
      return {
        address: [v => !!v || "Address is required"],
        description: [v => !!v || "Description is required"],
        name: [v => !!v || "Name is required"]
      };
    },
    editAssetAddressLabel: function() {
      if (this.editAssetData.address.formatted_address === "") {
        return "";
      } else {
        return "Address";
      }
    },
    rulesEditAssetData: function() {
      return {
        address: [v => !!v || "Address is required"],
        description: [v => !!v || "Description is required"],
        name: [v => !!v || "Name is required"]
      };
    },
    assets: function() {
      if (this.assetsGeoJson) {
        if (this.bounds) {
          let assetsToReturn = [];

          this.assetsGeoJson.features.forEach(assetGeoJson => {
            // console.log(assetGeoJson);
            let cords = new latLng(
              assetGeoJson.geometry.coordinates[1],
              assetGeoJson.geometry.coordinates[0]
            );
            if (this.bounds.contains(cords)) {
              assetsToReturn.push(assetGeoJson);
            }
          });
          // some test added
          return assetsToReturn;
        } else {
          return this.assetsGeoJson.features;
        }
      } else {
        return [];
      }
    }
  },
  async mounted() {
    await this.getAssets();
  }
};
</script>

<style>
.topPadding {
  padding-top: 0px;
  margin-top: -8px;
}
</style>

<template>
  <!--  <v-tab-item>-->

  <v-row no-gutters>
    <ShareComponent
      :openShareDialog="inspectionShareDialog"
      :assetID="$route.params.assetID"
      :inspectionID="inspectionShareID"
      :assetName="$route.params.assetName"
      :type="shareType"
      @closeShareDialog="inspectionShareDialog = false"
      v-if="inspectionShareDialog"
    />

    <Collaborator
      :openCollaboratorDialog="assetCollaboratorDialog"
      :assetID="$route.params.assetID"
      :inspectionID="inspectionShareID"
      :type="shareType"
      @closeCollaborateDialog="assetCollaboratorDialog = false"
      v-if="assetCollaboratorDialog"
    />

    <v-tooltip bottom v-if="!hideTimeline && showArrow">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="() => hideTimeLineHandler(true)"
          style="position:absolute;left:34%;marginTop:-43px;zIndex:100;"
        >
          <v-icon large>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <span>Shrink</span>
    </v-tooltip>

    <v-tooltip bottom v-if="hideTimeline && showArrow">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="() => hideTimeLineHandler(false)"
          style="position:absolute;marginTop:-43px;zIndex:100;"
        >
          <v-icon large>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
      <span>Expand</span>
    </v-tooltip>

    <!-- <div
      style="display:flex;flexFlow:row-reverse"
    >

    <v-btn
      color="primary"
      class="ma-2 white--text"
      @click="
        $router.push({
          path: '/app/upload-data',
          query: { assetDataID: $route.params.assetID }
        })
      "
      style="position:absolute;top:88px;zIndex:100;"
      v-if="!hideTimeline"
    >
      Upload
      <v-icon right dark>
        mdi-cloud-upload
      </v-icon>
    </v-btn>

    </div> -->

    <v-col
      xl="3"
      lg="4"
      md="5"
      :style="
        hideTimeline
          ? 'height: 100vh; overflow:auto;display:none;paddingTop:20px;'
          : 'height: 100vh; overflow:auto;marginTop:2px;paddingTop:20px;'
      "
    >
      <v-container fluid>
        <div
          style="position:relative;marginBottom:20px;"
          v-if="!$store.state.isShared"
        >
          <v-btn
            color="primary"
            class="ma-2 white--text"
            x-small
            @click="
              $router.push({
                path: '/app/upload-data',
                query: { assetDataID: $route.params.assetID }
              })
            "
            style="position:absolute;top:-27px;zIndex:1;left:0"
            v-if="!hideTimeline"
          >
            New Survey
            <v-icon right dark>
              mdi-cloud-upload
            </v-icon>
          </v-btn>

          <v-btn
            color="primary"
            class="ma-2 white--text"
            x-small
            @click="
              $router.push({
                path: '/app/create-survey',
                query: { assetDataID: $route.params.assetID }
              })
            "
            style="position:absolute;top:-27px;zIndex:100;right:90px"
            v-if="!hideTimeline"
          >
            Request Survey
            <v-icon right dark>
              mdi-cloud-upload
            </v-icon>
          </v-btn>
        </div>

        <v-timeline reverse dense>
          <Loading v-if="loading" />

          <span
            class="timelineDataResult"
            style="paddingRight:52px;"
            v-else-if="asset_data_details.length < 1"
          >
            No Data Added. Please Upload Data!
          </span>

          <v-timeline-item
            v-else
            v-for="(data_item, index) in asset_data_details"
            :key="data_item.pk"
            small
            :color="data_item.source == 'flight' ? 'green' : 'orange'"
          >
            <v-card
              :style="
                $route.params.inspectionID == data_item.pk &&
                  'border:1px solid white'
              "
              @click="
                $router.push(
                  `${navPath}/data-point/${data_item.pk}${
                    $store.state.isShared ? '?uuid=' + $route.query.uuid : ''
                  }`
                )
              "
            >
              <!-- @click="select_item(data_item)" -->
              <!-- :to="{ name: 'Asset Data Point' }" -->
              <div class="d-flex flex-no-wrap justify-space-between">
                <!-- <v-avatar class="ma-3" size="100" tile>
                  <v-img :src="data_item.img"></v-img>
                </v-avatar> -->
                <div>
                  <v-card-actions>
                    <v-chip
                      :color="
                        data_item.source == 'flight'
                          ? 'green'
                          : data_item.source == 'skydio'
                          ? '#07e'
                          : 'orange'
                      "
                    >
                      {{
                        data_item.source == "flight"
                          ? "survey"
                          : data_item.source
                      }}
                    </v-chip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                          icon
                          @click.stop="
                            inspectionShareID = data_item.pk;
                            inspectionShareDialog = true;
                            shareType = 'inspection';
                          "
                          v-if="!$store.state.isShared"
                        >
                          <v-icon>mdi-share</v-icon>
                        </v-btn>
                      </template>
                      <span>Share</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                      v-if="
                        data_item.can_add_collaborators &&
                          !$store.state.isShared
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2"
                          icon
                          @click.stop="
                            inspectionShareID = data_item.pk;
                            shareType = 'inspection';
                            assetCollaboratorDialog = true;
                          "
                        >
                          <v-icon>mdi-account-multiple-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Collaborate</span>
                    </v-tooltip>
                  </v-card-actions>
                  <v-card-title
                    class="headline"
                    v-text="data_item.name"
                  ></v-card-title>
                  <v-card-subtitle
                    style="color:rgba(255, 255, 255, 0.7);width:210px"
                    v-text="data_item.description"
                  ></v-card-subtitle>
                  <v-card-title
                    class="headline"
                    v-text="data_item.date"
                  ></v-card-title>
                </div>
                <div
                  style="display: flex;
                        flexFlow: column;
                        justifyContent: space-between;
                        alignItems: center;"
                >
                  <!-- <v-btn
                    fab
                    small
                    @click="
                      $router.push(`/app/edit-upload-data/${data_item.pk}`)
                    "
                    v-if="data_item.source != 'flight'"
                  >
                    <v-icon dark color="white">mdi-pencil</v-icon>
                  </v-btn> -->
                  <v-btn
                    small
                    icon
                    @click="
                      $router.push(`/app/edit-upload-data/${data_item.pk}`)
                    "
                    v-if="
                      data_item.source != 'flight' && !$store.state.isShared
                    "
                  >
                    <v-icon dark color="white">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="mr-5 mt-2"
                    small
                    color="primary"
                    @click="$router.push(`/app/surveys/${data_item.flight_id}`)"
                    v-else-if="!$store.state.isShared"
                    >View
                  </v-btn>
                  <v-btn
                    small
                    icon
                    @click="
                      e => {
                        e.stopPropagation();
                        inspectionDeleteID = data_item.pk;
                        inspectionDeleteIndex = index;
                        inspectionDeleteDialog = true;
                      }
                    "
                    v-if="
                      !$store.state.isShared && data_item.can_add_collaborators
                    "
                  >
                    <v-icon dark color="error darken-3">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-container>
    </v-col>
    <v-col>
      <div v-if="!$route.params.inspectionID">
        <v-card>
          <div
            style="display:flex;justifyContent: space-between;alignItems: center;"
          >
            <v-card-title>
              <span>{{ assetMountDetails.name }}</span>
            </v-card-title>

            <div>
              <v-btn
                color="primary"
                class="white--text mr-2"
                small
                @click.stop="
                  shareType = 'asset';
                  assetCollaboratorDialog = true;
                "
              >
                Collaborate
                <v-icon right dark>
                  mdi-account-multiple-plus
                </v-icon>
              </v-btn>

              <v-btn
                color="primary"
                class="white--text"
                small
                @click.stop="
                  shareType = 'asset';
                  inspectionShareDialog = true;
                "
              >
                Share
                <v-icon right dark>
                  mdi-share
                </v-icon>
              </v-btn>
            </div>
          </div>
          <v-container>
            <v-row>
              <v-col xl="6" lg="6" md="6">
                <div
                  id="assetMap"
                  style="z-index: 2;width: 100%; height: 50vh;"
                ></div>
                <v-card-subtitle class="mt-2">
                  <span>ADDRESS:</span>
                </v-card-subtitle>
                <v-card-text
                  style="color:rgba(255, 255, 255, 0.7);marginLeft:15px"
                >
                  {{ assetMountDetails.address }}
                </v-card-text>

                <v-card-subtitle class="mt-2">
                  <span>DESCRIPTION:</span>
                </v-card-subtitle>
                <v-card-text
                  style="color:rgba(255, 255, 255, 0.7);marginLeft:15px"
                >
                  {{ assetMountDetails.description }}
                </v-card-text>
              </v-col>

              <v-col xl="6" lg="6" md="6">
                <v-card-subtitle class="mt-2">
                  <span>DETAILS:</span>
                </v-card-subtitle>

                <v-simple-table style="width:100%;">
                  <thead>
                    <tr>
                      <th>
                        NAME
                      </th>
                      <th>
                        AMOUNT
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, keyVal) in assetMountTable" :key="keyVal">
                      <td style="textTransform:capitalize;">
                        {{ keyVal.replaceAll("_", " ") }}
                      </td>
                      <td>
                        {{ item }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
      <router-view :colNumber="colNumber" @loadComp="val => (showArrow = val)">
      </router-view>
    </v-col>
    <v-snackbar v-model="snackbar" right :color="success ? 'green' : 'red'">
      {{ snackbarMessage }}
    </v-snackbar>
    <v-dialog
      v-model="inspectionDeleteDialog"
      persistent
      max-width="600px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Delete Inspection</span>
        </v-card-title>
        <v-card-text>
          All the data associated to this inspection will be deleted. Do you
          wish to proceed?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="inspectionDeleteDialog = false"
            v-if="!inspectionDeleteLoading"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteInspection"
            :loading="inspectionDeleteLoading"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <!--  </v-tab-item>-->
</template>
<script>
import Loading from "../../components/misc/Loading";
import ShareComponent from "../../components/ShareComponent";
import Collaborator from "../../components/Collaborator";

import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";

import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
// const color_tag_mapping = {
//   image: "orange",
//   DTM: "green",
//   DSM: "blue",
//   Orthophoto: "yellow darken-1",
//   "Point Cloud": "gray",
//   "3D Model": "purple"
// };
export default {
  metaInfo() {
    return {
      title: this.$store.state.app_title,
      titleTemplate: "%s | Inspections"
    };
  },
  name: "Timeline",
  props: ["assetID"],
  components: {
    Loading,
    ShareComponent,
    Collaborator
  },
  data: () => ({
    assetCollaboratorDialog: false,
    shareType: undefined,
    navPath: "",
    openSkydioModal: false,
    inspectionShareDialog: false,
    inspectionShareID: undefined,
    loading: true,
    selected_data_item: null,
    dateDialog: false,
    dateMin: new Date().toISOString(),
    date: "",
    selectedDateID: null,
    pageAssetID: null,
    selectedIndex: null,
    primary: "",
    snackbar: false,
    success: false,
    snackbarMessage: "",
    hideTimeline: false,
    inspectionDeleteDialog: false,
    inspectionDeleteLoading: false,
    inspectionDeleteID: "",
    inspectionDeleteIndex: "",
    colNumber: 4,
    showArrow: false,
    assetMountDetails: {},
    assetMountTable: {}
  }),

  methods: {
    select_item(data_item) {
      console.log(data_item);
    },
    hideTimeLineHandler(val) {
      if (val) {
        this.colNumber = 3;
        this.$store.commit("changeShowMainMenu", false);
      } else {
        this.colNumber = 4;
        this.$store.commit("changeShowMainMenu", true);
      }

      this.hideTimeline = val;
      let b = document.getElementsByClassName("v-slide-group__wrapper");
      let g = document.getElementsByClassName("v-tabs-slider-wrapper");
      let full = b[1].clientWidth;
      let left = g[1].style.left.split("px");
      let subt = full - left[0];

      // this.$nextTick(() => {
      //   let x = document.getElementsByClassName("v-slide-group__wrapper");
      //   let y = document.getElementsByClassName("v-tabs-slider-wrapper");
      //   y[1].style.left = `${x[1].clientWidth - subt}px`;
      // });

      this.$nextTick(() => {
        let x = document.getElementsByClassName("v-slide-group__wrapper");
        let y = document.getElementsByClassName("v-tabs-slider-wrapper");

        let value;

        if (val) {
          value = x[1].clientWidth - subt + 56;
        } else {
          value = x[1].clientWidth - subt - 37;
        }

        setTimeout(() => {
          y[1].style.left = `${value}px`;
        }, 100);
      });
    },
    async getInspections() {
      this.loading = true;
      try {
        let res = await this.$http.get(
          `asset/${this.assetID}/inspections${
            this.$store.state.isShared ? "?uuid=" + this.$route.query.uuid : ""
          }`,
          {
            ...(localStorage.getItem("auth_token") && {
              headers: {
                Authorization: "token " + localStorage.getItem("auth_token")
              }
            })
          }
        );
        this.loading = false;

        this.asset_data_details = res.data;
      } catch (err) {
        console.log(err);
      }
    },
    deleteInspection() {
      this.inspectionDeleteLoading = true;
      this.$http
        .delete(`/inspection/${this.inspectionDeleteID}/delete/`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(() => {
          this.asset_data_details.splice(this.inspectionDeleteIndex, 1);
          this.inspectionDeleteDialog = false;
          this.inspectionDeleteLoading = false;
          this.inspectionDeleteID = null;
          this.inspectionDeleteIndex = null;
          this.success = true;
          this.snackbar = true;
          this.snackbarMessage = "Inspection has been removed successfully";
        })
        .catch(() => {
          this.inspectionDeleteLoading = false;
          this.success = false;
          this.snackbar = true;
          this.snackbarMessage = "Something went wrong";
        });
    },
    loadMap() {
      let mapboxStreetUrl =
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
      let mapboxStreetAttribution =
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';
      var streets = L.tileLayer(mapboxStreetUrl, {
          id: "MapID",
          tileSize: 512,
          zoomOffset: -1,
          attribution: mapboxStreetAttribution
        }),
        googleStreets = L.tileLayer(
          "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
          {
            maxZoom: 20,
            subdomains: ["mt0", "mt1", "mt2", "mt3"]
          }
        ),
        googleHybrid = L.tileLayer(
          "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
          {
            maxZoom: 20,
            subdomains: ["mt0", "mt1", "mt2", "mt3"]
          }
        );

      let map = L.map("assetMap", {
        center: [52.0116, 4.3571],
        zoom: 18,
        layers: [googleHybrid],
        gestureHandling: true
      });

      var baseMaps = {
        streets: streets,
        googleStreets,
        googleHybrid
      };

      L.control.layers(baseMaps).addTo(map);
      let points = JSON.parse(this.assetMountDetails.location);
      L.marker([points.coordinates[1], points.coordinates[0]]).addTo(map);
      // map.flyTo(new L.LatLng(points.coordinates[1], points.coordinates[0]), 18);
      map.fitBounds([[points.coordinates[1], points.coordinates[0]]]);
    }
  },
  async mounted() {
    this.navPath = `${
      this.$store.state.isShared ? "/shared" : "/app/asset-details"
    }/${this.$router.currentRoute.params.assetName}/timeline/${
      this.$router.currentRoute.params.assetID
    }`;

    await this.getInspections();
    if (!this.$route.params.inspectionID) {
      try {
        let res = await this.$http.get(
          `asset/${this.$route.params.assetID}/overview`,
          {
            ...(localStorage.getItem("auth_token") && {
              headers: {
                Authorization: "token " + localStorage.getItem("auth_token")
              }
            })
          }
        );

        this.assetMountDetails = { ...res.data };
        console.log("assetMount details are ", this.assetMountDetails);

        delete res.data.name;
        delete res.data.description;
        delete res.data.address;
        delete res.data.location;

        this.assetMountTable = res.data;
      } catch (err) {
        console.log(err);
      }

      this.loadMap();
    }
  }
};
</script>

<style scoped>
.timelineDataResult {
  margin-top: 50px;
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  display: block;
  text-align: center;
}

.v-application--is-ltr
  .v-timeline--reverse.v-timeline--dense
  .v-timeline-item
  .v-timeline-item__body
  > .v-card:before,
.v-application--is-ltr
  .v-timeline--reverse.v-timeline--dense
  .v-timeline-item
  .v-timeline-item__body
  .v-card:after {
  display: none;
}

.v-timeline:before {
  bottom: 0;
  content: "";
  height: 100vh;
  position: absolute;
  top: 0;
  width: 2px;
}

.headline {
  line-height: revert;
  font-size: 17px !important;
}

.v-card__subtitle,
.v-card__title {
  padding-top: 7px;
  padding-bottom: 10px;
}
</style>

<template>
  <div>
    <div>
      <div
        id="map"
        :style="{
          height: mapheight,
          zIndex: 1,
          ...(isTransparent && { background: 'transparent' })
        }"
      ></div>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-speed-dial
        v-if="!$store.state.isShared"
        v-model="createFeatureMenu"
        top
        right
        direction="bottom"
        transition="slide-y-reverse-transition"
        style="position: absolute"
        class="ml-4 mt-10 button-add-observations"
      >
        <template v-slot:activator>
          <v-btn
            v-model="createFeatureMenu"
            color="blue darken-2"
            dark
            fab
            small
          >
            <v-icon v-if="createFeatureMenu">mdi-close</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="green"
              v-on="on"
              @click="createFeatureOnImage('Marker')"
            >
              <v-icon>mdi-map-marker-outline</v-icon>
            </v-btn>
          </template>
          <span>Add marker</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="green"
              v-on="on"
              @click="createFeatureOnImage('Line')"
            >
              <v-icon>mdi-call-made</v-icon>
            </v-btn>
          </template>
          <span>Add Line</span>
        </v-tooltip>
        <!-- <v-tooltip left>
                    <template v-slot:activator="{ on }">
                    <v-btn
                        fab
                        dark
                        small
                        color="green"
                        v-on="on"
                        @click="createFeatureOnImage('Circle')"
                    >
                        <v-icon>mdi-shape-circle-plus</v-icon>
                    </v-btn>
                    </template>
                    <span>Add Circle</span>
                </v-tooltip> -->
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="green"
              v-on="on"
              @click="createFeatureOnImage('Polygon')"
            >
              <v-icon>mdi-shape-polygon-plus</v-icon>
            </v-btn>
          </template>
          <span>Add polygon</span>
        </v-tooltip>
      </v-speed-dial>
      <button
        v-if="imageIndex > 0"
        type="button"
        title="Previous"
        class="vue-lb-arrow vue-lb-left"
        :style="[
          defaultWidth != '100%' ? { zIndex: 2, top: '30%' } : { zIndex: 2 }
        ]"
        @click="
          showDraggable = false;
          $emit('imageChangeClicked', 'previous');
        "
      >
        <span>
          <svg
            fill="black"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 512 512"
          >
            <path
              d="M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9 c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8 L213.7,256z"
            ></path>
          </svg>
        </span>
      </button>
      <button
        v-if="imageIndex != arrayLength - 1"
        type="button"
        title="Next"
        class="vue-lb-arrow vue-lb-right"
        :style="[
          defaultWidth != '100%' ? { zIndex: 2, top: '30%' } : { zIndex: 2 }
        ]"
        style="zIndex:2;"
        @click="
          showDraggable = false;
          $emit('imageChangeClicked', 'next');
        "
      >
        <span>
          <svg
            fill="black"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 512 512"
          >
            <path
              d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z"
            ></path>
          </svg>
        </span>
      </button>

      <div
        v-drag
        class="draggableComp"
        :style="{ top: draggableDialogTop, left: draggableDialogLeft }"
        v-if="showDraggable"
      >
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ editableName }}
              <v-btn
                fab
                small
                @click="
                  annotatorSaveDialogEditing = true;
                  saveModal = true;
                "
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </span>
          </v-card-title>
          <v-card-subtitle>
            {{ editableDescription }}
          </v-card-subtitle>
          <v-card-subtitle class="topPadding">
            Category: {{ editableCategory }}
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <div style="textAlign:center;" v-if="editableIconShow">
                <v-btn
                  small
                  color="primary"
                  class="mr-5"
                  @click="finishEditing"
                >
                  Finish Editing
                </v-btn>
                <v-btn small color="primary" @click="cancelEditing">
                  Cancel Editing
                </v-btn>
              </div>
              <div style="textAlign:center;" v-else-if="draggableIconShow">
                <v-btn
                  small
                  color="primary"
                  class="mr-5"
                  @click="finishDraggable"
                >
                  Finish Dragging
                </v-btn>
                <v-btn small color="primary" @click="cancelDraggable">
                  Cancel Dragging
                </v-btn>
              </div>
              <v-simple-table v-else>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Process</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="editable_drawn_by == 'Human'">
                      <td>Drag</td>
                      <td>
                        <v-btn class="mr-1" fab small @click="draggableClicked">
                          <v-icon>mdi-drag-variant</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr v-if="editable_drawn_by == 'Human'">
                      <td>Edit</td>
                      <td>
                        <v-btn class="mr-1" fab small @click="editableClicked">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td>Remove</td>
                      <td>
                        <v-btn
                          class="mr-1"
                          fab
                          small
                          @click="
                            removeModal = true;
                            showDraggable = false;
                          "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- <v-row>

            <v-col cols="12">
              Are you Sure, you want to remove this observation?
            </v-col>

          </v-row> -->
            </v-container>
          </v-card-text>
          <v-card-actions v-if="!editableIconShow">
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="draggableDialogCloseHandler"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <AnnotatorSaveDialog
      :saveModal="saveModal"
      :editing="annotatorSaveDialogEditing"
      :namer="editableName"
      :descriptionr="editableDescription"
      :categoryr="editableCategory"
      @cancel="cancelNewObservation"
      @save="props => saveNewObservation(props, props.type)"
    />
    <AnnotatorRemoveDialog
      :removeModal="removeModal"
      @cancel="
        removeModal = false;
        showDraggable = true;
      "
      @remove="removeObservation"
    />
    <v-snackbar v-model="snackbar" right :color="snackbarColor">
      {{ snackbarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import * as L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import AnnotatorSaveDialog from "./AnnotatorSaveDialog";
import AnnotatorRemoveDialog from "./AnnotatorRemoveDialog";

export default {
  name: "AnnotatorDialogMainComp",
  props: [
    "imageID",
    "imageURL",
    "imageIndex",
    "arrayLength",
    "mapheight",
    "defaultWidth",
    "isTransparent"
  ],
  components: {
    AnnotatorSaveDialog,
    AnnotatorRemoveDialog
  },
  watch: {
    imageURL() {
      if (this.mainMap) {
        this.mainMap.remove();
        this.mainMap = null;
      }

      this.getImageWidthHeight(this.imageURL, (width, height) => {
        if (this.defaultWidth == "100%") {
          this.loadCamera(width, height);
        } else {
          // var browserWidth =
          //   window.innerWidth ||
          //   document.documentElement.clientWidth ||
          //   document.body.clientWidth;
          // browserWidth /= 2;
          let mapDivWidth = document.getElementById("map").clientWidth;
          let ratio = width / height;
          this.loadCamera(
            Math.round(mapDivWidth),
            Math.round(mapDivWidth) / ratio
          );
        }
      });
    }
  },
  data() {
    return {
      mainMap: null,
      overlay: false,
      createFeatureMenu: false,
      newObservation: L.layerGroup(),
      newObservationGeojson: {},
      existingObservations: L.layerGroup(),
      saveModal: false,
      removeModal: false,
      removeID: "",
      editableName: "",
      editableDescription: "",
      editableCategory: "",
      editable_drawn_by: "",
      annotatorSaveDialogEditing: false,
      snackbar: false,
      snackbarMessage: "",
      draggableDialogTop: "0px",
      draggableDialogLeft: "0px",
      showDraggable: false,
      editableLayer: null,
      editableIconShow: false,
      draggableIconShow: false,
      snackbarColor: "green",
      overlay_layer: null,
      editableSelectedLayer: null
    };
  },
  methods: {
    getImageWidthHeight(url, callback) {
      this.overlay = true;
      var img = new Image();
      img.src = url;
      img.onload = function() {
        callback(this.width, this.height);
      };
    },
    draggableDialogCloseHandler() {
      if (this.editableSelectedLayer.feature.geometry.type != "Point") {
        if (
          this.editableSelectedLayer.feature.properties.drawn_by == "Machine"
        ) {
          this.editableSelectedLayer.setStyle({ color: "#e21e1e" });
        } else {
          this.editableSelectedLayer.setStyle({ color: "#4992f7" });
        }
      }
      this.showDraggable = false;
    },
    loadCamera(width, height) {
      this.existingObservations.clearLayers();

      if (this.mainMap) {
        this.mainMap.remove();
      }

      let mainMap = L.map("map", {
        zoom: 1,
        center: [0, 0],
        zoomControl: false,
        attributionControl: false,
        crs: L.CRS.Simple,
        minZoom: 1,
        maxZoom: 4
      });

      this.mainMap = mainMap;

      this.mainMap.createPane("existingObservation");

      const mapDiv = document.getElementById("map");
      const resizeObserver = new ResizeObserver(() => {
        this.mainMap.invalidateSize();

        // if (this.mainMap) {
        //     this.mainMap.remove();
        //     this.mainMap = null;
        //   }
        // this.getImageWidthHeight(this.imageURL, (width, height) => {
        //   if (this.defaultWidth == "100%") {
        //     this.loadCamera(width, height);
        //   } else {
        //     var browserWidth = window.innerWidth
        //     || document.documentElement.clientWidth
        //     || document.body.clientWidth;
        //     browserWidth /= 2;
        //     let mapDivWidth = document.getElementById("map").clientWidth;
        //     console.log("map div width is ",mapDivWidth)
        //     console.log("I am inside very the change imageuri ",browserWidth)
        //     let ratio = width / height;
        //     this.loadCamera( Math.round(mapDivWidth), Math.round(mapDivWidth) / ratio);
        //   }
        // });
      });
      resizeObserver.observe(mapDiv);
      this.existingObservations.addTo(this.mainMap);

      L.control
        .zoom({
          position: "bottomright"
        })
        .addTo(this.mainMap);

      let w = width,
        h = height;

      let southWest = this.mainMap.unproject(
        [0, h],
        this.mainMap.getMaxZoom() - 1
      );
      let northEast = this.mainMap.unproject(
        [w, 0],
        this.mainMap.getMaxZoom() - 1
      );
      let bounds = new L.LatLngBounds(southWest, northEast);

      // let bounds = new L.LatLngBounds([0, 0], [h / 10, w / 10]);

      let overlay_layer = L.imageOverlay(this.imageURL, bounds);

      this.overlay_layer = overlay_layer;
      this.overlay_layer.addTo(this.mainMap);
      this.overlay_layer.on("load", () => {
        this.overlay = false;
        this.firstTime = false;
        this.loadImageObservations();
      });

      this.mainMap.on("pm:create", e => {
        let layer = e.layer;
        layer.options.pane = "newObservation";

        this.newObservation = layer;

        this.mainMap.pm.disableDraw(e.shape);
        this.annotatorSaveDialogEditing = false;
        this.saveModal = true;

        let geo = this.generateGeoJson();

        let overlayTest = this.overlay_layer._image;
        let yR = overlayTest.clientHeight / overlayTest.naturalHeight;
        let xR = overlayTest.clientWidth / overlayTest.naturalWidth;
        let needThing = geo.features[0].geometry;
        let modifiedCoordinates = [],
          x,
          y,
          first;
        if (needThing.type == "Point") {
          first = this.mainMap.project(needThing.coordinates);
          let modifiedX = first.y * -1;
          let modifiedY = first.x * -1;
          x = modifiedX / xR;
          y = modifiedY / yR;
          modifiedCoordinates.push(x);
          modifiedCoordinates.push(y);
          geo.features[0].geometry.coordinates = modifiedCoordinates;
        } else if (needThing.type == "LineString") {
          for (let i of needThing.coordinates) {
            first = this.mainMap.project(i);
            let modifiedX = first.y * -1;
            let modifiedY = first.x * -1;
            x = modifiedX / xR;
            y = modifiedY / yR;
            modifiedCoordinates.push([x, y]);
          }
          geo.features[0].geometry.coordinates = modifiedCoordinates;
        } else if (needThing.type == "Polygon") {
          for (let i of needThing.coordinates[0]) {
            first = this.mainMap.project(i);
            let modifiedX = first.y * -1;
            let modifiedY = first.x * -1;
            x = modifiedX / xR;
            y = modifiedY / yR;
            modifiedCoordinates.push([x, y]);
          }
          geo.features[0].geometry.coordinates[0] = modifiedCoordinates;
        }

        this.newObservationGeojson = geo;
      });

      this.mainMap.setMaxBounds(bounds);
      this.mainMap.fitBounds(bounds);
      this.mainMap.invalidateSize();
      this.$emit("loadedComplete");
    },
    loadImageObservations() {
      this.$http
        .get(`/get-annotations/${this.imageID}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(
          response => {
            let inspection_geo = { ...response.data };

            this.existingObservations.clearLayers();
            this.mainMap.removeLayer(this.newObservation);

            let needFor = inspection_geo.features;
            let otherCoordinates = [],
              num = 0,
              todo,
              test;
            let overlayTest = this.overlay_layer._image;
            let yR = overlayTest.clientHeight / overlayTest.naturalHeight;
            let xR = overlayTest.clientWidth / overlayTest.naturalWidth;
            for (let i of needFor) {
              if (i.geometry.type == "Point") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                test = this.mainMap.unproject([-todo[1], -todo[0]]);
                otherCoordinates.push(test.lat * xR);
                otherCoordinates.push(test.lng * yR);
                inspection_geo.features[
                  num
                ].geometry.coordinates = otherCoordinates;
              } else if (i.geometry.type == "LineString") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                for (let j of todo) {
                  test = this.mainMap.unproject([-j[1], -j[0]]);

                  otherCoordinates.push([test.lat * xR, test.lng * yR]);
                }
                inspection_geo.features[
                  num
                ].geometry.coordinates = otherCoordinates;
              } else if (i.geometry.type == "Polygon") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                let subNum = 0;
                for (let j of todo) {
                  for (let k of j) {
                    // console.log("checking this ",[-k[0],-k[1]]);
                    test = this.mainMap.unproject([-k[1], -k[0]]);
                    // console.log("test is ",test);
                    otherCoordinates.push([test.lat * xR, test.lng * yR]);
                  }
                  inspection_geo.features[num].geometry.coordinates[
                    subNum
                  ] = otherCoordinates;
                  subNum++;
                }
                // for(let j of todo[0]){
                //   console.log("checking this ",[-j[0],-j[1]]);
                //   test = this.mainMap.unproject([-j[1],-j[0]]);
                //   console.log("test is ",test);
                //   otherCoordinates.push([test.lat*xR,test.lng*yR]);
                // }
              }
              num++;
            }

            if (inspection_geo.features.length > 0) {
              let geo = L.geoJSON(inspection_geo, {
                pane: "existingObservation",
                pmIgnore: false,
                onEachFeature: (feature, layer) => {
                  // let popup_content = "";
                  // for (let key in feature.properties) {
                  //   if (["name", "severity", "status", "note"].includes(key)) {
                  //     let value = feature.properties[key];
                  //     popup_content =
                  //       popup_content + `<b>${key}:</b> ${value}<br>`;
                  //   }
                  // }
                  if (layer.feature.properties.drawn_by == "Machine") {
                    layer.setStyle({ color: "#e21e1e" });
                    layer.bindTooltip(
                      `${layer.feature.properties.confidence} %`,
                      { permanent: true, offset: [0, 0] }
                    );
                  }
                  if (!this.$store.state.isShared) {
                    layer.on("click", e => {
                      if (layer.feature.geometry.type != "Point") {
                        layer.setStyle({ color: "green" });
                      }
                      this.editableSelectedLayer = layer;
                      if (this.showDraggable && this.editableIconShow) {
                        this.snackbar = true;
                        this.snackbarMessage =
                          "Please Complete Previous Editing First!";
                        this.snackbarColor = "red";
                      } else {
                        this.removeID = e.target.feature.properties.pk;
                        this.editableName = e.target.feature.properties.name;
                        this.editable_drawn_by =
                          e.target.feature.properties.drawn_by;
                        this.editableDescription =
                          e.target.feature.properties.description;
                        this.editableCategory =
                          e.target.feature.properties.category;
                        // let rectangleWidth = e.target.getBounds().getEast() - e.target.getBounds().getWest();
                        // let rectangleHeight = e.target.getBounds().getNorth() - e.target.getBounds().getSouth();
                        // console.log("rectangle width is ",rectangleWidth);
                        // console.log("rectangle Height is ",rectangleHeight);
                        this.draggableDialogTop = `${e.originalEvent.clientY}px`;
                        this.draggableDialogLeft = `${e.originalEvent.clientX}px`;
                        // this.removeModal = true;
                        this.newObservationGeojson = {
                          features: [
                            {
                              ...e.target.feature
                            }
                          ],
                          type: "FeatureCollection"
                        };
                        this.showDraggable = true;
                        // layer.pm.enable({
                        //   allowSelfIntersection: false,
                        // });
                        this.editableLayer = layer;
                      }
                    });
                  }

                  // layer.bindPopup(popup_content);
                }
              });
              this.existingObservations.addLayer(geo);
            }
            // this.$emit('loadedComplete')
          },
          error => {
            console.log("error", error);
            throw error;
          }
        )
        .catch(error => {
          console.log("cerror", error);
          throw error;
        });

      // axios
      //   .get(this.imageURL)
      //   .then(
      //     (response) => {
      //       let inspection_geo = { ...response.data };
      //       console.log("inspection:", inspection_geo);

      //       this.existingObservations.clearLayers();
      //       this.mainMap.removeLayer(this.newObservation);

      //       if (inspection_geo.features.length > 0) {
      //         let geo = L.geoJSON(inspection_geo, {
      //           pane: "existingObservation",
      //           pmIgnore: true,
      //           onEachFeature: (feature, layer) => {
      //             let popup_content = "";
      //             for (let key in feature.properties) {
      //               if (["name", "severity", "status", "note"].includes(key)) {
      //                 let value = feature.properties[key];
      //                 popup_content =
      //                   popup_content + `<b>${key}:</b> ${value}<br>`;
      //               }
      //             }
      //             layer.bindPopup(popup_content);
      //           },
      //         });
      //         this.existingObservations.addLayer(geo);
      //       }
      //     },
      //     (error) => {
      //       console.log("error", error);
      //       throw error;
      //     }
      //   )
      //   .catch((error) => {
      //     console.log("cerror", error);
      //     throw error;
      //   });
    },
    createFeatureOnImage(type) {
      this.mainMap.pm.enableDraw(type, {
        snappable: true,
        snapDistance: 20
      });
    },
    generateGeoJson() {
      let layers = this.findLayers();

      let geo = {
        type: "FeatureCollection",
        features: []
      };
      layers.forEach(layer => {
        if (
          layer.options &&
          layer.options.pane &&
          layer.options.pane === "newObservation"
        ) {
          let geoJson = JSON.parse(JSON.stringify(layer.toGeoJSON()));
          if (!geoJson.properties) {
            geoJson.properties = {};
          }

          geoJson.properties.options = JSON.parse(
            JSON.stringify(layer.options)
          );

          if (layer.options.radius) {
            let radius = parseFloat(layer.options.radius);
            if (radius % 1 !== 0) {
              geoJson.properties.options.radius = radius.toFixed(6);
            } else {
              geoJson.properties.options.radius = radius.toFixed(0);
            }
          }

          if (layer instanceof L.Rectangle) {
            geoJson.properties.type = "rectangle";
          } else if (layer instanceof L.Circle) {
            geoJson.properties.type = "circle";
          } else if (layer instanceof L.CircleMarker) {
            geoJson.properties.type = "circlemarker";
          } else if (layer instanceof L.Polygon) {
            geoJson.properties.type = "polygon";
          } else if (layer instanceof L.Polyline) {
            geoJson.properties.type = "polyline";
          } else if (layer instanceof L.Marker) {
            geoJson.properties.type = "marker";
          }

          geo.features.push(geoJson);
        }
      });

      return geo;
    },

    findLayers() {
      let layers = [];
      this.mainMap.eachLayer(layer => {
        if (
          layer instanceof L.Polyline ||
          layer instanceof L.Marker ||
          layer instanceof L.Circle ||
          layer instanceof L.CircleMarker
        ) {
          layers.push(layer);
        }
      });

      layers = layers.filter(layer => !!layer.pm);
      layers = layers.filter(layer => !layer._pmTempLayer);

      return layers;
    },
    cancelNewObservation() {
      if (!this.annotatorSaveDialogEditing) {
        this.existingObservations.clearLayers();
        this.mainMap.removeLayer(this.newObservation);
        this.loadImageObservations();
      }
      this.saveModal = false;
      this.annotatorSaveDialogEditing = false;
    },
    saveNewObservation(prop, type) {
      this.saveModal = false;

      let obj = {};

      obj["type"] = this.newObservationGeojson["features"][0]["type"];
      obj["properties"] = {
        image_id: this.imageID,
        name: prop.name,
        description: prop.description,
        category: prop.annotationType
      };
      obj["geometry"] = this.newObservationGeojson["features"][0]["geometry"];

      let formData = new FormData();
      formData.set("geojson", JSON.stringify(obj));

      this.$http[`${type != "editingAPI" ? "post" : "put"}`](
        `${
          type != "editingAPI"
            ? "/create-annotation"
            : `/edit-annotations/${this.removeID}`
        }`,
        formData,
        {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        }
      )
        .then(() => {
          this.loadImageObservations();
          this.snackbar = true;
          if (type == "editingAPI") {
            this.snackbarMessage = "Observation has been successfully updated!";
          } else {
            this.snackbarMessage = "Observation has been successfully created!";
          }
          this.snackbarColor = "green";
          this.showDraggable = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    removeObservation(message) {
      this.removeModal = false;
      this.$http
        .delete(`/delete-annotations/${this.removeID}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(() => {
          this.loadImageObservations();
          this.snackbar = true;
          this.snackbarMessage = message || "Observation successfully removed!";
          this.snackbarColor = "green";
        })
        .catch(err => {
          console.log(err);
        });
    },
    editableClicked() {
      this.editableLayer.pm.enable({
        allowSelfIntersection: false
      });
      this.editableIconShow = true;
    },
    finishEditing() {
      this.editableIconShow = false;
      this.showDraggable = false;

      let layer = this.editableLayer;
      this.editableLayer.pm.disableLayerDrag();
      this.editableLayer.pm.disable();
      let geo = {
        type: "FeatureCollection",
        features: []
      };

      let geoJson = JSON.parse(JSON.stringify(layer.toGeoJSON()));

      if (!geoJson.properties) {
        geoJson.properties = {};
      }

      geoJson.properties.options = JSON.parse(JSON.stringify(layer.options));
      if (layer.options.radius) {
        let radius = parseFloat(layer.options.radius);
        if (radius % 1 !== 0) {
          geoJson.properties.options.radius = radius.toFixed(6);
        } else {
          geoJson.properties.options.radius = radius.toFixed(0);
        }
      }
      if (layer instanceof L.Rectangle) {
        geoJson.properties.type = "rectangle";
      } else if (layer instanceof L.Circle) {
        geoJson.properties.type = "circle";
      } else if (layer instanceof L.CircleMarker) {
        geoJson.properties.type = "circlemarker";
      } else if (layer instanceof L.Polygon) {
        geoJson.properties.type = "polygon";
      } else if (layer instanceof L.Polyline) {
        geoJson.properties.type = "polyline";
      } else if (layer instanceof L.Marker) {
        geoJson.properties.type = "marker";
      }

      geo.features.push(geoJson);

      let overlayTest = this.overlay_layer._image;
      let yR = overlayTest.clientHeight / overlayTest.naturalHeight;
      let xR = overlayTest.clientWidth / overlayTest.naturalWidth;
      let needThing = geo.features[0].geometry;
      let modifiedCoordinates = [],
        x,
        y,
        first;
      if (needThing.type == "Point") {
        first = this.mainMap.project(needThing.coordinates);
        let modifiedX = first.y * -1;
        let modifiedY = first.x * -1;
        x = modifiedX / xR;
        y = modifiedY / yR;
        modifiedCoordinates.push(x);
        modifiedCoordinates.push(y);
        geo.features[0].geometry.coordinates = modifiedCoordinates;
      } else if (needThing.type == "LineString") {
        for (let i of needThing.coordinates) {
          first = this.mainMap.project(i);
          let modifiedX = first.y * -1;
          let modifiedY = first.x * -1;
          x = modifiedX / xR;
          y = modifiedY / yR;
          modifiedCoordinates.push([x, y]);
        }
        geo.features[0].geometry.coordinates = modifiedCoordinates;
      } else if (needThing.type == "Polygon") {
        for (let i of needThing.coordinates[0]) {
          first = this.mainMap.project(i);
          let modifiedX = first.y * -1;
          let modifiedY = first.x * -1;
          x = modifiedX / xR;
          y = modifiedY / yR;
          modifiedCoordinates.push([x, y]);
        }
        geo.features[0].geometry.coordinates[0] = modifiedCoordinates;
      }

      this.newObservationGeojson = geo;
      this.saveNewObservation(
        {
          annotationType: this.editableCategory,
          name: this.editableName,
          description: this.editableDescription
        },
        "editingAPI"
      );
      // this.loadImageObservations();
    },
    cancelEditing() {
      this.editableLayer.pm.disableLayerDrag();
      this.editableLayer.pm.disable();
      this.loadImageObservations();
      this.editableIconShow = false;
      this.showDraggable = false;
    },
    draggableClicked() {
      this.editableLayer.pm.enableLayerDrag();
      this.draggableIconShow = true;
    },
    finishDraggable() {
      this.draggableIconShow = false;
      this.showDraggable = false;

      let layer = this.editableLayer;
      this.editableLayer.pm.disableLayerDrag();
      let geo = {
        type: "FeatureCollection",
        features: []
      };

      let geoJson = JSON.parse(JSON.stringify(layer.toGeoJSON()));

      if (!geoJson.properties) {
        geoJson.properties = {};
      }

      geoJson.properties.options = JSON.parse(JSON.stringify(layer.options));
      if (layer.options.radius) {
        let radius = parseFloat(layer.options.radius);
        if (radius % 1 !== 0) {
          geoJson.properties.options.radius = radius.toFixed(6);
        } else {
          geoJson.properties.options.radius = radius.toFixed(0);
        }
      }
      if (layer instanceof L.Rectangle) {
        geoJson.properties.type = "rectangle";
      } else if (layer instanceof L.Circle) {
        geoJson.properties.type = "circle";
      } else if (layer instanceof L.CircleMarker) {
        geoJson.properties.type = "circlemarker";
      } else if (layer instanceof L.Polygon) {
        geoJson.properties.type = "polygon";
      } else if (layer instanceof L.Polyline) {
        geoJson.properties.type = "polyline";
      } else if (layer instanceof L.Marker) {
        geoJson.properties.type = "marker";
      }

      geo.features.push(geoJson);
      let overlayTest = this.overlay_layer._image;
      let yR = overlayTest.clientHeight / overlayTest.naturalHeight;
      let xR = overlayTest.clientWidth / overlayTest.naturalWidth;
      let needThing = geo.features[0].geometry;
      let modifiedCoordinates = [],
        x,
        y,
        first;
      if (needThing.type == "Point") {
        first = this.mainMap.project(needThing.coordinates);
        let modifiedX = first.y * -1;
        let modifiedY = first.x * -1;
        x = modifiedX / xR;
        y = modifiedY / yR;
        modifiedCoordinates.push(x);
        modifiedCoordinates.push(y);
        geo.features[0].geometry.coordinates = modifiedCoordinates;
      } else if (needThing.type == "LineString") {
        for (let i of needThing.coordinates) {
          first = this.mainMap.project(i);
          let modifiedX = first.y * -1;
          let modifiedY = first.x * -1;
          x = modifiedX / xR;
          y = modifiedY / yR;
          modifiedCoordinates.push([x, y]);
        }
        geo.features[0].geometry.coordinates = modifiedCoordinates;
      } else if (needThing.type == "Polygon") {
        for (let i of needThing.coordinates[0]) {
          first = this.mainMap.project(i);
          let modifiedX = first.y * -1;
          let modifiedY = first.x * -1;
          x = modifiedX / xR;
          y = modifiedY / yR;
          modifiedCoordinates.push([x, y]);
        }
        geo.features[0].geometry.coordinates[0] = modifiedCoordinates;
      }
      this.newObservationGeojson = geo;
      this.saveNewObservation(
        {
          annotationType: this.editableCategory,
          name: this.editableName,
          description: this.editableDescription
        },
        "editingAPI"
      );
      // this.loadImageObservations();
    },
    cancelDraggable() {
      this.editableLayer.pm.disableLayerDrag();
      this.loadImageObservations();
      this.draggableIconShow = false;
      this.showDraggable = false;
    }
  },
  mounted() {
    // if(this.imageURL){
    this.getImageWidthHeight(this.imageURL, (width, height) => {
      if (this.defaultWidth == "100%") {
        this.loadCamera(width, height);
      } else {
        var browserWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;

        let ratio = width / height;
        this.loadCamera(
          Math.round(browserWidth),
          Math.round(browserWidth) / ratio
        );
      }
    });
  }
  // }
};
</script>

<style scoped>
.draggableComp {
  width: 400px;
  position: absolute;
  z-index: 1000;
}
</style>

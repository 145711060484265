<template>
  <v-dialog v-model="modal" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Removing Annotation</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              Are you Sure, you want to remove this annotation?
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('cancel')"
          v-if="!loading"
          >Cancel</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          :loading="loading"
          @click="$emit('remove')"
        >
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AnnotatorRemoveDialog",
  props: ["removeModal", "loading"],
  data() {
    return {
      modal: false
    };
  },
  watch: {
    removeModal(newValue) {
      this.modal = newValue;
    }
  }
};
</script>

<template>
  <div fluid>
    <v-container fluid>
      <v-row>
        <v-col>
          <!-- <div style="display:flex;alignItems:center;">
            <div style="flexGrow:1;display:flex;alignItems:center;">
              <v-btn icon @click="$router.push('/app/home')">
                <v-icon large>mdi-arrow-left</v-icon>
              </v-btn>
              <span
                style="fontSize:20px;cursor:pointer;"
                @click="$router.push('/app/home')"
                >Back</span
              >
            </div>
            
          </div> -->

          <v-tabs>
            <v-tab
              @click="
                $router.push(
                  `/app/asset-details/${$route.params.assetName}/timeline/${assetID}`
                )
              "
            >
              Timeline
            </v-tab>
            <v-tab
              @click="
                $router.push(
                  `/app/asset-details/${$route.params.assetName}/documents/${assetID}`
                )
              "
              v-if="!$store.state.isShared"
            >
              Documents
            </v-tab>
          </v-tabs>
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import { latLng } from "leaflet";

export default {
  name: "AssetDetails",
  data: () => ({
    assetID: null
  }),
  mounted() {
    this.assetID = this.$route.params.assetID;
  }
};
</script>
<style>
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
</style>

<template>
  <v-container fluid>
    <h1>Quotes Requests</h1>
    <div class="loadingDivStyle" v-if="pageLoading">
      <Loading loadingText="Fetching Quotes Requests!" v-if="pageLoading" />
    </div>
    <v-container fluid v-else>
      <div class="loadingDivStyle" v-if="!requestQuotesList.length">
        <h1 class="nothingFoundHeading">No Requests Found!</h1>
      </div>
      <v-card
        class="mt-4 pl-2 pr-2"
        style="border:1px solid white"
        v-else
        v-for="(item, ind) in requestQuotesList"
        :key="item.pk"
      >
        <div class="dateGrid pt-3">
          <div style="marginLeft:20px">
            <v-card-text><h3>Details</h3></v-card-text>
            <v-list-item>
              <v-list-item-content>
                <h4>
                  <span class="listName">Email: </span
                  >{{ item.properties.email }}
                </h4>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <h4>
                  <span class="listName">Phone: </span
                  >{{ item.properties.phone }}
                </h4>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <div style="display:flex;alignItems:center;">
                  <h4 style="width:50%">
                    <span class="listName">Service Level: </span>
                  </h4>
                  <div class="radioDiv">
                    <div class="flexStyle">
                      <i :class="item.properties.service_level.icon"></i>
                    </div>
                    <div class="divHead">
                      <div style="textAlign:center">
                        {{ item.properties.service_level.name }}
                      </div>
                      <p style="textAlign:center">
                        {{ item.properties.service_level.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <div style="display:flex;alignItems:center;">
                  <h4 style="width:50%">
                    <span class="listName">Building Category: </span>
                  </h4>
                  <div class="buildingDiv">
                    <div class="flexStyle">
                      <img :src="`/icons/${item.properties.category.icon}`" />
                    </div>
                    <div class="divHead">
                      <div style="textAlign:center">
                        {{ item.properties.category.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <div style="display:flex;alignItems:center;">
                  <h4 style="width:50%">
                    <span class="listName">Type of 3D Model: </span>
                  </h4>
                  <div class="radioDiv">
                    <div class="flexStyle">
                      <div class="modelLabel">
                        <img
                          :src="
                            `/icons/${
                              item.properties.type_of_3d_model.icon.split(
                                '+'
                              )[0]
                            }`
                          "
                        />
                        <img
                          style="width:32px;height:32px;"
                          :src="
                            `/icons/${
                              item.properties.type_of_3d_model.icon.split(
                                '+'
                              )[1]
                            }`
                          "
                        />
                      </div>
                    </div>
                    <div class="divHead">
                      <div style="textAlign:center">
                        {{ item.properties.type_of_3d_model.name }}
                      </div>
                      <p style="textAlign:center">
                        {{ item.properties.type_of_3d_model.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <div style="display:flex;alignItems:center;">
                  <h4 style="width:50%">
                    <span class="listName">Quality of 3D Model: </span>
                  </h4>
                  <div class="radioDiv">
                    <div class="flexStyle">
                      <img
                        style="width:65px;height:auto;"
                        :src="item.properties.quality.icon"
                      />
                    </div>
                    <div class="divHead">
                      <div style="textAlign:center">
                        {{ item.properties.quality.name }}
                      </div>
                      <p style="textAlign:center">
                        {{ item.properties.quality.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div style="margin:16px;height:450px">
            <FlightListMap
              :geoJSONData="item"
              style="height: 100%; z-index: 12"
            />
          </div>
        </div>

        <v-btn
          class="mb-3 mt-3"
          block
          rounded
          color="primary"
          @click="() => createModalOpenHandler(ind)"
          >Create Survey From Quote
        </v-btn>
      </v-card>
    </v-container>
    <v-dialog v-model="modal" persistent width="800px">
      <v-container fluid>
        <v-card class="mt-4 pa-4">
          <v-select
            :menu-props="{ maxHeight: '400' }"
            :items="assets"
            :loading="assetLoading"
            :hint="assetLoadingReturn"
            :disabled="assetLoading"
            label="Asset *"
            v-model="selected_asset"
            persistent-hint
            clearable
            class="mb-5"
          >
          </v-select>

          <v-dialog
            ref="dialogDate"
            v-model="dateModal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Date Range for Survey *"
                hint="Choose date range for Survey"
                readonly
                v-bind="attrs"
                persistent-hint
                v-on="on"
                class="mb-5"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable range :min="dateMin">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateModal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialogDate.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-btn
            class="mt-4"
            block
            rounded
            color="primary"
            :loading="createLoading"
            :disabled="!selected_asset || date.length < 2"
            @click="createSurveyFromQuoteHandler"
            >Create
          </v-btn>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              v-if="!createLoading"
              @click="modal = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import Loading from "../components/misc/Loading";
import FlightListMap from "../components/flight/FlightListMap";

export default {
  name: "QuoteRequests",
  components: {
    Loading,
    FlightListMap
  },
  data: () => ({
    requestQuotesList: [],
    pageLoading: true,
    modal: false,
    dateModal: false,
    date: [],
    dateMin: new Date().toISOString(),
    assetLoading: false,
    assetsGeoJson: null,
    selected_asset: null,
    currentInd: undefined,
    createLoading: false
  }),
  methods: {
    createModalOpenHandler(ind) {
      this.currentInd = ind;
      this.modal = true;
      this.selected_asset = null;
      this.date = [];
      this.createLoading = false;
      if (!this.assetsGeoJson) {
        this.getAssets();
      }
    },
    async createSurveyFromQuoteHandler() {
      this.createLoading = true;

      let uploadObj = new FormData();
      uploadObj.append("asset_id", this.selected_asset.id);
      uploadObj.append("date_start", this.date[0]);
      uploadObj.append("date_end", this.date[1]);
      uploadObj.append(
        "quote_id",
        this.requestQuotesList[this.currentInd].properties.pk
      );

      try {
        let res = await this.$http.post(
          "/create-survey-from-quote",
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.$store.commit("snackbarActivator", {
          open: true,
          success: true,
          message: "Action successful"
        });
        this.$router.push({
          path: `surveys/${res.data.flight_id}`
        });
      } catch (e) {
        this.$store.commit("snackbarActivator", {
          open: true,
          success: false,
          message: "something went wrong! Please Try Again"
        });
        this.createLoading = false;
      }
    },
    async getAssets() {
      this.assetLoading = true;
      let res = await this.$http.get("/assets/", {
        headers: {
          Authorization: "token " + localStorage.getItem("auth_token")
        }
      });

      this.assetsGeoJson = res.data;

      this.assetLoading = false;
    }
  },
  computed: {
    assets: function() {
      if (this.assetsGeoJson) {
        let assetsToReturn = [];
        this.assetsGeoJson.features.forEach(asset => {
          assetsToReturn.push({
            text: asset.properties.name,
            value: {
              id: asset.id,
              location: asset.geometry.coordinates
            }
          });
        });
        return assetsToReturn;
      }
      return [];
    },
    assetLoadingReturn() {
      if (this.assetLoading) {
        return "Wait! Data is being fetched";
      }
      return "Select any Asset";
    }
  },
  async mounted() {
    try {
      let resQuoteOptions = await this.$http.get("/get-quote-options");
      let res = await this.$http.get("/get-quotes", {
        headers: {
          Authorization: "token " + localStorage.getItem("auth_token")
        }
      });

      res.data.features.forEach(val => {
        let foundIndex = resQuoteOptions.data.service_levels.findIndex(
          el => el.id == val.properties.service_level
        );
        foundIndex != -1 &&
          (val.properties.service_level =
            resQuoteOptions.data.service_levels[foundIndex]);

        foundIndex = resQuoteOptions.data.categories.findIndex(
          el => el.id == val.properties.category
        );
        foundIndex != -1 &&
          (val.properties.category =
            resQuoteOptions.data.categories[foundIndex]);

        foundIndex = resQuoteOptions.data.types_of_3d_model.findIndex(
          el => el.id == val.properties.type_of_3d_model
        );
        foundIndex != -1 &&
          (val.properties.type_of_3d_model =
            resQuoteOptions.data.types_of_3d_model[foundIndex]);

        foundIndex = resQuoteOptions.data.qualities.findIndex(
          el => el.id == val.properties.quality
        );
        foundIndex != -1 &&
          (val.properties.quality = resQuoteOptions.data.qualities[foundIndex]);
      });

      this.requestQuotesList = res.data.features;
      this.pageLoading = false;
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style scoped>
.loadingDivStyle {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nothingFoundHeading {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  text-align: center;
}

.dateGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
  column-gap: 5px;
  row-gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.listName {
  color: darkgray;
}

.radioDiv {
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: rgba(109, 142, 164, 0.1);
}

.flexStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconSize {
  font-size: 40px;
}

.divHead {
  font-family: "Quicksand", sans-serif;
}

.divHead div {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.divHead p {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 17px;
}

.buildingDiv {
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 132px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(109, 142, 164, 0.1);
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  padding: 8px;
}

.buildingDiv img {
  width: 60px;
  height: 60px;
}

.buildingDiv span {
  margin-top: 10px;
}

.modelLabel {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 80px;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/css/iconfont.css";
import * as VueGoogleMaps from "vue2-google-maps-withscopedautocomp";
import {
  LMap,
  LTileLayer,
  LMarker,
  LGeoJson,
  LFeatureGroup,
  LControlLayers
} from "vue2-leaflet";
import axios from "axios";
import VueLazyLoad from "vue-lazyload";
import VueMeta from "vue-meta";
import drag from "v-drag";
import VueScrollactive from "vue-scrollactive";

Vue.use(VueScrollactive);

Vue.use(drag);
Vue.use(VueLazyLoad);
Vue.use(VueMeta);

const http = axios.create({
  baseURL: "https://dev-api.aerinspect.com"
});
Vue.prototype.$http = http;
Vue.config.productionTip = false;
Vue.config.silent = false;

Vue.component("l-map", LMap);
Vue.component("l-feature-group", LFeatureGroup);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-geo-json", LGeoJson);
Vue.component("l-control-layers", LControlLayers);

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places"
  },
  installComponents: true
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

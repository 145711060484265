// v-model="selected_asset"
<template>
  <v-container fluid>
    <CreateAssetDialog
      :openCreateDialog="assetCreateDialog"
      @dialogClose="assetCreateDialog = false"
      @dialogAssetCreationComplete="assetCreationComplete"
      v-if="assetCreateDialog"
    />

    <SkydioDroneDialog
      :openSkydioModal="openSkydioModal"
      @cancel="openSkydioModal = false"
      @fetchInspections="openSkydioModal = false"
      @selectedFlight="res => (selected_flight_result = res)"
      @selectedFlightDescription="des => (selected_flight_description = des)"
    />

    <h1>Upload Data</h1>

    <div class="loadingDivStyle" v-if="loading || uploadingProgress">
      <Loading loadingText="Creating Inspection!" v-if="loading" />

      <div v-else>
        <v-progress-linear v-model="uploadProgressNumber" height="40">
          <strong>{{ uploadProgressNumber }}%</strong>
        </v-progress-linear>
        <span class="progressLoadingDetail">
          {{ uploadProgressStatement }}
        </span>
      </div>
    </div>
    <v-container fluid v-else>
      <form @submit.prevent="createInspection">
        <v-select
          :menu-props="{ maxHeight: '400' }"
          :items="assets"
          :loading="assetLoading"
          :hint="assetLoadingReturn"
          :disabled="assetLoading"
          label="Asset *"
          v-model="selected_asset"
          persistent-hint
          clearable
          class="mb-5"
        >
          <template v-slot:append-outer>
            <v-btn class="mr-1" fab small @click="assetCreateDialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-select>

        <v-text-field
          v-model="nameOfInspection"
          label="Survey Name *"
          :rules="NameRules"
          required
        />

        <v-textarea
          label="Description *"
          rows="3"
          :rules="descriptionNameRules"
          v-model="descriptionOfInspection"
        />

        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Date of Inspection *"
              hint="Choose date  for Inspection"
              readonly
              v-bind="attrs"
              persistent-hint
              v-on="on"
              class="mb-5"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" scrollable :max="dateMax">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <div class="dateGrid">
          <div>
            <v-label>Raw data/images Attachments</v-label>
            <div class="uploadMainDivStyle">
              <div class="dropper mt-3 mb-3">
                <v-btn
                  color="primary"
                  class="ma-2 white--text"
                  x-small
                  @click="openSkydioModal = true"
                  style="position:absolute;bottom:calc(50% - 80px);zIndex:10"
                >
                  Skydio Survey
                  <v-icon right dark>
                    mdi-upload
                  </v-icon>
                </v-btn>

                <input
                  type="file"
                  class="dropperInput"
                  multiple
                  title="Upload Data"
                  accept="video/*,image/*,.zip"
                  @change="fileChangeHandler($event, 'uploadRawFilesList')"
                />
                <div class="dropperSpan" v-if="!uploadRawFilesList.length">
                  <span>
                    Drag or Select Raw data/images Files Here!
                  </span>
                  <span style="color:#e21e1e;">
                    IMAGES | VIDEOS | ZIP
                  </span>
                  <span>OR</span>
                </div>
                <table class="dropperTable" v-if="uploadRawFilesList.length">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Thumb</th>
                      <th>Name</th>
                      <th>Size</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, ind) in uploadRawFilesList"
                      :key="item.name"
                    >
                      <td>{{ ind + 1 }}</td>
                      <td v-if="item.imagePresent">
                        <img
                          class="uploadTableImage"
                          :src="item.fileOriginPath"
                        />
                      </td>
                      <td v-else>No Image</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.fileSize }}</td>
                      <td style="textAlign:center">
                        <v-btn
                          fab
                          small
                          @click="
                            removeUploadFileHandler(ind, 'uploadRawFilesList')
                          "
                        >
                          <v-icon dark color="red">mdi-cancel</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p
              style='fontFamily: "Quicksand", sans-serif; fontSize: 15px;'
              v-if="selected_flight_description"
            >
              {{ selected_flight_description }}
            </p>
          </div>

          <div>
            <v-label>Digital Terrain Model Attachments</v-label>
            <div class="uploadMainDivStyle">
              <div class="dropper mt-3 mb-3">
                <input
                  type="file"
                  class="dropperInput"
                  multiple
                  accept=".tif"
                  title="Upload Data"
                  @change="
                    fileChangeHandler($event, 'uploadDigitalTerrainModelFiles')
                  "
                />
                <div
                  class="dropperSpan"
                  v-if="!uploadDigitalTerrainModelFiles.length"
                >
                  <span>Drag or Select Digital Terrain Model Files Here!</span>
                  <span style="color:#e21e1e;">TIFF</span>
                </div>
                <table
                  class="dropperTable"
                  v-if="uploadDigitalTerrainModelFiles.length"
                >
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Thumb</th>
                      <th>Name</th>
                      <th>Size</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, ind) in uploadDigitalTerrainModelFiles"
                      :key="item.name"
                    >
                      <td>{{ ind + 1 }}</td>
                      <td v-if="item.imagePresent">
                        <img
                          class="uploadTableImage"
                          :src="item.fileOriginPath"
                        />
                      </td>
                      <td v-else>No Image</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.fileSize }}</td>
                      <td style="textAlign:center">
                        <v-btn
                          fab
                          small
                          @click="
                            removeUploadFileHandler(
                              ind,
                              'uploadDigitalTerrainModelFiles'
                            )
                          "
                        >
                          <v-icon dark color="red">mdi-cancel</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <v-label>Digital Surface Model Attachments</v-label>
            <div class="uploadMainDivStyle">
              <div class="dropper mt-3 mb-3">
                <input
                  type="file"
                  class="dropperInput"
                  multiple
                  title="Upload Data"
                  accept=".tif"
                  @change="
                    fileChangeHandler($event, 'uploadDigitalSurfaceModelFiles')
                  "
                />
                <div
                  class="dropperSpan"
                  v-if="!uploadDigitalSurfaceModelFiles.length"
                >
                  <span>Drag or Select Digital Surface Model Files Here!</span>
                  <span style="color:#e21e1e;">TIFF</span>
                </div>
                <table
                  class="dropperTable"
                  v-if="uploadDigitalSurfaceModelFiles.length"
                >
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Thumb</th>
                      <th>Name</th>
                      <th>Size</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, ind) in uploadDigitalSurfaceModelFiles"
                      :key="item.name"
                    >
                      <td>{{ ind + 1 }}</td>
                      <td v-if="item.imagePresent">
                        <img
                          class="uploadTableImage"
                          :src="item.fileOriginPath"
                        />
                      </td>
                      <td v-else>No Image</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.fileSize }}</td>
                      <td style="textAlign:center">
                        <v-btn
                          fab
                          small
                          @click="
                            removeUploadFileHandler(
                              ind,
                              'uploadDigitalSurfaceModelFiles'
                            )
                          "
                        >
                          <v-icon dark color="red">mdi-cancel</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <v-label>Orthophoto Attachments</v-label>
            <div class="uploadMainDivStyle">
              <div class="dropper mt-3 mb-3">
                <input
                  type="file"
                  class="dropperInput"
                  multiple
                  accept=".jpg,.tif"
                  title="Upload Data"
                  @change="fileChangeHandler($event, 'uploadOrthophotoFiles')"
                />
                <div class="dropperSpan" v-if="!uploadOrthophotoFiles.length">
                  <span>Drag or Select Orthophoto Files Here!</span>
                  <span style="color:#e21e1e;">JPG | TIFF</span>
                </div>
                <table class="dropperTable" v-if="uploadOrthophotoFiles.length">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Thumb</th>
                      <th>Name</th>
                      <th>Size</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, ind) in uploadOrthophotoFiles"
                      :key="item.name"
                    >
                      <td>{{ ind + 1 }}</td>
                      <td v-if="item.imagePresent">
                        <img
                          class="uploadTableImage"
                          :src="item.fileOriginPath"
                        />
                      </td>
                      <td v-else>No Image</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.fileSize }}</td>
                      <td style="textAlign:center">
                        <v-btn
                          fab
                          small
                          @click="
                            removeUploadFileHandler(
                              ind,
                              'uploadOrthophotoFiles'
                            )
                          "
                        >
                          <v-icon dark color="red">mdi-cancel</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <v-label>3D Model Attachments</v-label>
            <div class="uploadMainDivStyle">
              <div class="dropper mt-3 mb-3">
                <input
                  type="file"
                  class="dropperInput"
                  multiple
                  accept=".zip"
                  title="Upload Data"
                  @change="fileChangeHandler($event, 'upload3DModelFiles')"
                />
                <div class="dropperSpan" v-if="!upload3DModelFiles.length">
                  <span>Drag or Select 3D Model Files Here!</span>
                  <span style="color:#e21e1e;">ZIP</span>
                </div>
                <table class="dropperTable" v-if="upload3DModelFiles.length">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Thumb</th>
                      <th>Name</th>
                      <th>Size</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, ind) in upload3DModelFiles"
                      :key="item.name"
                    >
                      <td>{{ ind + 1 }}</td>
                      <td v-if="item.imagePresent">
                        <img
                          class="uploadTableImage"
                          :src="item.fileOriginPath"
                        />
                      </td>
                      <td v-else>No Image</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.fileSize }}</td>
                      <td style="textAlign:center">
                        <v-btn
                          fab
                          small
                          @click="
                            removeUploadFileHandler(ind, 'upload3DModelFiles')
                          "
                        >
                          <v-icon dark color="red">mdi-cancel</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <v-label>Point cloud Attachments</v-label>
            <div class="uploadMainDivStyle">
              <div class="dropper mt-3 mb-3">
                <input
                  type="file"
                  class="dropperInput"
                  accept=".laz,.las,.zip"
                  multiple
                  title="Upload Data"
                  @change="fileChangeHandler($event, 'uploadPointcloudFiles')"
                />
                <div class="dropperSpan" v-if="!uploadPointcloudFiles.length">
                  <span>Drag or Select Point cloud Files Here!</span>
                  <span style="color:#e21e1e;">LAZ | LAS | ZIP</span>
                </div>
                <table class="dropperTable" v-if="uploadPointcloudFiles.length">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Thumb</th>
                      <th>Name</th>
                      <th>Size</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, ind) in uploadPointcloudFiles"
                      :key="item.name"
                    >
                      <td>{{ ind + 1 }}</td>
                      <td v-if="item.imagePresent">
                        <img
                          class="uploadTableImage"
                          :src="item.fileOriginPath"
                        />
                      </td>
                      <td v-else>No Image</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.fileSize }}</td>
                      <td style="textAlign:center">
                        <v-btn
                          fab
                          small
                          @click="
                            removeUploadFileHandler(
                              ind,
                              'uploadPointcloudFiles'
                            )
                          "
                        >
                          <v-icon dark color="red">mdi-cancel</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <v-label>Camera Attachments</v-label>
            <div class="uploadMainDivStyle">
              <div class="dropper mt-3 mb-3">
                <input
                  type="file"
                  class="dropperInput"
                  accept=".txt"
                  multiple
                  title="Upload Data"
                  @change="fileChangeHandler($event, 'uploadCameraFiles')"
                />
                <div class="dropperSpan" v-if="!uploadCameraFiles.length">
                  <span
                    >Drag or Select (Omega, Phi and Kappa) Camera Files
                    Here!</span
                  >
                  <span style="color:#e21e1e;">TXT</span>
                </div>
                <table class="dropperTable" v-if="uploadCameraFiles.length">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Thumb</th>
                      <th>Name</th>
                      <th>Size</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, ind) in uploadCameraFiles"
                      :key="item.name"
                    >
                      <td>{{ ind + 1 }}</td>
                      <td v-if="item.imagePresent">
                        <img
                          class="uploadTableImage"
                          :src="item.fileOriginPath"
                        />
                      </td>
                      <td v-else>No Image</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.fileSize }}</td>
                      <td style="textAlign:center">
                        <v-btn
                          fab
                          small
                          @click="
                            removeUploadFileHandler(ind, 'uploadCameraFiles')
                          "
                        >
                          <v-icon dark color="red">mdi-cancel</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <RequiredFields :message="errorMessage" v-if="fieldsCheck" />

        <v-btn
          type="submit"
          class="mt-4"
          block
          rounded
          color="primary"
          :disabled="fieldsCheck"
          >Upload
        </v-btn>
      </form>
    </v-container>
    <v-snackbar v-model="snackbar" right color="green">
      {{ success_msg }}
    </v-snackbar>
  </v-container>
</template>

<script>
import CreateAssetDialog from "../components/asset/CreateAssetDialog";
import RequiredFields from "../components/misc/RequiredFields";
import axios from "axios";
import Loading from "../components/misc/Loading";
import SkydioDroneDialog from "../components/asset/SkydioDroneDialog.vue";

export default {
  name: "UploadData",

  metaInfo() {
    return {
      title: this.$store.state.app_title,
      titleTemplate: "%s | Upload Data"
    };
  },

  components: {
    CreateAssetDialog,
    RequiredFields,
    Loading,
    SkydioDroneDialog
  },

  data() {
    return {
      openSkydioModal: false,
      selected_flight_result: undefined,
      selected_flight_description: undefined,
      assetCreateDialog: false,
      assetsGeoJson: null,
      flight_types: [],
      date: "",
      modal: false,
      nameOfInspection: null,
      NameRules: [v => !!v || "Name is required"],
      descriptionNameRules: [v => !!v || "Description is required"],
      descriptionOfInspection: null,
      selected_asset: null,
      assetLoading: true,
      dateMax: new Date().toISOString(),
      errorMessage: "Complete Required (*) Fields to enable create button.",
      loading: false,
      uploadingProgress: false,
      uploadProgressNumber: 0,
      uploadProgressStatement: "Uploading File",
      uploadRawFilesList: [],
      uploadDigitalTerrainModelFiles: [],
      uploadDigitalSurfaceModelFiles: [],
      uploadOrthophotoFiles: [],
      upload3DModelFiles: [],
      uploadPointcloudFiles: [],
      uploadCameraFiles: [],
      snackbar: false,
      success_msg: ""
    };
  },
  computed: {
    assets: function() {
      if (this.assetsGeoJson) {
        let assetsToReturn = [];
        this.assetsGeoJson.features.forEach(asset => {
          assetsToReturn.push({
            text: asset.properties.name,
            value: {
              id: asset.id,
              location: asset.geometry.coordinates
            }
          });
        });

        return assetsToReturn;
      }
      return [];
    },
    assetLoadingReturn() {
      if (this.assetLoading) {
        return "Wait! Data is being fetched";
      }
      return "Select any Asset";
    },
    fieldsCheck() {
      if (
        this.selected_asset &&
        this.date.length > 0 &&
        this.nameOfInspection &&
        this.descriptionOfInspection
      ) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.getAssets();
  },
  methods: {
    assetCreationComplete() {
      this.snackbar = true;
      this.success_msg = "Asset has been successfully created";
      this.assetCreateDialog = false;
      this.selected_asset = null;
      this.getAssets();
    },
    createInspection() {
      console.log("selected flight is ", this.selected_flight_result);

      let foundObjAsset = this.assetsGeoJson.features.find(
        el => el.id == this.selected_asset.id
      );

      this.loading = true;

      let createInspectionObj = new FormData();
      createInspectionObj.append("name", this.nameOfInspection);
      createInspectionObj.append("description", this.descriptionOfInspection);
      createInspectionObj.append("asset", this.selected_asset.id);
      createInspectionObj.append("date", this.date);
      if (this.selected_flight_result) {
        createInspectionObj.append("source", "skydio");
        createInspectionObj.append("flight_id", this.selected_flight_result);
      } else {
        createInspectionObj.append("source", "upload");
      }

      let recursionArr = [
        {
          name: "uploadRawFilesList",
          file_type: "raw",
          message: "Raw Files"
        },
        {
          name: "uploadDigitalTerrainModelFiles",
          file_type: "processed",
          processed_type: "dtm",
          message: "Digital Terrain Model Files"
        },
        {
          name: "uploadDigitalSurfaceModelFiles",
          file_type: "processed",
          processed_type: "dsm",
          message: "Digital Surface Model Files"
        },
        {
          name: "uploadOrthophotoFiles",
          file_type: "processed",
          processed_type: "ortho",
          message: "Orthophoto Files"
        },
        {
          name: "upload3DModelFiles",
          file_type: "processed",
          processed_type: "3d",
          message: "3D Model Files"
        },
        {
          name: "uploadPointcloudFiles",
          file_type: "processed",
          processed_type: "point_cloud",
          message: "Point Cloud Files"
        },
        {
          name: "uploadCameraFiles",
          file_type: "processed",
          processed_type: "cameras_file",
          message: "Camera Files"
        }
      ];

      let totalFiles = 0,
        requiredIDToRouter = this.selected_asset.id;

      for (let i of recursionArr) {
        totalFiles += this[i.name].length;
      }

      this.$http
        .post("/inspections/", createInspectionObj, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(async res => {
          this.loading = false;
          if (totalFiles > 0) {
            this.uploadingProgress = true;

            for (let [mainInd, j] of recursionArr.entries()) {
              let arrLength = this[j.name].length;

              for (let [ind, i] of this[j.name].entries()) {
                let uploadObj = new FormData();
                uploadObj.append("file_type", j.file_type);
                uploadObj.append("inspection_id", res.data.pk);
                uploadObj.append("filename", i.name);
                uploadObj.append("mime_type", i.type);
                if (j.processed_type) {
                  uploadObj.append("processed_type", j.processed_type);
                }

                let myPromise = new Promise((resolve, reject) => {
                  this.$http
                    .post("/inspections/get-upload-object/", uploadObj, {
                      headers: {
                        Authorization:
                          "token " + localStorage.getItem("auth_token")
                      }
                    })
                    .then(async resIns => {
                      try {
                        let config = {
                          onUploadProgress: progressEvent => {
                            var percentCompleted = Math.round(
                              (progressEvent.loaded * 100) / progressEvent.total
                            );
                            this.uploadProgressNumber = percentCompleted;

                            this.uploadProgressStatement = `Uploading ${
                              j.message
                            } ${ind + 1}/${arrLength}`;

                            // if(percentCompleted == 100 && ind == arrLength-1 && totalFiles == tempFileCount){
                            //   this.snackbar = true;
                            //   this.success_msg = "Inspection has been successfully created!";
                            //   this.uploadingProgress = false;
                            //   this.date = '';
                            //   this.selected_asset = null;
                            //   this.dateMin = new Date().toISOString();
                            //   this.uploadProgressNumber = 0;
                            //   this.uploadProgressStatement = 'Uploading File';
                            //   this.uploadRawFilesList = [];
                            //   this.uploadDigitalTerrainModelFiles = [];
                            //   this.uploadDigitalSurfaceModelFiles = [];
                            //   this.uploadOrthophotoFiles = [];
                            //   this.upload3DModelFiles = [];
                            //   this.uploadPointcloudFiles = [];
                            // }
                          },
                          headers: {
                            "Content-Type": i.type
                          }
                        };

                        let resAxios = await axios.put(
                          resIns.data.presigned_url,
                          i,
                          config
                        );

                        if (resAxios) {
                          try {
                            let uploadCompleteObj = new FormData();
                            uploadCompleteObj.append("file_id", resIns.data.id);
                            let resComp = await this.$http.post(
                              "/upload-completed",
                              uploadCompleteObj,
                              {
                                headers: {
                                  Authorization:
                                    "token " +
                                    localStorage.getItem("auth_token")
                                }
                              }
                            );

                            if (resComp) {
                              // console.log("upload-completed response ", resComp);
                              resolve(ind);
                              // if (totalFiles == tempFileCount) {
                              //   this.snackbar = true;
                              //   this.success_msg =
                              //     "Inspection has been successfully created!";
                              //   this.uploadingProgress = false;
                              //   this.date = "";
                              //   this.selected_asset = null;
                              //   this.dateMin = new Date().toISOString();
                              //   this.uploadProgressNumber = 0;
                              //   this.uploadProgressStatement = "Uploading File";
                              //   this.uploadRawFilesList = [];
                              //   this.uploadDigitalTerrainModelFiles = [];
                              //   this.uploadDigitalSurfaceModelFiles = [];
                              //   this.uploadOrthophotoFiles = [];
                              //   this.upload3DModelFiles = [];
                              //   this.uploadPointcloudFiles = [];

                              //   this.$router.push(
                              //     `/app/asset-details/${foundObjAsset.properties.name}/timeline/${this.selected_asset.id}`
                              //   );
                              // }
                            }
                          } catch (err) {
                            console.log(err);
                            reject(err);
                          }
                        }
                      } catch (err) {
                        console.log(
                          "Some error has occured while uploading the files",
                          err
                        );
                      }
                    })
                    .catch(errIns => {
                      console.log(
                        "some error occured in inside",
                        errIns.response.data
                      );
                      this.loading = false;
                    });
                });
                await myPromise;
              }

              if (mainInd == recursionArr.length - 1) {
                this.snackbar = true;
                this.success_msg = "Inspection has been successfully created!";
                this.uploadingProgress = false;
                this.date = "";
                this.selected_asset = null;
                this.dateMin = new Date().toISOString();
                this.uploadProgressNumber = 0;
                this.uploadProgressStatement = "Uploading File";
                this.uploadRawFilesList = [];
                this.uploadDigitalTerrainModelFiles = [];
                this.uploadDigitalSurfaceModelFiles = [];
                this.uploadOrthophotoFiles = [];
                this.upload3DModelFiles = [];
                this.uploadPointcloudFiles = [];
                this.uploadCameraFiles = [];

                this.$router.push(
                  `/app/asset-details/${foundObjAsset.properties.name}/timeline/${requiredIDToRouter}/data-point/${res.data.pk}`
                );
              }
            }
          } else {
            this.snackbar = true;
            this.success_msg = "Inspection has been successfully created!";
            this.uploadingProgress = false;
            this.date = "";
            this.selected_asset = null;
            this.dateMin = new Date().toISOString();
            this.uploadProgressNumber = 0;
            this.uploadProgressStatement = "Uploading File";
            this.uploadRawFilesList = [];
            this.uploadDigitalTerrainModelFiles = [];
            this.uploadDigitalSurfaceModelFiles = [];
            this.uploadOrthophotoFiles = [];
            this.upload3DModelFiles = [];
            this.uploadPointcloudFiles = [];
            this.uploadCameraFiles = [];

            this.$router.push(
              `/app/asset-details/${foundObjAsset.properties.name}/timeline/${requiredIDToRouter}/data-point/${res.data.pk}`
            );
          }
        })
        .catch(err => {
          console.log("error occured", err);
        });
    },
    async getAssets() {
      let res = await this.$http.get("/assets/", {
        headers: {
          Authorization: "token " + localStorage.getItem("auth_token")
        }
      });

      this.assetsGeoJson = res.data;

      if (this.$route.query.assetDataID) {
        let foundObj = res.data.features.find(
          el => el.id == this.$route.query.assetDataID
        );
        if (foundObj) {
          this.selected_asset = {
            id: foundObj.id,
            location: foundObj.geometry.coordinates
          };
        }
      }

      this.assetLoading = false;
    },
    niceBytes(x) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    },
    fileChangeHandler(e, arrName) {
      for (let i = 0; i < e.target.files.length; i++) {
        e.target.files[i].fileOriginPath = URL.createObjectURL(
          e.target.files[i]
        );
        if (e.target.files[i].type.split("/").shift() == "image") {
          e.target.files[i].imagePresent = true;
        } else {
          e.target.files[i].imagePresent = false;
        }
        e.target.files[i].fileSize = this.niceBytes(e.target.files[i].size);
      }

      this[arrName].push(...e.target.files);
    },
    removeUploadFileHandler(ind, arrName) {
      let duplicateArray = [...this[arrName]];
      duplicateArray.splice(ind, 1);
      this[arrName] = duplicateArray;
    }
  }
};
</script>

<style scoped>
.dropper {
  min-height: 30vh;
  border: 2px dashed white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s;
}

.dropper:hover {
  background-color: #eeeeee42;
}

.dropperInput {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.dropperSpan {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  pointer-events: none;
  display: flex;
  align-items: center;
  flex-flow: column;
}

.loadingDivStyle {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropperTable {
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  align-self: flex-start;
}

.dropperTable thead tr {
  background-color: grey;
}

.dropperTable thead th {
  position: sticky;
  top: 0;
  background-color: grey;
  z-index: 1;
}

.dropperTable td {
  padding: 0.75rem;
  border-bottom: 1px dashed grey;
}

.uploadTableImage {
  max-width: 4em;
  max-height: 4em;
  vertical-align: middle;
  border-style: none;
}

.progressLoadingDetail {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  display: inline-block;
  margin-top: 10px;
}

.uploadMainDivStyle {
  position: relative;
  max-height: 450px;
  overflow-y: scroll;
}

.dateGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 500px);
  column-gap: 24px;
  row-gap: 56px;
  margin-top: 26px;
  width: 100%;
  margin-left: 15px;
  justify-content: center;
}
</style>

<template>
  <v-dialog v-model="modal" persistent max-width="900px">
    <v-card>
      <v-card-title>
        <span class="headline">Request Drone</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-select
              :menu-props="{ maxHeight: '400' }"
              :items="drones"
              :loading="dronesLoading"
              hint="Select Any Drone"
              :disabled="dronesLoading"
              label="Drones *"
              item-text="serial"
              item-value="serial"
              v-model="selected_drone"
              persistent-hint
              clearable
              class="mb-5"
            >
              <template v-slot:append-outer>
                <v-btn
                  class="mr-1"
                  fab
                  small
                  :disabled="!selected_drone"
                  @click="checkDroneFlights"
                >
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </template>
            </v-select>
          </v-row>
          <v-row v-if="drone_dates.length > 0">
            <v-simple-table style="width:100%;">
              <thead>
                <tr>
                  <th class="text-left">
                    SR
                  </th>
                  <th class="text-left">
                    TAKE OFF
                  </th>
                  <th class="text-left">
                    LANDING
                  </th>
                  <th class="text-left">
                    ACTIONS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, ind) in drone_dates" :key="item.flight_id">
                  <td>{{ ind + 1 }}</td>
                  <td>
                    {{
                      item.takeoff
                        ? new Date(item.takeoff)
                            .toString()
                            .split(" ")
                            .slice(0, 5)
                            .join(" ")
                        : "----"
                    }}
                  </td>
                  <td>
                    {{
                      item.landing
                        ? new Date(item.landing)
                            .toString()
                            .split(" ")
                            .slice(0, 5)
                            .join(" ")
                        : "----"
                    }}
                  </td>
                  <td>
                    <v-btn
                      color="primary"
                      block
                      small
                      @click="
                        selectedFlight = item;
                        confirmSelectFlight = true;
                      "
                      >Select</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-row>
          <v-row v-else-if="no_dates">
            <div style="textAlign:center;width:100%">
              <h2>No Dates Available</h2>
            </div>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="confirmSelectFlight"
      persistent
      max-width="600px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Select Flight</span>
        </v-card-title>
        <v-card-text v-if="selectedFlight">
          Are you sure, you want to select the flight with take off on
          {{
            selectedFlight.takeoff
              ? new Date(selectedFlight.takeoff)
                  .toString()
                  .split(" ")
                  .slice(0, 5)
                  .join(" ")
              : "----"
          }}
          and landing on
          {{
            selectedFlight.landing
              ? new Date(selectedFlight.landing)
                  .toString()
                  .split(" ")
                  .slice(0, 5)
                  .join(" ")
              : "----"
          }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="confirmSelectFlight = false"
            v-if="!selectionLoading"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="fetchDroneFlightData"
            :loading="selectionLoading"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  name: "SkydioDroneDialog",
  props: ["openSkydioModal"],
  data() {
    return {
      modal: false,
      selected_drone: null,
      dronesLoading: true,
      drones: [],
      drone_dates: [],
      no_dates: false,
      confirmSelectFlight: false,
      selectionLoading: false,
      selectedFlight: undefined
    };
  },
  watch: {
    openSkydioModal(newValue) {
      this.modal = newValue;
    }
  },
  methods: {
    closeDialog() {
      this.selected_drone = null;
      this.drone_dates = [];
      this.$emit("cancel");
    },
    async getDrones() {
      try {
        let res = await this.$http.get("/skydio/get-drones", {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });

        this.drones = res.data;
        this.dronesLoading = false;
      } catch (err) {
        console.log("soemthing went wrong ", err);
      }
    },
    async checkDroneFlights() {
      try {
        this.dronesLoading = true;
        let res = await this.$http.get(
          `/skydio/get-available-flights/${this.selected_drone}`,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );

        this.dronesLoading = false;
        this.drone_dates = res.data;
        if (res.data.length < 1) {
          this.no_dates = true;
        }
      } catch (err) {
        console.log("soemthing went wrong ", err);
      }
    },
    async fetchDroneFlightData() {
      this.$store.commit("snackbarActivator", {
        open: true,
        success: true,
        message: "Flight has been successfully selected"
      });
      this.$emit("selectedFlight", this.selectedFlight.flight_id);
      this.$emit(
        "selectedFlightDescription",
        `Skydio selected flight with take off on
          ${
            this.selectedFlight.takeoff
              ? new Date(this.selectedFlight.takeoff)
                  .toString()
                  .split(" ")
                  .slice(0, 5)
                  .join(" ")
              : "----"
          }
          and landing on
          ${
            this.selectedFlight.landing
              ? new Date(this.selectedFlight.landing)
                  .toString()
                  .split(" ")
                  .slice(0, 5)
                  .join(" ")
              : "----"
          }`
      );
      this.closeDialog();
      // try {
      //   this.selectionLoading = true;
      //   let uploadObj = new FormData();
      //   uploadObj.append("asset_id", this.$route.params.assetID);
      //   uploadObj.append("flight_id", this.selectedFlight.flight_id);
      //   let res = await this.$http.post(`/skydio/download-flight-data`, uploadObj, {
      //     headers: {
      //       Authorization: "token " + localStorage.getItem("auth_token")
      //     }
      //   });
      //   console.log("response is ",res.data);
      //   setTimeout(() => {
      //     this.selectionLoading = false;
      //     this.confirmSelectFlight = false;
      //     this.$emit("fetchInspections");
      //     this.$store.commit("snackbarActivator", {
      //       open: true,
      //       success: true,
      //       message: "Flight has been successfully downloaded"
      //     });
      //     this.closeDialog();
      //   }, 5000);
      // } catch (err) {
      //   if (err.response.data.error.includes("this flight already")) {
      //     this.$store.commit("snackbarActivator", {
      //       open: true,
      //       success: false,
      //       message: "Flight has been already downloaded"
      //     });
      //     this.selectionLoading = false;
      //   }
      // }
    }
  },
  mounted() {
    this.getDrones();
  }
};
</script>

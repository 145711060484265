<template>
  <v-dialog ref="dialog" v-model="modal" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="cyan"
        class="mb-2 mt-3"
        block
        v-bind="attrs"
        v-on="on"
        :disabled="dataToList.length < 1"
        >Previous Files</v-btn
      >
    </template>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              SR
            </th>
            <th class="text-left">
              NAME
            </th>
            <th class="text-left">
              DOWNLOAD
            </th>
            <th class="text-left">
              ACTIONS
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, ind) in dataToList" :key="ind">
            <td>{{ ind + 1 }}</td>
            <td>{{ item.file_name }}</td>
            <td>
              <a :href="item.file_url" target="_blank">
                Download
              </a>
            </td>
            <td>
              <v-btn fab small @click="fileRemoveHandler(item.pk, ind)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-dialog>
</template>

<script>
export default {
  name: "PilotFilesDialog",
  props: ["filesData"],
  data() {
    return {
      modal: false,
      dataToList: []
    };
  },
  methods: {
    async fileRemoveHandler(id, index) {
      let filename = this.dataToList[index].file_name;
      this.dataToList[index].file_name = "Removing ...";
      try {
        await this.$http.delete(`/profile/delete-pilot-files/${id}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        this.dataToList.splice(index, 1);
        this.$emit("fileRemoved", true);
      } catch (err) {
        console.log("Something went wrong");
        this.dataToList[index].file_name = filename;
        this.$emit("fileRemoved", false);
      }
    }
  },
  mounted() {
    this.dataToList = this.filesData;
  }
};
</script>

<template>
  <login-parent>
    <v-snackbar v-model="success" color="success" top right
      >Password has been reset successfully</v-snackbar
    >
    <v-snackbar v-model="error" color="error" top right:timeout="10000">{{
      error_msg
    }}</v-snackbar>
    <v-row justify="center">
      <v-col md="8">
        <v-card class="rounded-lg" style="min-height: 50vh">
          <v-container fluid align="center" style="min-height: 50vh">
            <v-row justify="center" align="center" style="min-height: 49vh">
              <v-col md="6">
                <div class="text-center">
                  <v-icon color="white " class="font" style="font-size: 100px">
                    mdi-key
                  </v-icon>
                  <h1>Reset Password</h1>
                  <v-form
                    @submit.prevent="confirmPasswordHandler"
                    v-model="valid"
                  >
                    <v-text-field
                      label="Password"
                      type="password"
                      prepend-inner-icon="mdi-key"
                      :rules="rules.password"
                      v-model="password"
                    ></v-text-field>

                    <v-text-field
                      label="Confirm Password"
                      type="password"
                      prepend-inner-icon="mdi-key"
                      :rules="rules.password"
                      v-model="confirmPassword"
                    ></v-text-field>
                    <v-btn
                      color="primary mt-2"
                      block
                      rounded
                      elevation="2"
                      type="submit"
                      :loading="loading"
                      :disabled="!valid"
                    >
                      Submit
                    </v-btn>
                  </v-form>

                  <!-- <v-btn
                    to="/"
                    class="mt-3"
                    color="primary"
                    block
                    rounded
                    outlined
                    elevation="2"
                  >
                    Login
                  </v-btn> -->
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </login-parent>
</template>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
<script>
import LoginParent from "../components/LoginParent";

export default {
  name: "ForgetPassword",
  components: { LoginParent },
  data: () => {
    return {
      reveal: false,
      password: "",
      confirmPassword: "",
      rules: {
        password: [v => !!v || "Password is required"]
      },
      error_m: { name: "" },
      success: false,
      loading: false,
      error: false,
      error_msg: "",
      valid: false
    };
  },
  methods: {
    confirmPasswordHandler() {
      this.loading = true;
      this.$http
        .post("/rest-auth/password/reset/confirm/", {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          new_password1: this.password,
          new_password2: this.confirmPassword
        })
        .then(res => {
          console.log("sent");
          console.log(res.data);
          this.loading = false;
          this.success = true;
          setTimeout(() => {
            this.$router.push("/");
          }, 4000);
        })
        .catch(e => {
          this.error = true;
          this.loading = false;
          this.error_msg = e.response.data;
        });
    }
  }
};
</script>

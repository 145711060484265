import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

let PRIMARY = "#FF6600",
  SECONDARY = "#303641",
  ACCENT = "#82B1FF",
  ERROR = "#FF5252",
  INFO = "#2196F3",
  SUCCESS = "#4CAF50",
  WARNING = "#FFC107";

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: PRIMARY,
        secondary: SECONDARY,
        accent: ACCENT,
        error: ERROR,
        info: INFO,
        success: SUCCESS,
        warning: WARNING
      }
    }
  }
});

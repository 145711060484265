var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{style:(Object.assign({}, {height: _vm.mapheight,
        zIndex: 1},
        (_vm.isTransparent && { background: 'transparent' }))),attrs:{"id":"map"}}),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(!_vm.$store.state.isShared)?_c('v-speed-dial',{staticClass:"ml-4 mt-10 button-add-observations",staticStyle:{"position":"absolute"},attrs:{"top":"","right":"","direction":"bottom","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-2","dark":"","fab":"","small":""},model:{value:(_vm.createFeatureMenu),callback:function ($$v) {_vm.createFeatureMenu=$$v},expression:"createFeatureMenu"}},[(_vm.createFeatureMenu)?_c('v-icon',[_vm._v("mdi-close")]):_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true}],null,false,272773710),model:{value:(_vm.createFeatureMenu),callback:function ($$v) {_vm.createFeatureMenu=$$v},expression:"createFeatureMenu"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.createFeatureOnImage('Marker')}}},on),[_c('v-icon',[_vm._v("mdi-map-marker-outline")])],1)]}}],null,false,181569171)},[_c('span',[_vm._v("Add marker")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.createFeatureOnImage('Line')}}},on),[_c('v-icon',[_vm._v("mdi-call-made")])],1)]}}],null,false,2910703747)},[_c('span',[_vm._v("Add Line")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.createFeatureOnImage('Polygon')}}},on),[_c('v-icon',[_vm._v("mdi-shape-polygon-plus")])],1)]}}],null,false,1133339386)},[_c('span',[_vm._v("Add polygon")])])],1):_vm._e(),(_vm.imageIndex > 0)?_c('button',{staticClass:"vue-lb-arrow vue-lb-left",style:([
        _vm.defaultWidth != '100%' ? { zIndex: 2, top: '30%' } : { zIndex: 2 }
      ]),attrs:{"type":"button","title":"Previous"},on:{"click":function($event){_vm.showDraggable = false;
        _vm.$emit('imageChangeClicked', 'previous');}}},[_c('span',[_c('svg',{attrs:{"fill":"black","x":"0px","y":"0px","width":"100%","height":"100%","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9 c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8 L213.7,256z"}})])])]):_vm._e(),(_vm.imageIndex != _vm.arrayLength - 1)?_c('button',{staticClass:"vue-lb-arrow vue-lb-right",staticStyle:{"zIndex":"2"},style:([
        _vm.defaultWidth != '100%' ? { zIndex: 2, top: '30%' } : { zIndex: 2 }
      ]),attrs:{"type":"button","title":"Next"},on:{"click":function($event){_vm.showDraggable = false;
        _vm.$emit('imageChangeClicked', 'next');}}},[_c('span',[_c('svg',{attrs:{"fill":"black","x":"0px","y":"0px","width":"100%","height":"100%","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z"}})])])]):_vm._e(),(_vm.showDraggable)?_c('div',{directives:[{name:"drag",rawName:"v-drag"}],staticClass:"draggableComp",style:({ top: _vm.draggableDialogTop, left: _vm.draggableDialogLeft })},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.editableName)+" "),_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){_vm.annotatorSaveDialogEditing = true;
                _vm.saveModal = true;}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.editableDescription)+" ")]),_c('v-card-subtitle',{staticClass:"topPadding"},[_vm._v(" Category: "+_vm._s(_vm.editableCategory)+" ")]),_c('v-card-text',[_c('v-container',[(_vm.editableIconShow)?_c('div',{staticStyle:{"textAlign":"center"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"small":"","color":"primary"},on:{"click":_vm.finishEditing}},[_vm._v(" Finish Editing ")]),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.cancelEditing}},[_vm._v(" Cancel Editing ")])],1):(_vm.draggableIconShow)?_c('div',{staticStyle:{"textAlign":"center"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"small":"","color":"primary"},on:{"click":_vm.finishDraggable}},[_vm._v(" Finish Dragging ")]),_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.cancelDraggable}},[_vm._v(" Cancel Dragging ")])],1):_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Process")]),_c('th',[_vm._v("Action")])])]),_c('tbody',[(_vm.editable_drawn_by == 'Human')?_c('tr',[_c('td',[_vm._v("Drag")]),_c('td',[_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","small":""},on:{"click":_vm.draggableClicked}},[_c('v-icon',[_vm._v("mdi-drag-variant")])],1)],1)]):_vm._e(),(_vm.editable_drawn_by == 'Human')?_c('tr',[_c('td',[_vm._v("Edit")]),_c('td',[_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","small":""},on:{"click":_vm.editableClicked}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]):_vm._e(),_c('tr',[_c('td',[_vm._v("Remove")]),_c('td',[_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","small":""},on:{"click":function($event){_vm.removeModal = true;
                          _vm.showDraggable = false;}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])])]},proxy:true}],null,false,3106599863)})],1)],1),(!_vm.editableIconShow)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.draggableDialogCloseHandler}},[_vm._v("Cancel")])],1):_vm._e()],1)],1):_vm._e()],1),_c('AnnotatorSaveDialog',{attrs:{"saveModal":_vm.saveModal,"editing":_vm.annotatorSaveDialogEditing,"namer":_vm.editableName,"descriptionr":_vm.editableDescription,"categoryr":_vm.editableCategory},on:{"cancel":_vm.cancelNewObservation,"save":function (props) { return _vm.saveNewObservation(props, props.type); }}}),_c('AnnotatorRemoveDialog',{attrs:{"removeModal":_vm.removeModal},on:{"cancel":function($event){_vm.removeModal = false;
      _vm.showDraggable = true;},"remove":_vm.removeObservation}}),_c('v-snackbar',{attrs:{"right":"","color":_vm.snackbarColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
// v-model="selected_asset"
<template>
  <v-container fluid>
    <CreateAssetDialog
      :openCreateDialog="assetCreateDialog"
      @dialogClose="assetCreateDialog = false"
      @dialogAssetCreationComplete="assetCreationComplete"
      v-if="assetCreateDialog"
    />

    <h1>Request a survey</h1>

    <div class="loadingDivStyle" v-if="loading || uploadingProgress">
      <Loading loadingText="Creating survey!" v-if="loading" />

      <div v-else>
        <v-progress-linear v-model="uploadProgressNumber" height="40">
          <strong>{{ uploadProgressNumber }}%</strong>
        </v-progress-linear>
        <span class="progressLoadingDetail">
          {{ uploadProgressStatement }}
        </span>
      </div>
    </div>
    <v-container fluid v-else>
      <form @submit.prevent="createFlight">
        <v-select
          :menu-props="{ maxHeight: '400' }"
          :items="assets"
          :loading="assetLoading"
          :hint="assetLoadingReturn"
          :disabled="assetLoading"
          label="Asset *"
          @change="assetChangeHandler"
          v-model="selected_asset"
          persistent-hint
          clearable
          class="mb-5"
        >
          <template v-slot:append-outer>
            <v-btn class="mr-1" fab small @click="assetCreateDialog = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-select>

        <v-select
          :items="flight_types"
          :menu-props="{ maxHeight: '400' }"
          :loading="flightTypeLoading"
          :hint="flightTypeLoadingReturn"
          :disabled="flightTypeLoading"
          v-model="selected_flight_type"
          label="Survey Type *"
          item-text="name"
          item-value="pk"
          multiple
          persistent-hint
          class="mb-5"
        ></v-select>

        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Date Range for Survey *"
              hint="Choose date range for Survey"
              readonly
              v-bind="attrs"
              persistent-hint
              v-on="on"
              class="mb-5"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" scrollable range :min="dateMin">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-label>Location *</v-label>
        <custom-leaflet-map-draw
          @draw="mapDrawn"
          @delete="mapDeleted"
          :compX="mapCompX"
          :compY="mapCompY"
          :zoomLevel="mapZoomLevel"
        ></custom-leaflet-map-draw>

        <v-text-field
          v-model="nameOfCreateFlight"
          class="mt-5"
          label="Name *"
          :rules="NameRules"
          required
        />

        <v-textarea
          label="Description *"
          rows="3"
          class="mb-5"
          v-model="description"
          :rules="descriptionNameRules"
          required
        />

        <v-text-field label="Reference" class="mb-5" v-model="reference">
        </v-text-field>

        <v-label>Attachments</v-label>
        <div class="uploadMainDivStyle">
          <div class="dropper mt-3">
            <input
              type="file"
              class="dropperInput"
              multiple
              title="Upload Data"
              @change="fileChangeHandler"
            />
            <span class="dropperSpan">Drag Files Here!</span>
            <table class="dropperTable" v-if="uploadFilesList.length">
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Thumb</th>
                  <th>Name</th>
                  <th>Size</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, ind) in uploadFilesList" :key="item.name">
                  <td>{{ ind + 1 }}</td>
                  <td v-if="item.imagePresent">
                    <img class="uploadTableImage" :src="item.fileOriginPath" />
                  </td>
                  <td v-else>No Image</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.fileSize }}</td>
                  <td style="textAlign:center">
                    <v-btn fab small @click="removeUploadFileHandler(ind)">
                      <v-icon dark color="red">mdi-cancel</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
        ></vue-dropzone> -->
        <RequiredFields :message="errorMessage" v-if="fieldsCheck" />

        <v-btn
          type="submit"
          class="mt-4"
          block
          rounded
          color="primary"
          :disabled="fieldsCheck"
          >Create
        </v-btn>
      </form>
    </v-container>
    <v-snackbar v-model="snackbar" right color="green">
      {{ success_msg }}
    </v-snackbar>
  </v-container>
</template>

<script>
// import vueDropzone from "../components/vue-dropzone/vue-dropzone";
import CreateAssetDialog from "../components/asset/CreateAssetDialog";
import CustomLeafletMapDraw from "@/components/CustomLeafletMapDraw";
import RequiredFields from "../components/misc/RequiredFields";
import axios from "axios";
import Loading from "../components/misc/Loading";

export default {
  name: "CreateFlight",

  metaInfo() {
    return {
      title: this.$store.state.app_title,
      titleTemplate: "%s | Request a survey"
    };
  },

  components: {
    CreateAssetDialog,
    CustomLeafletMapDraw,
    RequiredFields,
    Loading
    // vueDropzone
  },

  data() {
    return {
      assetCreateDialog: false,
      assetsGeoJson: null,
      nameOfCreateFlight: null,
      NameRules: [v => !!v || "Name is required"],
      descriptionNameRules: [v => !!v || "Description is required"],
      flight_types: [],
      date: [],
      modal: false,
      selected_asset: null,
      selected_flight_type: null,
      reference: null,
      description: null,
      flightGeoJSON: {},
      assetLoading: true,
      flightTypeLoading: true,
      dateMin: new Date().toISOString(),
      mapCompX: 52.0116,
      mapCompY: 4.3571,
      mapZoomLevel: 18,
      errorMessage:
        "Complete Required (*) Fields to enable create button. Drawing on map is necessary.",
      loading: false,
      uploadingProgress: false,
      uploadProgressNumber: 0,
      uploadProgressStatement: "Uploading File",
      uploadFilesList: [],
      snackbar: false,
      success_msg: ""
    };
  },
  computed: {
    assets: function() {
      if (this.assetsGeoJson) {
        let assetsToReturn = [];
        this.assetsGeoJson.features.forEach(asset => {
          assetsToReturn.push({
            text: asset.properties.name,
            value: {
              id: asset.id,
              location: asset.geometry.coordinates
            }
          });
        });
        return assetsToReturn;
      }
      return [];
    },
    assetLoadingReturn() {
      if (this.assetLoading) {
        return "Wait! Data is being fetched";
      }
      return "Select any Asset";
    },
    flightTypeLoadingReturn() {
      if (this.flightTypeLoading) {
        return "Wait! Data is being fetched";
      }
      return "Select any survey Type";
    },
    fieldsCheck() {
      if (
        this.selected_flight_type &&
        this.selected_asset &&
        this.date.length > 0 &&
        this.description &&
        this.nameOfCreateFlight &&
        Object.keys(this.flightGeoJSON).length > 0
      ) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.getAssets();
    this.getFlightTypes();
  },
  methods: {
    assetCreationComplete() {
      this.snackbar = true;
      this.success_msg = "Asset has been successfully created";
      this.assetCreateDialog = false;
      this.selected_asset = null;
      this.getAssets();
    },
    mapDrawn(flightGeoJSON) {
      this.flightGeoJSON = flightGeoJSON.features[0].geometry;
    },
    mapDeleted() {
      this.flightGeoJSON = {};
    },
    createFlight() {
      this.loading = true;

      let createFlightObj = {
        asset_id: this.selected_asset.id,
        flight_type_ids: [...this.selected_flight_type],
        date_start: this.date[0],
        date_end: this.date[1],
        name: this.nameOfCreateFlight,
        description: this.description,
        reference: String(this.reference),
        geojson: JSON.stringify(this.flightGeoJSON)
      };

      this.$http
        .post("/create-flight/", createFlightObj, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(async res => {
          this.loading = false;

          if (this.uploadFilesList.length > 0) {
            this.uploadingProgress = true;
            let arrLength = this.uploadFilesList.length;

            for (let [ind, i] of this.uploadFilesList.entries()) {
              let uploadObj = new FormData();
              uploadObj.append("file_type", "attachment");
              uploadObj.append("inspection_id", res.data.inspection);
              uploadObj.append("filename", i.name);
              uploadObj.append("mime_type", i.type);

              let myPromise = new Promise((resolve, reject) => {
                this.$http
                  .post("/inspections/get-upload-object/", uploadObj, {
                    headers: {
                      Authorization:
                        "token " + localStorage.getItem("auth_token")
                    }
                  })
                  .then(async resIns => {
                    try {
                      let config = {
                        onUploadProgress: progressEvent => {
                          var percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          );
                          this.uploadProgressNumber = percentCompleted;

                          this.uploadProgressStatement = `Uploading File ${ind +
                            1}/${arrLength}`;
                          // if(percentCompleted == 100 && ind == arrLength-1){
                          //   this.snackbar = true;
                          //   this.success_msg = "Flight has been successfully uploaded";
                          //   this.$router.push({
                          //     path:`flights/${res.data.pk}`
                          //   });
                          //   this.uploadingProgress = false;
                          //   this.date = [];
                          //   this.selected_asset = null;
                          //   this.selected_flight_type = null;
                          //   this.reference = null;
                          //   this.description = null;
                          //   this.flightGeoJSON =  {};
                          //   this.dateMin = new Date().toISOString();
                          //   this.mapCompX = 52.0116;
                          //   this.mapCompY = 4.3571;
                          //   this.mapZoomLevel = 18;
                          //   this.uploadProgressNumber = 0;
                          //   this.uploadProgressStatement = 'Uploading File';
                          //   this.uploadFilesList = [];
                          // }
                        },
                        headers: {
                          "Content-Type": i.type
                        }
                      };

                      let resAxiosPut = await axios.put(
                        resIns.data.presigned_url,
                        i,
                        config
                      );

                      if (resAxiosPut) {
                        try {
                          let uploadCompleteObj = new FormData();
                          uploadCompleteObj.append("file_id", resIns.data.id);
                          let resComp = await this.$http.post(
                            "/upload-completed",
                            uploadCompleteObj,
                            {
                              headers: {
                                Authorization:
                                  "token " + localStorage.getItem("auth_token")
                              }
                            }
                          );

                          if (resComp) {
                            resolve(ind);
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      }
                    } catch (err) {
                      console.log(
                        "Some error has occured while uploading the files",
                        err
                      );
                      reject(err);
                    }
                  })
                  .catch(errIns => {
                    console.log(
                      "some error occured in inside",
                      errIns.response.data
                    );
                    this.loading = false;
                  });
              });

              let resMyPromise = await myPromise;

              if (resMyPromise == arrLength - 1) {
                this.snackbar = true;
                this.success_msg =
                  "Survey Files has been successfully uploaded";
                this.$router.push({
                  path: `surveys/${res.data.pk}`
                });
                this.uploadingProgress = false;
                this.date = [];
                this.selected_asset = null;
                this.selected_flight_type = null;
                this.reference = null;
                this.description = null;
                this.flightGeoJSON = {};
                this.dateMin = new Date().toISOString();
                this.mapCompX = 52.0116;
                this.mapCompY = 4.3571;
                this.mapZoomLevel = 18;
                this.uploadProgressNumber = 0;
                this.uploadProgressStatement = "Uploading File";
                this.uploadFilesList = [];
              }
            }
          } else {
            this.snackbar = true;
            this.success_msg = "Survey has been successfully created";
            this.$router.push({
              path: `surveys/${res.data.pk}`
            });
          }
        })
        .catch(err => {
          console.log("error occured", err.response.data);
        });
    },
    async getAssets() {
      let res = await this.$http.get("/assets/", {
        headers: {
          Authorization: "token " + localStorage.getItem("auth_token")
        }
      });

      this.assetsGeoJson = res.data;

      if (this.$route.query.assetDataID) {
        let foundObj = res.data.features.find(
          el => el.id == this.$route.query.assetDataID
        );
        if (foundObj) {
          this.selected_asset = {
            id: foundObj.id,
            location: foundObj.geometry.coordinates
          };
        }
      }

      this.assetLoading = false;
    },
    async getFlightTypes() {
      let res = await this.$http.get("/flight-types/", {
        headers: {
          Authorization: "token " + localStorage.getItem("auth_token")
        }
      });
      this.flight_types = res.data;
      this.flightTypeLoading = false;
    },
    assetChangeHandler(e) {
      this.mapZoomLevel = 18;
      this.mapCompX = e.location[1];
      this.mapCompY = e.location[0];
      this.selected_asset = e;
    },
    niceBytes(x) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    },
    fileChangeHandler(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        e.target.files[i].fileOriginPath = URL.createObjectURL(
          e.target.files[i]
        );
        if (e.target.files[i].type.split("/").shift() == "image") {
          e.target.files[i].imagePresent = true;
        } else {
          e.target.files[i].imagePresent = false;
        }
        e.target.files[i].fileSize = this.niceBytes(e.target.files[i].size);
      }

      this.uploadFilesList.push(...e.target.files);
    },
    removeUploadFileHandler(ind) {
      let duplicateArray = [...this.uploadFilesList];
      duplicateArray.splice(ind, 1);
      this.uploadFilesList = duplicateArray;
    }
  }
};
</script>

<style scoped>
.dropper {
  min-height: 30vh;
  border: 2px dashed white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s;
}

.dropper:hover {
  background-color: #eeeeee42;
}

.dropperInput {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.dropperSpan {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  position: absolute;
  pointer-events: none;
}

.loadingDivStyle {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropperTable {
  width: 100%;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  align-self: flex-start;
}

.dropperTable thead tr {
  background-color: grey;
}

.dropperTable thead th {
  position: sticky;
  top: 0;
  background-color: grey;
  z-index: 1000;
}

.dropperTable td {
  padding: 0.75rem;
  border-bottom: 1px dashed grey;
}

.uploadTableImage {
  max-width: 4em;
  max-height: 4em;
  vertical-align: middle;
  border-style: none;
}

.progressLoadingDetail {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  display: inline-block;
  margin-top: 10px;
}

.uploadMainDivStyle {
  position: relative;
  max-height: 450px;
  overflow-y: scroll;
}
</style>

<template>
  <div>
    <!-- style="left:calc(50% + 100px);top:calc(50% - 130px)" -->

    <!-- Draggable Info Component -->

    <div
      v-drag
      class="draggableComp"
      :style="[
        showDraggable
          ? {
              visibility: 'visible',
              left: 'calc(50% + 100px)',
              top: 'calc(50% - 130px)'
            }
          : { visibility: 'hidden' }
      ]"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ clickedData.name }}
            <v-btn
              fab
              small
              @click="
                showDraggable = false;
                potreeEditingData.editingMode = true;
                potreeEditingData.eName = clickedData.name;
                potreeEditingData.eDescription = clickedData.description;
                saveModal = true;
              "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </span>
        </v-card-title>
        <v-card-subtitle>
          {{ clickedData.description }}
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Process</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Visibility</td>
                    <td>
                      <v-btn class="mr-1" fab small @click="visibilityHandler">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Remove</td>
                    <td>
                      <v-btn
                        class="mr-1"
                        fab
                        small
                        @click="
                          showDraggable = false;
                          removeModal = true;
                        "
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="draggableCloseHandler"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>

    <AnnotatorRemoveDialog
      :removeModal="removeModal"
      :loading="removeModalLoading"
      @cancel="
        removeModal = false;
        showDraggable = true;
      "
      @remove="removePotreeAnnotationHandler"
    />

    <v-navigation-drawer
      permanent
      expand-on-hover
      style="position:absolute;zIndex:10;backgroundColor:rgba(30, 30, 30, 0.28)"
    >
      <v-list nav dense style="cursor:pointer">
        <!-- <v-list-item >
          <v-list-item-icon>
            <v-icon> mdi-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Controls</v-list-item-title>
        </v-list-item> -->
        <!-- <v-list-item
          
        >
          <v-list-item-icon>
            <v-icon>mdi-drawing</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Draw</v-list-item-title>
        </v-list-item> -->
        <v-list-item @click="showMeasure = !showMeasure">
          <v-list-item-icon>
            <v-icon>mdi-tape-measure</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Measure</v-list-item-title>
        </v-list-item>

        <v-list-group :value="false" prepend-icon="mdi-wrench">
          <template v-slot:activator>
            <v-list-item-title>Manage</v-list-item-title>
          </template>

          <v-list-group :value="false" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Distance</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item style="display:none">
              <v-list-item-title></v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(distance, ind) in distanceList"
              :key="distance.uuid"
              @click="zoomHandlerToSpecific(distance, 'distanceList', ind)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ distance.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Height</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item style="display:none">
              <v-list-item-title></v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(height, ind) in heightList"
              :key="height.uuid"
              @click="zoomHandlerToSpecific(height, 'heightList', ind)"
            >
              <v-list-item-title>{{ height.name }}</v-list-item-title>

              <!-- <v-list-item-icon>
              <v-icon >mdi-camera-image</v-icon>
            </v-list-item-icon> -->
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Area</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item style="display:none">
              <v-list-item-title></v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(area, ind) in areaList"
              :key="area.uuid"
              @click="zoomHandlerToSpecific(area, 'areaList', ind)"
            >
              <v-list-item-title>{{ area.name }}</v-list-item-title>

              <!-- <v-list-item-icon>
              <v-icon >mdi-camera-image</v-icon>
            </v-list-item-icon> -->
            </v-list-item>
          </v-list-group>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Angle</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item style="display:none">
              <v-list-item-title></v-list-item-title>
            </v-list-item>
            <v-list-item
              v-for="(angle, ind) in angleList"
              :key="angle.uuid"
              @click="zoomHandlerToSpecific(angle, 'angleList', ind)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ angle.name }}</v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-icon>
              <v-icon >mdi-camera-image</v-icon>
            </v-list-item-icon> -->
            </v-list-item>
          </v-list-group>
        </v-list-group>
        <v-list-item @click="show2DImages = !show2DImages">
          <v-list-item-icon>
            <v-icon>mdi-camera-image</v-icon>
          </v-list-item-icon>
          <v-list-item-title>2D Images</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="potree_container" style="width: 100%%; height: 100vh;">
      <!-- style="position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; " -->

      <div
        id="potree_render_area"
        style="background-image: url('/buildPotree/potree/resources/images/background.jpg');"
      ></div>
      <div id="potree_sidebar_container"></div>

      <PotreeViewerSaveDialog
        :saveModal="saveModal"
        :loading="saveModalLoading"
        :editingMode="potreeEditingData.editingMode"
        :eName="potreeEditingData.eName"
        :eDescription="potreeEditingData.eDescription"
        @save="savePotreeAnnotationDialog"
        @cancel="cancelPotreeAnnotations"
        @edit="editPotreeAnnotationHandler"
      />

      <div
        v-drag
        class="draggableComp"
        style="right: 400px;top: calc(50% - 130px);"
        v-if="editModal"
      >
        <v-card>
          <v-card-title>
            <span class="headline">
              Edit Annotation
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div style="textAlign:center;">
                <v-btn
                  small
                  color="primary"
                  class="mr-5"
                  :loading="editModalLoading"
                  @click="editModalConfirmHandler"
                >
                  Finish Editing
                </v-btn>
                <v-btn
                  small
                  color="primary"
                  @click="editModalCancelHandler"
                  v-if="!editModalLoading"
                >
                  Cancel Editing
                </v-btn>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-sheet
      class="mx-auto"
      elevation="8"
      style="position: absolute;zIndex: 100;bottom: 0;left:0;right:0;backgroundColor:#1e1e1e47;"
      :style="[
        show2DImages ? { visibility: 'visible' } : { visibility: 'hidden' }
      ]"
    >
      <div
        style="display: flex; alignItems: center;flexFlow: row-reverse;paddingTop: 10px;"
      >
        <v-btn dark text @click="show2DImages = false">
          Close
        </v-btn>
      </div>
      <div
        class="collectedDataResult"
        style="marginTop:30px;paddingBottom:30px"
        v-if="potreeImagesCollectedDataArr.length < 1"
      >
        Please Upload Some Images!
      </div>

      <v-slide-group
        v-model="sliderModel"
        class="pa-4 pt-0"
        center-active
        show-arrows
        v-else
      >
        <v-slide-item
          v-for="(image, index) in potreeImagesCollectedDataArr"
          :key="image.id"
          style="width:100px;height:100px;marginLeft:15px;overflow:revert"
          class="d-flex child-flex leaflet-container"
          v-slot="{ active, toggle }"
        >
          <div
            :id="'map' + index + 'test'"
            style="width:100px;zIndex:1;height:100px;boxSizing: content-box;"
            :style="[
              active
                ? {
                    border: '5px solid rgb(255, 102, 0)',
                    borderRadius: '10px'
                  }
                : { border: 'none' }
            ]"
            @click="
              () => {
                toggle();
                imageToPotreeHandler(image);
              }
            "
          >
            <div
              class="pricedrop-tag"
              v-if="image.loadComplete && image.annotation_count > 0"
            >
              <div class="pricedrop-ribbon urgent">
                {{ image.annotation_count }} Annotation{{
                  image.annotation_count > 1 ? "s" : ""
                }}
              </div>
            </div>
            <div
              style="position: absolute;
                      bottom: 5px;
                      left: 5px;
                      z-index: 1000;"
            >
              <v-btn
                fab
                x-small
                @click.stop="() => bigAnnotatorOpenHandler(index)"
              >
                <v-icon dark color="white">mdi-pencil</v-icon>
              </v-btn>
            </div>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <div
      style="position:absolute;userSelect:none;left:calc(50% - 65px);top:calc(50% - 130px);background:#212121;borderRadius:16px;padding:20px 0px;zIndex:2000;"
      v-drag
      v-if="showMeasure"
    >
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            style="borderBottom:1px solid #303030;padding:4px"
            @click="toolPotreeHandler('DISTANCE')"
          >
            <button>
              <svg
                width="40px"
                height="40px"
                fill="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  d="M19 2a3.003 3.003 0 0 0-3 3c.003.458.112.91.319 1.319l-.026-.026-10 10 .026.026A2.962 2.962 0 0 0 5 16a3 3 0 1 0 3 3 2.963 2.963 0 0 0-.319-1.319l.026.026 10-10-.026-.026c.41.207.86.316 1.319.319a3 3 0 1 0 0-6zM5 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM19 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                ></path>
              </svg>
            </button>
          </div>
        </template>
        <span>Measure a Distance</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            style="borderBottom:1px solid #303030;padding:4px"
            @click="toolPotreeHandler('HEIGHT')"
          >
            <button>
              <svg
                width="40px"
                height="40px"
                fill="white"
                style="transform:rotate(-45deg)"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  d="M19 2a3.003 3.003 0 0 0-3 3c.003.458.112.91.319 1.319l-.026-.026-10 10 .026.026A2.962 2.962 0 0 0 5 16a3 3 0 1 0 3 3 2.963 2.963 0 0 0-.319-1.319l.026.026 10-10-.026-.026c.41.207.86.316 1.319.319a3 3 0 1 0 0-6zM5 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM19 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                ></path>
              </svg>
            </button>
          </div>
        </template>
        <span>Measure a Height</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            style="borderBottom:1px solid #303030;padding:4px"
            @click="toolPotreeHandler('AREA')"
          >
            <button>
              <svg
                width="40px"
                height="40px"
                fill="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  d="M20 16.184V7.816A2.992 2.992 0 1 0 16.184 4H7.816A2.993 2.993 0 1 0 4 7.816v8.368A2.992 2.992 0 1 0 7.816 20h8.368A2.993 2.993 0 1 0 20 16.184zM16.184 18H7.816A2.995 2.995 0 0 0 6 16.184V7.816A2.996 2.996 0 0 0 7.816 6h8.368A2.997 2.997 0 0 0 18 7.816v8.368A2.996 2.996 0 0 0 16.184 18zM19 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zM5 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0 16a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm14 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                ></path>
              </svg>
            </button>
          </div>
        </template>
        <span>Measure an Area</span>
      </v-tooltip>

      <!-- <v-tooltip left>
          <template v-slot:activator="{ on }">
            <div style="borderBottom:1px solid #303030;padding:4px;" v-on="on">
              <button>
                <svg
                  width="40px"
                  height="40px"
                  fill="white"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <path
                    d="M22 6c-.535-2.628-5.32-4-10-4S2.535 3.372 2 6v12c.535 2.628 5.32 4 10 4s9.465-1.372 10-4V6zM12 4c5.176 0 8 1.651 8 2.5S17.176 9 12 9 4 7.349 4 6.5 6.824 4 12 4zm0 16c-5.176 0-8-1.651-8-2.5V9.303A16.972 16.972 0 0 0 12 11a16.972 16.972 0 0 0 8-1.697V17.5c0 .849-2.824 2.5-8 2.5z"
                  ></path>
                  <path
                    d="m4.127 14.626.974 1.747a8.06 8.06 0 0 1 1.379-.589l-.63-1.898c-.596.193-1.172.44-1.723.74z"
                  ></path>
                  <path
                    d="m7.6 13.424.402 1.96a17.88 17.88 0 0 1 1.58-.25l-.225-1.987c-.602.068-1.193.161-1.757.277z"
                  ></path>
                  <path
                    d="M14.414 15.133c.548.063 1.08.147 1.58.25l.403-1.96a20.072 20.072 0 0 0-1.757-.277l-.226 1.987z"
                  ></path>
                  <path
                    d="m18.143 13.884-.629 1.898c.477.153.938.35 1.378.588l.975-1.747a9.98 9.98 0 0 0-1.724-.74z"
                  ></path>
                  <path
                    d="m11.117 13.016.073 1.999c.255-.01.514-.015.776-.015.275-.005.563.004.84.015l.073-2c-.29-.01-.582-.015-.877-.015-.318-.007-.594.005-.885.016z"
                  ></path>
                </svg>
              </button>
            </div>
          </template>
          <span>Measure a Volume</span>
        </v-tooltip> -->

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div
            style="borderBottom:1px solid #303030;padding:4px;paddingLeft:8px;paddingTop:8px"
            v-on="on"
            @click="toolPotreeHandler('ANGLE')"
          >
            <button>
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="35px"
                height="35px"
                fill="white"
                viewBox="0 0 93.535 93.535"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M88.653,93.535c2.255,0,4.07-1.814,4.07-4.07V74.834c0-2.256-1.815-4.07-4.07-4.07s-4.07,1.814-4.07,4.07v3.245H43.936
			c0.138-0.248,0.248-0.522,0.248-0.825c0-1.705-0.138-3.438-0.413-5.115c-0.138-0.879-0.962-1.513-1.87-1.348
			c-0.88,0.139-1.485,0.962-1.348,1.87c0.248,1.513,0.357,3.053,0.357,4.593c0,0.303,0.11,0.577,0.248,0.825H16.239V48.596
			c1.402,0.221,2.805,0.522,4.152,0.936c0.165,0.056,0.33,0.082,0.468,0.082c0.688,0,1.348-0.439,1.567-1.154
			c0.275-0.854-0.22-1.76-1.072-2.035c-1.65-0.495-3.328-0.88-5.006-1.1c-0.027,0-0.055,0-0.082,0V8.14h3.245
			c2.255,0,4.07-1.815,4.07-4.07c0-2.255-1.815-4.07-4.07-4.07H4.881c-2.256,0-4.07,1.815-4.07,4.07c0,2.255,1.814,4.07,4.07,4.07
			h3.245v74.036c0,2.256,1.815,4.07,4.07,4.07h72.413v3.218C84.583,91.719,86.398,93.535,88.653,93.535z"
                    />
                    <path
                      d="M23.967,49.036c-0.385,0.798-0.055,1.788,0.743,2.173c1.375,0.688,2.695,1.458,3.96,2.365
			c0.275,0.191,0.605,0.303,0.936,0.303c0.522,0,1.018-0.247,1.319-0.688c0.523-0.742,0.358-1.76-0.385-2.283
			c-1.402-0.989-2.888-1.87-4.4-2.612C25.315,47.909,24.354,48.238,23.967,49.036z"
                    />
                    <path
                      d="M37.856,58.084c-1.018-1.375-2.145-2.668-3.383-3.878c-0.632-0.634-1.677-0.604-2.31,0.026
			c-0.633,0.633-0.605,1.679,0.027,2.312c1.1,1.072,2.117,2.255,3.025,3.492c0.33,0.44,0.825,0.659,1.32,0.659
			c0.33,0,0.688-0.108,0.962-0.329C38.241,59.816,38.378,58.799,37.856,58.084z"
                    />
                    <path
                      d="M39.479,68.178c0.22,0.688,0.853,1.127,1.54,1.127c0.165,0,0.33-0.027,0.495-0.082c0.853-0.275,1.32-1.211,1.045-2.062
			c-0.522-1.622-1.21-3.219-1.98-4.73c-0.412-0.797-1.402-1.1-2.2-0.688c-0.797,0.412-1.1,1.402-0.688,2.199
			C38.378,65.29,38.984,66.721,39.479,68.178z"
                    />
                    <g>
                      <path
                        d="M46.491,53.546c-0.412-0.247-0.715-0.688-0.715-1.237c0-0.771,0.633-1.375,1.431-1.375c0.302,0,0.55,0.109,0.77,0.248
				c0.715,0.412,1.32,0.604,2.173,0.604c1.678,0,2.585-1.267,2.776-3.108c-0.66,0.578-1.594,0.936-2.776,0.936
				c-2.971,0-4.868-1.649-4.868-4.399v-0.027c0-2.751,2.118-4.786,5.198-4.786c1.843,0,2.915,0.44,3.933,1.485
				c0.963,0.963,1.623,2.448,1.623,5.143v0.027c0.027,4.455-2.035,7.48-5.939,7.48C48.583,54.537,47.509,54.18,46.491,53.546z
				 M53.011,45.103v-0.027c0-1.21-0.908-2.09-2.394-2.09s-2.312,0.853-2.312,2.062v0.027c0,1.21,0.908,2.062,2.394,2.062
				C52.186,47.137,53.011,46.286,53.011,45.103z"
                      />
                      <path
                        d="M58.181,47.496v-0.027c0-3.959,2.447-7.068,6.078-7.068c3.603,0,6.051,3.054,6.051,7.014v0.026
				c0,3.961-2.448,7.068-6.078,7.068C60.602,54.537,58.181,51.457,58.181,47.496z M67.257,47.496v-0.027
				c0-2.475-1.211-4.345-2.998-4.345s-2.971,1.814-2.971,4.317v0.027c0,2.502,1.184,4.346,2.997,4.346
				C66.101,51.814,67.257,49.971,67.257,47.496z"
                      />
                      <path
                        d="M72.179,43.563v-0.027c0-1.65,1.32-2.998,3.025-2.998s2.997,1.32,2.997,2.971v0.027c0,1.65-1.319,2.998-3.024,2.998
				C73.444,46.534,72.179,45.213,72.179,43.563z M72.261,48.238c0-0.356,0.304-0.633,0.634-0.633h4.565
				c0.356,0,0.633,0.304,0.633,0.633c0,0.357-0.303,0.633-0.633,0.633h-4.565C72.564,48.898,72.261,48.596,72.261,48.238z
				 M76.607,43.563v-0.027c0-0.853-0.577-1.595-1.431-1.595c-0.853,0-1.375,0.715-1.375,1.567v0.027
				c0,0.853,0.578,1.595,1.431,1.595S76.607,44.388,76.607,43.563z"
                      />
                    </g>
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>
          </div>
        </template>
        <span>Measure an Angle</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            style="borderBottom:1px solid #303030;padding:4px"
            @click="centerModelHandler"
          >
            <button>
              <svg
                width="40px"
                height="40px"
                fill="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path d="M5 15H3v4a2.006 2.006 0 0 0 2 2h4v-2H5v-4z"></path>
                <path d="M5 5h4V3H5a2.006 2.006 0 0 0-2 2v4h2V5z"></path>
                <path d="M19 3h-4v2h4v4h2V5a2.006 2.006 0 0 0-2-2z"></path>
                <path d="M19 19h-4v2h4a2.006 2.006 0 0 0 2-2v-4h-2v4z"></path>
                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
              </svg>
            </button>
          </div>
        </template>
        <span>Center Model</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            style="borderBottom:1px solid #303030;padding:4px"
            @click="viewer.scene.cameraP.zoom += 0.5"
          >
            <button>
              <svg
                width="40px"
                height="40px"
                fill="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  d="M15.5 14h-.79l-.28-.27a6.51 6.51 0 1 0-.7.7l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0a4.5 4.5 0 1 1 4.16-2.777A4.493 4.493 0 0 1 9.5 14z"
                ></path>
                <path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z"></path>
              </svg>
            </button>
          </div>
        </template>
        <span>Zoom In</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <div
            v-on="on"
            style="borderBottom:1px solid #303030;padding:4px"
            @click="viewer.scene.cameraP.zoom -= 0.5"
          >
            <button>
              <svg
                width="40px"
                height="40px"
                fill="white"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fit=""
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  d="M15.5 14h-.79l-.28-.27a6.51 6.51 0 1 0-.7.7l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0a4.5 4.5 0 1 1 4.16-2.777A4.493 4.493 0 0 1 9.5 14z"
                ></path>
                <path d="M12 10H7V9h5v1z"></path>
              </svg>
            </button>
          </div>
        </template>
        <span>Zoom Out</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
// import * as THREE from "./threejs/build/three.module.js";
// import * as THREE from "three";
// import Potree from '../buildPotree/potree/potree.js';
// import Potree from '../../../public/buildPotree/potree/potree.js'
// import '../../../public/buildPotree/potree/potree.css'

import * as L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

const Potree = window.Potree;
const THREE = window.THREE;
const TWEEN = window.TWEEN;

import PotreeViewerSaveDialog from "./PotreeViewerSaveDialog.vue";
import AnnotatorRemoveDialog from "./AnnotatorRemoveDialog.vue";

import proj4 from "proj4";

export default {
  name: "PotreeViewer",
  props: ["resultData", "potreeCamera", "potreeImagesCollectedData"],
  components: {
    PotreeViewerSaveDialog,
    AnnotatorRemoveDialog
  },
  data() {
    return {
      show2DImages: false,
      viewer: undefined,
      sliderModel: null,
      measureStarted: false,
      saveModal: false,
      saveModalLoading: false,
      currentMeasurement: undefined,
      saveGEOJSON: undefined,
      arrayLength: undefined,
      potreeImagesCollectedDataArr: [],
      showMeasure: false,
      distanceList: [],
      heightList: [],
      areaList: [],
      angleList: [],
      lastUUIDClicked: undefined,
      initialPosition: "test",
      showDraggable: false,
      clickedData: {},
      removeModal: false,
      removeModalLoading: false,
      potreeEditingData: {},
      fullyLoaded: false,
      editModal: false,
      editModalLoading: false,
      editModalUuid: undefined,
      initialYaw: undefined,
      initialPitch: undefined
    };
  },
  methods: {
    imageToPotreeHandler(image) {
      let image_properties = this.getImageProperties(image.file_name);
      console.log("image properties are ", image_properties);
      if (image_properties) {
        const { Omega, Phi, Kappa } = image_properties;

        let firstProjection = "+proj=longlat +datum=WGS84 +no_defs";
        let secondProjection =
          "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs";

        let valuesOfProj4 = proj4(firstProjection, secondProjection, [
          Number(image_properties.X),
          Number(image_properties.Y)
        ]);

        console.log("values of proj4 are ", valuesOfProj4);
        console.log("Omega --- Phi --- Kappa", Omega, "---", Phi, "---", Kappa);

        this.viewer.scene.view.position.x = valuesOfProj4[0];
        this.viewer.scene.view.position.y = valuesOfProj4[1];
        this.viewer.scene.view.position.z = Number(image_properties.Z);

        let a = new THREE.Euler(
          (Number(Omega) * Math.PI) / 180,
          (Number(Phi) * Math.PI) / 180,
          (Number(Kappa) * Math.PI) / 180,
          "XYZ"
        );
        // console.log("a is ",a)
        // let b = new THREE.Vector3( 0, 0, -1 );

        let b = new THREE.Vector3(0, 0.05, -1);
        b.applyEuler(a);
        b.x = b.x + valuesOfProj4[0];
        b.y = b.y + valuesOfProj4[1];
        b.z = b.z + Number(image_properties.Z);

        this.viewer.scene.view.lookAt(b);

        console.log("viewer is ", this.viewer);
      }
    },
    // imageToPotreeHandler(image) {
    //   // console.log("projection is ",this.viewer.getProjection())
    //   let image_properties = this.getImageProperties(image.file_name);
    //   console.log("image properties are ", image_properties);
    //   if (image_properties) {

    //     let {
    //       r11,
    //       r12,
    //       r13,
    //       r21,
    //       r22,
    //       r23,
    //       r31,
    //       r32,
    //       r33
    //     } = image_properties;

    //     let firstProjection = "+proj=longlat +datum=WGS84 +no_defs";
    //     let secondProjection =
    //       "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs";

    //     let valuesOfProj4 = proj4(firstProjection, secondProjection, [
    //       Number(image_properties.X),
    //       Number(image_properties.Y)
    //     ]);
    //     console.log("values of proj4 are ", valuesOfProj4);

    //     // this.viewer.scene.view.yaw =
    //     //   (Math.PI / 180) * Number(image_properties.Kappa);
    //     // let valOmega = (Math.PI / 180) * Number(image_properties.Omega);
    //     // this.viewer.scene.view.pitch = valOmega - Math.PI / 2;

    //     // The following code is for testing purpose
    //     // this.viewer.scene.view.yaw = (Math.PI / 180) * Number(image_properties.Kappa);
    //     // let valOmega = (Math.PI / 180) * Number(image_properties.Phi);
    //     // this.viewer.scene.view.pitch = valOmega - Math.PI / 2;

    //     const m = new THREE.Matrix4();
    //       m.set(  Number(r11), Number(r21), Number(r31), valuesOfProj4[0],
    //               Number(r12), Number(r22), Number(r32), valuesOfProj4[1],
    //               Number(r13), Number(r23), Number(r33), Number(image_properties.Z),
    //               0, 0, 0, 1);
    //       console.log("check the matrix values ",m)
    //       let matrixEulerTest = new THREE.Euler();
    //       matrixEulerTest.setFromRotationMatrix(m,"ZXY")
    //       console.log("euler from matrix is ",matrixEulerTest);

    //     this.viewer.scene.view.yaw = matrixEulerTest.z;
    //     this.viewer.scene.view.pitch = matrixEulerTest.x - Math.PI / 2;

    //     console.log("yaw is ", this.viewer.scene.view.yaw);
    //     console.log("pitch is ", this.viewer.scene.view.pitch);

    //     // let pointMaterial = new THREE.PointsMaterial({
    //     //   size: 10,
    //     //   color: 0xff0000,
    //     //   sizeAttenuation: false
    //     // });
    //     // var pointGeometry = new THREE.Geometry();
    //     // pointGeometry.vertices.push(
    //     //   new THREE.Vector3(
    //     //     valuesOfProj4[0],
    //     //     valuesOfProj4[1],
    //     //     image_properties.Z
    //     //   )
    //     // );
    //     // var point = new THREE.PointCloud(pointGeometry, pointMaterial);
    //     // this.viewer.scene.scene.add(point);

    //     let a = new THREE.Vector3(
    //       valuesOfProj4[0],
    //       valuesOfProj4[1],
    //       Number(image_properties.Z)
    //     );
    //     // eslint-disable-next-line no-unused-vars
    //     let b = new THREE.Vector3(valuesOfProj4[0], valuesOfProj4[1], 0);

    //     // this.zoomToPotreeImage(500, a, b,image_properties.Phi);
    //     this.viewer.scene.view.radius = Number(image_properties.Z);
    //     this.viewer.scene.view.position.copy(a);
    //      this.viewer.scene.cameraP.rotation.set(
    //       Math.PI / 2 + this.viewer.scene.view.pitch,
    //       matrixEulerTest.y,
    //       this.viewer.scene.view.yaw,
    //       "ZXY"
    //     );
    //     // this.viewer.scene.cameraP.rotation.set(
    //     //   Math.PI / 2 + this.viewer.scene.view.pitch,
    //     //   (Math.PI / 180) * Number(image_properties.Phi),
    //     //   this.viewer.scene.view.yaw,
    //     //   "ZXY"
    //     // );
    //     // const testThis = new THREE.Euler(  Math.PI / 2 + this.viewer.scene.view.pitch,
    //     //   (Math.PI / 180) * Number(image_properties.Phi),
    //     //   this.viewer.scene.view.yaw,
    //     //   "ZXY" );
    //       // console.log("check the direct euler value ",testThis);

    //     this.viewer.scene.cameraP.updateMatrix();
    //     this.viewer.scene.cameraP.updateMatrixWorld();
    //     this.viewer.scene.cameraP.updateProjectionMatrix();
    //     console.log("rotation is ", this.viewer.scene.cameraP.rotation);
    //     setTimeout(() => {
    //       console.log("after yaw is ", this.viewer.scene.view.yaw);
    //       console.log("after pitch is ", this.viewer.scene.view.pitch);
    //       console.log("initial pitch is ", this.initialPitch);
    //       console.log("viewer is ", this.viewer);
    //     }, 20000);
    //   }
    // },
    // eslint-disable-next-line no-unused-vars
    zoomToPotreeImage(animationDuration = 0, a, b, p) {
      let view = this.viewer.scene.view;

      let camera = this.viewer.scene.cameraP.clone();
      camera.rotation.copy(this.viewer.scene.cameraP.rotation);
      // camera.rotation.order = "ZXY";

      // camera.rotation.x = Math.PI / 2 + view.pitch;
      console.log("value of p is ", p);
      // camera.rotation.y = (Math.PI / 180) * Number(p);
      // camera.rotation.z = view.yaw;
      camera.rotation.set(
        Math.PI / 2 + view.pitch,
        (Math.PI / 180) * Number(p),
        view.yaw,
        "YXZ"
      );
      camera.updateMatrix();
      camera.updateMatrixWorld();

      let startPosition = view.position.clone();
      let endPosition = a;
      // eslint-disable-next-line no-unused-vars
      let startTarget = view.getPivot();
      // eslint-disable-next-line no-unused-vars
      let endTarget = b;

      // eslint-disable-next-line no-unused-vars
      let easing = TWEEN.Easing.Quartic.Out;

      {
        // animate camera position
        let pos = startPosition.clone();
        let tween = new TWEEN.Tween(pos).to(endPosition, animationDuration);
        tween.easing(easing);

        tween.onUpdate(() => {
          view.position.copy(pos);
        });

        tween.onComplete(() => {
          let V = new THREE.Vector3().subVectors(endTarget, endPosition);
          let radius = V.length();
          this.viewer.scene.view.radius = 10;
          console.log("radius is ", radius);
        });

        tween.start();
      }

      // {
      //   // animate camera target
      //   let target = startTarget.clone();
      //   let tween = new TWEEN.Tween(target).to(endTarget, animationDuration);
      //   tween.easing(easing);
      //   tween.onUpdate(() => {
      //     view.lookAt(target);
      //   });
      //   tween.onComplete(() => {
      //     view.lookAt(target);
      //     // this.viewer.scene.view.yaw = -1.5210009464763711851
      //     // this.viewer.scene.view.pitch = -0.7853981633981372
      //   });

      //   tween.start();
      // }
    },
    getImageProperties(iamge_name) {
      return this.potreeCamera.find(item => iamge_name.includes(item.PhotoID));
    },
    async removePotreeAnnotationHandler() {
      this[this.clickedData.type].splice(this.clickedData.ind, 1);
      this.removeModalLoading = true;
      await this.$http.delete(
        `/delete-potree-annotation/${this.clickedData.pk}`,
        {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        }
      );
      this.removeModalLoading = false;
      this.removeModal = false;
      let foundMeasurement = this.viewer.scene.measurements.find(
        el => this.clickedData.uuid == el.uuid
      );
      this.viewer.scene.removeMeasurement(foundMeasurement);
      this.lastUUIDClicked = undefined;
      this.$store.commit("snackbarActivator", {
        open: true,
        success: true,
        message: "Annotation has been removed successfully"
      });
      this.viewer.fitToScreen();
    },
    async editPotreeAnnotationHandler(obj) {
      let returnedJSON = this.measurementToFeatures(
        this.viewer.scene.measurements[this.clickedData.foundInd]
      );

      let geojson = {
        type: "FeatureCollection",
        features: returnedJSON
      };

      this.saveModalLoading = true;
      let uploadObj = new FormData();
      geojson.name = obj.name;
      geojson.description = obj.description;

      uploadObj.append("annotation_obj", JSON.stringify(geojson));
      uploadObj.append("inspection_id", this.$route.params.inspectionID);

      await this.$http.put(
        `/edit-potree-annotations/${this.clickedData.pk}`,
        uploadObj,
        {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        }
      );

      let saveObj = {
        uuid: this.viewer.scene.measurements[this.clickedData.foundInd].uuid,
        pk: this.clickedData.pk,
        name: obj.name,
        description: obj.description
      };

      switch (geojson.features[0].properties.name) {
        case "Distance":
          this.distanceList.splice(this.clickedData.ind, 1, saveObj);
          break;
        case "Area":
          this.areaList.splice(this.clickedData.ind, 1, saveObj);
          break;
        case "Angle":
          this.angleList.splice(this.clickedData.ind, 1, saveObj);
          break;
        case "Height":
          this.heightList.splice(this.clickedData.ind, 1, saveObj);
      }
      this.saveModalLoading = false;
      this.saveModal = false;
      this.potreeEditingData.editingMode = false;
      this.$store.commit("snackbarActivator", {
        open: true,
        success: true,
        message: "Annotation successfully Edited"
      });

      if (this.lastUUIDClicked) {
        let lastFoundObjIndex = this.viewer.scene.measurements.findIndex(
          el => this.lastUUIDClicked == el.uuid
        );
        const color = new THREE.Color(0xff0000);
        this.viewer.scene.measurements[lastFoundObjIndex].color = color;
      }
      this.showDraggable = false;
      this.viewer.fitToScreen();
    },
    visibilityHandler() {
      let foundIndex = this.viewer.scene.measurements.findIndex(
        el => this.clickedData.uuid == el.uuid
      );
      this.viewer.scene.measurements[foundIndex].visible = !this.viewer.scene
        .measurements[foundIndex].visible;
    },
    draggableCloseHandler() {
      if (this.lastUUIDClicked) {
        let lastFoundObjIndex = this.viewer.scene.measurements.findIndex(
          el => this.lastUUIDClicked == el.uuid
        );
        const color = new THREE.Color(0xff0000);
        this.viewer.scene.measurements[lastFoundObjIndex].color = color;
      }
      this.showDraggable = false;
      this.viewer.fitToScreen();
    },
    centerModelHandler() {
      // console.log("x position is ",this.initialPosition);
      // let camera = this.viewer.scene.getActiveCamera();
      // this.viewer.scene.cameraP.position.set(this.initialPosition.x,this.initialPosition.y,this.initialPosition.z)
      // this.viewer.scene.view.position.set(this.initialPosition.x,this.initialPosition.y,this.initialPosition.z);
      // this.viewer.scene.view.lookAt(this.initialPosition.x,this.initialPosition.y,this.initialPosition.z);
      this.viewer.scene.view.yaw = this.initialYaw;
      this.viewer.scene.view.pitch = this.initialPitch;
      this.viewer.scene.cameraP.zoom = 1;
      this.viewer.fitToScreen();
    },
    zoomHandlerToSpecific(obj, type, ind) {
      let foundObjIndex = this.viewer.scene.measurements.findIndex(
        el => obj.uuid == el.uuid
      );

      obj.type = type;
      obj.ind = ind;
      obj.foundInd = foundObjIndex;

      this.clickedData = obj;

      if (this.lastUUIDClicked) {
        let lastFoundObjIndex = this.viewer.scene.measurements.findIndex(
          el => this.lastUUIDClicked == el.uuid
        );
        const color = new THREE.Color(0xff0000);
        this.viewer.scene.measurements[lastFoundObjIndex].color = color;
      }

      const color5 = new THREE.Color("skyblue");
      this.viewer.scene.measurements[foundObjIndex].color = color5;

      let mynode = new THREE.Object3D();
      let boundArr = [];
      for (let i of this.viewer.scene.measurements[foundObjIndex].spheres) {
        boundArr.push(i.position);
      }
      // mynode.boundingBox = new THREE.Box3().setFromObject(this.viewer.scene.measurements[0].spheres[0]);

      mynode.boundingBox = new THREE.Box3().setFromPoints(boundArr);

      this.lastUUIDClicked = this.viewer.scene.measurements[foundObjIndex].uuid;
      this.showDraggable = true;

      this.viewer.zoomTo(mynode, 1, 500);
    },
    toolPotreeHandler(type) {
      if (type == "ANGLE") {
        this.viewer.measuringTool.startInsertion({
          showDistances: false,
          showAngles: true,
          showArea: false,
          closed: true,
          maxMarkers: 3,
          name: "Angle"
        });
      } else if (type == "DISTANCE") {
        this.viewer.measuringTool.startInsertion({
          showDistances: true,
          showArea: false,
          closed: false,
          name: "Distance"
        });
      } else if (type == "AREA") {
        this.viewer.measuringTool.startInsertion({
          showDistances: true,
          showArea: true,
          closed: true,
          name: "Area"
        });
      } else if (type == "HEIGHT") {
        this.viewer.measuringTool.startInsertion({
          showDistances: false,
          showHeight: true,
          showArea: false,
          closed: false,
          maxMarkers: 2,
          name: "Height"
        });
      }
    },
    bigAnnotatorOpenHandler(ind) {
      let image = this.potreeImagesCollectedData[ind];

      let obj = {
        imageName: image.file_name,
        imageID: image.id,
        imageURL: image.image_url,
        imageIndex: ind
      };

      this.$emit("bigAnnotatorOpener", obj);
    },
    potreeInitiator() {
      this.viewer = new Potree.Viewer(
        document.getElementById("potree_render_area")
      );
      window.viewer = this.viewer;
      console.log("three is ", THREE);

      this.viewer.setEDLEnabled(true);
      this.viewer.setFOV(60);
      this.viewer.setPointBudget(1_000_000);
      this.viewer.loadSettingsFromURL();

      // this.viewer.setDescription(
      //   "Point cloud courtesy of <a target='_blank' href='https://www.sigeom.ch/'>sigeom sa</a>"
      // );

      this.viewer.loadGUI(() => {
        this.viewer.setLanguage("en");
        // $("#menu_tools").next().show();
        // $("#menu_scene").next().show();
        // this.viewer.toggleSidebar();
        document.getElementById("potree_quick_buttons").style.display = "none";
      });

      console.log("this viewer is ", this.viewer);

      // this.viewer.measuringTool.onAdd(()=>{
      // 	console.log("fsdfa")
      // });
      let mainVar;
      // this.viewer.scene.addEventListener("measurement_added", e => {
      //   mainVar = e;
      //   console.log("measurment is added",e);
      //   if (this.fullyLoaded) {
      //     this.measureStarted = true;
      //   }
      // });

      this.viewer.measuringTool.addEventListener(
        "start_inserting_measurement",
        e => {
          mainVar = e;
          console.log("measurment is added");
          if (this.fullyLoaded) {
            this.measureStarted = true;
          }
        }
      );

      // this.viewer.addEventListener("scene_changed", () => {
      //   console.log("I am inside scene changed");
      // });
      this.viewer.measuringTool.addEventListener("cancel_insertions", () => {
        console.log("insertions are cancelled");
      });
      let domElement = this.viewer.renderer.domElement;
      domElement.addEventListener("mouseup", e => {
        if (e.which == 3) {
          if (this.measureStarted) {
            let returnedJSON = this.measurementToFeatures(mainVar.measure);

            let geojson = {
              type: "FeatureCollection",
              features: returnedJSON
            };

            this.saveGEOJSON = geojson;
            this.currentMeasurement = mainVar.measure;
            this.saveModal = true;
            this.measureStarted = false;
          }
          //  setTimeout(()=>{
          //    let scene = this.viewer.scene;
          //    console.log("I have reached inside timeout")
          //   //  this.measurementAddAgain(returnedJSON);
          //   // mainVar.measurement.points = [];
          //   let measure = new Potree.Measure();
          //   measure.closed = false;
          //   measure.addMarker(new THREE.Vector3(-13705020.726000976, 4639714.809000732, 2.1840000591278113));
          //   measure.addMarker(new THREE.Vector3(-13704922.709000366, 4639676.285998108, 3.5909998855590857));
          //   measure.addMarker(new THREE.Vector3(-13704817.385501433, 4639810.793498962, 8.10600022888184));
          //   measure.addMarker(new THREE.Vector3(-13704916.52700116, 4639947.183003601, 12.373999877929691));
          //   measure.addMarker(new THREE.Vector3(-13705059.031001281, 4639836.199999984, 3.397000118255619));

          //   scene.addMeasurement(measure);

          //   console.log("result is ",mainVar.measurement)
          //   this.viewer.measuringTool.onAdd({measurement:mainVar.measurement});
          //  },10000)
        }
      });

      Potree.loadPointCloud(
        `https://dev-api.aerinspect.com/get-tile${
          this.resultData.tiles_path
            .split("https://aer-2-dev_s3_amazonaws_com")[1]
            .split("/tiles")[0]
        }/potree_tiles/cloud.js`,
        "lion",
        e => {
          let scene = this.viewer.scene;

          let material = e.pointcloud.material;
          material.size = 1;
          material.pointSizeType = Potree.PointSizeType.ADAPTIVE;

          scene.addPointCloud(e.pointcloud);
          this.viewer.fitToScreen();

          this.getAnnotations();
          // setTimeout(() => {
          // console.log(
          //   "viewer rotation is ",
          //   this.viewer.scene.cameraP.rotation
          // );
          // this.showDraggable = true;
          // this.initialPosition = {...this.viewer.scene.cameraP.position};
          // this.initialPosition = {...this.viewer.scene.view.position};
          // console.log("viewer pivot is ",this.viewer.scene.view.getPivot().y);
          // }, 50000);
          // setTimeout(()=>{
          //   console.log("Now zoom has started");
          //   setInterval(()=>{
          //     console.log("I am inside the interval")
          //     this.viewer.scene.cameraP.zoom += 0.5;
          //   },1500)
          // },50000)
          // const cameraParamsPath = "http://5.9.65.151/mschuetz/potree/resources/pointclouds/helimap/epalinges/img_selected/IXM35_190522_nodistortion.xml";

          // Potree.OrientedImageLoader.load(cameraParamsPath, this.potreeCameraTxt, this.viewer).then( images => {
          //   console.log("I am inside oriented and images are ",images)
          //   this.viewer.scene.addOrientedImages(images);
          // });
        }
      );
    },
    measurementToFeatures(measurement) {
      // let coords = measurement.points.map(e => e.position.toArray());

      let coords = [];
      measurement.points.forEach((e, ind) => {
        if (
          ind != measurement.points.length - 1 ||
          measurement.name == "Angle" ||
          measurement.name == "Height" ||
          this.editModalUuid
        ) {
          coords.push([e.position.x, e.position.y, e.position.z]);
        }
      });

      let features = [];

      if (coords.length === 1) {
        let feature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coords[0]
          },
          properties: {
            name: measurement.name
          }
        };
        features.push(feature);
      } else if (coords.length > 1 && !measurement.closed) {
        let object = {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: coords
          },
          properties: {
            name: measurement.name
          }
        };

        features.push(object);
      } else if (coords.length > 1 && measurement.closed) {
        let object = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [[...coords, coords[0]]]
          },
          properties: {
            name: measurement.name
          }
        };
        features.push(object);
      }

      if (measurement.showDistances) {
        measurement.edgeLabels.forEach(label => {
          if (label.text != "0.00 m") {
            let labelPoint = {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: label.position.toArray()
              },
              properties: {
                distance: label.text
              }
            };
            features.push(labelPoint);
          }
        });
      }

      if (measurement.showArea) {
        let point = measurement.areaLabel.position;
        let labelArea = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: point.toArray()
          },
          properties: {
            area: measurement.areaLabel.text
          }
        };
        features.push(labelArea);
      }

      return features;
    },
    measurementAddAgain(features) {
      let pointMaterial = new THREE.PointsMaterial({
        size: 3,
        color: 0xff0000,
        sizeAttenuation: false
      });
      let lineMaterial = new THREE.LineBasicMaterial({
        color: 0x00ffff,
        linewidth: 5
      });

      for (var i = 0; i < features.length; i++) {
        var coord = features[i].geometry.coordinates;
        var geotype = features[i].geometry.type;

        if (geotype.toLowerCase() == "point") {
          var pointGeometry = new THREE.Geometry();
          pointGeometry.vertices.push(
            new THREE.Vector3(coord[0], coord[1], coord[2])
          );
          var point = new THREE.PointCloud(pointGeometry, pointMaterial);
          this.viewer.scene.scene.add(point);
        } else if (geotype.toLowerCase() == "linestring") {
          var lineGeometry = new THREE.Geometry();
          for (var j = 0; j < coord.length; j++) {
            lineGeometry.vertices.push(
              new THREE.Vector3(coord[j][0], coord[j][1], coord[j][2])
            );
          }

          var line = new THREE.Line(lineGeometry, lineMaterial);
          this.viewer.scene.scene.add(line);
        } else {
          console.log(
            features[i].geometry.type + "Geometry type not (yet) supported"
          );
        }
      }
    },
    async savePotreeAnnotationDialog(obj) {
      try {
        this.saveModalLoading = true;
        let uploadObj = new FormData();
        this.saveGEOJSON.name = obj.name;
        this.saveGEOJSON.description = obj.description;

        uploadObj.append("annotation_obj", JSON.stringify(this.saveGEOJSON));
        uploadObj.append("inspection_id", this.$route.params.inspectionID);

        let res = await this.$http.post(
          "/create-potree-annotation",
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        let saveObj = {
          uuid: this.currentMeasurement.uuid,
          pk: res.data.id,
          name: obj.name,
          description: obj.description
        };

        switch (this.saveGEOJSON.features[0].properties.name) {
          case "Distance":
            this.distanceList.push(saveObj);
            break;
          case "Area":
            this.areaList.push(saveObj);
            break;
          case "Angle":
            this.angleList.push(saveObj);
            break;
          case "Height":
            this.heightList.push(saveObj);
            break;
        }
        this.saveModalLoading = false;
        this.saveModal = false;
        this.$store.commit("snackbarActivator", {
          open: true,
          success: true,
          message: "Annotation successfully added"
        });
        this.currentMeasurement.addEventListener("marker_dropped", elg => {
          this.editModalUuid = elg.measurement.uuid;
          this.editModal = true;
          this.showMeasure = false;
          this.showDraggable = false;
        });
      } catch (err) {
        console.log("something went wrong");
      }
    },
    cancelPotreeAnnotations() {
      this.saveModal = false;
      // this.viewer.scene.removeAllMeasurements();
      if (this.potreeEditingData.editingMode) {
        this.showDraggable = true;
      } else {
        this.viewer.scene.removeMeasurement(this.currentMeasurement);
      }
    },
    async getAnnotations() {
      try {
        let res = await this.$http.get(
          `/get-potree-annotations/${this.$route.params.inspectionID}`,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.viewer.scene.removeAllMeasurements();
        this.heightList = [];
        this.areaList = [];
        this.distanceList = [];
        this.angleList = [];
        for (let i of res.data) {
          this.annotatonsAdder(
            i.fields.annotation_obj.features[0],
            i.pk,
            i.fields.annotation_obj.name,
            i.fields.annotation_obj.description
          );
        }
        this.fullyLoaded = true;
        this.initialYaw = this.viewer.scene.view.yaw;
        this.initialPitch = this.viewer.scene.view.pitch;
      } catch (err) {
        console.log("something went wrong", err);
      }
    },
    annotatonsAdder(obj, pk, name, description) {
      let scene = this.viewer.scene;
      let measure = new Potree.Measure();

      if (obj.properties.name == "Distance") {
        measure.name = "Distance";
        measure.closed = false;
        for (let i of obj.geometry.coordinates) {
          measure.addMarker(new THREE.Vector3(i[0], i[1], i[2]));
        }
        scene.addMeasurement(measure);
        this.distanceList.push({ uuid: measure.uuid, pk, name, description });
      } else if (obj.properties.name == "Area") {
        measure.name = "Area";
        measure.closed = true;
        measure.showArea = true;
        // for (let i of obj.geometry.coordinates[0]) {
        //   measure.addMarker(new THREE.Vector3(i[0], i[1], i[2]));
        // }
        obj.geometry.coordinates[0].forEach((i, ind) => {
          if (ind != obj.geometry.coordinates[0].length - 1) {
            measure.addMarker(new THREE.Vector3(i[0], i[1], i[2]));
          }
        });
        scene.addMeasurement(measure);
        this.areaList.push({ uuid: measure.uuid, pk, name, description });
      } else if (obj.properties.name == "Angle") {
        measure.name = "Angle";
        measure.closed = true;
        measure.showAngles = true;
        measure.showDistances = false;
        obj.geometry.coordinates[0].forEach((i, ind) => {
          if (ind != obj.geometry.coordinates[0].length - 1) {
            measure.addMarker(new THREE.Vector3(i[0], i[1], i[2]));
          }
        });
        // for (let i of obj.geometry.coordinates[0]) {
        //   measure.addMarker(new THREE.Vector3(i[0], i[1], i[2]));
        // }
        scene.addMeasurement(measure);
        this.angleList.push({ uuid: measure.uuid, pk, name, description });
      } else if (obj.properties.name == "Height") {
        measure.name = "Height";
        measure.closed = false;
        measure.showDistances = false;
        measure.showHeight = true;
        for (let i of obj.geometry.coordinates) {
          measure.addMarker(new THREE.Vector3(i[0], i[1], i[2]));
        }
        scene.addMeasurement(measure);
        this.heightList.push({ uuid: measure.uuid, pk, name, description });
      }

      measure.addEventListener("marker_dropped", elg => {
        this.editModalUuid = elg.measurement.uuid;
        this.editModal = true;
        this.showMeasure = false;
        this.showDraggable = false;
      });
    },
    async editModalConfirmHandler() {
      this.editModalLoading = true;
      let obj = {};
      let foundObjIndex = this.viewer.scene.measurements.findIndex(
        el => this.editModalUuid == el.uuid
      );

      let foundIn = this.distanceList.findIndex(
        el => el.uuid == this.editModalUuid
      );
      if (foundIn == -1) {
        foundIn = this.heightList.findIndex(
          el => el.uuid == this.editModalUuid
        );
        if (foundIn == -1) {
          foundIn = this.areaList.findIndex(
            el => el.uuid == this.editModalUuid
          );
          if (foundIn == -1) {
            foundIn = this.angleList.findIndex(
              el => el.uuid == this.editModalUuid
            );
            obj.type = "angleList";
          } else {
            obj.type = "areaList";
          }
        } else {
          obj.type = "heightList";
        }
      } else {
        obj.type = "distanceList";
      }

      obj.pk = this[obj.type][foundIn].pk;
      obj.ind = foundIn;
      obj.foundInd = foundObjIndex;

      this.clickedData = obj;
      await this.editPotreeAnnotationHandler({
        name: this[obj.type][foundIn].name,
        description: this[obj.type][foundIn].description
      });
      this.editModalLoading = false;
      this.editModal = false;
      this.showMeasure = true;
      this.editModalUuid = undefined;
    },
    async editModalCancelHandler() {
      this.editModalLoading = true;
      this.lastUUIDClicked = undefined;
      await this.getAnnotations();
      this.editModalLoading = false;
      this.editModal = false;
      this.showMeasure = true;
      this.editModalUuid = undefined;
    },
    getImageWidthHeight(url, callback) {
      var img = new Image();
      img.src = url;
      img.onload = function() {
        callback();
      };
    },
    loadCamera(width, height, imageURL, id, ind) {
      // let duplicateObj = { ...this.collectedData[ind] };
      // duplicateObj.existingObservations = L.layerGroup();

      // this.collectedData.splice(ind, 1, duplicateObj);

      this.potreeImagesCollectedDataArr[
        ind
      ].existingObservations = L.layerGroup();
      this.potreeImagesCollectedDataArr[ind].existingObservations.clearLayers();

      if (this.potreeImagesCollectedDataArr[ind].mainMap) {
        this.potreeImagesCollectedDataArr[ind].mainMap.remove();
      }

      this.potreeImagesCollectedDataArr[ind].algoSelection = false;

      let mainMap = L.map(`map${ind}test`, {
        zoom: 0,
        center: [0, 0],
        zoomControl: false,
        attributionControl: false,
        crs: L.CRS.Simple,
        minZoom: 1,
        maxZoom: 4,
        scrollWheelZoom: false
      });
      mainMap.dragging.disable();
      mainMap.doubleClickZoom.disable();

      // duplicateObj = { ...this.imagesData2d[ind] };
      // this.imagesData2d[ind].mainMap = mainMap;

      // duplicateObj.mainMap = mainMap;
      // this.imagesData2d.splice(ind, 1, duplicateObj);
      this.potreeImagesCollectedDataArr[ind].mainMap = mainMap;

      this.potreeImagesCollectedDataArr[ind].mainMap.createPane(
        "existingObservation"
      );

      this.potreeImagesCollectedDataArr[ind].existingObservations.addTo(
        this.potreeImagesCollectedDataArr[ind].mainMap
      );

      let w = width,
        h = height;

      let southWest = this.potreeImagesCollectedDataArr[ind].mainMap.unproject(
        [0, h],
        this.potreeImagesCollectedDataArr[ind].mainMap.getMaxZoom() - 1
      );
      let northEast = this.potreeImagesCollectedDataArr[ind].mainMap.unproject(
        [w, 0],
        this.potreeImagesCollectedDataArr[ind].mainMap.getMaxZoom() - 1
      );
      let bounds = new L.LatLngBounds(southWest, northEast);
      // let bounds = new L.LatLngBounds([0, 0], [h / 10, w / 10]);
      let overlay_layer = L.imageOverlay(imageURL, bounds, {
        interactive: true
      });
      this.potreeImagesCollectedDataArr[
        ind
      ].mainMap.overlay_layer = overlay_layer;
      this.potreeImagesCollectedDataArr[ind].mainMap.overlay_layer.addTo(
        this.potreeImagesCollectedDataArr[ind].mainMap
      );
      let otherObj = { ...this.potreeImagesCollectedDataArr[ind] };
      otherObj.loadComplete = true;
      this.potreeImagesCollectedDataArr.splice(ind, 1, otherObj);
      this.potreeImagesCollectedDataArr[ind].mainMap.overlay_layer.on(
        "load",
        () => {
          // this.overlay = false;
          this.loadImageObservations(id, ind);
        }
      );

      this.potreeImagesCollectedDataArr[ind].mainMap.setMaxBounds(bounds);
      this.potreeImagesCollectedDataArr[ind].mainMap.fitBounds(bounds);
      this.potreeImagesCollectedDataArr[ind].mainMap.invalidateSize();
    },
    loadImageObservations(id, ind, editAnnotation) {
      this.$http
        .get(`/get-annotations/${id}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(
          response => {
            let inspection_geo = { ...response.data };

            this.potreeImagesCollectedDataArr[
              ind
            ].newObservation = L.layerGroup();
            if (this.potreeImagesCollectedDataArr[ind].existingObservations) {
              this.potreeImagesCollectedDataArr[
                ind
              ].existingObservations.clearLayers();
            }

            if (this.potreeImagesCollectedDataArr[ind].newObservation) {
              this.potreeImagesCollectedDataArr[ind].mainMap.removeLayer(
                this.potreeImagesCollectedDataArr[ind].newObservation
              );
            }

            let needFor = inspection_geo.features;
            let otherCoordinates = [],
              num = 0,
              todo,
              test;
            let overlayTest = this.potreeImagesCollectedDataArr[ind].mainMap
              .overlay_layer._image;
            let yR =
              overlayTest.clientHeight /
              this.potreeImagesCollectedDataArr[ind].height;
            let xR =
              overlayTest.clientWidth /
              this.potreeImagesCollectedDataArr[ind].width;
            for (let i of needFor) {
              if (i.geometry.type == "Point") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                test = this.potreeImagesCollectedDataArr[
                  ind
                ].mainMap.unproject([-todo[1], -todo[0]]);
                otherCoordinates.push(test.lat * xR);
                otherCoordinates.push(test.lng * yR);
                inspection_geo.features[
                  num
                ].geometry.coordinates = otherCoordinates;
              } else if (i.geometry.type == "LineString") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                for (let j of todo) {
                  test = this.potreeImagesCollectedDataArr[
                    ind
                  ].mainMap.unproject([-j[1], -j[0]]);

                  otherCoordinates.push([test.lat * xR, test.lng * yR]);
                }
                inspection_geo.features[
                  num
                ].geometry.coordinates = otherCoordinates;
              } else if (i.geometry.type == "Polygon") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                let subNum = 0;
                for (let j of todo) {
                  for (let k of j) {
                    test = this.potreeImagesCollectedDataArr[
                      ind
                    ].mainMap.unproject([-k[1], -k[0]]);
                    otherCoordinates.push([test.lat * xR, test.lng * yR]);
                  }
                  inspection_geo.features[num].geometry.coordinates[
                    subNum
                  ] = otherCoordinates;
                  subNum++;
                }
              }
              num++;
            }

            if (inspection_geo.features.length > 0) {
              let geo = L.geoJSON(inspection_geo, {
                pane: "existingObservation",
                pmIgnore: false,
                onEachFeature: (feature, layer) => {
                  if (layer.feature.properties.drawn_by == "Machine") {
                    layer.setStyle({ color: "#e21e1e" });
                    // layer.bindTooltip(
                    //   `${layer.feature.properties.confidence} %`,
                    //   { permanent: true, offset: [0, 0] }
                    // );
                  }
                }
              });

              this.potreeImagesCollectedDataArr[
                ind
              ].existingObservations.addLayer(geo);
            }
            if (editAnnotation) {
              let otherObj = { ...this.potreeImagesCollectedDataArr[ind] };
              otherObj.annotation_count = response.data.features.length;
              this.potreeImagesCollectedDataArr.splice(ind, 1, otherObj);
            }
          },
          error => {
            console.log("error", error);
            throw error;
          }
        )
        .catch(error => {
          console.log("cerror", error);
          throw error;
        });
    }
  },
  async mounted() {
    this.potreeImagesCollectedDataArr = this.potreeImagesCollectedData;
    this.potreeInitiator();
    for (let [ind, i] of this.potreeImagesCollectedDataArr.entries()) {
      this.getImageWidthHeight(i.thumbnail_url, () => {
        let ratio = i.width / i.height;
        let mapContainer = document.getElementById("map0test");
        this.loadCamera(
          Math.round(mapContainer.clientWidth),
          Math.round(mapContainer.clientWidth / ratio),
          i.thumbnail_url,
          i.id,
          ind
        );
      });
    }
  }
};
</script>

<style scoped>
.pricedrop-tag {
  position: absolute;
  left: -9px;
  top: 12%;
  z-index: 445;
}

.urgent {
  margin-bottom: 10px;
  background: #d54b4f;
}

.urgent:before {
  border-left: 10px solid #d54b4f;
}

.pricedrop-ribbon {
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 12px;
  padding: 3px 0px 4px 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}

.pricedrop-ribbon:before {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  border-top: 9px solid transparent;
  border-bottom: 10px solid transparent;
}

.urgent:after {
  border-top: 5px solid #993538;
}

.pricedrop-ribbon:after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  border-left: 5px solid transparent;
}

.leaflet-container {
  background: #10181b;
  outline: 0;
}

.draggableComp {
  width: 400px;
  position: absolute;
  z-index: 1000;
  user-select: none;
}
</style>

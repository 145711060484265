<template>
  <div>
    <v-carousel
      :show-arrows="false"
      :hide-delimiters="true"
      :hide-delimiter-background="true"
      cycle
      height="100vh"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
      ></v-carousel-item>
    </v-carousel>
    <v-overlay>
      <v-container style="width: 100vh">
        <slot></slot>
      </v-container>
    </v-overlay>
  </div>
</template>
<script>
export default {
  name: "LoginParent",
  data() {
    return {
      items: [
        {
          // src: "https://app.aerinspect.com/static/landing_page/images/img-2.JPG"
          src: ""
        }
      ]
    };
  },
  mounted() {
    this.$http.get("/get-auth-image").then(res => {
      this.items[0].src = res.data.image;
    });
  }
};
</script>

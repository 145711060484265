<template>
  <div
    class="loadingDivStyle"
    style="display:flex;flexFlow:column;"
    v-if="loadingComments"
  >
    <Loading loadingText="Loading Comments..." />
  </div>
  <v-container style="height: 95vh; overflow-y: auto" v-else>
    <v-dialog
      v-model="commentDeleteDialog"
      persistent
      max-width="600px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Delete Comment</span>
        </v-card-title>
        <v-card-text>
          Are you sure, you want to delete the comment?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="commentDeleteDialog = false"
            v-if="!commentDeleteLoading"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteComment"
            :loading="commentDeleteLoading"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="commentEditDialog"
      persistent
      max-width="600px"
      transition="dialog-top-transition"
    >
      <v-form>
        <v-card>
          <v-card-title>
            <span class="headline">Edit Comment</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12"> </v-col>
                <v-col cols="12">
                  <v-textarea label="Comment" v-model="editText"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="commentEditDialog = false"
              v-if="!commentEditLoading"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="editComment"
              :loading="commentEditLoading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-row>
      <v-col>
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="title white--text pl-0">
            Comments
          </v-toolbar-title>
        </v-app-bar>
        <v-container>
          <v-timeline dense clipped>
            <v-timeline-item
              fill-dot
              class="white--text mb-12"
              color="primary"
              large
              v-if="!$store.state.isShared"
            >
              <template v-slot:icon>
                <span>USER</span>
              </template>
              <v-textarea v-model="input" label="Leave a comment...">
                <template v-slot:append>
                  <v-btn
                    color="primary"
                    class="mx-0"
                    depressed
                    @click="comment"
                  >
                    Post
                  </v-btn>
                </template>
              </v-textarea>
            </v-timeline-item>

            <span class="collectedDataResult" v-if="events.length < 1">
              No Comments!
            </span>

            <v-slide-x-transition group v-else>
              <v-timeline-item
                v-for="event in timeline"
                :key="event.id"
                class="mb-4"
                small
              >
                <v-card>
                  <v-card-title>
                    {{
                      new Date(event.commented_at)
                        .toString()
                        .split(" ")
                        .slice(0, 4)
                        .join(" ")
                    }}
                  </v-card-title>

                  <v-row justify="space-between">
                    <v-col cols="7" v-text="event.text"></v-col>
                    <v-col
                      class="text-right"
                      cols="5"
                      v-text="
                        new Date(event.commented_at)
                          .toString()
                          .split(' ')
                          .slice(4)
                          .join(' ')
                      "
                    ></v-col>
                  </v-row>
                  <!-- <v-card-actions>
                  <v-btn
                    class="ml-2 mt-5"
                    icon
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-2 mt-5"
                    icon
                    color="error darken-3"
                    >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions> -->
                  <v-row
                    justify="end"
                    style="padding:8px;"
                    v-if="!$store.state.isShared"
                  >
                    <v-btn
                      class="ml-2 mt-5"
                      icon
                      @click="
                        editText = event.text;
                        currentSelectedID = event.id;
                        commentEditDialog = true;
                      "
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      class="ml-2 mt-5"
                      icon
                      color="error darken-3"
                      @click="
                        currentSelectedID = event.id;
                        commentDeleteDialog = true;
                      "
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-row>
                </v-card>
              </v-timeline-item>
            </v-slide-x-transition>

            <!-- <v-timeline-item class="mb-6" hide-dot>
              <span>TODAY</span>
            </v-timeline-item> -->

            <!-- <v-timeline-item
              class="mb-4"
              color="grey"
              icon-color="grey lighten-2"
              small
            >
              <v-row justify="space-between">
                <v-col cols="7">
                  This order was archived.
                </v-col>
                <v-col class="text-right" cols="5">
                  15:26 EDT
                </v-col>
              </v-row>
            </v-timeline-item> -->

            <!-- <v-timeline-item class="mb-4" small>
              <v-row justify="space-between">
                <v-col cols="7">
                  <v-chip class="white--text ml-0" color="purple" label small>
                    APP
                  </v-chip>
                  Digital Downloads fulfilled 1 item.
                </v-col>
                <v-col class="text-right" cols="5">
                  15:25 EDT
                </v-col>
              </v-row>
            </v-timeline-item> -->

            <!-- <v-timeline-item class="mb-4" color="grey" small>
              <v-row justify="space-between">
                <v-col cols="7">
                  Order confirmation email was sent to John Leider
                  (john@vuetifyjs.com).
                </v-col>
                <v-col class="text-right" cols="5">
                  15:25 EDT
                </v-col>
              </v-row>
            </v-timeline-item> -->

            <!-- <v-timeline-item class="mb-4" hide-dot>
              <v-btn class="mx-0">
                Resend Email
              </v-btn>
            </v-timeline-item> -->

            <!-- <v-timeline-item class="mb-4" color="grey" small>
              <v-row justify="space-between">
                <v-col cols="7">
                  A $15.00 USD payment was processed on PayPal Express Checkout
                </v-col>
                <v-col class="text-right" cols="5">
                  15:25 EDT
                </v-col>
              </v-row>
            </v-timeline-item> -->

            <!-- <v-timeline-item color="grey" small>
              <v-row justify="space-between">
                <v-col cols="7">
                  John Leider placed this order on Online Store (checkout
                  #1937432132572).
                </v-col>
                <v-col class="text-right" cols="5">
                  15:25 EDT
                </v-col>
              </v-row>
            </v-timeline-item> -->
          </v-timeline>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Loading from "../misc/Loading.vue";

export default {
  name: "Comments",
  props: ["inspectionID"],
  components: {
    Loading
  },
  data() {
    return {
      events: [],
      input: null,
      currentSelectedID: null,
      commentDeleteDialog: false,
      commentDeleteLoading: false,
      commentEditDialog: false,
      commentEditLoading: false,
      editText: null,
      loadingComments: true
    };
  },
  // watch: {
  //   inspectionID(){
  //     this.getComments();
  //   }
  // },
  computed: {
    timeline() {
      return this.events.slice().reverse();
    }
  },
  mounted() {
    this.getComments();
  },
  methods: {
    async comment() {
      if (this.input) {
        let uploadObj = new FormData();
        uploadObj.append("text", this.input);
        uploadObj.append("inspection_id", this.inspectionID);
        try {
          let res = await this.$http.post(
            `/create-comment/${this.inspectionID}`,
            uploadObj,
            {
              headers: {
                Authorization: "token " + localStorage.getItem("auth_token")
              }
            }
          );
          this.events.push({
            id: res.data.id,
            text: this.input,
            commented_at: new Date().toISOString()
            // time: time.replace(
            //   /:\d{2}\sGMT-\d{4}\s\((.*)\)/,
            //   // eslint-disable-next-line
            //           (match, contents, offset) => {
            //     return ` ${contents
            //       .split(" ")
            //       .map(v => v.charAt(0))
            //       .join("")}`;
            //   }
            // )
          });
        } catch (err) {
          console.log(err);
        }

        this.input = null;
      }
    },
    async getComments() {
      try {
        let res = await this.$http.get(`/get-comments/${this.inspectionID}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        this.events = res.data.comments;
        this.loadingComments = false;
      } catch (err) {
        console.log(err);
      }
    },
    async editComment() {
      if (this.editText) {
        try {
          this.commentEditLoading = true;
          let uploadObj = new FormData();
          uploadObj.append("text", this.editText);
          uploadObj.append("id", this.currentSelectedID);
          await this.$http.put(
            `/edit-comment/${this.currentSelectedID}`,
            uploadObj,
            {
              headers: {
                Authorization: "token " + localStorage.getItem("auth_token")
              }
            }
          );
          let foundIndex = this.events.findIndex(
            el => el.id == this.currentSelectedID
          );
          let foundObj = { ...this.events[foundIndex] };
          foundObj.text = this.editText;
          this.events.splice(foundIndex, 1, foundObj);
          this.commentEditLoading = false;
          this.commentEditDialog = false;
        } catch (err) {
          console.log(err);
        }
      }
    },
    async deleteComment() {
      try {
        this.commentDeleteLoading = true;
        await this.$http.delete(`/delete-comment/${this.currentSelectedID}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        let foundIndex = this.events.findIndex(
          el => el.id == this.currentSelectedID
        );
        this.events.splice(foundIndex, 1);
        this.commentDeleteLoading = false;
        this.commentDeleteDialog = false;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style scoped>
.loadingDivStyle {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

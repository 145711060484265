<template>
  <div style="position:relative">
    <div class="addressDiv">
      <gmap-autocomplete
        ref="address_autocomplete"
        placeholder=""
        @place_changed="setEditAssetDataAddress"
      >
        <template v-slot:input="slotProps">
          <v-text-field
            v-model="editAssetData.address.formatted_address"
            :label="editAssetAddressLabel"
            ref="input"
            v-on:listeners="slotProps.listeners"
            v-on:attrs="slotProps.attrs"
            :placeholder="'Address'"
          />
        </template>
      </gmap-autocomplete>
    </div>
    <div
      id="MapID"
      :style="
        `z-index: 2;width: 100%; ${
          this.layerType ? 'height: 80vh;' : 'height: 50vh;'
        }`
      "
    >
      map
    </div>
  </div>
</template>
<script>
import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
export default {
  name: "CustomLeafletMapDraw",
  data() {
    return {
      map: null,
      editAssetData: {
        id: "",
        description: "",
        name: "",
        address: {
          formatted_address: ""
        }
      },
      drawnItems: undefined,
      drawControlFull: undefined,
      drawControlEdit: undefined
    };
  },
  props: ["compX", "compY", "zoomLevel", "layerType"],
  watch: {
    compX(newVal) {
      this.map.flyTo(new L.LatLng(newVal, this.compY), this.zoomLevel);
      this.editAssetData = {
        id: "",
        description: "",
        name: "",
        address: {
          formatted_address: ""
        }
      };
    }
  },
  computed: {
    editAssetAddressLabel: function() {
      if (this.editAssetData.address.formatted_address === "") {
        return "";
      } else {
        return "Address";
      }
    }
  },
  methods: {
    setEditAssetDataAddress: function(address) {
      this.map.flyTo(
        new L.LatLng(
          address.geometry.location.lat(),
          address.geometry.location.lng()
        ),
        this.zoomLevel
      );
      this.editAssetData.address = address;
    }
  },
  mounted() {
    // let mapboxSatelliteUrl =
    //   "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
    // let mapboxSatelliteAttribution =
    //   "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community";
    let mapboxStreetUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    let mapboxStreetAttribution =
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

    // var satellite = L.tileLayer(mapboxSatelliteUrl, {
    //     id: "MapID",
    //     tileSize: 512,
    //     zoomOffset: -1,
    //     attribution: mapboxSatelliteAttribution
    //   }),
    var streets = L.tileLayer(mapboxStreetUrl, {
        id: "MapID",
        tileSize: 512,
        zoomOffset: -1,
        attribution: mapboxStreetAttribution
      }),
      // toner = L.tileLayer(
      //   "//stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png",
      //   {
      //     attribution:
      //       'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      //     subdomains: "abcd",
      //     maxZoom: 20,
      //     minZoom: 0,
      //     label: "Toner"
      //   }
      // ),
      // googleSat = L.tileLayer(
      //   "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      //   {
      //     maxZoom: 20,
      //     subdomains: ["mt0", "mt1", "mt2", "mt3"]
      //   }
      // ),
      googleStreets = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"]
        }
      ),
      googleHybrid = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"]
        }
      );
    // googleTerrain = L.tileLayer(
    //   "http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
    //   {
    //     maxZoom: 20,
    //     subdomains: ["mt0", "mt1", "mt2", "mt3"]
    //   }
    // );
    this.map = L.map("MapID", {
      center: [this.compX, this.compY],
      zoom: this.zoomLevel,
      ...(this.layerType
        ? { layers: [googleStreets] }
        : { layers: [googleHybrid] }),
      gestureHandling: true
    });
    var baseMaps = {
      // satellite: satellite,
      streets: streets,
      // toner,
      // googleSat,
      googleStreets,
      googleHybrid
      // googleTerrain
    };

    if (!this.layerType) {
      L.control.layers(baseMaps).addTo(this.map);
    }
    const drawnItems = new L.FeatureGroup();
    this.map.addLayer(drawnItems);
    this.drawnItems = drawnItems;

    let drawControlFull = new L.Control.Draw();

    var drawControlEdit = new L.Control.Draw({
      edit: {
        featureGroup: drawnItems,
        edit: false
      },
      draw: false
    });
    this.map.addControl(drawControlFull);
    this.drawControlFull = drawControlFull;
    this.drawControlEdit = drawControlEdit;
    let _this = this;
    this.map.on(L.Draw.Event.CREATED, function(e) {
      var type = e.layerType,
        layer = e.layer;

      if (type === "marker") {
        layer.bindPopup("A popup!");
      }

      drawnItems.addLayer(layer);
      console.log(drawnItems.toGeoJSON());
      drawControlFull.remove();
      drawControlEdit.addTo(_this.map);
      _this.$emit("draw", drawnItems.toGeoJSON());
    });
    this.map.on("draw:deleted", function() {
      drawControlEdit.remove();
      drawControlFull.addTo(_this.map);
      _this.$emit("delete");
    });
  }
};
</script>

<style scoped>
.addressDiv {
  position: absolute;
  z-index: 3;
  width: 75%;
  background: #10050575;
  left: 50%;
  transform: translateX(-50%);
  top: 2%;
  height: 55px;
  padding: 0 10px;
}
</style>

<template>
  <v-timeline align-top dense>
    <v-timeline-item
      large
      color="grey"
      v-for="(item, ind) in createFlightDataActive"
      :key="ind"
    >
      <template v-slot:icon>
        <v-avatar class="primary" v-if="item.active">
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </v-avatar>
        <v-avatar v-else>
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </v-avatar>
      </template>

      <v-container fluid>
        <!--                    <div class="font-weight-normal">-->
        <!--                      <strong>Abdullah</strong> @ Monday-->
        <!--                    </div>-->
        <div class="timelineActiveColor" v-if="item.active">
          {{ item.text }}
        </div>
        <div v-else>{{ item.text }}</div>
      </v-container>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  props: ["flightProgress"],
  data: () => ({
    timeLineList: [
      {
        icon: "mdi-account",
        text: "Searching Engineer"
      },
      {
        icon: "mdi-folder",
        text: "Pending Authorization"
      },
      {
        icon: "mdi-airplane",
        text: "Survey Execution"
      },
      {
        icon: "mdi-arrow-down",
        text: "Processing/Reviewing"
      },
      {
        icon: "mdi-check",
        text: "Completed"
      }
    ]
  }),
  computed: {
    createFlightDataActive() {
      let progress = "nothing";

      if (this.flightProgress) {
        progress = this.flightProgress || "nothing";
      }
      let duplicateArr = [...this.timeLineList];

      if (progress == "Searching Pilot") {
        duplicateArr[0].active = true;
        return duplicateArr;
      } else if (progress == "Pending Authorization") {
        duplicateArr[0].active = true;
        duplicateArr[1].active = true;
        return duplicateArr;
      } else if (
        progress == "Flight Execution" ||
        progress == "Flight Performed"
      ) {
        duplicateArr[0].active = true;
        duplicateArr[1].active = true;
        duplicateArr[2].active = true;
        return duplicateArr;
      } else if (progress == "Insight Generation" || progress == "Processing") {
        duplicateArr[0].active = true;
        duplicateArr[1].active = true;
        duplicateArr[2].active = true;
        duplicateArr[3].active = true;
        return duplicateArr;
      } else if (progress == "Completed") {
        duplicateArr[0].active = true;
        duplicateArr[1].active = true;
        duplicateArr[2].active = true;
        duplicateArr[3].active = true;
        duplicateArr[4].active = true;
        return duplicateArr;
      }

      return duplicateArr;
    }
  }
};
</script>

<style scoped>
.timelineActiveColor {
  color: #ff6600;
}
</style>

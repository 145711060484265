<template>
  <login-parent>
    <v-row justify="center">
      <v-col md="8">
        <v-card class="rounded-lg" style="min-height: 50vh">
          <v-container fluid align="center" style="min-height: 50vh">
            <v-row justify="center" align="center" style="min-height: 49vh">
              <v-col md="6">
                <div class="text-center">
                  <v-icon color="white " class="font" style="font-size: 100px">
                    mdi-key
                  </v-icon>
                  <h1>Change Password</h1>
                  <v-text-field
                    label="New Password"
                    type="password"
                    prepend-inner-icon="mdi-key"
                  ></v-text-field>
                  <v-text-field
                    label="Password Again"
                    type="password"
                    prepend-inner-icon="mdi-key"
                  ></v-text-field>

                  <v-btn color="primary mt-2 mb-3" block rounded elevation="2">
                    Change Password
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </login-parent>
</template>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
<script>
import LoginParent from "../components/LoginParent";

export default {
  name: "Login",
  components: { LoginParent },
  data: () => {
    return {
      reveal: false
    };
  }
};
</script>

<template>
  <div>
    <v-dialog
      v-model="assetShareDialog"
      persistent
      min-width="600px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Share</span>
        </v-card-title>
        <v-card-text>
          <div
            style="display: flex; flexFlow: column; justifyContent: space-around; alignItems: center; height: 117px;"
            v-if="loading"
          >
            <Loading loadingText="Creating Share URL!" />
          </div>
          <div style="position:relative" v-else>
            <v-text-field :value="encodeURI(text)" readonly></v-text-field>
            <div
              style="position:absolute;top:-16px;right:0;display:flex;flexFlow:column;alignItems:center;"
            >
              <copy-to-clipboard :text="encodeURI(text)" @copy="handleCopy">
                <v-btn class="ml-2" fab>
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </copy-to-clipboard>
              <span style="marginTop: 13px; fontSize: 12px;">Copy Link</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            v-if="!loading"
            @click="
              $emit('closeShareDialog');
              assetShareDialog = false;
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "./misc/Loading.vue";
import CopyToClipboard from "vue-copy-to-clipboard";

export default {
  name: "ShareComponent",
  props: ["assetID", "inspectionID", "assetName", "openShareDialog", "type"],
  components: {
    Loading,
    CopyToClipboard
  },
  data: () => ({
    assetShareDialog: false,
    loading: true,
    text: `https://${window.location.hostname}/shared/ASSETNAME/timeline`
  }),
  methods: {
    handleCopy() {
      let message;
      if (this.type == "asset") {
        message = "Asset Share Link Copied!";
      } else if (this.type == "inspection") {
        message = "Inspection Share Link Copied!";
      }
      this.$store.commit("snackbarActivator", {
        open: true,
        success: true,
        message
      });
    }
  },
  async mounted() {
    this.assetShareDialog = this.openShareDialog;
    let uploadObj = new FormData();

    try {
      let res;
      let textToShow = this.text.replace("ASSETNAME", this.assetName);

      if (this.type == "asset") {
        uploadObj.append("asset_id", this.assetID);
        res = await this.$http.post(
          `/share/create-asset-share/${this.assetID}`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.text = `${textToShow}/${this.assetID}?uuid=${res.data.uuid}`;
      } else if (this.type == "inspection") {
        uploadObj.append("inspection_id", this.inspectionID);
        res = await this.$http.post(
          `/share/create-inspection-share/${this.inspectionID}`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.text = `${textToShow}/${this.assetID}/data-point/${this.inspectionID}?uuid=${res.data.uuid}`;
      }
      this.loading = false;
    } catch (err) {
      console.log("something went wrong");
    }
  }
};
</script>

<template>
  <div class="errorDivStyle">
    <img src="../../assets/errormark.png" />
    <span :style="[errorHeadingColour && { color: errorHeadingColour }]">{{
      errorMessage
    }}</span>
  </div>
</template>

<script>
export default {
  props: ["errorMessage", "errorHeadingColour"]
};
</script>

<style scoped>
.errorDivStyle {
  width: 100%;
  flex-flow: column;
  display: flex;
  align-items: center;
}

.errorDivStyle img {
  width: 25%;
  height: auto;
}

.errorDivStyle span {
  display: inline-block;
  margin-top: 40px;
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../views/Login";
import SignUp from "../views/SignUp";
import ForgetPassword from "../views/ForgetPassword";
import ForgetPasswordLink from "../views/ForgetPasswordLink";
import ResetPasswordConfirm from "../views/ResetPasswordConfirm";
// import Landing from "../views/Landing";
import Layout from "../views/Layout";
import Home from "../views/Home";
import Profile from "../views/Profile";
import CreateFlight from "../views/CreateFlight";
import AssetDetail from "../views/AssetDetail";
import store from "../store";
import Test from "../views/Test";
import AssetDataPoint from "@/views/asset-details/AssetDataPoint";
import Timeline from "@/views/asset-details/Timeline";
import AssetDocuments from "@/views/asset-details/AssetDocuments";
import Flights from "@/views/Flights";
import FlightDetails from "@/views/FlightDetails";
import UploadData from "@/views/UploadData";
import EditUploadData from "@/views/EditUploadData";
import NotFound from "@/views/NotFound";
import GetaQuote from "@/views/GetaQuote";
import QuoteRequests from "@/views/QuoteRequests";

import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      auth: false
    },
    beforeEnter: function(to, from, next) {
      let token = localStorage.getItem("auth_token");
      if (token) {
        next("/app/home");
      } else {
        next();
      }
    }
  },
  {
    path: "/admin",
    name: "Admin",
    meta: {
      auth: false
    },
    beforeEnter: function() {
      window.location.replace("https://dev-api.aerinspect.com/admin");
    }
  },
  {
    path: "/register",
    name: "SignUp",
    component: SignUp,
    meta: {
      auth: false
    }
  },
  {
    path: "/get-quote",
    name: "Quote",
    component: GetaQuote,
    meta: {
      auth: false
    }
  },
  {
    path: "/shared/:assetName",
    name: "Shared Asset Details",
    component: AssetDetail,
    meta: {
      auth: false
    },
    beforeEnter: (to, from, next) => {
      // console.log("to is ", to);
      // console.log("to params are ", to.params);
      // console.log("store is ", store.state.isShared);
      axios
        .get(
          `https://dev-api.aerinspect.com/share/check-uuid?uuid=${to.query.uuid}`
        )
        .then(res => {
          if (res.data.is_valid) {
            store.commit("changeIsShared", true);
            next();
          } else {
            store.commit("snackbarActivator", {
              open: true,
              success: false,
              message: "Invalid Share URL"
            });
            next("/");
          }
        })
        .catch(() => {
          store.commit("snackbarActivator", {
            open: true,
            success: false,
            message: "Something went wrong"
          });
          next("/");
        });
    },
    children: [
      {
        path: "timeline/:assetID",
        name: "Asset Details Timeline",
        component: Timeline,
        props: route => {
          return {
            ...route.params
          };
        },
        meta: {
          collapse_menu: true,
          auth: false
        },
        children: [
          {
            path: "data-point/:inspectionID",
            name: "Asset Data Point",
            component: AssetDataPoint,
            props: route => {
              return {
                ...route.params
              };
            },
            meta: {
              collapse_menu: true,
              auth: false
            }
          }
        ]
      },
      {
        path: "documents/:assetID",
        name: "Asset Details Documents",
        component: AssetDocuments,
        meta: {
          collapse_menu: true,
          auth: false
        }
      }
    ]
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      auth: false
    }
  },
  {
    path: "/reset-password-confirm/:uid/:token",
    name: "ResetPasswordConfirm",
    component: ResetPasswordConfirm,
    meta: {
      auth: false
    }
  },
  {
    path: "/change-password",
    name: "ForgetPasswordLink",
    component: ForgetPasswordLink,
    meta: {
      auth: false
    }
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
    meta: {
      auth: true
    }
  },
  {
    path: "/app",
    name: "Layout",
    component: Layout,
    meta: {
      auth: true
    },
    children: [
      {
        path: "home",
        name: "Home",
        component: Home,
        meta: {
          collapse_menu: true,
          auth: true
        }
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          auth: true,
          collapse_menu: true
        }
      },
      {
        path: "quote-requests",
        name: "Quote Requests",
        component: QuoteRequests,
        meta: {
          auth: true,
          collapse_menu: true
        }
      },
      {
        path: "asset-details/:assetName",
        name: "Asset Details",
        component: AssetDetail,
        meta: {
          collapse_menu: true,
          auth: true
        },
        children: [
          {
            path: "timeline/:assetID",
            name: "Asset Details Timeline",
            component: Timeline,
            props: route => {
              return {
                ...route.params
              };
            },
            meta: {
              collapse_menu: true,
              auth: true
            },
            children: [
              {
                path: "data-point/:inspectionID",
                name: "Asset Data Point",
                component: AssetDataPoint,
                props: route => {
                  return {
                    ...route.params
                  };
                },
                meta: {
                  collapse_menu: true,
                  auth: true
                }
              }
            ]
          },
          {
            path: "documents/:assetID",
            name: "Asset Details Documents",
            component: AssetDocuments,
            meta: {
              collapse_menu: true,
              auth: true
            }
          }
        ]
      },
      {
        path: "create-survey",
        name: "Create Survey",
        component: CreateFlight,
        meta: {
          auth: true,
          collapse_menu: true
        }
      },
      {
        path: "surveys",
        name: "Surveys",
        component: Flights,
        meta: {
          auth: true,
          collapse_menu: true
        }
      },
      {
        path: "surveys/:flightID",
        name: "Survey Details",
        component: FlightDetails,
        props: route => {
          return {
            ...route.params
          };
        },
        meta: {
          auth: true,
          collapse_menu: true
        }
      },
      {
        path: "upload-data",
        name: "Upload Data",
        component: UploadData,
        meta: {
          auth: true,
          collapse_menu: true
        }
      },
      {
        path: "edit-upload-data/:inspectionID",
        name: "Edit Upload Data",
        component: EditUploadData,
        meta: {
          auth: true,
          collapse_menu: true
        }
      },
      {
        path: "*",
        name: "NotFound-404",
        component: NotFound,
        meta: {
          auth: true,
          collapse_menu: true
        }
      }
    ]
  },
  {
    path: "*",
    name: "NotFound-404",
    component: NotFound,
    meta: {
      auth: true,
      collapse_menu: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  console.log(to.meta.collapse_menu);
  if (to.meta.collapse_menu) {
    console.log("true---");
    store.commit("set_minisidebar", true);
  } else {
    console.log("false---");
    store.commit("set_minisidebar", false);
  }
  if (to.meta.auth) {
    let token = localStorage.getItem("auth_token");
    if (token) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;

<template>
  <v-dialog
    v-model="cookieDialog"
    persistent
    max-width="600px"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Cookies</span>
      </v-card-title>
      <v-card-text>
        This website uses its own cookies to offer a better service. If you go
        on surfing, we will consider you accepting its use. You will find more
        information in our
        <a :href="$store.state.cookie_url" target="_blank">Cookies Policy</a>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cookieDialog = false">
          Decline
        </v-btn>
        <v-btn color="primary" text @click="acceptCookie">
          Accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "CookieComp",
  data() {
    return {
      cookieDialog: true
    };
  },
  methods: {
    acceptCookie() {
      Cookies.set("webCookieAccept", true);
      this.cookieDialog = false;
    }
  }
};
</script>

<style scoped>
>>> .v-dialog {
  position: absolute;
  bottom: 0;
}
</style>

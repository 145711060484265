<template>
  <v-container fluid>
    <h1>Surveys</h1>
    <div class="loadingDivStyle" v-if="pageLoading">
      <Loading loadingText="Fetching Surveys!" v-if="pageLoading" />
    </div>
    <v-container fluid v-else>
      <div class="loadingDivStyle" v-if="!flightsList.length">
        <h1 class="nothingFoundHeading">No Survey Found!</h1>
      </div>

      <v-card
        class="mt-4"
        style="border:1px solid white"
        v-else
        v-for="(item, ind) in flightsList"
        :key="ind"
      >
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="title white--text pl-0 ">
            {{ item.name ? item.name : "Survey Info" }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn color="white" icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-subtitle class="specialDronePageDescription">
          {{ item.description }}
        </v-card-subtitle>

        <div class="dateGrid pt-3">
          <div class="pricedrop-tag" v-if="item.archived">
            <div class="pricedrop-ribbon urgent">
              ARCHIVED
            </div>
          </div>

          <div class="dateGridInner">
            <div style="marginLeft:-4px">
              <v-card-text>
                <h3 style="marginLeft:23px;">Survey Status</h3>
                <TimeLineStatus :flightProgress="item.progress" />
              </v-card-text>
            </div>

            <div style="marginLeft:20px">
              <v-card-text><h3>Details</h3></v-card-text>
              <FlightDetailsCard
                :assetName="item.asset.name"
                :startDate="item.date_start"
                :endDate="item.date_end"
                :editable="false"
                :geom_type="item.geom_type"
                :geom_measurement="item.geom_measurement"
                :cost="item.cardCost"
              />
            </div>
          </div>

          <v-row>
            <v-col md="4" sm="12" cols="12">
              <div style="marginLeft:20px">
                <v-card-text>
                  <h3>Area Details</h3>
                  <br />
                  <b>Address:</b>
                  <p>{{ item.asset.address }}</p>
                  <br />
                  <b>Description:</b>
                  <p>{{ item.asset.description }}</p>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" :to="{ path: `surveys/${item.pk}` }">
                    <v-icon>
                      mdi-check
                    </v-icon>
                    Survey Details
                  </v-btn>
                </v-card-actions>
              </div>
            </v-col>
            <v-col md="8" sm="12" cols="12">
              <!-- <gmap-map
                :center="{ lat: 10, lng: 10 }"
                :zoom="7"
                map-type-id="terrain"
                :options="{
                  zoomControl: true,
                  mapTypeControl: true,
                  scaleControl: true,
                  streetViewControl: true,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUi: false
                }"
                class="fill-height"
                style="width: 100%;"
              ></gmap-map> -->
              <div style="margin:16px;height:450px">
                <FlightListMap
                  :geoJSONData="item.geoJSONData"
                  style="height: 100%; z-index: 12"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
import Loading from "../components/misc/Loading";
import TimeLineStatus from "../components/flight/TimeLineStatus";
import FlightDetailsCard from "../components/flight/FlightDetailsCard";
import FlightListMap from "../components/flight/FlightListMap";

export default {
  metaInfo() {
    return {
      title: this.$store.state.app_title,
      titleTemplate: "%s | Surveys"
    };
  },
  components: {
    Loading,
    TimeLineStatus,
    FlightDetailsCard,
    FlightListMap
  },
  data: () => ({
    pageLoading: true,
    flightsList: [],
    userDetials: {},
    tileProviders: [
      {
        name: "OpenStreetMap",
        visible: false,
        attribution:
          "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      },
      {
        name: "Toner",
        visible: false,
        url: "//stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png",
        attribution:
          'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      },
      {
        name: "Satellite",
        visible: false,
        url:
          "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        attribution:
          "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
      },
      {
        name: "googleSat",
        visible: false,
        url: "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      },
      {
        name: "googleStreets",
        visible: true,
        url: "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      },
      {
        name: "googleHybrid",
        visible: false,
        url: "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      },
      {
        name: "googleTerrain",
        visible: false,
        url: "http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      }
    ]
  }),
  mounted() {
    this.getProfileData();
  },
  methods: {
    async getProfileData() {
      try {
        let res = await this.$http.get("/profile/details/", {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        console.log("Layout response is", res.data);

        this.userDetials = res.data;
        this.getFlights();
      } catch (err) {
        console.log(err);
      }
    },
    async getFlights() {
      try {
        let res = await this.$http.get("/flights/", {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        let anotherArr = [...res.data];

        for (let [ind, i] of anotherArr.entries()) {
          let geoArea = {
            type: "Feature",
            geometry: {
              ...i.area
            }
          };
          anotherArr[ind].geoJSONData = geoArea;
          if (this.userDetials.user_type == "Client") {
            anotherArr[ind].cardCost = i.clients_price;
          } else {
            anotherArr[ind].cardCost = i.pilots_cost;
          }
        }
        this.flightsList = anotherArr;
        this.pageLoading = false;
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style scoped>
.loadingDivStyle {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nothingFoundHeading {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  text-align: center;
}
</style>

<style>
.specialDronePageDescription {
  font-size: 16px;
  margin-top: -25px;
  margin-left: 20px;
  margin-right: 20px;
}
.pricedrop-tag {
  position: absolute;
  left: -16px;
  top: 12%;
  z-index: 445;
}

.urgent {
  margin-bottom: 10px;
  background: #d54b4f;
}

.urgent:before {
  border-left: 10px solid #d54b4f;
}

.pricedrop-ribbon {
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 12px;
  padding: 7px 3px 8px 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}

.pricedrop-ribbon:before {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  border-top: 13px solid transparent;
  border-bottom: 14px solid transparent;
}

.urgent:after {
  border-top: 5px solid #993538;
}

.pricedrop-ribbon:after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  border-left: 5px solid transparent;
}

.dateGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(540px, 1fr));
  column-gap: 5px;
  row-gap: 10px;
  width: 100%;
  justify-content: center;
}

.dateGridInner {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  column-gap: 5px;
  row-gap: 10px;
  width: 100%;
  justify-content: center;
}
</style>

<template>
  <v-container fluid>
    <v-dialog
      v-model="uploadDocumentDialog"
      persistent
      max-width="900px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Upload Document</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <DocumentUpload
              :assetID="$route.params.assetID"
              @uploadCompleted="uploadDialogResultHandler"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="uploadDocumentDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div style="display:flex;flexFlow:row-reverse">
      <v-btn
        color="primary"
        class="ma-2 white--text"
        @click="uploadDocumentDialog = true"
      >
        Upload
        <v-icon right dark>
          mdi-cloud-upload
        </v-icon>
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12">
        <div style="display:flex">
          <v-text-field
            label="Search By Name"
            class="mb-5"
            @input="e => searchHandler(e, 'file_name')"
            :value="file_name"
          />
          <v-text-field
            label="Search By File Type"
            class="mb-5 ml-5"
            @input="e => searchHandler(e, 'mime_type')"
            :value="mime_type"
          />
        </div>
      </v-col>
    </v-row>

    <div class="documentLoadingDivStyle" v-if="loading">
      <Loading loadingText="Fetching Data!" />
    </div>

    <span class="documentDataResult" v-else-if="documentsData.length < 1">
      {{ documentMessage }}
    </span>

    <v-row v-else>
      <v-col
        style="position:relative"
        xl="2"
        md="3"
        cols="12"
        v-for="(item, index) in documentsData"
        :key="index"
      >
        <v-avatar
          tile
          color="primary"
          style="position:absolute;right:0;z-index:3;cursor:pointer;"
          @click="documentRemoveHandler(item.id, index)"
        >
          <v-icon dark>mdi-delete</v-icon>
        </v-avatar>
        <a :href="item.file_url" target="_blank" style="textDecoration:none">
          <v-card style="cursor:pointer">
            <v-responsive :aspect-ratio="16 / 9">
              <v-container class="fill-height">
                <v-row align="center" justify="center">
                  <v-col cols="12" class="text-center">
                    <div class="separateLoadingDivStyle" v-if="item.loading">
                      <Loading loadingText="Deleting!" />
                    </div>
                    <v-img
                      src="../../assets/documentpic.png"
                      aspect-ratio="1"
                      style="cursor:pointer"
                      v-else
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-responsive>
            <v-card-title>
              {{ item.file_name }}
            </v-card-title>

            <v-card-subtitle>
              {{ item.mime_type }}
            </v-card-subtitle>
          </v-card>
        </a>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" right :color="success ? 'green' : 'red'">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Loading from "../../components/misc/Loading";
import DocumentUpload from "../../components/asset/DocumentUpload";

export default {
  name: "AssetDocuments",
  components: {
    Loading,
    DocumentUpload
  },
  data: () => ({
    loading: true,
    documentsData: [],
    originalDocumentsData: [],
    documentMessage: "No Data!",
    uploadDocumentDialog: false,
    snackbar: false,
    success: true,
    snackbarMessage: "Files has been successfully uploaded",
    file_name: "",
    mime_type: ""
  }),
  methods: {
    uploadDialogResultHandler(value) {
      if (value) {
        this.snackbar = true;
        this.success = true;
        this.snackbarMessage = "Files has been successfully uploaded";
        this.uploadDocumentDialog = false;
        this.fetchDocuments();
      } else {
        this.success = false;
        this.snackbar = false;
        this.snackbarMessage = "Something went wrong";
      }
    },
    async fetchDocuments() {
      try {
        this.loading = true;
        let res = await this.$http.get(
          `asset/${this.$route.params.assetID}/documents`,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.documentsData = res.data;
        this.originalDocumentsData = res.data;
        if (res.data.length < 1) {
          this.documentMessage = "No Data!";
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.documentMessage = "Something went wrong!";
      }
    },
    async documentRemoveHandler(id, index) {
      try {
        let duplicateObj = { ...this.documentsData[index] };
        duplicateObj.loading = true;
        this.documentsData.splice(index, 1, duplicateObj);
        await this.$http.delete(`/asset-document/${id}/delete`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        let foundIndex = this.documentsData.findIndex(el => el.id == id);
        this.documentsData.splice(foundIndex, 1);
        this.originalDocumentsData.splice(foundIndex, 1);
        this.snackbar = true;
        this.success = true;
        this.snackbarMessage = "File has been removed successfully";
      } catch (err) {
        this.snackbar = false;
        this.success = false;
        this.snackbarMessage = "Something went wrong";
        let duplicateObj = { ...this.documentsData[index] };
        duplicateObj.loading = false;
        this.documentsData.splice(index, 1, duplicateObj);
      }
    },
    searchHandler(e, name) {
      this[name] = e;
      let duplicateArr = [...this.originalDocumentsData];
      let newArr = duplicateArr.filter(
        el =>
          el.file_name.toLowerCase().includes(this.file_name.toLowerCase()) &&
          el.mime_type.toLowerCase().includes(this.mime_type.toLowerCase())
      );
      this.documentsData = newArr;
    }
  },
  mounted() {
    this.fetchDocuments();
  }
};
</script>

<style scoped>
.documentLoadingDivStyle {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documentDataResult {
  margin-top: 50px;
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  display: block;
  text-align: center;
}

.separateLoadingDivStyle {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000054;
}
</style>

<template>
  <v-container fluid>
    <h1>Survey</h1>

    <div style="display:flex;alignItems:center;marginTop:20px">
      <v-btn icon @click="$router.push('/app/surveys')">
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
      <span
        style="fontSize:20px;cursor:pointer;"
        @click="$router.push('/app/surveys')"
        >Back</span
      >
    </div>

    <div class="loadingDivStyle" v-if="pageLoading">
      <Loading loadingText="Fetching Survey Data!" v-if="pageLoading" />
    </div>

    <ErrorComp
      errorMessage="The page doesnot exist or you donot have permission to access this page"
      v-else-if="pageError"
    />

    <v-container fluid v-else>
      <v-card>
        <v-app-bar flat color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="title white--text pl-0">
            {{ flightName ? flightName : "Survey Info" }}
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn color="white" icon>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-subtitle class="specialDronePageDescription">
          {{ flightDescription }}
        </v-card-subtitle>

        <div class="dateGrid">
          <div style="marginLeft:-4px">
            <v-card-text>
              <h3>Survey Status</h3>
              <TimeLineStatus :flightProgress="flightData.progress" />
            </v-card-text>
          </div>

          <div style="marginLeft:20px">
            <v-card-text><h3>Survey Details</h3></v-card-text>
            <FlightDetailsCard
              :flightID="flightID"
              :assetName="assetDetail.name"
              :startDate="flightData.date_start"
              :endDate="flightData.date_end"
              :flightDateComp="flightDate"
              :backupDateComp="backupDate"
              :editable="editable && flightAccepted ? true : false"
              :editableFlightDate="editableFlightDate"
              :geom_type="flightData.geom_type"
              :geom_measurement="flightData.geom_measurement"
              :cost="cardCost"
              @flightDateSelected="flightDateChanger"
              @backupDateSelected="backupDateChanger"
            />
          </div>

          <div style="marginLeft:20px">
            <v-card-text>
              <h3>Area Details</h3>
              <br />
              <b>Address:</b>
              <p>{{ this.assetDetail.address }}</p>
              <br />
              <b>Description:</b>
              <p>{{ this.assetDetail.description }}</p>
            </v-card-text>
            <v-container fluid>
              <v-row no-gutters>
                <v-col>
                  <v-btn
                    color="secondary"
                    class="mb-2"
                    block
                    @click="archiveHandler"
                    v-if="
                      (isSuperUser || isClient) &&
                        !flightArchived &&
                        !flightProcessing &&
                        !flightReviewing
                    "
                    >Archive</v-btn
                  >

                  <!-- <v-btn 
                    color="secondary" 
                    class="mb-2" 
                    block
                    v-if="isSuperUser || isClient"
                  >Clone</v-btn> -->

                  <v-dialog
                    ref="dialog"
                    v-model="cloneModal"
                    :return-value.sync="cloneDate"
                    persistent
                    width="290px"
                  >
                    <h3>Choose Date Range</h3>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="secondary"
                        class="mb-2"
                        block
                        v-bind="attrs"
                        v-on="on"
                        v-if="isSuperUser || isClient"
                        >Clone</v-btn
                      >
                    </template>
                    <v-date-picker
                      v-model="cloneDate"
                      scrollable
                      range
                      :min="cloneDateMin"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="cloneModal = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="cloneHandler">
                        Clone
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>

                  <v-tooltip
                    bottom
                    v-if="
                      (isSuperUser || isTrustedPilot || isUntrustedPilot) &&
                        flightApproved &&
                        !flightProcessing &&
                        !flightReviewing &&
                        !flightCompleted &&
                        !flightArchived
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :color="
                          !(collectedData.length && flightOperationPlanURL)
                            ? 'rgba(255, 255, 255, 0.12) !important'
                            : 'success'
                        "
                        class="mb-2"
                        block
                        @click="completeFlightHandler"
                        >Completed
                      </v-btn>
                    </template>
                    <span>
                      {{
                        !(collectedData.length && flightOperationPlanURL)
                          ? "Upload Data First! Collected Data and Survey Operation Plan is necessary"
                          : "Click to complete the survey"
                      }}
                    </span>
                  </v-tooltip>

                  <v-btn
                    color="success"
                    class="mb-2"
                    block
                    :loading="approveLoading"
                    @click="flightApproveHandler"
                    v-if="
                      (isSuperUser || isTrustedPilot) &&
                        flightAccepted &&
                        flightDate &&
                        !flightApproved
                    "
                    >Approve</v-btn
                  >

                  <v-btn
                    color="error"
                    class="mb-2"
                    block
                    v-if="
                      (isSuperUser || isClient) &&
                        !flightApproved &&
                        !flightArchived
                    "
                    >Cancel</v-btn
                  >
                </v-col>
              </v-row>
              <v-row
                v-if="(isTrustedPilot || isUntrustedPilot) && !flightAccepted"
              >
                <v-col cols="6">
                  <v-btn
                    color="success"
                    block
                    :loading="acceptLoading"
                    @click="acceptOrDeclineFlight('accept')"
                    >Accept</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn
                    color="error"
                    block
                    :loading="rejectLoading"
                    @click="acceptOrDeclineFlight('reject')"
                    >Decline</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="flightProcessing">
                <v-col cols="12">
                  <span class="flightStatusStyle" style="display:block"
                    >Flight is under Process</span
                  >
                </v-col>
              </v-row>
              <v-row
                v-if="
                  flightAccepted &&
                    !flightDate &&
                    (isTrustedPilot || isUntrustedPilot || isSuperUser) &&
                    !flightArchived
                "
              >
                <v-col cols="12">
                  <span class="flightStatusStyle" style="display:block"
                    >Select Survey Date</span
                  >
                  <span class="flightStatusStyle" style="fontSize:23px"
                    >(Survey is accepted by engineer)</span
                  >
                </v-col>
              </v-row>
              <v-row
                v-if="
                  flightAccepted &&
                    flightDate &&
                    (isTrustedPilot || isSuperUser) &&
                    !flightApproved &&
                    !flightArchived
                "
              >
                <v-col cols="12">
                  <span class="flightStatusStyle" style="display:block"
                    >Waiting Approval</span
                  >
                  <span class="flightStatusStyle" style="fontSize:23px"
                    >(Click approve button to approve)</span
                  >
                </v-col>
              </v-row>
              <v-row
                v-if="
                  flightAccepted &&
                    flightDate &&
                    isUntrustedPilot &&
                    !flightApproved &&
                    !flightArchived
                "
              >
                <v-col cols="12">
                  <span class="flightStatusStyle" style="display:block"
                    >Pending Approval</span
                  >
                  <span class="flightStatusStyle" style="fontSize:23px"
                    >(waiting for approval from admin)</span
                  >
                </v-col>
              </v-row>
              <v-row v-if="flightArchived">
                <v-col cols="12">
                  <span class="flightStatusStyle" style="display:block"
                    >Survey Archived</span
                  >
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>

        <v-row style="marginTop:-35px;">
          <v-col cols="12">
            <v-card-text>
              <h3>Quick Links:</h3>
            </v-card-text>
            <div>
              <ul class="quickLinksStyle">
                <li
                  @click="$router.push(`${pagePushLink}?tab=graphics`)"
                  v-if="showImagesTab"
                >
                  <v-icon>mdi-camera-image</v-icon>
                  <span>Graphics</span>
                </li>

                <li
                  @click="$router.push(`${pagePushLink}?tab=2d`)"
                  v-if="show2DTab"
                >
                  <v-icon>mdi-map</v-icon>
                  <span>2D Map</span>
                </li>

                <li @click="$router.push(`${pagePushLink}?tab=processing`)">
                  <v-icon>mdi-cog</v-icon>
                  <span>Processing</span>
                </li>

                <li
                  @click="$router.push(`${pagePushLink}?tab=cesium`)"
                  v-if="showCesiumTab"
                >
                  <v-icon>mdi-file-presentation-box</v-icon>
                  <span>Cesium</span>
                </li>

                <li
                  @click="$router.push(`${pagePushLink}?tab=potree`)"
                  v-if="showPotreeTab"
                >
                  <v-icon>mdi-grid-large</v-icon>
                  <span>Potree</span>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div style="margin:16px;">
              <FlightListMap
                style="width: 100%; height: 50vh;zIndex:1"
                :geoJSONData="geoJSONData"
              />
            </div>
          </v-col>
        </v-row>

        <div class="dateGridlower" v-if="isSuperUser">
          <div>
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-toolbar-title class="title white--text pl-0">
                Manage Survey
              </v-toolbar-title>
            </v-app-bar>
            <v-container fluid>
              <!-- <v-row>
                <v-col cols="8">
                  <v-select :items="items" label="Flight Status"></v-select>
                </v-col>
                <v-col cols="4">
                  <v-btn color="primary" block>Change Status</v-btn>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="8">
                  <v-select
                    :items="clientsListProcessing"
                    label="Client"
                    :loading="clientsLoading"
                    :hint="clientsLoadingReturn"
                    :disabled="clientsLoading"
                    :error="clientFetchingError || clientAssigningError"
                    :error-messages="clientErrorCheck"
                    v-model="selected_client"
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    color="primary"
                    block
                    :disabled="
                      clientsLoading ||
                        !selected_client ||
                        selected_client == original_selected_client
                    "
                    @click="assignClient"
                    >Change Client</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-select
                    :items="pilotsListProcessing"
                    label="Engineers"
                    :loading="pilotsLoading"
                    :hint="pilotsLoadingReturn"
                    :disabled="pilotsLoading"
                    :error="pilotFetchingError || pilotAssigningError"
                    :error-messages="pilotErrorCheck"
                    v-model="selected_pilot"
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    color="primary"
                    block
                    :disabled="
                      pilotsLoading ||
                        !selected_pilot ||
                        selected_pilot == original_selected_pilot
                    "
                    @click="assignPilot"
                    >Change Engineer</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div>
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-toolbar-title class="title white--text pl-0">
                Survey Costs
              </v-toolbar-title>
            </v-app-bar>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Engineer's Cost"
                    type="number"
                    v-model="pilotCost"
                  ></v-text-field>
                  <v-text-field
                    label="Client's Price"
                    type="number"
                    v-model="clientPrice"
                  ></v-text-field>
                  <v-text-field
                    label="Quotation URL"
                    v-model="quotationURL"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    :disabled="
                      costDisabledButtonProcessing || updateCostButtonDisabled
                    "
                    @click="updateCostHandler"
                    >Update Costs</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>

        <v-row
          v-if="
            ((isSuperUser && flightApproved) ||
              ((isTrustedPilot || isUntrustedPilot) && flightApproved)) &&
              !flightArchived
          "
        >
          <v-col>
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-toolbar-title class="title white--text pl-0">
                Project Deliverables
              </v-toolbar-title>
            </v-app-bar>
            <v-container>
              <v-row>
                <v-col>
                  <h3>Collected Data</h3>

                  <UploadComp
                    :inspectionID="flightData.inspection"
                    fileType="raw"
                    fileMessageType="Collected Data Files"
                    :multipleAllow="true"
                    acceptFiles="video/*,image/*"
                    @uploadComplete="getCollectedData"
                  />
                  <GalleryLightboxDialog :galleryData="collectedData" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>Logs Files From Survey</h3>
                  <UploadComp
                    :inspectionID="flightData.inspection"
                    fileType="log"
                    fileMessageType="Log Files"
                    :multipleAllow="true"
                    @uploadComplete="getCollectedData"
                  />
                  <LogFilesDialog
                    :logData="logfiles"
                    @fileRemoved="dialogFileRemoveHandler"
                    heading="Previous Log Files"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>Survey Operations Plan</h3>
                  <UploadComp
                    :inspectionID="flightData.inspection"
                    fileType="plan"
                    fileMessageType="Flight Operations Plan Files"
                    :multipleAllow="false"
                  />
                  <v-btn
                    color="cyan"
                    class="mb-2 mt-3"
                    block
                    :disabled="flightOperationPlanURL ? false : true"
                  >
                    <a
                      :href="flightOperationPlanURL"
                      target="_blank"
                      :style="
                        flightOperationPlanURL
                          ? 'text-decoration:none;color:white'
                          : 'text-decoration:none;color:gray'
                      "
                    >
                      Download Previous Survey Operation files
                    </a>
                  </v-btn>
                  <!-- <vue-dropzone
                    id="dropzone-operations-plan"
                    :options="{
                      url: 'https://httpbin.org/post',
                      thumbnailWidth: 150,
                      maxFilesize: 0.5
                    }"
                  >
                  </vue-dropzone>
                  <v-btn block color="success">Upload</v-btn>
                  <v-btn block color="secondary">Download</v-btn> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <Comments :inspectionID="flightData.inspection" />
        <!-- <v-row>
          <v-col>
            <v-app-bar flat color="rgba(0, 0, 0, 0)">
              <v-toolbar-title class="title white--text pl-0">
                Comments
              </v-toolbar-title>
            </v-app-bar>
            <v-container>
              <v-timeline dense clipped>
                <v-timeline-item
                  fill-dot
                  class="white--text mb-12"
                  color="primary"
                  large
                >
                  <template v-slot:icon>
                    <span>JL</span>
                  </template>
                  <v-text-field
                    v-model="input"
                    hide-details
                    flat
                    label="Leave a comment..."
                    solo
                    @keydown.enter="comment"
                  >
                    <template v-slot:append>
                      <v-btn color="primary" class="mx-0" depressed @click="comment">
                        Post
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-timeline-item>

                <v-slide-x-transition group>
                  <v-timeline-item
                    v-for="event in timeline"
                    :key="event.id"
                    class="mb-4"
                    color="pink"
                    small
                  >
                    <v-row justify="space-between">
                      <v-col cols="7" v-text="event.text"></v-col>
                      <v-col
                        class="text-right"
                        cols="5"
                        v-text="event.time"
                      ></v-col>
                    </v-row>
                  </v-timeline-item>
                </v-slide-x-transition>

                <v-timeline-item class="mb-6" hide-dot>
                  <span>TODAY</span>
                </v-timeline-item>

                <v-timeline-item
                  class="mb-4"
                  color="grey"
                  icon-color="grey lighten-2"
                  small
                >
                  <v-row justify="space-between">
                    <v-col cols="7">
                      This order was archived.
                    </v-col>
                    <v-col class="text-right" cols="5">
                      15:26 EDT
                    </v-col>
                  </v-row>
                </v-timeline-item>

                <v-timeline-item class="mb-4" small>
                  <v-row justify="space-between">
                    <v-col cols="7">
                      <v-chip
                        class="white--text ml-0"
                        color="purple"
                        label
                        small
                      >
                        APP
                      </v-chip>
                      Digital Downloads fulfilled 1 item.
                    </v-col>
                    <v-col class="text-right" cols="5">
                      15:25 EDT
                    </v-col>
                  </v-row>
                </v-timeline-item>

                <v-timeline-item class="mb-4" color="grey" small>
                  <v-row justify="space-between">
                    <v-col cols="7">
                      Order confirmation email was sent to John Leider
                      (john@vuetifyjs.com).
                    </v-col>
                    <v-col class="text-right" cols="5">
                      15:25 EDT
                    </v-col>
                  </v-row>
                </v-timeline-item>

                <v-timeline-item class="mb-4" hide-dot>
                  <v-btn class="mx-0">
                    Resend Email
                  </v-btn>
                </v-timeline-item>

                <v-timeline-item class="mb-4" color="grey" small>
                  <v-row justify="space-between">
                    <v-col cols="7">
                      A $15.00 USD payment was processed on PayPal Express
                      Checkout
                    </v-col>
                    <v-col class="text-right" cols="5">
                      15:25 EDT
                    </v-col>
                  </v-row>
                </v-timeline-item>

                <v-timeline-item color="grey" small>
                  <v-row justify="space-between">
                    <v-col cols="7">
                      John Leider placed this order on Online Store (checkout
                      #1937432132572).
                    </v-col>
                    <v-col class="text-right" cols="5">
                      15:25 EDT
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-container>
          </v-col>
        </v-row> -->
      </v-card>
    </v-container>
    <v-snackbar v-model="snackbar" right color="green">
      {{ this.snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
// import VueDropzone from "@/components/vue-dropzone/vue-dropzone";
import Loading from "../components/misc/Loading";
import ErrorComp from "../components/misc/ErrorComp";

import TimeLineStatus from "../components/flight/TimeLineStatus";
import FlightDetailsCard from "../components/flight/FlightDetailsCard";
import FlightListMap from "../components/flight/FlightListMap";
import UploadComp from "../components/flight/UploadComp";
import Comments from "../components/flight/Comments.vue";

import GalleryLightboxDialog from "../components/flight/Gallery/GalleryLightboxDialog";
import LogFilesDialog from "../components/flight/LogFilesDialog";

export default {
  metaInfo() {
    return {
      title: this.$store.state.app_title,
      titleTemplate: "%s | Survey Details"
    };
  },
  components: {
    // VueDropzone,
    Loading,
    ErrorComp,
    TimeLineStatus,
    FlightDetailsCard,
    FlightListMap,
    UploadComp,
    GalleryLightboxDialog,
    LogFilesDialog,
    Comments
  },
  props: ["flightID"],
  data() {
    return {
      pagePushLink: undefined,
      pageLoading: true,
      pageError: false,
      assetDetail: {},
      items: [],
      events: [],
      input: null,
      nonce: 0,
      pilotsList: null,
      cardCost: 0,
      pilotsLoading: true,
      selected_pilot: null,
      original_selected_pilot: null,
      pilotFetchingError: false,
      pilotAssigningError: false,
      assigningPilot: false,
      clientsList: null,
      clientsLoading: true,
      selected_client: null,
      original_selected_client: null,
      clientFetchingError: false,
      clientAssigningError: false,
      assigningClient: false,
      flightData: null,
      geoJSONCompX: "65.94",
      geoJSONCompY: "-52.03",
      groJSONData: "",
      userDetials: {},
      editable: true,
      editableFlightDate: true,
      isSuperUser: false,
      isClient: false,
      isTrustedPilot: false,
      isUntrustedPilot: false,
      flightAccepted: false,
      flightApproved: false,
      flightArchived: false,
      flightProcessing: false,
      flightReviewing: false,
      flightCompleted: false,
      flightDateSelected: false,
      flightDate: null,
      backupDate: null,
      snackbar: false,
      snackbarMessage: "Engineer has been successfully assigned",
      pilotCost: null,
      clientPrice: null,
      quotationURL: null,
      originalPilotCost: null,
      originalClientPrice: null,
      originalQuotationURL: null,
      updateCostButtonDisabled: false,
      cloneModal: false,
      cloneDate: [],
      cloneDateMin: new Date().toISOString(),
      collectedData: [],
      logfiles: [],
      flightOperationPlanURL: "",
      flightName: null,
      flightDescription: null,
      showImagesTab: false,
      showCesiumTab: false,
      showPotreeTab: false,
      show2DTab: false,
      acceptLoading: false,
      rejectLoading: false,
      approveLoading: false
    };
  },

  computed: {
    pilotsListProcessing() {
      if (this.pilotsList) {
        let arrToReturn = [];

        for (let i of this.pilotsList) {
          arrToReturn.push({
            text: `${i.first_name} ${i.last_name}`,
            value: i.user_id
          });
        }
        return arrToReturn;
      }
      return [];
    },
    pilotsLoadingReturn() {
      if (this.assigningPilot) {
        return "Wait! Assigning Engineer";
      } else if (this.pilotsLoading) {
        return "Wait! Data is being fetched";
      } else if (
        this.selected_pilot == this.original_selected_pilot &&
        this.selected_pilot
      ) {
        return "This is current engineer of survey";
      }
      return "Select any Engineer";
    },
    pilotErrorCheck() {
      if (this.pilotFetchingError) {
        return "An error occured while Fetching Data";
      } else if (this.pilotAssigningError) {
        return "An error occured while Assigning Engineer";
      }
      return null;
    },
    clientsListProcessing() {
      if (this.clientsList) {
        let arrToReturn = [];

        for (let i of this.clientsList) {
          arrToReturn.push({
            text: `${i.first_name} ${i.last_name}`,
            value: i.user_id
          });
        }
        return arrToReturn;
      }
      return [];
    },
    clientsLoadingReturn() {
      if (this.assigningClient) {
        return "Wait! Assigning Client";
      } else if (this.clientsLoading) {
        return "Wait! Data is being fetched";
      } else if (
        this.selected_client == this.original_selected_client &&
        this.selected_client
      ) {
        return "This is current client of survey";
      }
      return "Select any Client";
    },
    clientErrorCheck() {
      if (this.clientFetchingError) {
        return "An error occured while Fetching Data";
      } else if (this.clientAssigningError) {
        return "An error occured while Assigning Client";
      }
      return null;
    },
    costDisabledButtonProcessing() {
      if (
        this.pilotCost == this.originalPilotCost &&
        this.clientPrice == this.originalClientPrice &&
        this.quotationURL == this.originalQuotationURL
      ) {
        return true;
      }

      return false;
    },
    timeline() {
      return this.events.slice().reverse();
    }
  },

  methods: {
    async get3DProcessedData() {
      let cesiumFileFound = false,
        potreeFileFound = false,
        data2dPresent = false;

      try {
        let res = await this.$http.get(
          `/inspection/${this.flightData.inspection}/processed-data`,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        for (let i of res.data) {
          if (i.processed_type == "3d") {
            switch (i.file_name.split(".").pop()) {
              case "zip":
                cesiumFileFound = true;
                break;
            }
          }

          if (i.processed_type == "point_cloud") {
            potreeFileFound = true;
          }

          if (["dtm", "dsm", "ortho"].includes(i.processed_type)) {
            data2dPresent = true;
          }
        }

        if (cesiumFileFound) {
          this.showCesiumTab = true;
        } else {
          this.showCesiumTab = false;
        }

        if (potreeFileFound) {
          this.showPotreeTab = true;
        } else {
          this.showPotreeTab = false;
        }

        if (data2dPresent) {
          this.show2DTab = true;
        } else {
          this.show2DTab = false;
        }
      } catch (err) {
        console.log("something went wrong ", err);
      }
    },
    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(
          /:\d{2}\sGMT-\d{4}\s\((.*)\)/,
          // eslint-disable-next-line
          (match, contents, offset) => {
            return ` ${contents
              .split(" ")
              .map(v => v.charAt(0))
              .join("")}`;
          }
        )
      });

      this.input = null;
    },
    async getFlightData() {
      try {
        let res = await this.$http.get(`/flights/${this.flightID}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        this.flightData = { ...res.data };
        await this.getCollectedData();
        await this.get3DProcessedData();
        this.flightDateMin = res.data.date_start;
        this.flightDateMax = res.data.date_end;
        this.backupDateMax = res.data.date_end;
        this.flightDate = res.data.flight_date;
        this.backupDate = res.data.backup_date;
        this.flightName = res.data.name;
        this.flightDescription = res.data.description;
        if (this.flightDate) {
          this.editableFlightDate = false;
        }
        if (this.backupDate) {
          this.editable = false;
        }

        let geoArea = {
          type: "Feature",
          geometry: {
            ...res.data.area
          }
        };
        this.geoJSONData = geoArea;
        // this.getAsset(res.data.asset);
        this.assetDetail = res.data.asset;
        console.log("result is ", res.data);
        if (this.userDetials.user_type == "Client") {
          this.cardCost = res.data.clients_price;
        } else {
          this.cardCost = res.data.pilots_cost;
        }

        if (this.userDetials.user_type == "Client") {
          if (this.userDetials.is_superuser) {
            this.pilotCost = res.data.pilots_cost;
            this.clientPrice = res.data.clients_price;
            this.originalPilotCost = res.data.pilots_cost;
            this.originalClientPrice = res.data.clients_price;
            if (!res.data.quotation) {
              this.quotationURL = "";
              this.originalQuotationURL = "";
            } else {
              this.quotationURL = res.data.quotation;
              this.originalQuotationURL = res.data.quotation;
            }

            this.getPilots();
            this.getClients();

            if (res.data.plan_url) {
              this.flightOperationPlanURL = res.data.plan_url;
            }
          } else {
            this.editable = false;
          }
        }

        if (res.data.progress == "Processing") {
          this.flightProcessing = true;
        } else if (res.data.progress == "Reviewing") {
          this.flightReviewing = true;
        }

        if (res.data.accepted) {
          this.flightAccepted = true;
        } else {
          this.flightAccepted = false;
        }

        if (res.data.approve) {
          this.flightApproved = true;
          this.editable = false;
          if (
            (this.userDetials.user_type == "Client" &&
              this.userDetials.is_superuser) ||
            this.userDetials.user_type == "Pilot"
          ) {
            // this.getCollectedData();
          }
        } else {
          this.flightApproved = false;
        }

        if (res.data.archived) {
          this.flightArchived = true;
        } else {
          this.flightArchived = false;
        }

        if (res.data.progress == "Completed") {
          this.flightCompleted = true;
        } else {
          this.flightCompleted = false;
        }

        if (this.userDetials.is_superuser) {
          if (this.userDetials.user_type == "Client") {
            this.isSuperUser = true;
          }
        } else if (!this.userDetials.is_superuser) {
          if (this.userDetials.user_type == "Client") {
            this.isClient = true;
          } else if (this.userDetials.user_type == "Pilot") {
            if (this.userDetials.is_trusted) {
              this.isTrustedPilot = true;
            } else if (!this.userDetials.is_trusted) {
              this.isUntrustedPilot = true;
            }
          }
        }
        this.pageLoading = false;
        this.pagePushLink = `/app/asset-details/${res.data.asset.name}/timeline/${res.data.asset.pk}/data-point/${res.data.inspection}`;
        console.log("flight response is ", res.data);
      } catch (err) {
        this.pageLoading = false;
        this.pageError = true;
        console.log("Flight fetching error", err);
      }
    },
    async getPilots() {
      try {
        this.pilotsLoading = true;
        let res = await this.$http.get("/profile/get-pilots/", {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        this.pilotsList = [...res.data];
        this.selected_pilot = this.flightData.pilot;
        this.original_selected_pilot = this.flightData.pilot;
        this.pilotsLoading = false;
      } catch (err) {
        this.pilotsLoading = false;
        this.pilotFetchingError = true;
      }
    },
    async getClients() {
      try {
        this.clientsLoading = true;
        let res = await this.$http.get("/profile/get-clients/", {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        this.clientsList = [...res.data];
        this.selected_client = this.flightData.client;
        this.original_selected_client = this.flightData.client;
        this.clientsLoading = false;
      } catch (err) {
        this.clientsLoading = false;
        this.clientFetchingError = true;
      }
    },
    async getAsset(id) {
      try {
        this.pilotsLoading = true;
        let res = await this.$http.get(`/assets/details/${id}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });

        this.pageLoading = false;
        this.assetDetail = { ...res.data };
      } catch (err) {
        console.log(err);
      }
    },
    async assignPilot() {
      this.pilotsLoading = true;
      try {
        let uploadObj = new FormData();
        uploadObj.append("pilot_id", this.selected_pilot);

        await this.$http.post(
          `/flight/${this.flightID}/update-pilot`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.snackbar = true;
        this.snackbarMessage = "Engineer has been successfully assigned";
        this.original_selected_pilot = this.selected_pilot;
        this.pilotsLoading = false;
      } catch (err) {
        console.log("error is ", err);
        this.pilotsLoading = false;
        this.pilotAssigningError = true;
      }
    },
    async assignClient() {
      this.clientsLoading = true;
      try {
        let uploadObj = new FormData();
        uploadObj.append("client_id", this.selected_client);

        await this.$http.post(
          `/flight/${this.flightID}/update-client`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.snackbar = true;
        this.snackbarMessage = "Client has been successfully assigned";
        this.original_selected_client = this.selected_client;
        this.clientsLoading = false;
      } catch (err) {
        console.log("error is ", err);
        this.clientsLoading = false;
        this.clientAssigningError = true;
      }
    },
    async updateCostHandler() {
      this.updateCostButtonDisabled = true;
      try {
        let uploadObj = new FormData();
        uploadObj.append("pilots_cost", this.pilotCost);
        uploadObj.append("clients_price", this.clientPrice);
        uploadObj.append("quotation", this.quotationURL);

        let res = await this.$http.put(
          `/flight/${this.flightID}/update-quote`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.snackbar = true;
        this.snackbarMessage = "Survey costs has been updated successfully";
        this.originalPilotCost = this.pilotCost;
        this.originalClientPrice = this.clientPrice;
        this.originalQuotationURL = this.quotationURL;
        this.updateCostButtonDisabled = false;
        console.log("costs response is ", res.data);
      } catch (err) {
        console.log("error is ", err);
        this.updateCostButtonDisabled = false;
      }
    },
    async getProfileData() {
      try {
        let res = await this.$http.get("/profile/details/", {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });

        this.userDetials = res.data;
        this.getFlightData();
      } catch (err) {
        console.log(err);
      }
    },
    async acceptOrDeclineFlight(type) {
      try {
        let uploadObj = new FormData();
        uploadObj.append("action", type);

        if (type == "accept") {
          this.acceptLoading = true;
        } else if (type == "reject") {
          this.rejectLoading = true;
        }

        await this.$http.post(
          `/flight/${this.flightID}/accept-reject-flight`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.snackbar = true;
        if (type == "accept") {
          this.snackbarMessage = "Survey has been successfully accepted";
          this.flightAccepted = true;
          this.editableFlightDate = true;
          this.editable = true;
          this.acceptLoading = false;
          this.flightData.progress = "Pending Authorization";
        } else if (type == "reject") {
          this.snackbarMessage = "Survey has been successfully declined";
          this.rejectLoading = false;
          this.$router.push({
            name: "Surveys"
          });
        }
      } catch (err) {
        console.log("error in accept decline ", err);
      }
    },
    async flightApproveHandler() {
      try {
        this.approveLoading = true;
        await this.$http.post(
          `/flight/${this.flightID}/approve-flight`,
          {},
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        this.snackbar = true;
        this.snackbarMessage = "Survey has been successfully approved";
        this.flightApproved = true;
        this.approveLoading = false;
        this.flightData.progress = "Flight Execution";
        this.editable = false;
      } catch (err) {
        console.log("error in fligth approved is ", err);
      }
    },
    async cloneHandler() {
      this.$refs.dialog.save(this.cloneDate);

      let createFlightObj = {
        asset_id: this.flightData.asset,
        flight_type_ids: this.flightData.flight_types,
        date_start: this.cloneDate[0],
        date_end: this.cloneDate[1],
        description: this.flightData.description,
        reference: String(this.flightData.reference),
        geojson: JSON.stringify(this.flightData.area)
      };

      try {
        await this.$http.post("/create-flight/", createFlightObj, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });

        this.snackbar = true;
        this.snackbarMessage = "Survey has been cloned successfully";
      } catch (err) {
        console.log(err);
      }
    },
    async archiveHandler() {
      try {
        await this.$http.post(
          `/flight/${this.flightID}/archive-flight`,
          {},
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );

        this.snackbar = true;
        this.snackbarMessage = "Survey has been archived successfully";
        this.flightArchived = true;
      } catch (err) {
        console.log(err);
      }
    },
    async completeFlightHandler() {
      if (this.collectedData.length && this.flightOperationPlanURL) {
        try {
          await this.$http.post(
            `/flight/${this.flightID}/complete`,
            {},
            {
              headers: {
                Authorization: "token " + localStorage.getItem("auth_token")
              }
            }
          );

          this.snackbar = true;
          this.snackbarMessage = "Survey has been completed successfully";
        } catch (err) {
          console.log(err);
        }
      }
    },
    async getCollectedData() {
      try {
        let imagesVideosFound = false;
        let res = await this.$http.get(
          `/inspection/${this.flightData.inspection}/data`,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        let collectedDataTemp = [],
          logfilesTemp = [];
        for (let i of res.data) {
          if (i.file_type == "raw") {
            collectedDataTemp.push({ ...i });
            let requiredMime = i.mime_type;
            let firstValMime = requiredMime.split("/");
            if (["image", "video"].includes(firstValMime[0])) {
              imagesVideosFound = true;
            }
          } else if (i.file_type == "log") {
            logfilesTemp.push({ ...i });
          }
        }

        this.collectedData = [...collectedDataTemp];
        this.logfiles = [...logfilesTemp];
        if (imagesVideosFound) {
          this.showImagesTab = true;
        } else {
          this.showImagesTab = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    flightDateChanger(val) {
      this.flightDate = val;
      this.editable = true;
      this.editableFlightDate = false;
      this.snackbar = true;
      if (this.isTrustedPilot) {
        this.snackbarMessage =
          "Survey date added. Please approve the survey to proceed";
      } else {
        this.snackbarMessage =
          "Survey date added. Please wait for an admin to approve it";
      }
    },
    backupDateChanger(val) {
      this.backupDate = val;
      this.editable = false;
      this.snackbar = true;
      this.snackbarMessage = "Backup date has been successfully updated";
    },
    dialogFileRemoveHandler(value) {
      if (value) {
        this.snackbar = true;
        this.snackbarMessage = "Files has been successfully removed";
      } else {
        this.snackbar = true;
        this.snackbarMessage = "Something went wrong";
      }
    }
  },
  async mounted() {
    this.getProfileData();
  }
};
</script>

<style scoped>
.dateGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  column-gap: 5px;
  row-gap: 10px;
  width: 100%;
  justify-content: center;
}

.dateGridlower {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(570px, 1fr));
  column-gap: 5px;
  row-gap: 10px;
  width: 100%;
  justify-content: center;
}

.loadingDivStyle {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flightStatusStyle {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
}

.quickLinksStyle {
  list-style: none;
  display: flex;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.quickLinksStyle li {
  margin: 0px 20px;
  cursor: pointer;
}

.quickLinksStyle span {
  margin-left: 10px;
}

.quickLinksStyle li span:hover {
  text-decoration: underline;
}
</style>

<template>
  <login-parent>
    <v-snackbar v-model="success" color="success" top right>Success</v-snackbar>
    <v-snackbar v-model="error" color="error" top right :timeout="10000">{{
      error_msg
    }}</v-snackbar>
    <v-row justify="center">
      <v-col md="4" v-if="$store.state.landingContent">
        <v-card class="rounded-lg" style="min-height: 50vh">
          <div style="fontFamily: Quicksand, sans-serif;">
            <h2>{{ $store.state.landingContent.heading }}</h2>
            <p style="fontWeight:600">
              {{ $store.state.landingContent.text }}
            </p>
          </div>
        </v-card>
      </v-col>
      <v-col md="8">
        <v-card class="rounded-lg" style="min-height: 50vh">
          <v-container fluid>
            <v-form @submit.prevent="signup" v-model="valid">
              <v-row justify="center" align="center">
                <v-col cols="6">
                  <div class="text-center">
                    <v-icon
                      v-if="!$store.state.logoURL"
                      color="white"
                      class="font"
                      style="font-size: 100px"
                    >
                      mdi-account-plus
                    </v-icon>
                    <img
                      style="marginLeft:-20px"
                      :src="$store.state.logoURL"
                      v-else
                    />
                    <h1>Sign Up</h1>
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center" align="start">
                <v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    label="First Name"
                    prepend-inner-icon="mdi-account"
                    v-model="signup_details.first_name"
                    :rules="rules.first_name"
                    :error-messages="errors.first_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Last Name"
                    prepend-inner-icon="mdi-account"
                    v-model="signup_details.last_name"
                    :rules="rules.last_name"
                    :error-messages="errors.last_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Email"
                    type="email"
                    prepend-inner-icon="mdi-mail"
                    v-model="signup_details.email"
                    :rules="rules.email"
                    :error-messages="errors.email"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Phone No"
                    prepend-inner-icon="mdi-phone"
                    v-model="signup_details.telephone"
                    :rules="rules.telephone"
                    :error-messages="errors.telephone"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Company Name"
                    prepend-inner-icon="mdi-domain"
                    v-model="signup_details.company_name"
                    :rules="rules.company_name"
                    :error-messages="errors.company_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-0 pb-0">
                  <v-select
                    :items="user_types"
                    label="User Type"
                    prepend-inner-icon="mdi-account-multiple"
                    v-model="signup_details.user_type"
                    :rules="rules.user_type"
                    :error-messages="errors.user_type"
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Password"
                    type="password"
                    prepend-inner-icon="mdi-key"
                    v-model="signup_details.password_1"
                    :rules="rules.password_1"
                    :error-messages="errors.password_1"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Password Again"
                    type="password"
                    prepend-inner-icon="mdi-key"
                    v-model="signup_details.password_2"
                    :rules="rules.password_2"
                    :error-messages="errors.password_2"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="pt-0 pb-0">
                  <gmap-autocomplete
                    ref="address_autocomplete"
                    class="introInput"
                    placeholder=""
                    @place_changed="setAddress"
                  >
                    <template v-slot:input="slotProps">
                      <v-text-field
                        v-model="signup_details.address.formatted_address"
                        prepend-inner-icon="mdi-map"
                        :label="addressLabel"
                        ref="input"
                        v-on:listeners="slotProps.listeners"
                        v-on:attrs="slotProps.attrs"
                        :placeholder="'Address'"
                        :rules="rules.address"
                        :error-messages="errors.address"
                      ></v-text-field>
                    </template>
                  </gmap-autocomplete>
                </v-col>

                <v-col cols="6" class="pt-0 pb-0">
                  <v-text-field
                    label="VAT No"
                    prepend-inner-icon="mdi-card"
                    v-model="signup_details.vat"
                    :rules="rules.vat"
                    :error-messages="errors.vat"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="center" align="center">
                <v-col cols="6">
                  <v-btn
                    color="primary"
                    block
                    outlined
                    rounded
                    elevation="2"
                    to="/"
                    >Login</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn
                    color="primary"
                    block
                    rounded
                    elevation="2"
                    type="submit"
                    :loading="loading"
                    :disabled="!valid"
                  >
                    Sign Up
                  </v-btn>
                </v-col>
              </v-row>
              <a
                target="_blank"
                v-for="(button, index) in $store.state.landingLinks"
                :key="index"
                :href="button.button_link"
              >
                <v-btn
                  :color="button.text_color"
                  :text="true"
                  block
                  rounded
                  elevation="2"
                  class="mt-2"
                  :style="[{ backgroundColor: button.button_color }]"
                >
                  {{ button.button_text }}
                </v-btn>
              </a>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </login-parent>
</template>
<style scoped></style>
<script>
import LoginParent from "../components/LoginParent";

export default {
  name: "SignUp",
  metaInfo() {
    return {
      title: this.$store.state.app_title,
      titleTemplate: "%s | User Sign Up"
    };
  },
  components: { LoginParent },
  data: () => ({
    user_types: ["Pilot", "Client"],

    signup_details: {
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password_1: "",
      password_2: "",
      user_type: "",
      telephone: "",
      address: {
        formatted_address: ""
      },
      vat: ""
    },
    errors: {
      first_name: "",
      last_name: "",
      company_name: "",
      email: "",
      password_1: "",
      password_2: "",
      user_type: "",
      telephone: "",
      address: "",
      vat: ""
    },

    loading: false,
    success: false,
    error: false,
    error_msg: "",
    valid: false
  }),
  mounted() {},
  computed: {
    addressLabel: function() {
      if (this.signup_details.address.formatted_address === "") {
        return "";
      } else {
        return "Address";
      }
    },
    rules: function() {
      let r = {
        email: [
          v => !!v || "E-mail is required",
          v => /.+@.+/.test(v) || "E-mail must be valid"
        ],
        first_name: [v => !!v || "First Name is required"],
        last_name: [v => !!v || "Last Name is required"],
        telephone: [v => !!v || "Phone No is required"],
        company_name: [v => !!v || "Company Name is required"],
        user_type: [v => !!v || "User Type is required"],
        password_1: [v => !!v || "Password is required"],
        password_2: [v => !!v || "Password is required"],
        address: [v => !!v || "Address is required"],
        vat: []
      };
      const samerule = function(v) {
        return v === this.signup_details.password_1 || "Values do not match";
      }.bind(this);

      r.password_2.push(samerule);
      return r;
    }
  },
  methods: {
    setAddress: function(address) {
      this.signup_details.address = address;
    },
    signup() {
      this.loading = true;
      this.signup_details.address = this.signup_details.address.formatted_address;
      console.log("----signup submitted");
      console.log(this.signup_details);

      this.$http
        .post("/profile/registration/", this.signup_details)
        .then(res => {
          this.loading = false;
          console.log("signup complete", res.data);
          this.success = true;
          setTimeout(
            function() {
              this.$router.push("/");
            }.bind(this),
            1000
          );
        })
        .catch(err => {
          console.log(err.response.data);
          this.error = true;
          this.loading = false;
          console.log();

          this.error_msg = err.response.data[Object.keys(err.response.data)][0];
          this.errors = err.response.data;
          this.signup_details.address = {
            formatted_address: this.signup_details.address
          };
        });
    }
  }
};
</script>

<template>
  <v-dialog v-model="modal" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Adding Annotation</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="name"
                label="Name"
                :rules="NameRules"
                required
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                label="Description"
                :rules="DescriptionRules"
                rows="3"
                v-model="description"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="closeCameraCreateObservationDialog()"
          v-if="!loading"
          >Cancel</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          :loading="loading"
          @click="saveNewObservation()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AnnotatorCesiumSaveDialog",
  props: ["saveModal", "loading", "editingMode", "eName", "eDescription"],
  data() {
    return {
      modal: false,
      name: null,
      description: null,
      NameRules: [v => !!v || "Name is required"],
      DescriptionRules: [v => !!v || "Description is required"]
    };
  },
  watch: {
    saveModal(newValue) {
      this.modal = newValue;
      if (newValue) {
        if (this.editingMode) {
          this.name = this.eName;
          this.description = this.eDescription;
        } else {
          this.name = null;
          this.description = null;
        }
      }
    }
  },
  methods: {
    closeCameraCreateObservationDialog() {
      this.$emit("cancel");
    },

    saveNewObservation() {
      if (this.name && this.description) {
        let obj = {
          name: this.name,
          description: this.description
        };

        if (this.editingMode) {
          this.$emit("edit", obj);
        } else {
          this.$emit("save", obj);
        }
      }
    }
  }
};
</script>

<template>
  <v-app>
    <router-view> </router-view>
  </v-app>
</template>

<script>
// import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",

  components: {
    // HelloWorld
  },

  data: () => ({
    //
  })
};
</script>

<style lang="scss">
@import "css/styles.scss";
</style>

<style>
.v-application--wrap {
  max-width: revert !important;
}
</style>

<template>
  <div
    class="loadingDivStyle"
    style="display:flex;flexFlow:column;"
    v-if="loading"
  >
    <Loading loadingText="Fetching Data" />
    <!-- <div 
      id="containerspecial" 
      style="width:800px;height:500px;"
    ></div> -->
  </div>

  <div style="position:relative;marginTop:-142px;" v-else>
    <span
      style="fontSize:1.5em;fontWeight:bold;position:absolute;top:6px;zIndex:1;left:45px;"
    >
      {{ $route.params.assetName }}
    </span>
    <v-dialog
      v-model="sendingAlgoDialog"
      persistent
      max-width="600px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Sending Data For {{ algoSelectionMode }}</span>
        </v-card-title>
        <v-card-text>
          <div
            class="loadingDivStyle"
            style="display:flex;flexFlow:column;height:30vh;"
          >
            <Loading loadingText="Wait! while data is being sent" />
            <!-- <div 
              id="containerspecial" 
              style="width:800px;height:500px;"
            ></div> -->
          </div>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
          >
            Yes
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-tabs
      style="marginTop:94px;"
      background-color="primary"
      right
      v-model="tab"
    >
      <v-tab v-if="showImagesTab" @change="graphicsTabChanger" href="#graphics"
        >Media</v-tab
      >
      <v-tab @change="tabs2DChangeHandler" href="#2d" v-if="show2DTab">
        2D Viewer
      </v-tab>

      <v-tab
        v-if="!$store.state.isShared"
        @change="$router.push('?tab=processing')"
        href="#processing"
        >Processing</v-tab
      >
      <v-tab
        v-if="showCesiumTab"
        @change="$router.push(`${$store.state.isShared ? `?tab=cesium&uuid=${$route.query.uuid}`:'?tab=cesium'}`)"
        href="#cesium"
        >3D Model</v-tab
      >
      <v-tab
        v-if="!$store.state.isShared"
        @change="$router.push('?tab=comments')"
        href="#comments"
        >Comments</v-tab
      >
      <v-tab
        v-if="showPotreeTab"
        @change="$router.push(`${$store.state.isShared ? `?tab=potree&uuid=${$route.query.uuid}`:'?tab=potree'}`)"
        href="#potree"
        >Point Cloud</v-tab
      >
    </v-tabs>
    <!-- images -->
    <v-tabs-items class="tabs-items-elements" v-model="tab">
      <v-tab-item v-if="showImagesTab" value="graphics">
        <div style="margin:15px;">
          <!-- <v-container style="height: 95vh; overflow-y: auto"> -->

          <v-tabs v-model="tabGraphics">
            <v-tab v-if="collectedData.length > 0">Images</v-tab>
            <v-tab v-if="collectedVideoData.length > 0">Videos</v-tab>
          </v-tabs>

          <v-tabs-items class="tabs-items-elements" v-model="tabGraphics">
            <v-tab-item v-if="collectedData.length">
              <v-container style="height: 95vh; overflow-y: auto">
                <div style="paddingBottom:150px;">
                  <div
                    style="display:flex;flexFlow:row-reverse;"
                    v-if="!showAlgoSelections && !$store.state.isShared"
                  >
                    <v-btn
                      class="mr-5"
                      small
                      color="primary"
                      @click="
                        valueAlgoSelectionAssigner(false);
                        selectedAlgoCount = 0;
                        showAlgoSelections = true;
                        algoSelectionMode = 'TRAINING';
                      "
                    >
                      Select Images for training
                    </v-btn>
                    <v-btn
                      class="mr-5"
                      small
                      color="primary"
                      @click="
                        valueAlgoSelectionAssigner(false);
                        selectedAlgoCount = 0;
                        showAlgoSelections = true;
                        algoSelectionMode = 'DETECTION';
                      "
                    >
                      Select Images for detection
                    </v-btn>
                  </div>
                  <div
                    style="display: flex;alignItems: center;justifyContent: space-between;"
                    v-else-if="!$store.state.isShared"
                  >
                    <v-checkbox
                      v-model="selectAllAlgoSelection"
                      :label="
                        algoSelectionMode == 'TRAINING'
                          ? 'SELECT ALL FOR TRAINING'
                          : 'SELECT ALL FOR DETECTION'
                      "
                      @change="
                        val => valueAlgoSelectionAssigner(val, 'complete')
                      "
                    ></v-checkbox>
                    <span style="fontSize:20px">
                      {{ selectedAlgoCount }} SELECTED
                    </span>
                    <div>
                      <v-btn
                        class="mr-5"
                        small
                        color="primary"
                        @click="confirmAlgoHandler"
                      >
                        Confirm
                      </v-btn>
                      <v-btn
                        class="mr-5"
                        small
                        color="primary"
                        @click="
                          showAlgoSelections = false;
                          algoSelectionMode = null;
                        "
                      >
                        cancel
                      </v-btn>
                    </div>
                  </div>
                  <v-row>
                    <div class="dateGrid">
                      <div
                        v-for="(image, index) in collectedData"
                        :key="image.id"
                        style="width:100%;height:261px;padding:2px;"
                        class="d-flex child-flex"
                      >
                        <div
                          :id="'map' + index"
                          style="width:100%;zIndex:1;height:261px;"
                        >
                          <div
                            class="upperInfoArea"
                            style="marginTop:-51px;height:revert;"
                            v-if="showAlgoSelections"
                          >
                            <v-checkbox
                              @change="
                                val =>
                                  algoSelectionHandlerForAnnotation(val, index)
                              "
                              v-model="image.algoSelection"
                              :label="image.file_name"
                            ></v-checkbox>
                          </div>
                          <div class="upperInfoArea" v-else>
                            {{ image.file_name }}
                          </div>
                          <div
                            class="pricedrop-tag"
                            v-if="
                              image.loadComplete && image.annotation_count > 0
                            "
                          >
                            <div class="pricedrop-ribbon urgent">
                              {{ image.annotation_count }} Annotation{{
                                image.annotation_count > 1 ? "s" : ""
                              }}
                            </div>
                          </div>
                          <div
                            class="separateLoadingDivStyle"
                            v-if="image.imageLoading"
                          >
                            <Loading loadingText="Deleting!" />
                          </div>

                          <div
                            class="deleteArea"
                            v-else-if="!$store.state.isShared"
                          >
                            <v-btn
                              icon
                              @click="
                                e =>
                                  annotatorDialogOpener(
                                    e,
                                    image.image_url,
                                    image.id,
                                    image.file_name,
                                    index
                                  )
                              "
                            >
                              <v-icon dark color="white">mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              color="error darken-3"
                              @click="
                                e => deleteClickHandler(e, image.id, index)
                              "
                            >
                              <v-icon dark>mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-row>
                </div>
              </v-container>
            </v-tab-item>

            <v-tab-item v-if="collectedVideoData.length">
              <v-col
                v-for="video in collectedVideoData"
                :key="video.id"
                class="d-flex child-flex"
                cols="3"
              >
                <v-hover v-slot="{ hover }">
                  <v-img
                    :src="video.thumbnail_url"
                    :lazy-src="video.thumbnail_url"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    :elevation="hover ? 16 : 2"
                    :class="{ overlay: hover }"
                    :gradient="
                      hover
                        ? 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'
                        : ''
                    "
                    @click="videoModalOpener(video)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-hover>
              </v-col>
            </v-tab-item>
          </v-tabs-items>

          <!-- <span class="collectedDataResult" v-if="collectedData.length < 1">
            No Data
          </span> -->

          <!-- <GalleryLightbox 
          :galleryData="collectedData"
          cols="4"
          v-else
        /> -->

          <!-- <div style="paddingBottom:70px;" v-else>
            <div
              style="display:flex;flexFlow:row-reverse;"
              v-if="!showAlgoSelections && !$store.state.isShared"
            >
              <v-btn
                class="mr-5"
                small
                color="primary"
                @click="
                  valueAlgoSelectionAssigner(false);
                  selectedAlgoCount = 0;
                  showAlgoSelections = true;
                  algoSelectionMode = 'TRAINING';
                "
              >
                Select Images for training
              </v-btn>
              <v-btn
                class="mr-5"
                small
                color="primary"
                @click="
                  valueAlgoSelectionAssigner(false);
                  selectedAlgoCount = 0;
                  showAlgoSelections = true;
                  algoSelectionMode = 'DETECTION';
                "
              >
                Select Images for detection
              </v-btn>
            </div>
            <div
              style="display: flex;alignItems: center;justifyContent: space-between;"
              v-else-if="!$store.state.isShared"
            >
              <v-checkbox
                v-model="selectAllAlgoSelection"
                :label="
                  algoSelectionMode == 'TRAINING'
                    ? 'SELECT ALL FOR TRAINING'
                    : 'SELECT ALL FOR DETECTION'
                "
                @change="val => valueAlgoSelectionAssigner(val, 'complete')"
              ></v-checkbox>
              <span style="fontSize:20px">
                {{ selectedAlgoCount }} SELECTED
              </span>
              <div>
                <v-btn
                  class="mr-5"
                  small
                  color="primary"
                  @click="confirmAlgoHandler"
                >
                  Confirm
                </v-btn>
                <v-btn
                  class="mr-5"
                  small
                  color="primary"
                  @click="
                    showAlgoSelections = false;
                    algoSelectionMode = null;
                  "
                >
                  cancel
                </v-btn>
              </div>
            </div>
            <v-row>
              <v-col
                v-for="(image, index) in collectedData"
                :key="image.id"
                class="d-flex child-flex"
                :cols="colNumber"
              >
              <div class="dateGrid">
                <div
                  v-for="(image, index) in collectedData"
                  :key="image.id"
                  style="width:100%;height:261px;padding:2px;"
                  class="d-flex child-flex"
                >
                  <div
                    :id="'map' + index"
                    style="width:100%;zIndex:1;height:261px;"
                  >
                    <div
                      class="upperInfoArea"
                      style="marginTop:-51px;height:revert;"
                      v-if="showAlgoSelections"
                    >
                      <v-checkbox
                        @change="
                          val => algoSelectionHandlerForAnnotation(val, index)
                        "
                        v-model="image.algoSelection"
                        :label="image.file_name"
                      ></v-checkbox>
                    </div>
                    <div class="upperInfoArea" v-else>
                      {{ image.file_name }}
                    </div>
                    <div
                      class="pricedrop-tag"
                      v-if="image.loadComplete && image.annotation_count > 0"
                    >
                      <div class="pricedrop-ribbon urgent">
                        {{ image.annotation_count }} Annotation{{
                          image.annotation_count > 1 ? "s" : ""
                        }}
                      </div>
                    </div>
                    <div
                      class="separateLoadingDivStyle"
                      v-if="image.imageLoading"
                    >
                      <Loading loadingText="Deleting!" />
                    </div>

                    <div class="deleteArea" v-else-if="!$store.state.isShared">
                      <v-btn
                        icon
                        @click="
                          e =>
                            annotatorDialogOpener(
                              e,
                              image.image_url,
                              image.id,
                              image.file_name,
                              index
                            )
                        "
                      >
                        <v-icon dark color="white">mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="error darken-3"
                        @click="e => deleteClickHandler(e, image.id, index)"
                      >
                        <v-icon dark>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
              </v-col>
            </v-row>
          </div> -->

          <!-- <v-row>
          <v-col v-for="n in 15" :key="n" class="d-flex child-flex" cols="4">
            <v-hover v-slot="{ hover }">
              <v-img
                :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
                :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                aspect-ratio="1"
                class="grey lighten-2"
                :elevation="hover ? 16 : 2"
                :class="{ overlay: hover }"
                :gradient="
                  hover
                    ? 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'
                    : ''
                "
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-hover>
          </v-col>
        </v-row> -->
          <!-- </v-container> -->
        </div>
      </v-tab-item>

      <!-- 2D viewer -->
      <v-tab-item v-if="show2DTab" value="2d">
        <div
          style="position:relative;marginTop:48px;display:flex;justifyContent:center;width:calc(100% - 15px)"
        >
          <div id="map2d" style="width:100%;height:80vh;overflow:hidden;"></div>
        </div>
        <!-- <v-checkbox
        v-model="checkbox2DMap"
        label="View Map"
      ></v-checkbox>
      <l-map 
        :center="[30.3753, 69.3451]" 
        :zoom="10" 
        style="height: 95vh"
        v-if="checkbox2DMap"
      >
        <l-tile-layer
          :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"
          :attribution="
            '&copy; <a href=\'http://osm.org/copyright\'>OpenStreetMap</a> contributors'
          "
        ></l-tile-layer>
        <l-marker :lat-lng="[30.3753, 69.3451]"></l-marker>
      </l-map> -->
      </v-tab-item>

      <!-- processing -->
      <v-tab-item v-if="!$store.state.isShared" value="processing">
        <v-container>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Process</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>3D Model Creation</td>
                  <td>Completed</td>
                  <td>
                    <v-btn small color="primary" disabled>
                      Process
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Point Cloud Creation</td>
                  <td>Completed</td>
                  <td>
                    <v-btn small color="primary" disabled>
                      Process
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>DSM Creation</td>
                  <td>Completed</td>
                  <td>
                    <v-btn small color="primary" disabled>
                      Process
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>DTM Creation</td>
                  <td>Completed</td>
                  <td>
                    <v-btn small color="primary" disabled>
                      Process
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Orthomosaic Creation</td>
                  <td>Completed</td>
                  <td>
                    <v-btn small color="primary" disabled>
                      Process
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" style="textAlign:right">
                    <v-btn small color="primary">
                      Process All
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- <iframe 
            src="https://aerinspect.com/3d/3D_Models/vuurtoren/App/index.html" 
            title="testingIt"
            style="width:100%;height:68vh;"
          ></iframe> -->
        </v-container>
      </v-tab-item>

      <!-- Cesium -->
      <v-tab-item class="cesium-tab-item" v-if="showCesiumTab" value="cesium">
        <CesiumViewer
          :inspectionID="inspectionID"
          :imagesData2dProp="cesiumImagesCollectedData"
          :cesiumCamera="potreeCamera"
          @bigAnnotatorOpener="cesiumInfoAnnotator"
        ></CesiumViewer>
      </v-tab-item>

      <!-- Comments -->
      <v-tab-item v-if="!$store.state.isShared" value="comments">
        <Comments :inspectionID="inspectionID" />
      </v-tab-item>

      <v-tab-item v-if="showPotreeTab" value="potree">
        <PotreeViewer
          :resultData="potreeData"
          :potreeImagesCollectedData="potreeImagesCollectedData"
          :potreeCamera="potreeCamera"
          @bigAnnotatorOpener="cesiumInfoAnnotator"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar v-model="snackbar" right :color="success ? 'green' : 'red'">
      {{ snackbarMessage }}
    </v-snackbar>
    <AnnotatorDialog
      :imageName="imageName"
      :imageID="imageID"
      :imageURL="imageURL"
      :dialogOpen="dialogValue"
      :imageIndex="imageIndex"
      :arrayLength="collectedData.length"
      :isTransparent="imageTransparent"
      @dialogClose="annotatorDialogCloseHandler"
      @imageChangeClicked="type => changeURLImage(type)"
    />
    <VideoPlayerDialog
      :openVideoModal="openVideoModal"
      :videoModalData="videoModalData"
      :playerFileName="playerFileName"
      :playerOptions="playerOptions"
      @cancel="openVideoModal = false"
    />
  </div>
</template>

<script>
// import axios from "axios";

// import GalleryLightbox from '../../components/flight/Gallery/GalleryLightbox';
import Loading from "../../components/misc/Loading";

import * as L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import AnnotatorDialog from "../../components/asset/Annotator/AnnotatorDialog.vue";
// import ErrorComp from "../../components/misc/ErrorComp";
import PotreeViewer from "./PotreeViewer.vue";
import Comments from "../../components/flight/Comments.vue";

import CesiumViewer from "./AssetCesiumViewer";

import VideoPlayerDialog from "../../components/asset/VideoPlayerDialog.vue";

// eslint-disable-next-line no-unused-vars
import * as groupedLayersTest from "leaflet-groupedlayercontrol";

const color_tag_mapping = {
  image: "orange",
  DTM: "green",
  DSM: "blue",
  Orthophoto: "yellow darken-1",
  "Point Cloud": "gray",
  "3D Model": "purple"
};

export default {
  name: "AssetDataPoint",
  props: ["inspectionID", "colNumber"],
  components: {
    // GalleryLightbox,
    Loading,
    AnnotatorDialog,
    // ErrorComp,
    CesiumViewer,
    // ModelObj,
    Comments,
    PotreeViewer,
    VideoPlayerDialog
  },
  data() {
    return {
      potreeCamera: [],
      loading: true,
      tab: 0,
      tabGraphics: 0,
      checkbox2DMap: false,
      d3ViewerLoading: true,
      d3ViewerLoadingText: "Loading...",
      d3ViewerError: false,
      errorMessageText: "Something went wrong",
      d3ModelNotFound: false,
      potreeData: undefined,
      asset_data_details: [
        {
          img:
            "https://app.aerinspect.com/static/landing_page/images/img-2.JPG",
          date: "Jan. 30, 2021",
          tag: "Uploaded",
          color: color_tag_mapping["image"]
        },
        {
          img:
            "https://app.aerinspect.com/static/landing_page/images/img-2.JPG",
          date: "Jan. 15, 2021",
          tag: "Flight",
          color: color_tag_mapping["DTM"]
        },
        {
          img:
            "https://app.aerinspect.com/static/landing_page/images/img-2.JPG",
          date: "Jan. 7, 2021",
          tag: "DSM",
          color: color_tag_mapping["DSM"]
        },
        {
          img:
            "https://app.aerinspect.com/static/landing_page/images/img-2.JPG",
          date: "Jan. 5, 2021",
          tag: "Orthophoto",
          color: color_tag_mapping["Orthophoto"]
        },
        {
          img:
            "https://app.aerinspect.com/static/landing_page/images/img-2.JPG",
          date: "Dec. 30, 2020",
          tag: "Point Cloud",
          color: color_tag_mapping["Point Cloud"]
        },
        {
          img:
            "https://app.aerinspect.com/static/landing_page/images/img-2.JPG",
          date: "Dec. 25, 2020",
          tag: "#D Model",
          color: color_tag_mapping["3D Model"]
        },

        {
          img:
            "https://app.aerinspect.com/static/landing_page/images/img-2.JPG",
          date: "Dec. 24, 2020",
          tag: "image",
          color: color_tag_mapping["image"]
        },
        {
          img:
            "https://app.aerinspect.com/static/landing_page/images/img-2.JPG",
          date: "Dec. 22, 2020",
          tag: "DTM",
          color: color_tag_mapping["DTM"]
        }
      ],
      collectedData: [],
      collectedVideoData: [],
      cesiumImagesCollectedData: [],
      potreeImagesCollectedData: [],
      snackbarMessage: "",
      snackbar: false,
      success: true,
      imageName: "",
      imageTransparent: false,
      imageID: "",
      imageURL: "",
      imageIndex: null,
      dialogValue: false,
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      cube: null,
      controls: null,
      d3JPGFile: null,
      d3MTLFile: null,
      d3OBJFile: null,
      d3GLTFFile: null,
      processedDataResult: [],
      map: null,
      showImagesTab: false,
      show2DTab: false,
      show3DTab: false,
      showPotreeTab: false,
      showCesiumTab: false,
      selectAllAlgoSelection: false,
      showAlgoSelections: false,
      algoSelectionMode: null,
      selectedAlgoCount: 0,
      sendingAlgoDialog: false,
      openVideoModal: false,
      videoModalData: undefined,
      playerOptions: undefined,
      playerFileName: undefined
    };
  },
  watch: {
    inspectionID() {
      this.tab = 0;
      // this.getCollectedData();
      this.initialTestCase();
    }
  },
  methods: {
    tabs2DChangeHandler() {
      setTimeout(() => {
        this.mapAssigner();
      }, 2000);
      this.$router.push(`${this.$store.state.isShared ? `?tab=2d&uuid=${this.$route.query.uuid}`:'?tab=2d'}`);
    },
    graphicsTabChanger() {
      this.$router.push(`${this.$store.state.isShared ? `?tab=graphics&uuid=${this.$route.query.uuid}`:'?tab=graphics'}`);
      for (let [ind, i] of this.collectedData.entries()) {
        this.getImageWidthHeight(i.thumbnail_url, () => {
          let ratio = i.width / i.height;
          let mapContainer = document.getElementById("map0");
          this.loadCamera(
            Math.round(mapContainer.clientWidth),
            Math.round(mapContainer.clientWidth / ratio),
            i.thumbnail_url,
            i.id,
            ind
          );
        });
      }
    },
    cesiumInfoAnnotator(obj) {
      this.imageName = obj.imageName;
      this.imageID = obj.imageID;
      this.imageURL = obj.imageURL;
      this.imageIndex = obj.imageIndex;
      this.imageTransparent = true;
      this.dialogValue = true;
    },
    async confirmAlgoHandler() {
      if (this.selectedAlgoCount > 0) {
        this.sendingAlgoDialog = true;
        let idToSend = [];
        for (let i of this.collectedData) {
          if (i.algoSelection) {
            idToSend.push(i.id);
          }
        }
        let uploadObj = new FormData();
        if (this.algoSelectionMode == "TRAINING") {
          uploadObj.append("method", "train");
        } else if (this.algoSelectionMode == "DETECTION") {
          uploadObj.append("method", "detect");
        }
        idToSend.forEach(item => {
          uploadObj.append("ids", item);
        });
        // uploadObj.append('ids',idToSend);
        try {
          await this.$http.post("/send-images-to-ai", uploadObj, {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          });
          this.selectAllAlgoSelection = false;
          this.showAlgoSelections = false;
          this.success = true;
          this.snackbarMessage = "Images sent successfully";
          this.snackbar = true;
          this.sendingAlgoDialog = false;
        } catch (err) {
          console.log("something went wrong ", err);
          this.success = false;
          this.snackbarMessage = "Something went wrong! Try Again";
          this.snackbar = true;
        }
        // setTimeout(() => {
        //   this.selectAllAlgoSelection = false;
        //   this.showAlgoSelections = false;
        //   this.success = true;
        //   this.snackbarMessage = "Annotation sent successfully";
        //   this.snackbar = true;
        //   this.sendingAlgoDialog = false;
        // }, 3000);
      } else {
        this.success = false;
        this.snackbarMessage = "Select atleast any one image";
        this.snackbar = true;
      }
    },
    videoModalOpener(video) {
      console.log("video is ", video);
      this.playerFileName = video.file_name;
      this.playerOptions = {
        width: "900",
        height: "700",
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: video.mime_type,
            src: video.image_url
          }
        ],
        poster:
          "https://backend.buynbulq.com/images/5e988fd024aa9a0007de9d4b-wholesale-maybelline-new-overstock-cosmetic-lots-14.jpeg"
      };
      this.openVideoModal = true;
    },
    valueAlgoSelectionAssigner(val, type) {
      if (type == "complete") {
        if (val) {
          this.selectedAlgoCount = this.collectedData.length;
        } else {
          this.selectedAlgoCount = 0;
        }
      }
      let newArr = [];
      for (let i of this.collectedData) {
        newArr.push({ ...i, algoSelection: val || false });
      }
      this.collectedData = newArr;
    },
    async mapAssigner() {
      if (this.map) {
        this.map.remove();
      }

      let dtmLink, minZoom, maxZoom;
      let minX, minY, maxX, maxY;
      let orthoLink, orthoMinZoom, orthoMaxZoom;
      let dsmLink, dsmMinZoom, dsmMaxZoom, DSM, DTM, Ortho;

      for (let i of this.processedDataResult) {
        if (i.processed_type == "dtm") {
          dtmLink = i.tiles_path_without_host;
          minZoom = i.min_zoom;
          maxZoom = i.max_zoom;
          minX = i.min_x;
          minY = i.min_y;
          maxX = i.max_x;
          maxY = i.max_y;
        } else if (i.processed_type == "ortho") {
          orthoLink = i.tiles_path_without_host;
          orthoMinZoom = i.min_zoom;
          orthoMaxZoom = i.max_zoom;
          minX = i.min_x;
          minY = i.min_y;
          maxX = i.max_x;
          maxY = i.max_y;
        } else if (i.processed_type == "dsm") {
          dsmLink = i.tiles_path_without_host;
          dsmMinZoom = i.min_zoom;
          dsmMaxZoom = i.max_zoom;
          minX = i.min_x;
          minY = i.min_y;
          maxX = i.max_x;
          maxY = i.max_y;
        }
      }

      if (dsmLink) {
        L.TileLayer.MyCustomDsmLayer = L.TileLayer.extend({
          getTileUrl: function(coords) {
            let custom_url = L.TileLayer.prototype.getTileUrl.call(
              this,
              coords
            );

            return custom_url;
          }
        });

        L.tileLayer.myCustomDsmLayer = function(templateUrl, options) {
          return new L.TileLayer.MyCustomDsmLayer(templateUrl, options);
        };

        DSM = L.tileLayer.myCustomDsmLayer(
          `https://dev-api.aerinspect.com/get-tile/${dsmLink}/{z}/{x}/{y}.png`,
          {
            minZoom: dsmMinZoom,
            maxZoom: dsmMaxZoom,
            tms: true,
            errorTileUrl: "/empty_tile.png"
          }
        );
      }

      if (dtmLink) {
        L.TileLayer.MyCustomLayer = L.TileLayer.extend({
          getTileUrl: function(coords) {
            let custom_url = L.TileLayer.prototype.getTileUrl.call(
              this,
              coords
            );

            return custom_url;
          }
        });

        L.tileLayer.myCustomLayer = function(templateUrl, options) {
          return new L.TileLayer.MyCustomLayer(templateUrl, options);
        };

        DTM = L.tileLayer.myCustomLayer(
          `https://dev-api.aerinspect.com/get-tile/${dtmLink}/{z}/{x}/{y}.png`,
          {
            minZoom,
            maxZoom,
            tms: true,
            errorTileUrl: "/empty_tile.png"
          }
        );
      }

      if (orthoLink) {
        L.TileLayer.MyCustomOrthoLayer = L.TileLayer.extend({
          getTileUrl: function(coords) {
            let custom_url = L.TileLayer.prototype.getTileUrl.call(
              this,
              coords
            );
            return custom_url;
          }
        });

        L.tileLayer.myCustomOrthoLayer = function(templateUrl, options) {
          return new L.TileLayer.MyCustomOrthoLayer(templateUrl, options);
        };

        Ortho = L.tileLayer.myCustomOrthoLayer(
          `https://dev-api.aerinspect.com/get-tile/${orthoLink}/{z}/{x}/{y}.png`,
          {
            minZoom: orthoMinZoom,
            maxZoom: orthoMaxZoom,
            tms: true,
            errorTileUrl: "/empty_tile.png"
          }
        );
      }

      let mapboxStreetUrl =
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
      let mapboxStreetAttribution =
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

      var streets = L.tileLayer(mapboxStreetUrl, {
          // id: "MapID",
          // maxZoom: 20,
          tileSize: 512,
          zoomOffset: -1,
          attribution: mapboxStreetAttribution
        }),
        googleStreets = L.tileLayer(
          "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
          {
            maxZoom: 20,
            subdomains: ["mt0", "mt1", "mt2", "mt3"]
          }
        ),
        googleHybrid = L.tileLayer(
          "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
          {
            maxZoom: 20,
            subdomains: ["mt0", "mt1", "mt2", "mt3"]
          }
        );

      this.map = L.map("map2d", {
        center: [52.0116, 4.3571],
        zoom: 18,
        layers: [googleStreets],
        maxBounds: [
          //south west
          [minY, minX],
          //north east
          [maxY, maxX]
        ]
      });

      var baseMaps = {
        streets: streets,
        googleStreets,
        googleHybrid
      };

      // let otherLayers = {
      //   ...(DSM && { DSM }),
      //   ...(DTM && { DTM }),
      //   ...(Ortho && { Ortho })
      // }
      var otherLayers = {
        Processed: {
          ...(DSM && { DSM }),
          ...(DTM && { DTM }),
          ...(Ortho && { Ortho })
        }
      };

      // L.control.layers(baseMaps).addTo(this.map);
      // L.control.layers(otherLayers).addTo(this.map);
      L.control.groupedLayers(baseMaps, otherLayers).addTo(this.map);

      const mapDiv = document.getElementById("map2d");
      const resizeObserver = new ResizeObserver(() => {
        console.log("I am inside map2d");
        this.map.invalidateSize();
      });
      resizeObserver.observe(mapDiv);
    },
    // async mapAssigner() {
    //   if (this.map) {
    //     this.map.remove();
    //   }

    //   let dtmLink, minZoom, maxZoom, minX, minY, maxX, maxY, DTM;
    //   let orthoLink, orthoMinZoom, orthoMaxZoom, Ortho;
    //   let dsmLink, dsmMinZoom, dsmMaxZoom, DSM;

    //   for (let i of this.processedDataResult) {
    //     if (i.processed_type == "dtm") {
    //       dtmLink = i.tiles_path_without_host;
    //       minZoom = i.min_zoom;
    //       maxZoom = i.max_zoom;
    //       minX = i.min_x;
    //       minY = i.min_y;
    //       maxX = i.max_x;
    //       maxY = i.max_y;
    //     } else if (i.processed_type == "ortho") {
    //       orthoLink = i.tiles_path_without_host;
    //       orthoMinZoom = i.min_zoom;
    //       orthoMaxZoom = i.max_zoom;
    //       minX = i.min_x;
    //       minY = i.min_y;
    //       maxX = i.max_x;
    //       maxY = i.max_y;
    //     } else if (i.processed_type == "dsm") {
    //       dsmLink = i.tiles_path_without_host;
    //       dsmMinZoom = i.min_zoom;
    //       dsmMaxZoom = i.max_zoom;
    //       minX = i.min_x;
    //       minY = i.min_y;
    //       maxX = i.max_x;
    //       maxY = i.max_y;
    //     }
    //   }

    // if (dtmLink) {
    //   L.TileLayer.MyCustomLayer = L.TileLayer.extend({
    //     getTileUrl: function(coords) {
    //       let custom_url = L.TileLayer.prototype.getTileUrl.call(
    //         this,
    //         coords
    //       );

    //       return custom_url;
    //     }
    //   });

    //     L.tileLayer.myCustomLayer = function(templateUrl, options) {
    //       return new L.TileLayer.MyCustomLayer(templateUrl, options);
    //     };

    //     DTM = L.tileLayer.myCustomLayer(
    //       `https://dev-api.aerinspect.com/get-tile/${dtmLink}/{z}/{x}/{y}.png`,
    //       {
    //         minZoom,
    //         maxZoom,
    //         tms: true,
    //         errorTileUrl: "/empty_tile.png"
    //       }
    //     );
    //   }

    //   if (orthoLink) {
    //     L.TileLayer.MyCustomOrthoLayer = L.TileLayer.extend({
    //       getTileUrl: function(coords) {
    //         let custom_url = L.TileLayer.prototype.getTileUrl.call(
    //           this,
    //           coords
    //         );
    //         return custom_url;
    //       }
    //     });

    //     L.tileLayer.myCustomOrthoLayer = function(templateUrl, options) {
    //       return new L.TileLayer.MyCustomOrthoLayer(templateUrl, options);
    //     };

    //     Ortho = L.tileLayer.myCustomOrthoLayer(
    //       `https://dev-api.aerinspect.com/get-tile/${orthoLink}/{z}/{x}/{y}.png`,
    //       {
    //         minZoom: orthoMinZoom,
    //         maxZoom: orthoMaxZoom,
    //         tms: true,
    //         errorTileUrl: "/empty_tile.png"
    //       }
    //     );
    //   }

    //   if (dsmLink) {
    //     L.TileLayer.MyCustomDsmLayer = L.TileLayer.extend({
    //       getTileUrl: function(coords) {
    //         let custom_url = L.TileLayer.prototype.getTileUrl.call(
    //           this,
    //           coords
    //         );

    //         return custom_url;
    //       }
    //     });

    //     L.tileLayer.myCustomDsmLayer = function(templateUrl, options) {
    //       return new L.TileLayer.MyCustomDsmLayer(templateUrl, options);
    //     };

    //     DSM = L.tileLayer.myCustomDsmLayer(
    //       `https://dev-api.aerinspect.com/get-tile/${dsmLink}/{z}/{x}/{y}.png`,
    //       {
    //         minZoom: dsmMinZoom,
    //         maxZoom: dsmMaxZoom,
    //         tms: true,
    //         errorTileUrl: "/empty_tile.png"
    //       }
    //     );
    //   }

    //   let mapboxStreetUrl =
    //     "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    //   let mapboxStreetAttribution =
    //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

    //   var streets = L.tileLayer(mapboxStreetUrl, {
    //     attribution: mapboxStreetAttribution,
    //     minZoom,
    //     maxZoom
    //   });

    //   this.map = L.map("map2d", {
    //     center: [52.0116, 4.3571],
    //     zoom: minZoom,
    //     maxBounds: [
    //       //south west
    //       [minY, minX],
    //       //north east
    //       [maxY, maxX]
    //     ],
    //     maxZoom: 22,
    //     // layers: [streets]
    //   });

    //   var baseMaps = {
    //     ...(DSM && { DSM }),
    //     ...(DTM && { DTM }),
    //     ...(Ortho && { Ortho }),
    //     // streets,
    //   };

    //   streets.addTo(this.map);
    //   L.control.layers(null, baseMaps).addTo(this.map);
    //   // L.control.layers(baseMaps).addTo(this.map);
    // },
    async getProcessedData() {
      try {
        let res = await this.$http.get(
          `/inspection/${this.inspectionID}/processed-data${
            this.$store.state.isShared ? "?uuid=" + this.$route.query.uuid : ""
          }`,
          {
            ...(localStorage.getItem("auth_token") && {
              headers: {
                Authorization: "token " + localStorage.getItem("auth_token")
              }
            })
          }
        );
        console.log("processed response is ",res);
        this.processedDataResult = [...res.data];
      } catch (err) {
        console.log("something went wrong ", err);
      }
    },
    algoSelectionHandlerForAnnotation(val, index) {
      if (val) {
        this.selectedAlgoCount += 1;
        this.selectAllAlgoSelection = false;
      } else {
        this.selectedAlgoCount -= 1;
        this.selectAllAlgoSelection = false;
      }
      let duplicateObj = { ...this.collectedData[index] };
      duplicateObj.algoSelection = val || false;
      this.collectedData.splice(index, 1, duplicateObj);
    },
    async getCollectedData() {
      this.loading = true;
      this.$emit("loadComp", false);
      try {
        let res = await this.$http.get(
          `/inspection/${this.inspectionID}/data${
            this.$store.state.isShared ? "?uuid=" + this.$route.query.uuid : ""
          }`,
          {
            ...(localStorage.getItem("auth_token") && {
              headers: {
                Authorization: "token " + localStorage.getItem("auth_token")
              }
            })
          }
        );
        console.log("response of getter is collected ", res.data);
        let collectedData = [],
          collectedVideoData = [];
        for (let i of res.data) {
          let requiredMime = i.mime_type;
          if (i.file_type == "raw") {
            let firstValMime = requiredMime.split("/");
            if (firstValMime[0] == "image") {
              collectedData.push({ ...i });
            } else if (firstValMime[0] == "video") {
              collectedVideoData.push({ ...i });
            }
          }
          // if (firstValMime[0] != "application") {
          //   if (i.file_type == "raw") {
          //     let mimeType = i.mime_type;
          //     mimeType = mimeType.split("/");
          //     if (mimeType[0] != "video") {
          //       collectedData.push({ ...i });
          //     }
          //   }
          // }
        }

        this.cesiumImagesCollectedData = JSON.parse(
          JSON.stringify(collectedData)
        );

        this.potreeImagesCollectedData = JSON.parse(
          JSON.stringify(collectedData)
        );

        if (collectedData.length > 0 || collectedVideoData.length > 0) {
          this.collectedData = collectedData;
          this.collectedVideoData = collectedVideoData;
          console.log("collected video data is ", collectedVideoData);
          this.showImagesTab = true;
          // this.loading = false;
          // this.$emit("loadComp");
        } else {
          this.showImagesTab = false;
        }

        // for (let [ind, i] of this.collectedData.entries()) {
        //   this.getImageWidthHeight(i.thumbnail_url, () => {
        //     let ratio = i.width / i.height;
        //     let mapContainer = document.getElementById("map0");
        //     this.loadCamera(
        //       Math.round(mapContainer.clientWidth),
        //       Math.round(mapContainer.clientWidth / ratio),
        //       i.thumbnail_url,
        //       i.id,
        //       ind
        //     );
        //   });
        // }
      } catch (err) {
        console.log(err);
      }
    },
    check2DDataPresent() {
      let val = this.processedDataResult.some(
        el =>
          el.processed_type == "dtm" ||
          el.processed_type == "dsm" ||
          el.processed_type == "ortho"
      );

      if (!val) {
        this.show2DTab = false;
      } else {
        this.show2DTab = true;
      }
      return val;
    },
    get3DProcessedData() {
      let count = 0;
      let cesiumFileFound = false,
        potreeFileFound = false;
      console.log("processed result is ", this.processedDataResult);
      for (let i of this.processedDataResult) {
        if (i.processed_type == "3d") {
          count += 1;
          switch (i.file_name.split(".").pop()) {
            case "mtl":
              this.d3MTLFile = i.file_url;
              break;
            case "jpg":
              this.d3JPGFile = i.file_url;
              break;
            case "obj":
              this.d3OBJFile = i.file_url;
              break;
            case "zip":
              cesiumFileFound = true;
              break;
          }
        }
        if (i.processed_type == "point_cloud") {
          potreeFileFound = true;

          this.potreeData = i;
        }
        if (i.processed_type == "cameras_file") {
          this.potreeCamera = i.omega_phi_kapa;
        }
      }

      if (cesiumFileFound) {
        this.showCesiumTab = true;
      } else {
        this.showCesiumTab = false;
      }

      if (potreeFileFound) {
        this.showPotreeTab = true;
      } else {
        this.showPotreeTab = false;
      }

      if (count < 3) {
        this.d3ModelNotFound = true;
        this.d3ViewerError = true;
        this.errorMessageText = "No Files Found";
        this.show3DTab = false;
      } else {
        this.show3DTab = true;
        this.d3ModelNotFound = false;
        this.d3ViewerError = false;
      }
    },
    changeURLImage(type) {
      let currentIndex = this.imageIndex;

      if (type == "next") {
        currentIndex += 1;
      } else if (type == "previous") {
        currentIndex -= 1;
      }

      let image = this.collectedData[currentIndex];

      this.imageName = image.file_name;
      this.imageID = image.id;
      this.imageURL = image.image_url;
      this.imageIndex = currentIndex;
    },
    getImageWidthHeight(url, callback) {
      var img = new Image();
      img.src = url;
      img.onload = function() {
        callback();
      };
    },
    loadCamera(width, height, imageURL, id, ind) {
      // let duplicateObj = { ...this.collectedData[ind] };
      // duplicateObj.existingObservations = L.layerGroup();

      // this.collectedData.splice(ind, 1, duplicateObj);

      this.collectedData[ind].existingObservations = L.layerGroup();
      this.collectedData[ind].existingObservations.clearLayers();

      if (this.collectedData[ind].mainMap) {
        this.collectedData[ind].mainMap.remove();
      }

      this.collectedData[ind].algoSelection = false;

      let mainMap = L.map(`map${ind}`, {
        zoom: 0,
        center: [0, 0],
        zoomControl: false,
        attributionControl: false,
        crs: L.CRS.Simple,
        minZoom: 1,
        maxZoom: 4,
        scrollWheelZoom: false
      });

      mainMap.dragging.disable();
      mainMap.doubleClickZoom.disable();

      // duplicateObj = { ...this.collectedData[ind] };
      // this.collectedData[ind].mainMap = mainMap;

      // duplicateObj.mainMap = mainMap;
      // this.collectedData.splice(ind, 1, duplicateObj);
      this.collectedData[ind].mainMap = mainMap;

      this.collectedData[ind].mainMap.createPane("existingObservation");

      this.collectedData[ind].existingObservations.addTo(
        this.collectedData[ind].mainMap
      );

      let w = width,
        h = height;

      let southWest = this.collectedData[ind].mainMap.unproject(
        [0, h],
        this.collectedData[ind].mainMap.getMaxZoom() - 1
      );
      let northEast = this.collectedData[ind].mainMap.unproject(
        [w, 0],
        this.collectedData[ind].mainMap.getMaxZoom() - 1
      );
      let bounds = new L.LatLngBounds(southWest, northEast);
      // let bounds = new L.LatLngBounds([0, 0], [h / 10, w / 10]);
      let overlay_layer = L.imageOverlay(imageURL, bounds, {
        interactive: true
      });
      this.collectedData[ind].mainMap.overlay_layer = overlay_layer;
      this.collectedData[ind].mainMap.overlay_layer.addTo(
        this.collectedData[ind].mainMap
      );
      let otherObj = { ...this.collectedData[ind] };
      otherObj.loadComplete = true;
      this.collectedData.splice(ind, 1, otherObj);
      this.collectedData[ind].mainMap.overlay_layer.on("load", () => {
        // this.overlay = false;
        this.loadImageObservations(id, ind);
      });
      this.collectedData[ind].mainMap.overlay_layer.on("click", () => {
        // this.overlay = false;
        let image = this.collectedData[ind];

        this.imageName = image.file_name;
        this.imageID = image.id;
        this.imageURL = image.image_url;
        this.imageIndex = ind;
        this.imageTransparent = false;
        this.dialogValue = true;
      });
      this.collectedData[ind].mainMap.setMaxBounds(bounds);
      this.collectedData[ind].mainMap.fitBounds(bounds);
      this.collectedData[ind].mainMap.invalidateSize();
    },
    loadImageObservations(id, ind, editAnnotation) {
      this.$http
        .get(`/get-annotations/${id}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(
          response => {
            let inspection_geo = { ...response.data };

            this.collectedData[ind].newObservation = L.layerGroup();
            if (this.collectedData[ind].existingObservations) {
              this.collectedData[ind].existingObservations.clearLayers();
            }

            if (this.collectedData[ind].newObservation) {
              this.collectedData[ind].mainMap.removeLayer(
                this.collectedData[ind].newObservation
              );
            }

            let needFor = inspection_geo.features;
            let otherCoordinates = [],
              num = 0,
              todo,
              test;
            let overlayTest = this.collectedData[ind].mainMap.overlay_layer
              ._image;
            let yR = overlayTest.clientHeight / this.collectedData[ind].height;
            let xR = overlayTest.clientWidth / this.collectedData[ind].width;
            for (let i of needFor) {
              if (i.geometry.type == "Point") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                test = this.collectedData[ind].mainMap.unproject([
                  -todo[1],
                  -todo[0]
                ]);
                otherCoordinates.push(test.lat * xR);
                otherCoordinates.push(test.lng * yR);
                inspection_geo.features[
                  num
                ].geometry.coordinates = otherCoordinates;
              } else if (i.geometry.type == "LineString") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                for (let j of todo) {
                  test = this.collectedData[ind].mainMap.unproject([
                    -j[1],
                    -j[0]
                  ]);

                  otherCoordinates.push([test.lat * xR, test.lng * yR]);
                }
                inspection_geo.features[
                  num
                ].geometry.coordinates = otherCoordinates;
              } else if (i.geometry.type == "Polygon") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                let subNum = 0;
                for (let j of todo) {
                  for (let k of j) {
                    test = this.collectedData[ind].mainMap.unproject([
                      -k[1],
                      -k[0]
                    ]);
                    otherCoordinates.push([test.lat * xR, test.lng * yR]);
                  }
                  inspection_geo.features[num].geometry.coordinates[
                    subNum
                  ] = otherCoordinates;
                  subNum++;
                }
              }
              num++;
            }

            if (inspection_geo.features.length > 0) {
              let geo = L.geoJSON(inspection_geo, {
                pane: "existingObservation",
                pmIgnore: false,
                onEachFeature: (feature, layer) => {
                  if (layer.feature.properties.drawn_by == "Machine") {
                    layer.setStyle({ color: "#e21e1e" });
                    // layer.bindTooltip(
                    //   `${layer.feature.properties.confidence} %`,
                    //   { permanent: true, offset: [0, 0] }
                    // );
                  }
                }
              });

              this.collectedData[ind].existingObservations.addLayer(geo);
            }
            if (editAnnotation) {
              let otherObj = { ...this.collectedData[ind] };
              otherObj.annotation_count = response.data.features.length;
              this.collectedData.splice(ind, 1, otherObj);
            }
          },
          error => {
            console.log("error", error);
            throw error;
          }
        )
        .catch(error => {
          console.log("cerror", error);
          throw error;
        });
    },
    annotatorDialogCloseHandler(val) {
      this.dialogValue = false;

      this.loadImageObservations(val.id, val.index, true);
    },
    async deleteClickHandler(e, id, index) {
      e.stopPropagation();
      try {
        // var element = document.getElementById(`map${index}`);
        // element.parentNode.removeChild(element);

        let otherObj = { ...this.collectedData[index] };
        otherObj.imageLoading = true;
        this.collectedData.splice(index, 1, otherObj);
        await this.$http.delete(`/file/${id}/delete`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        let foundIndex = this.collectedData.findIndex(el => el.id == id);
        this.collectedData.splice(foundIndex, 1);
        //   console.log("index is ",index);
        //   console.log("found index is ",foundIndex);
        this.snackbarMessage = "Annotation has been successfully removed";
        this.success = true;
        this.snackbar = true;
      } catch (err) {
        console.log("error is ", err);
        this.snackbarMessage = "Something went wrong";
        this.success = false;
        this.snackbar = true;
        let duplicateObj = { ...this.collectedData[index] };
        duplicateObj.imageLoading = false;
        this.collectedData.splice(index, 1, duplicateObj);
      }
    },
    annotatorDialogOpener(e, imageSRC, image_ID, image_Name, index) {
      e.stopPropagation();
      this.imageName = image_Name;
      this.imageID = image_ID;
      this.imageURL = imageSRC;
      this.imageIndex = index;
      this.imageTransparent = false;
      this.dialogValue = true;
    },
    animate: function() {
      // requestAnimationFrame(this.animate);
      // this.mesh.rotation.x += 0.01;
      // this.mesh.rotation.y += 0.02;
      // this.camera.lookAt( this.scene.position );
      this.renderer.render(this.scene, this.camera);
    },
    onProgress: function(xhr) {
      if (xhr.lengthComputable) {
        const percentComplete = (xhr.loaded / xhr.total) * 100;

        // console.log( Math.round( percentComplete, 2 ) + '% downloaded' );
        this.d3ViewerLoadingText = `Loading file OBJ 2/3 (${Math.round(
          percentComplete,
          2
        )}%)`;
      }
    },
    onError: function(err) {
      console.log("Here is some error in OBJ", err);
      this.d3ViewerError = true;
      this.errorMessageText = "Something Went Wrong";
    },
    onProgressMLT: function(xhr) {
      if (xhr.lengthComputable) {
        const percentComplete = (xhr.loaded / xhr.total) * 100;

        // console.log( Math.round( percentComplete, 2 ) + '% downloaded' );
        this.d3ViewerLoadingText = `Loading file MLT 1/3 (${Math.round(
          percentComplete,
          2
        )}%)`;
      }
    },
    onErrorMLT: function(err) {
      console.log("Here is some error in MLT", err);
      this.d3ViewerError = true;
      this.errorMessageText = "Something Went Wrong";
    },
    initialTestCase: async function() {
      await this.getCollectedData();
      await this.getProcessedData();
      this.get3DProcessedData();

      if (this.check2DDataPresent()) {
        if (!this.showImagesTab) {
          // this.loading = false;
          // this.$emit("loadComp");
          console.log("I am here in map assigner");
          setTimeout(() => this.mapAssigner(), 2000);
        }
      } else if (!this.d3ModelNotFound) {
        if (!this.showImagesTab) {
          // this.loading = false;
          // this.$emit("loadComp");
        }
      } else {
        // this.loading = false;
        // this.$emit("loadComp");
      }

      if (!this.$route.query.tab) {
        if (this.showImagesTab) {
          this.$router.push(`${this.$store.state.isShared ? `?tab=graphics&uuid=${this.$route.query.uuid}`:'?tab=graphics'}`);
          for (let [ind, i] of this.collectedData.entries()) {
            this.getImageWidthHeight(i.thumbnail_url, () => {
              let ratio = i.width / i.height;
              let mapContainer = document.getElementById("map0");
              this.loadCamera(
                Math.round(mapContainer.clientWidth),
                Math.round(mapContainer.clientWidth / ratio),
                i.thumbnail_url,
                i.id,
                ind
              );
            });
          }
        } else if (this.show2DTab) {
          this.$router.push(`${this.$store.state.isShared ? `?tab=2d&uuid=${this.$route.query.uuid}`:'?tab=2d'}`);
        } else {
          this.$router.push("?tab=processing");
        }
      } else {
        this.tab = this.$route.query.tab;
        if (this.$route.query.tab == "graphics") {
          for (let [ind, i] of this.collectedData.entries()) {
            this.getImageWidthHeight(i.thumbnail_url, () => {
              let ratio = i.width / i.height;
              let mapContainer = document.getElementById("map0");
              this.loadCamera(
                Math.round(mapContainer.clientWidth),
                Math.round(mapContainer.clientWidth / ratio),
                i.thumbnail_url,
                i.id,
                ind
              );
            });
          }
        } else if (this.$route.query.tab == "2d") {
          setTimeout(() => this.mapAssigner(), 2000);
        }
      }
      this.loading = false;
      this.$emit("loadComp", true);
    }
  },
  mounted() {
    this.initialTestCase();
  }
};
</script>

<style scoped>
.loadingDivStyle {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteArea {
  /* background: #00000099; */
  background: #867b7b;
  position: absolute;
  bottom: -34px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
}

.upperInfoArea {
  font-size: 17px;
  margin-top: -27px;
  overflow: hidden;
  height: 30px;
}

.annotatorCountArea {
  background: #36a0c7;
  position: absolute;
  top: -34px;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
}

.separateLoadingDivStyle {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000054;
  z-index: 401;
  position: absolute;
}

.leaflet-container {
  overflow: revert;
}

/* .v-application .d-flex:nth-child(-n + 3) {
  margin-top: 30px;
} */

.v-application .d-flex {
  margin-top: 20px;
}

.v-slide-group__wrapper {
  contain: revert;
}

.tabs-items-elements {
  height: 90vh;
}

.cesium-tab-item {
  height: 100%;
}

.dateGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 261px);
  column-gap: 24px;
  row-gap: 56px;
  margin-top: 26px;
  width: 100%;
  margin-left: 15px;
  justify-content: center;
}
</style>

<style>
.collectedDataResult {
  margin-top: 50px;
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 35px;
  display: block;
  text-align: center;
}
</style>

<template>
  <v-app v-if="pageLoading">
    <div class="loadingDivStyle">
      <Loading loadingText="Wait! Authenticating" />
    </div>
  </v-app>
  <v-app v-else>
    <CookieComp v-if="showCookieComp" />

    <v-navigation-drawer
      app
      v-model="drawer"
      :mini-variant.sync="$store.state.mini_sidebar"
      permanent
      v-if="$store.state.showMainMenu"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <!-- <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img> -->
          <v-icon large>
            {{ user_type == "Client" ? "mdi-account" : "mdi-airplane" }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          {{ name }}
        </v-list-item-title>

        <v-btn icon @click.stop="$store.commit('toggle_minisidebar')">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item dense to="/app/profile">
              <v-list-item-icon>
                <v-icon v-text="'mdi-account'"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout" dense>
              <v-list-item-icon>
                <v-icon v-text="'mdi-logout'"></v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          :style="cssVars"
          v-for="item in items"
          :key="item.title"
          link
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!--        <v-app-bar app clipped-right color="primary" dark>-->
    <!--            <div class="d-flex align-center">-->
    <!--                <v-img alt="Aerinspect Logo" class="shrink mr-2" contain-->
    <!--                       src="../assets/logo_aerinspect.png"-->
    <!--                       transition="scale-transition"-->
    <!--                       width="40"/>-->

    <!--                <h1>Aerinspect</h1>-->
    <!--            </div>-->

    <!--        </v-app-bar>-->

    <v-main>
      <router-view></router-view>
      <!--  for left bar menu item -->
      <!--        <v-navigation-drawer-->
      <!--                fixed-->
      <!--                permanent-->
      <!--                mini-variant-->
      <!--                right-->
      <!--                clipped-->
      <!--                app-->
      <!--        >-->

      <!--            <v-divider></v-divider>-->

      <!--            <v-list dense>-->
      <!--                <v-list-item-->
      <!--                        v-for="item in items"-->
      <!--                        :key="item.title"-->
      <!--                >-->
      <!--                    <v-list-item-icon>-->
      <!--                        <v-icon>{{ item.icon }}</v-icon>-->
      <!--                    </v-list-item-icon>-->

      <!--                    <v-list-item-content>-->
      <!--                        <v-list-item-title>{{ item.title }}</v-list-item-title>-->
      <!--                    </v-list-item-content>-->
      <!--                </v-list-item>-->
      <!--            </v-list>-->
      <!--        </v-navigation-drawer>-->
    </v-main>
    <v-snackbar
      v-model="testValue"
      right
      :color="$store.state.snackbarSuccess ? 'green' : 'red'"
    >
      {{ $store.state.snackbarMessage }}
    </v-snackbar>
  </v-app>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import Loading from "../components/misc/Loading";
import CookieComp from "../components/CookieComp.vue";
import Cookies from "js-cookie";

export default {
  name: "Home",
  components: {
    // HelloWorld
    Loading,
    CookieComp
  },
  // metaInfo() {
  //   return {
  //     title: process.env.VUE_APP_TITLE,
  //   };
  // },
  data() {
    return {
      cookieDialog: true,
      drawer: true,
      name: "John Leider",
      items: [
        {
          title: "Home",
          icon: "mdi-home-city",
          to: "/app/home",
          collapse_menu: true
        },
        {
          title: "Request a Survey",
          icon: "mdi-read",
          to: "/app/create-survey"
        },
        {
          title: "Surveys",
          icon: "mdi-text-box",
          to: "/app/surveys"
        },
        {
          title: "Upload Data",
          icon: "mdi-upload",
          to: "/app/upload-data"
        }
      ],
      mini: false,
      pageLoading: true,
      user_type: "Client",
      primaryColor: "#FF6600",
      showCookieComp: false
    };
  },
  computed: {
    cssVars() {
      return {
        "--bg-border-left": `5px ${this.primaryColor} solid`
      };
    },
    testValue: {
      /* By default get() is used */
      get() {
        return this.$store.state.snackbarValue;
      },
      /* We add a setter */
      set(value) {
        this.$store.commit("snackbarActivator", {
          open: value,
          success: this.$store.state.snackbarSuccess
        });
      }
    }
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
    async getProfileData() {
      try {
        let res = await this.$http.get("/profile/details/", {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        console.log("Layout response of user_type is", res.data);
        this.user_type = res.data.user_type;
        if (res.data.user_type == "Pilot") {
          this.items = [
            {
              title: "Home",
              icon: "mdi-home-city",
              to: "/app/home",
              collapse_menu: true
            },
            {
              title: "Surveys",
              icon: "mdi-text-box",
              to: "/app/surveys"
            },
            {
              title: "Upload Data",
              icon: "mdi-upload",
              to: "/app/upload-data"
            }
          ];
        } else if (res.data.is_superuser) {
          this.items = [
            {
              title: "Home",
              icon: "mdi-home-city",
              to: "/app/home",
              collapse_menu: true
            },
            {
              title: "Request a Survey",
              icon: "mdi-read",
              to: "/app/create-survey"
            },
            {
              title: "Surveys",
              icon: "mdi-text-box",
              to: "/app/surveys"
            },
            {
              title: "Upload Data",
              icon: "mdi-upload",
              to: "/app/upload-data"
            },
            {
              title: "Quote Requests",
              icon: "mdi-cash",
              to: "/app/quote-requests"
            }
          ];
        }
        this.name = `${res.data.first_name} ${res.data.last_name}`;
        this.pageLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getSkin() {
      if (this.$store.state.fetchSkin) {
        try {
          let res = await this.$http.get(
            `/get-skin?domain=${window.location.hostname}`
          );
          // let res = await this.$http.get(
          //   `/get-skin?domain=dev-portal.aerinspect.com`
          // );

          if (res.data.cookie_url) {
            this.$store.commit("setCookieURL", res.data.cookie_url);
          }
          if (Object.keys(res.data).length > 0) {
            let resNext = await this.$http.get(
              `/get-landing-links/${res.data.id}`
            );
            let title = res.data.site_name || "Aerinspect";
            document.title = title;
            this.$store.commit("setAppTitle", title);
            let resLandingCotent = await this.$http.get(
              `/get-landing-content/${res.data.id}`
            );
            let {
              primary,
              secondry,
              accent,
              error,
              info,
              success,
              warning
            } = res.data;

            const favicon = document.getElementById("favicon");
            favicon.href = res.data.favicon_url;

            this.$vuetify.theme.themes.dark.primary = primary || "#FF6600";
            this.$vuetify.theme.themes.dark.secondry = secondry || "#303641";
            this.$vuetify.theme.themes.dark.accent = accent || "#82B1FF";
            this.$vuetify.theme.themes.dark.error = error || "#FF5252";
            this.$vuetify.theme.themes.dark.info = info || "#2196F3";
            this.$vuetify.theme.themes.dark.success = success || "#4CAF50";
            this.$vuetify.theme.themes.dark.warning = warning || "#FFC107";

            this.$store.commit("setLogoURL", res.data.logo_url);
            this.$store.commit("changeFetchSkin", false);
            this.$store.commit("setLandingLinks", resNext.data.links);
            if (resLandingCotent.data.heading && resLandingCotent.data.text) {
              this.$store.commit("setLandingContent", resLandingCotent.data);
            }
          }
        } catch (err) {
          console.log("something went wrong");
        }
      }
    }
  },
  async mounted() {
    if (!Cookies.get("webCookieAccept")) {
      this.showCookieComp = true;
    }
    this.getProfileData();

    this.getSkin();

    // let res = await this.$http.get("/collab/get_collabrators_list/?asset_id=40", {
    //     headers: {
    //       Authorization: "token " + localStorage.getItem("auth_token")
    //     }
    //   });
    //   let uploadObj = new FormData();
    //   uploadObj.append('user_id',22);
    //   uploadObj.append('asset_id',40);
    // let res = await this.$http.post("/collab/add_asset_collab/40", uploadObj,{
    //         headers: {
    //           Authorization: "token " + localStorage.getItem("auth_token")
    //         }
    //       });
    // console.log("response of get ctes is ",res.data)
    // setTimeout(()=>{
    //   // this.$vuetify.theme.themes.dark.primary = 'white'
    //   // console.log("http is ",this.$http);
    //   const favicon = document.getElementById("favicon");
    //   favicon.href = "https://www.google.com/favicon.ico";
    // },15000)
    // console.log("base url is ",process.env.BASE_URL);
  }
};
</script>

<style scoped>
.loadingDivStyle {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-list-item--active {
  border-left: var(--bg-border-left);
}

a.v-list-item.v-list-item--link {
  transition: all 0.3s;
}

a.v-list-item.v-list-item--link:hover {
  border-left: var(--bg-border-left);
}
</style>

<template>
  <div class="alert error mt-5">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "RequiredFields",
  props: ["message"]
};
</script>

<style scoped>
.alert {
  margin: 0;
  padding: 0 20px;
  line-height: 37px;
  font-size: 17px;
  border-radius: 2px;
  color: #fff;
  font-family: "Quicksand", sans-serif;
}

.error {
  background: #ee5253;
}
</style>

<template>
  <div>
    <v-dialog
      v-model="assetCreateDialog"
      persistent
      max-width="600px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Create Asset</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name*"
                  prepend-inner-icon="mdi-pencil"
                  v-model="newAssetData.name"
                  :rules="rulesNewAssetData.name"
                  :error-messages="errorsNewAssetData.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Description*"
                  prepend-inner-icon="mdi-pencil"
                  v-model="newAssetData.description"
                  :rules="rulesNewAssetData.description"
                  :error-messages="errorsNewAssetData.description"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <gmap-autocomplete
                  ref="address_autocomplete"
                  class="introInput"
                  placeholder=""
                  @place_changed="setNewAssetDataAddress"
                >
                  <template v-slot:input="slotProps">
                    <v-text-field
                      v-model="newAssetData.address.formatted_address"
                      prepend-inner-icon="mdi-map"
                      :label="newAssetAddressLabel"
                      ref="input"
                      v-on:listeners="slotProps.listeners"
                      v-on:attrs="slotProps.attrs"
                      :placeholder="'Address'"
                      :rules="rulesNewAssetData.address"
                      :error-messages="errorsNewAssetData.address"
                    ></v-text-field>
                  </template>
                </gmap-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="assetCreateDialogClose"
            v-if="!loading"
          >
            Close
          </v-btn>
          <v-btn color="primary" text :loading="loading" @click="createAsset">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="error" color="error" top right :timeout="10000"
      >{{ error_msg }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["openCreateDialog"],
  data: () => ({
    assetCreateDialog: false,
    error_msg: "",
    error: false,
    newAssetData: {
      description: "",
      name: "",
      address: {
        formatted_address: ""
      }
    },
    errorsNewAssetData: {
      address: "",
      description: "",
      name: ""
    },
    loading: false
  }),
  computed: {
    newAssetAddressLabel: function() {
      if (this.newAssetData.address.formatted_address === "") {
        return "";
      } else {
        return "Address";
      }
    },
    rulesNewAssetData: function() {
      return {
        address: [v => !!v || "Address is required"],
        description: [v => !!v || "Description is required"],
        name: [v => !!v || "Name is required"]
      };
    }
  },
  methods: {
    setNewAssetDataAddress: function(address) {
      this.newAssetData.address = address;
    },
    assetCreateDialogClose() {
      this.assetCreateDialog = false;
      this.$emit("dialogClose");
    },
    createAsset() {
      this.loading = true;
      let asset = {
        name: this.newAssetData.name,
        description: this.newAssetData.description,
        address: this.newAssetData.address.formatted_address,
        latitude: this.newAssetData.address.geometry.location.lat(),
        longitude: this.newAssetData.address.geometry.location.lng()
      };
      // console.log("----save asset-----", asset);
      this.$http
        .post("/assets/", asset, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(() => {
          this.loading = false;
          // console.log("asset creation complete", res.data);
          this.assetCreateDialog = false;
          // this.$emit('dialogClose');
          this.$emit("dialogAssetCreationComplete");
        })
        .catch(err => {
          this.error = true;
          this.loading = false;
          this.error_msg = "Something went wrong";
          console.log("error is ", err);
          // this.error_msg = err.response.data[Object.keys(err.response.data)][0];
          // this.errorsNewAssetData = err.response.data;
        });
    }
  },
  mounted() {
    this.assetCreateDialog = this.openCreateDialog;
  }
};
</script>

<template>
  <v-list class="transparent">
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-map-marker</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <h4>{{ this.assetName }}</h4>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-map</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <!-- <h4>
                    12,772.52 m2 3d Point Cloud_
                  </h4> -->
        <h4 v-if="geom_type == 'Point'">
          {{ `${this.geom_type}` }}
        </h4>
        <h4 v-else-if="geom_type == 'Polygon'">
          {{
            `${this.commaFormat(this.geom_measurement)} m2 ${this.geom_type}`
          }}
        </h4>
        <h4 v-else>
          {{ `${this.commaFormat(this.geom_measurement)} m ${this.geom_type}` }}
        </h4>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <!-- <h4>Feb. 1, 2021 - Feb. 5, 2021</h4> -->
        <h4>
          {{ this.flightDateProcessing(this.startDate) }} -
          {{ this.flightDateProcessing(this.endDate) }}
        </h4>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-credit-card</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <h4>€ {{ cost }}</h4>
        <!-- Editable is {{editable}}
                  Editable Date is {{editableFlightDate}} -->
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-airplane</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <h4 v-if="!flightDate">
          Survey Date : ----
        </h4>
        <h4 v-else>
          Survey Date :
          {{
            new Date(this.flightDate)
              .toDateString()
              .split(" ")
              .slice(1)
              .join(" ")
          }}
        </h4>
      </v-list-item-content>
      <v-list-item-icon>
        <v-btn
          fab
          small
          v-if="editable && editableFlightDate ? true : false"
          @click="flightModal = true"
        >
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
        <v-dialog
          ref="dialog"
          v-model="flightModal"
          :return-value.sync="flightDate"
          persistent
          width="290px"
        >
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon 
                v-if="(editable && editableFlightDate) ? true : false"
                dark
              >mdi-pencil</v-icon>
            </v-btn>
          </template> -->
          <v-date-picker
            v-model="flightDate"
            scrollable
            :min="flightDateMin"
            :max="flightDateMax"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="flightModal = false">
              Cancel
            </v-btn>
            <!-- <v-btn text color="primary" @click="$refs.dialog.save(flightDate)">
              OK
            </v-btn> -->
            <v-btn text color="primary" @click="flightDateUpdater">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-list-item-icon>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-cloud-upload</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <h4 v-if="!backupDate">
          Backup Date : ----
        </h4>
        <h4 v-else>
          Backup Date :
          {{
            new Date(this.backupDate)
              .toDateString()
              .split(" ")
              .slice(1)
              .join(" ")
          }}
        </h4>
      </v-list-item-content>
      <v-list-item-icon>
        <v-btn
          fab
          small
          v-if="flightDate && editable"
          @click="backupModal = true"
        >
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          @click="flightDateSelectionError = true"
          v-else-if="editable"
        >
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>

        <v-dialog
          ref="dialog1"
          v-model="backupModal"
          :return-value.sync="backupDate"
          persistent
          width="290px"
        >
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon 
                v-if="flightDate && editable"
                dark
              >mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              @click="flightDateSelectionError = true"
            >
              <v-icon 
                v-if="!flightDate && editable"
              dark>mdi-pencil</v-icon>
            </v-btn>
          </template> -->
          <v-date-picker
            v-model="backupDate"
            scrollable
            :min="flightDate"
            :max="backupDateMax"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="backupModal = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="backupDateUpdater">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-list-item-icon>
    </v-list-item>
    <RequiredFields
      :message="flightDateBackupDateTextProcessing"
      v-if="flightDateSelectionError"
    />
  </v-list>
</template>

<script>
import RequiredFields from "../misc/RequiredFields";

export default {
  props: [
    "flightID",
    "flightDateComp",
    "backupDateComp",
    "assetName",
    "startDate",
    "endDate",
    "editable",
    "editableFlightDate",
    "geom_type",
    "geom_measurement",
    "cost"
  ],
  components: {
    RequiredFields
  },
  data: () => ({
    flightDate: "",
    backupDate: "",
    flightDateMin: new Date().toISOString(),
    flightDateMax: new Date().toISOString(),
    backupDateMax: new Date().toISOString(),
    flightModal: false,
    backupModal: false,
    flightDateSelectionError: false,
    ifEditable: false,
    ifEditableFlightDate: false
  }),
  computed: {
    flightDateBackupDateTextProcessing() {
      if (!this.flightDate) {
        return "Select Survey Date First!";
      }
      return " ";
    }
  },
  watch: {
    // flightDateComp(newValue) {
    //   console.log("check this new value of flight ",newValue);
    //   this.flightDate = newValue;
    // },
    // backupDateComp(newValue) {
    //   console.log("check this new value of backup ",newValue);
    //   this.backupDate = newValue;
    // },
    editable(newValue) {
      console.log("new value of editable is ", newValue);
      this.testTheThing();
    }
  },
  methods: {
    testTheThing() {
      console.log("editable value ", this.editable);
      console.log("editable flight date is ", this.editableFlightDate);
    },
    flightDateProcessing(dt) {
      if (dt) {
        return new Date(dt)
          .toDateString()
          .split(" ")
          .slice(1)
          .join(" ");
      }

      return " ---- ";
    },
    specialDateStructure(dt) {
      let dateArr = dt.split("-");
      let year = dateArr.shift();
      dateArr.push(year);
      dateArr = dateArr.join("-");
      return dateArr;
    },
    async flightDateUpdater() {
      this.$refs.dialog.save(this.flightDate);
      this.flightDateSelectionError = false;
      try {
        let uploadObj = new FormData();
        uploadObj.append(
          "flight_date",
          this.specialDateStructure(this.flightDate)
        );

        let res = await this.$http.post(
          `flight/${this.flightID}/update-flight-date`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        console.log("res is ", res.data);
        this.$emit("flightDateSelected", this.flightDate);
      } catch (err) {
        console.log("error is ", err);
      }
    },
    async backupDateUpdater() {
      this.$refs.dialog1.save(this.backupDate);
      try {
        let uploadObj = new FormData();
        uploadObj.append(
          "flight_date",
          this.specialDateStructure(this.flightDate)
        );
        uploadObj.append(
          "backup_date",
          this.specialDateStructure(this.backupDate)
        );

        let res = await this.$http.post(
          `flight/${this.flightID}/update-flight-date`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        console.log("res is ", res.data);
        this.$emit("backupDateSelected", this.backupDate);
      } catch (err) {
        console.log("error is ", err);
      }
    },
    commaFormat(num) {
      return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
    }
  },
  mounted() {
    this.flightDateMax = this.endDate;
    this.backupDateMax = this.endDate;
    this.flightDate = this.flightDateComp;
    this.backupDate = this.backupDateComp;
    console.log("Editable at mount is ", this.editable);
  }
};
</script>

<style scoped></style>

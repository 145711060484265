<template>
  <login-parent>
    <v-snackbar v-model="success" color="success" top right>Success</v-snackbar>
    <v-snackbar v-model="error" color="error" top right :timeout="10000">{{
      error_msg
    }}</v-snackbar>
    <v-snackbar
      v-model="testValue"
      right
      :color="$store.state.snackbarSuccess ? 'green' : 'red'"
    >
      {{ $store.state.snackbarMessage }}
    </v-snackbar>

    <v-row justify="center">
      <v-col md="4" v-if="$store.state.landingContent">
        <v-card class="rounded-lg" style="min-height: 50vh">
          <div style="fontFamily: Quicksand, sans-serif;">
            <h2>{{ $store.state.landingContent.heading }}</h2>
            <p style="fontWeight:600">
              {{ $store.state.landingContent.text }}
            </p>
          </div>
        </v-card>
      </v-col>
      <v-col md="8">
        <v-card class="rounded-lg" style="min-height: 50vh">
          <v-container fluid align="center" style="min-height: 50vh">
            <v-row justify="center" align="center" style="min-height: 50vh">
              <v-col md="6">
                <div class="text-center">
                  <v-icon
                    v-if="!$store.state.logoURL"
                    color="white"
                    class="font"
                    style="font-size: 100px"
                  >
                    mdi-account
                  </v-icon>
                  <img
                    style="marginLeft:-20px"
                    :src="$store.state.logoURL"
                    v-else
                  />
                  <h1>User Login</h1>
                  <v-form @submit.prevent="login" v-model="valid">
                    <v-text-field
                      label="Email"
                      type="email"
                      prepend-inner-icon="mdi-mail"
                      :rules="rules.email"
                      v-model="login_object.email"
                    ></v-text-field>
                    <v-text-field
                      label="Password"
                      type="password"
                      prepend-inner-icon="mdi-key"
                      :rules="rules.password"
                      v-model="login_object.password"
                    ></v-text-field>
                    <router-link to="/forget-password"
                      >Forgot Password</router-link
                    >

                    <v-btn
                      color="primary mt-2"
                      block
                      rounded
                      elevation="2"
                      type="submit"
                      :loading="loading"
                      :disabled="!valid"
                    >
                      Login
                    </v-btn>
                  </v-form>

                  <v-btn
                    to="/register"
                    class="mt-3"
                    color="primary"
                    block
                    rounded
                    outlined
                    elevation="2"
                  >
                    Sign Up
                  </v-btn>

                  <a
                    target="_blank"
                    v-for="(button, index) in $store.state.landingLinks"
                    :key="index"
                    :href="button.button_link"
                  >
                    <v-btn
                      :color="button.text_color"
                      :text="true"
                      block
                      rounded
                      elevation="2"
                      class="mt-2"
                      :style="[{ backgroundColor: button.button_color }]"
                    >
                      {{ button.button_text }}
                    </v-btn>
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </login-parent>
</template>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
<script>
import LoginParent from "../components/LoginParent";

export default {
  name: "Login",

  metaInfo() {
    return {
      title: this.$store.state.app_title,
      titleTemplate: "%s | User Login"
    };
  },
  components: {
    LoginParent
  },
  computed: {
    testValue: {
      /* By default get() is used */
      get() {
        return this.$store.state.snackbarValue;
      },
      /* We add a setter */
      set(value) {
        this.$store.commit("snackbarActivator", {
          open: value,
          success: this.$store.state.snackbarSuccess
        });
      }
    }
  },

  data: () => {
    return {
      login_object: {
        email: "",
        password: ""
      },
      rules: {
        email: [
          v => !!v || "E-mail is required",
          v => /.+@.+/.test(v) || "E-mail must be valid"
        ],
        password: [v => !!v || "Password is required"]
      },
      loading: false,
      success: false,
      error: false,
      error_msg: "",
      valid: false
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.$http
        .post("/rest-auth/login/", this.login_object)
        .then(res => {
          this.loading = true;
          console.log("response from body");
          console.log(res.data);
          localStorage.setItem("auth_token", res.data.key);
          this.success = true;
          setTimeout(
            function() {
              this.$router.push({ name: "Home" });
            }.bind(this),
            1000
          );
        })
        .catch(err => {
          this.error_msg = err.response.data[Object.keys(err.response.data)][0];
          this.error = true;
          this.loading = false;
        });
    },
    async getSkin() {
      if (this.$store.state.fetchSkin) {
        try {
          let res = await this.$http.get(
            `/get-skin?domain=${window.location.hostname}`
          );
          // let res = await this.$http.get(
          //   `/get-skin?domain=app.aerinspect.com`
          // );

          if (res.data.cookie_url) {
            this.$store.commit("setCookieURL", res.data.cookie_url);
          }
          if (Object.keys(res.data).length > 0) {
            let resNext = await this.$http.get(
              `/get-landing-links/${res.data.id}`
            );

            let title = res.data.site_name || "Aerinspect";
            document.title = title;
            this.$store.commit("setAppTitle", title);
            let resLandingCotent = await this.$http.get(
              `/get-landing-content/${res.data.id}`
            );

            let {
              primary,
              secondry,
              accent,
              error,
              info,
              success,
              warning
            } = res.data;

            const favicon = document.getElementById("favicon");
            favicon.href = res.data.favicon_url;

            this.$vuetify.theme.themes.dark.primary = primary || "#FF6600";
            this.$vuetify.theme.themes.dark.secondry = secondry || "#303641";
            this.$vuetify.theme.themes.dark.accent = accent || "#82B1FF";
            this.$vuetify.theme.themes.dark.error = error || "#FF5252";
            this.$vuetify.theme.themes.dark.info = info || "#2196F3";
            this.$vuetify.theme.themes.dark.success = success || "#4CAF50";
            this.$vuetify.theme.themes.dark.warning = warning || "#FFC107";

            this.$store.commit("setLogoURL", res.data.logo_url);
            this.$store.commit("changeFetchSkin", false);
            this.$store.commit("setLandingLinks", resNext.data.links);
            if (resLandingCotent.data.heading && resLandingCotent.data.text) {
              this.$store.commit("setLandingContent", resLandingCotent.data);
            }
          }
        } catch (err) {
          console.log("something went wrong");
        }
      }
    }
  },
  mounted() {
    this.getSkin();
  }
};
</script>

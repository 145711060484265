<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="cyan"
        class="mb-2 mt-3"
        block
        v-bind="attrs"
        v-on="on"
        :disabled="galleryData.length < 1"
        >Previous Collected Data</v-btn
      >
    </template>
    <div style="background-color: black;height:100vh;overflow-y:scroll">
      <v-toolbar dark color="primary">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="modal = false">
            Close
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <GalleryLightbox :galleryData="galleryData" cols="3" />
      <!-- <v-row>
          <v-col v-for="(image, index) in mediaProcessing" :key="index" class="d-flex child-flex" cols="3">
            <v-hover v-slot="{ hover }">
              <v-img
                :src="image.src"
                :lazy-src="image.thumb"
                aspect-ratio="1"
                class="grey lighten-2"
                @click="openGallery(index)"
                :elevation="hover ? 16 : 2"
                :class="{ overlay: hover }"
                :gradient="
                  hover
                    ? 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'
                    : ''
                "
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-hover>
          </v-col>
        </v-row> -->
      <!-- <LightBox
        ref="lightbox"
        :media="mediaProcessing"
        :show-caption="true"
        :show-light-box="false"
      /> -->
    </div>
  </v-dialog>
</template>

<script>
import GalleryLightbox from "./GalleryLightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

export default {
  name: "GalleryLightboxDialog",
  components: {
    GalleryLightbox
  },
  props: ["galleryData"],
  data() {
    return {
      modal: false
    };
  }
};
</script>

import {
  setFlightCameras,
  setSelectedCamera,
  setSidebarPanelIndex,
  setFilterImagesOnClick,
  setFilteredImages
} from "../mutation-types";

import axios from "axios";

export default {
  state: {
    flightCameras: {
      type: "FeatureCollection",
      features: []
    },
    selectedCamera: null,
    sidebarPanelIndex: null,

    isFilterImagesOnClick: false,
    filteredImages: []
  },

  actions: {
    loadFlightCameras({ commit }, camerasUrl) {
      axios
        .get(camerasUrl)
        .then(
          response => {
            let cameras = { ...response.data };
            console.log("cameras:", cameras);
            commit("setFlightCameras", cameras);
          },
          error => {
            console.log("error", error);
            throw error;
          }
        )
        .catch(error => {
          console.log("cerror", error);
          throw error;
        });
    },

    loadFlightCamerasAgisoft({ commit }, cameras_geojson) {
      cameras_geojson.features = cameras_geojson.features.map(item => {
        item.properties.photo_id = item.properties["@label"];
        return item;
      });
      // height: 3000
      // kappa: -85.04811817781987
      // omega: 1.8042403872190047
      // phi: 0.7136183944943884
      // photo_id: "dji_0645.JPG"
      // pitch: -1.8041003997488305
      // roll: -0.7139723218260204
      // width: 4000
      // yaw: 274.9743604676273
      console.log("cameras_geojson:", cameras_geojson);
      commit("setFlightCameras", cameras_geojson);
    },

    filterFlightImagesByCoordinates({ commit }, { flight_id, lng, lat }) {
      const flight_filter_images_url =
        process.env.NODE_ENV === "production"
          ? `/api/get-footprint?flight_id=${flight_id}&lng=${lng}&lat=${lat}`
          : `http://localhost:8000/api/get-footprint?flight_id=${flight_id}&lng=${lng}&lat=${lat}`;

      axios
        .get(flight_filter_images_url)
        .then(
          response => {
            let filtered_images = { ...response.data };
            let filtered_images_array = filtered_images.features;

            let filteredImages = [];
            for (let i = 0; i < filtered_images_array.length; i++) {
              let image_name = JSON.parse(
                filtered_images_array[i].properties.properties
              )["NAME"]; // TODO: fix image name OR add image id
              filteredImages.push(image_name);
            }

            commit("setFilteredImages", filteredImages);
          },
          error => {
            console.log("error", error);
            throw error;
          }
        )
        .catch(error => {
          console.log("cerror", error);
          throw error;
        });
    }
  },

  mutations: {
    [setFlightCameras]: (state, payload) => {
      state.flightCameras = payload;
    },
    [setSelectedCamera]: (state, payload) => {
      state.selectedCamera = payload;
    },
    [setSidebarPanelIndex]: (state, payload) => {
      state.sidebarPanelIndex = payload;
    },

    [setFilterImagesOnClick]: (state, payload) => {
      state.isFilterImagesOnClick = payload;
    },
    [setFilteredImages]: (state, payload) => {
      state.filteredImages = payload;
    }
  }
};

<template>
  <div>
    <v-row>
      <v-col
        v-for="(image, index) in galleryDataReal"
        :key="index"
        class="d-flex child-flex"
        :cols="cols"
      >
        <v-hover v-slot="{ hover }">
          <v-img
            :src="image.thumb"
            :lazy-src="image.thumb"
            aspect-ratio="1"
            class="grey lighten-2"
            @click="openGallery(index)"
            :elevation="hover ? 16 : 2"
            :class="{ overlay: hover }"
            :gradient="
              hover
                ? 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'
                : ''
            "
            style="cursor:pointer"
          >
            <div class="separateLoadingDivStyle" v-if="image.loading">
              <Loading loadingText="Deleting!" />
            </div>
            <div class="deleteArea" v-else>
              <v-btn
                icon
                @click="
                  e =>
                    annotatorDialogOpener(
                      e,
                      image.src,
                      image.id,
                      image.file_name
                    )
                "
              >
                <v-icon dark color="white">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                color="error darken-3"
                @click="e => deleteClickHandler(e, image.id, index)"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </div>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-hover>
      </v-col>
    </v-row>
    <LightBox
      ref="lightbox"
      :media="galleryDataReal"
      :show-caption="true"
      :show-light-box="false"
    />
    <v-snackbar v-model="snackbar" right :color="success ? 'green' : 'red'">
      {{ snackbarMessage }}
    </v-snackbar>

    <AnnotatorDialog
      :imageName="imageName"
      :imageID="imageID"
      :imageURL="imageURL"
      :dialogOpen="dialogValue"
      @dialogClose="dialogValue = false"
    />
  </div>
</template>

<script>
import LightBox from "vue-image-lightbox";
import Loading from "../../misc/Loading";
import AnnotatorDialog from "../../asset/Annotator/AnnotatorDialog";

require("vue-image-lightbox/dist/vue-image-lightbox.min.css");
export default {
  name: "GalleryLightbox",
  data: () => ({
    galleryDataReal: [],
    snackbar: false,
    success: false,
    snackbarMessage: "File has been successfully removed",
    imageName: "",
    imageID: "",
    imageURL: "",
    dialogValue: false
  }),
  components: {
    LightBox,
    Loading,
    AnnotatorDialog
  },
  props: ["galleryData", "cols"],
  watch: {
    galleryData(newVal) {
      let arrToReturn = [];
      for (let i of newVal) {
        if (i.mime_type.split("/").shift() == "image") {
          arrToReturn.unshift({
            thumb: i.thumbnail_url,
            src: i.image_url,
            ...i
          });
        } else if (i.mime_type.split("/").shift() == "video") {
          arrToReturn.push({
            thumb: i.thumbnail_url,
            sources: [
              {
                src: i.image_url,
                type: i.mime_type
              }
            ],
            width: 800,
            height: 600,
            autoplay: true,
            ...i,
            type: "video"
          });
        }
      }
      this.galleryDataReal = arrToReturn;
    }
  },
  mounted() {
    let arrToReturn = [];
    for (let i of this.galleryData) {
      if (i.mime_type.split("/").shift() == "image") {
        arrToReturn.unshift({
          thumb: i.thumbnail_url,
          src: i.image_url,
          ...i
        });
      } else if (i.mime_type.split("/").shift() == "video") {
        arrToReturn.push({
          thumb: i.thumbnail_url,
          sources: [
            {
              src: i.image_url,
              type: i.mime_type
            }
          ],
          width: 800,
          height: 600,
          autoplay: true,
          ...i,
          type: "video"
        });
      }
    }
    this.galleryDataReal = arrToReturn;
  },
  methods: {
    openGallery(index) {
      if (!this.galleryDataReal[index].loading) {
        this.$refs.lightbox.showImage(index);
      }
    },
    async deleteClickHandler(e, id, index) {
      e.stopPropagation();
      try {
        this.galleryDataReal[index].loading = true;
        let res = await this.$http.delete(`/file/${id}/delete`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        this.snackbarMessage = "File has been successfully removed";
        this.success = true;
        this.snackbar = true;
        this.galleryDataReal.splice(index, 1);
        console.log("Response is ", res);
      } catch (err) {
        this.snackbarMessage = "Something went wrong";
        this.success = false;
        this.snackbar = true;
        this.galleryDataReal[index].loading = false;
      }
    },
    async annotatorDialogOpener(e, imageSRC, image_ID, image_Name) {
      e.stopPropagation();
      this.imageName = image_Name;
      this.imageID = image_ID;
      this.imageURL = imageSRC;
      this.dialogValue = true;
    }
  }
};
</script>

<style scoped>
.deleteArea {
  background: #00000099;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
}

.separateLoadingDivStyle {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000054;
}
</style>

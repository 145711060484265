import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import cameras from "./modules/cameras.js";

let store = new Vuex.Store({
  state: {
    mini_sidebar: false,
    logoURL: undefined,
    isShared: false,
    snackbarValue: false,
    snackbarSuccess: false,
    snackbarMessage: "Success",
    showMainMenu: true,
    fetchSkin: true,
    landingLinks: [],
    landingContent: undefined,
    app_title: "Aerinspect",
    cookie_url: "#"
  },
  mutations: {
    set_minisidebar(state, value) {
      console.log("----set sidebar", value);
      state.mini_sidebar = value;
    },
    setLogoURL(state, value) {
      state.logoURL = value;
    },
    toggle_minisidebar(state) {
      console.log("----toggle sidebar", state.mini_sidebar);
      state.mini_sidebar = !state.mini_sidebar;
    },
    changeIsShared(state, value) {
      state.isShared = value;
    },
    snackbarActivator(state, value) {
      state.snackbarValue = value.open;
      state.snackbarSuccess = value.success;
      state.snackbarMessage = value.message;
    },
    changeShowMainMenu(state, value) {
      state.showMainMenu = value;
    },
    changeFetchSkin(state, value) {
      state.fetchSkin = value;
    },
    setLandingLinks(state, value) {
      state.landingLinks = value;
    },
    setLandingContent(state, value) {
      state.landingContent = value;
    },
    setAppTitle(state, value) {
      state.app_title = value;
    },
    setCookieURL(state, value) {
      state.cookie_url = value;
    }
  },
  actions: {},
  modules: {
    cameras
  }
});

export default store;

<template>
  <v-container fluid>
    <h1>Profile</h1>

    <div class="profileLoadingDivStyle" v-if="loading">
      <Loading :loadingText="loadingText" />
    </div>
    <v-container fluid v-else>
      <form @submit.prevent="changeProfile">
        <v-text-field
          label="First Name *"
          class="mb-5"
          v-model="first_name"
          :rules="[rules.required]"
        />

        <v-text-field
          label="Last Name *"
          class="mb-5"
          v-model="last_name"
          :rules="[rules.required]"
        />

        <v-text-field
          label="Email *"
          type="email"
          class="mb-5"
          v-model="email"
          :rules="[rules.required]"
        />

        <v-text-field
          label="Company *"
          class="mb-5"
          v-model="company"
          :rules="[rules.required]"
        />

        <v-text-field
          label="Phone *"
          class="mb-5"
          v-model="phone"
          :rules="[rules.required]"
        />

        <v-text-field label="Vat" class="mb-5" v-model="vat" />

        <v-textarea
          label="Address *"
          rows="3"
          class="mb-5"
          v-model="address"
          :rules="[rules.required]"
        />

        <PilotUpload
          :assetID="$route.params.assetID"
          @uploadCompleted="uploadDialogResultHandler"
          v-if="showUploadBox"
        />
        <PilotFilesDialog
          :filesData="pilotFiles"
          @fileRemoved="dialogFileRemoveHandler"
          v-if="showUploadBox"
        />

        <v-text-field
          @input="passwordChangeHandler"
          :value="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          label="Password For Verification *"
          @click:append="show1 = !show1"
          :error="passwordError"
          class="mt-4"
          :rules="[
            passwordRules().notCorrect,
            passwordRules().required,
            passwordRules().min
          ]"
        />

        <v-btn
          type="submit"
          class="mt-4"
          block
          rounded
          color="primary"
          :disabled="fieldsCheck"
          >Submit
        </v-btn>
      </form>
    </v-container>
    <v-snackbar v-model="snackbar" right :color="success ? 'green' : 'red'">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import Loading from "../components/misc/Loading";
import PilotUpload from "../components/profile/PilotUpload";
import PilotFilesDialog from "../components/profile/PilotFilesDialog";

export default {
  name: "Profile",
  components: {
    Loading,
    PilotUpload,
    PilotFilesDialog
  },
  data: () => ({
    loading: true,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    company: "",
    password: "",
    vat: "",
    show1: false,
    pilotFiles: [],
    showUploadBox: false,
    snackbar: false,
    success: true,
    snackbarMessage: "Profile has been successfully updated",
    passwordError: false,
    loadingText: "Fetching Profile Data!",
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters"
    },
    passwordRules() {
      return {
        required: () => !!this.password || "Required.",
        min: () => this.password.length >= 8 || "Min 8 characters",
        notCorrect: () => !this.passwordError || `Password is not correct`
      };
    }
  }),
  computed: {
    fieldsCheck() {
      if (
        this.first_name &&
        this.last_name &&
        this.phone &&
        this.address &&
        this.company &&
        this.email &&
        this.password
      ) {
        if (this.password.length > 7) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    async fetchProfileData() {
      try {
        let res = await this.$http.get("/profile/details/", {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });

        this.first_name = res.data.first_name;
        this.last_name = res.data.last_name;
        this.phone = res.data.telephone;
        this.address = res.data.address;
        this.company = res.data.company_name;
        this.email = res.data.email;
        this.vat = res.data.vat;
        if (res.data.user_type == "Pilot") {
          this.fetchPilotFiles();
          this.showUploadBox = true;
        } else {
          this.loading = false;
          this.showUploadBox = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchPilotFiles() {
      try {
        this.loading = true;
        let res = await this.$http.get("/profile/get-pilot-files/", {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        this.pilotFiles = [...res.data];
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async changeProfile() {
      this.loadingText = "Submitting Profile Data!";
      this.loading = true;
      try {
        let uploadObj = new FormData();
        uploadObj.append("first_name", this.first_name);
        uploadObj.append("last_name", this.last_name);
        uploadObj.append("address", this.address);
        uploadObj.append("email", this.email);
        uploadObj.append("telephone", this.phone);
        uploadObj.append("company_name", this.company);
        uploadObj.append("vat", this.vat);
        uploadObj.append("password", this.password);
        let res = await this.$http.put("/profile/edit/", uploadObj, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        console.log(res);
        this.loadingText = "Fetching Profile Data!";
        this.loading = false;
        this.snackbar = true;
        this.success = true;
        this.password = "";
        this.snackbarMessage = "Profile has been successfully updated";
      } catch (err) {
        if (err.response.data.password) {
          let strCheck = err.response.data.password[0];
          if (
            strCheck.includes("at least 8 characters") ||
            strCheck.includes("Password is not correct")
          ) {
            this.passwordError = true;
          }
        } else {
          this.snackbarMessage = "Something went wrong. Refresh Page!";
          this.success = false;
          this.snackbar = true;
        }
        this.loading = false;
      }
    },
    passwordChangeHandler(e) {
      this.passwordError = false;
      this.password = e;
    },
    uploadDialogResultHandler(value) {
      if (value) {
        this.snackbar = true;
        this.success = true;
        this.snackbarMessage = "Files has been successfully uploaded";
        this.fetchPilotFiles();
      } else {
        this.success = false;
        this.snackbar = true;
        this.snackbarMessage = "Something went wrong";
      }
    },
    dialogFileRemoveHandler(value) {
      if (value) {
        this.snackbar = true;
        this.success = true;
        this.snackbarMessage = "Files has been successfully removed";
      } else {
        this.success = false;
        this.snackbar = true;
        this.snackbarMessage = "Something went wrong";
      }
    }
  },
  mounted() {
    this.fetchProfileData();
  }
};
</script>

<style scoped>
.profileLoadingDivStyle {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<template>
  <v-dialog ref="dialog" v-model="modal" width="800" style="zIndex:1000;">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="cyan"
        class="mb-2 mt-3"
        block
        v-bind="attrs"
        v-on="on"
        :disabled="logDataFiles.length < 1"
        >{{ heading }}</v-btn
      >
    </template>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              SR
            </th>
            <th class="text-left">
              NAME
            </th>
            <th class="text-left">
              DOWNLOAD
            </th>
            <th class="text-left">
              ACTIONS
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, ind) in logDataFiles" :key="ind">
            <td>{{ ind + 1 }}</td>
            <td>{{ item.file_name }}</td>
            <td>
              <a :href="item.image_url" target="_blank">
                Download
              </a>
            </td>
            <td>
              <v-btn fab small @click="deleteClickHandler(item.id, ind)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-dialog>
</template>

<script>
export default {
  name: "LogFilesDialog",
  props: ["logData", "heading"],
  watch: {
    logData(newVal) {
      this.logDataFiles = newVal;
    }
  },
  data() {
    return {
      modal: false,
      logDataFiles: []
    };
  },
  computed: {},
  methods: {
    async deleteClickHandler(id, index) {
      let filename = this.logDataFiles[index].file_name;
      let fileObj = { ...this.logDataFiles[index] };
      fileObj.file_name = "Removing ...";

      try {
        this.logDataFiles.splice(index, 1, fileObj);
        let res = await this.$http.delete(`/file/${id}/delete`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        this.logDataFiles.splice(index, 1);
        this.$emit("fileRemoved", true);
        console.log("Response is ", res);
      } catch (err) {
        console.log("Something went wrong");
        fileObj.file_name = filename;
        this.logDataFiles.splice(index, 1, fileObj);
        this.$emit("fileRemoved", false);
      }
    }
  },
  mounted() {
    this.logDataFiles = this.logData;
  }
};
</script>

<template>
  <login-parent>
    <v-snackbar v-model="success" color="success" top right
      >Reset Email has been sent successfully</v-snackbar
    >
    <v-snackbar v-model="error" color="error" top right:timeout="10000">{{
      error_msg
    }}</v-snackbar>
    <v-row justify="center">
      <v-col md="8">
        <v-card class="rounded-lg" style="min-height: 50vh">
          <v-container fluid align="center" style="min-height: 50vh">
            <v-row justify="center" align="center" style="min-height: 49vh">
              <v-col md="6">
                <div class="text-center">
                  <v-icon color="white " class="font" style="font-size: 100px">
                    mdi-key
                  </v-icon>
                  <h1>Forgot Password</h1>
                  <v-form @submit.prevent="forgotPassword" v-model="valid">
                    <v-text-field
                      label="Email"
                      type="email"
                      prepend-inner-icon="mdi-mail"
                      v-model="email"
                      :rules="emailRules"
                      :error-messages="error_m.name"
                    ></v-text-field>

                    <v-btn
                      color="primary mt-2"
                      block
                      rounded
                      elevation="2"
                      type="submit"
                      :loading="loading"
                      :disabled="!valid"
                    >
                      Send Reset Link
                    </v-btn>
                  </v-form>

                  <v-btn
                    to="/"
                    class="mt-3"
                    color="primary"
                    block
                    rounded
                    outlined
                    elevation="2"
                  >
                    Login
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </login-parent>
</template>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
<script>
import LoginParent from "../components/LoginParent";

export default {
  name: "ForgetPassword",
  components: { LoginParent },
  data: () => {
    return {
      reveal: false,
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      error_m: { name: "" },
      success: false,
      loading: false,
      error: false,
      error_msg: "",
      valid: false
    };
  },
  methods: {
    forgotPassword() {
      this.loading = true;
      this.$http
        .post("/rest-auth/password/reset/", { email: this.email })
        .then(res => {
          console.log("sent");
          console.log(res.data);
          this.loading = false;
          this.success = true;
        })
        .catch(e => {
          this.error = true;
          this.error_msg = e.response.data;
        });
    }
  }
};
</script>

<template>
  <div style="width:100%;height:100vh;">
    <div
      :style="[showDraw ? { visibility: 'visible' } : { visibility: 'hidden' }]"
    >
      <cesium-draw
        ref="drawManager"
        @closeEvent="showDraw = false"
        @elementCreated="saveModal = true"
        @elementClicked="elementClickHandler"
        @createdGEOJSON="createdGEOJSONHandler"
        @elementEdited="elementEditedHandler"
      ></cesium-draw>
    </div>
    <div
      style="position:absolute;userSelect:none;left:426px;top:94px;"
      v-drag
      class="layer-control"
      v-if="showControls"
    >
      <div style="padding:10px 0px">
        <span>View Mode</span>
        <span
          @click="showControls = false"
          class="closebtn iconfont icondelete"
        ></span>
      </div>
      <v-btn-toggle v-model="viewMode" mandatory>
        <v-btn x-small> Earth </v-btn>
        <v-btn x-small> Model </v-btn>
      </v-btn-toggle>

      <v-divider></v-divider>

      <!--v-radio-group v-model="baseLayer">
          <v-radio label="Mapbox Satellite" value="mapbox_satellite"></v-radio>
          <v-radio label="OpenStreetMap" value="osm"></v-radio>
        </v-radio-group-->

      <v-checkbox
        class="mt-2"
        hide-details
        v-model="showCameras"
        light
        label="Cameras"
      ></v-checkbox>

      <v-checkbox
        v-if="pointCloudlLayerUrl"
        class="mt-2"
        hide-details
        v-model="showPointCloudLayer"
        light
        label="Point cloud"
      ></v-checkbox>
      <v-text-field
        v-if="showPointCloudLayer && pointCloudlLayerUrl"
        v-model="pointCloudOffset"
        class="pt-0 mt-0 mb-2"
        hide-details
      >
        <v-icon slot="append" @click="increasePointCloudOffset"
          >mdi-plus</v-icon
        >
        <v-icon slot="prepend" @click="decreasePointCloudOffset"
          >mdi-minus</v-icon
        >
      </v-text-field>

      <v-checkbox
        class="mt-2"
        hide-details
        v-model="showTiledModelLayer"
        light
        label="3D Model"
      ></v-checkbox>
      <v-text-field
        v-if="showTiledModelLayer"
        v-model="tiledModelOffset"
        class="pt-0 mt-0 mb-2"
        hide-details
      >
        <v-icon slot="append" @click="increaseTiledModelOffset"
          >mdi-plus</v-icon
        >
        <v-icon slot="prepend" @click="decreaseTiledModelOffset"
          >mdi-minus</v-icon
        >
      </v-text-field>

      <v-checkbox
        v-if="dsmLayerUrl"
        class="mt-2"
        hide-details
        v-model="showDsmLayer"
        light
        label="DSM"
        :disabled="viewMode == 1"
      ></v-checkbox>
      <v-slider
        v-if="showDsmLayer && dsmLayerUrl"
        v-model="dsmLayerOpacity"
        hide-details
        thumb-label
        :disabled="viewMode == 1"
      ></v-slider>

      <v-checkbox
        v-if="dtmLayerUrl"
        class="mt-2"
        hide-details
        v-model="showDtmLayer"
        light
        label="DTM"
        :disabled="viewMode == 1"
      ></v-checkbox>
      <v-slider
        v-if="showDtmLayer"
        v-model="dtmLayerOpacity"
        hide-details
        thumb-label
        :disabled="viewMode == 1"
      ></v-slider>

      <v-checkbox
        v-if="orthoLayerUrl"
        class="mt-2"
        hide-details
        v-model="showOrthoLayer"
        light
        label="Orthomosaic"
        :disabled="viewMode == 1"
      ></v-checkbox>
      <v-slider
        v-if="showOrthoLayer && orthoLayerUrl"
        v-model="orthoLayerOpacity"
        hide-details
        thumb-label
        :disabled="viewMode == 1"
      ></v-slider>

      <!-- <v-btn  @click="toggle('handlerLine')">
      {{ polylineDrawing ? 'Stop' : 'Polyline' }}
    </v-btn> -->
      <!-- <div style="display:flex;justifyContent:space-between">
          <v-btn small @click="showDraw = true">
            Draw
          </v-btn>
          <v-btn small @click="showMeasure = true">
            Measure
          </v-btn>
        </div> -->
      <!--v-divider></v-divider>

        <v-btn-toggle v-model="terrainProvider" mandatory>
          <v-btn x-small> Ellipsoid </v-btn>
          <v-btn x-small> Terrain </v-btn>
        </v-btn-toggle-->
    </div>

    <div
      style="position:absolute;userSelect:none;left:426px;top:94px;zIndex:10;"
      v-drag
      v-if="showMeasure"
    >
      <section class="sectionInsideDrag">
        <header class="headerSectionInsideDrag">
          <span>Measure</span>
          <span
            @click="showMeasure = false"
            class="closebtn iconfont icondelete"
          ></span>
        </header>
        <main class="el-main graphic-draw-main">
          <ul>
            <li @click="toggle('measureDistance')">
              <i title="Add Tag" class="fas fa-wave-square icon-class"></i>
              <span>
                Distance
              </span>
            </li>
            <li @click="toggle('measureArea')">
              <i title="Add Tag" class="fas fa-square"></i>
              <span>
                Area
              </span>
            </li>
            <li @click="toggle('measureHeight')">
              <i title="Add Tag" class="fas fa-arrows-alt-v"></i>
              <span>
                Height
              </span>
            </li>
            <li
              @click="
                $refs.measureDistance.clear();
                $refs.measureArea.clear();
                $refs.measureHeight.clear();
              "
            >
              <i title="Add Tag" class="fas fa-ban"></i>
              <span>
                Clear
              </span>
            </li>
          </ul>
        </main>
      </section>
    </div>

    <div class="viewer">
      <v-navigation-drawer
        permanent
        expand-on-hover
        style="position:absolute;zIndex:1;backgroundColor:rgba(30, 30, 30, 0.28)"
      >
        <v-list nav dense style="cursor:pointer">
          <v-list-item @click="showControls = !showControls">
            <v-list-item-icon>
              <v-icon> mdi-wrench</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Controls</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="showDraw = !showDraw"
            v-if="!$store.state.isShared"
          >
            <v-list-item-icon>
              <v-icon>mdi-drawing</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Draw</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showMeasure = !showMeasure">
            <v-list-item-icon>
              <v-icon>mdi-tape-measure</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Measure</v-list-item-title>
          </v-list-item>
          <v-list-item @click="show2DImages = !show2DImages">
            <v-list-item-icon>
              <v-icon>mdi-camera-image</v-icon>
            </v-list-item-icon>
            <v-list-item-title>2D Images</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <vc-viewer
        ref="vcViewer"
        :fullscreenButton="true"
        :infoBox="viewerInfoBox"
        @ready="viewerReady"
      >
        <vc-primitive-tileset
          :url="pointCloudlLayerUrl"
          @readyPromise="pointCloudReady"
          :pointCloudShading="pointCloudShading"
          :show="showPointCloudLayer"
          :maximumScreenSpaceError="4"
        ></vc-primitive-tileset>

        <vc-primitive-tileset
          :url="tiledModelLayerUrl"
          @readyPromise="tiledModelReady"
          :show="showTiledModelLayer"
          :baseScreenSpaceError="64"
          :maximumScreenSpaceError="2"
          :foveatedScreenSpaceError="false"
          :foveatedConeSize="undefined"
        ></vc-primitive-tileset>

        <vc-navigation
          ref="navigation"
          :options="navigationControlOptions"
        ></vc-navigation>

        <vc-layer-imagery v-if="showDsmLayer" :alpha="dsmLayerOpacityVal">
          <vc-provider-imagery-tile-mapservice
            v-if="showDsmLayer"
            :url="dsmLayerUrl"
            :show="showDsmLayer"
            :maximumLevel="maximumLevel"
          ></vc-provider-imagery-tile-mapservice>
        </vc-layer-imagery>

        <vc-layer-imagery v-if="showDtmLayer" :alpha="dtmLayerOpacityVal">
          <vc-provider-imagery-tile-mapservice
            v-if="showDtmLayer"
            :url="dtmLayerUrl"
            :show="showDtmLayer"
            :maximumLevel="maximumLevel"
          ></vc-provider-imagery-tile-mapservice>
        </vc-layer-imagery>

        <vc-layer-imagery v-if="showOrthoLayer" :alpha="orthoLayerOpacityVal">
          <vc-provider-imagery-tile-mapservice
            ref="layer"
            v-if="showOrthoLayer"
            :url="orthoLayerUrl"
            :show="showOrthoLayer"
            :maximumLevel="maximumLevel"
          ></vc-provider-imagery-tile-mapservice>
        </vc-layer-imagery>

        <vc-collection-primitive-label>
          <vc-primitive-label
            :position="cameraLabelPosition"
            :key="'label'"
            :text="cameraLabelContent"
            :horizontalOrigin="0"
            :verticalOrigin="1"
            :show="cameraLabelShow"
            :pixelOffset="{ x: 0, y: -10 }"
            :font="'16px sans-serif'"
            showBackground
          >
          </vc-primitive-label>
        </vc-collection-primitive-label>

        <vc-collection-primitive-point>
          <!-- <template v-for="(camera, subIndex) of flightCameras.features">
            <vc-primitive-point
              :position="{
                lng: camera.geometry.coordinates[0],
                lat: camera.geometry.coordinates[1],
                height: camera.geometry.coordinates[2]
              }"
              :key="'point' + subIndex"
              :id="'cameraEntity_' + camera.properties.photo_id"
              :pixelSize="8"
              :show="showCameras"
            ></vc-primitive-point>
          </template> -->
          <template v-for="(camera, subIndex) of foundCesiumCamera">
            <vc-primitive-point
              :position="{
                lng: Number(camera.X),
                lat: Number(camera.Y),
                height: Number(camera.Z)
              }"
              :key="'point' + subIndex"
              :id="'cameraEntity_' + camera.PhotoID"
              :pixelSize="8"
              :show="showCameras"
            ></vc-primitive-point>
          </template>
        </vc-collection-primitive-point>

        <vc-collection-primitive-point>
          <template v-for="(image, subIndex) of selectedCameraFeature">
            <vc-primitive-point
              :position="{
                lng: image.lng,
                lat: image.lat,
                height: image.height
              }"
              :key="'point' + subIndex"
              :id="'cameraEntity_' + image.photo_id"
              :pixelSize="14"
              :show="showCameras"
            ></vc-primitive-point>
          </template>
        </vc-collection-primitive-point>

        <vc-collection-primitive-point>
          <template v-for="(image, subIndex) of filterImagesPointFeature">
            <vc-primitive-point
              :position="{
                lng: image.lng,
                lat: image.lat,
                height: image.alt
              }"
              :key="'point' + subIndex"
              :pixelSize="12"
            ></vc-primitive-point>
          </template>
        </vc-collection-primitive-point>

        <!--vc-layer-imagery v-if="baseLayer === 'mapbox_satellite'">
          <vc-provider-imagery-mapbox
            :mapId="mapboxMapId"
            :accessToken="mapboxAccessToken"
          ></vc-provider-imagery-mapbox>
        </vc-layer-imagery-->

        <vc-layer-imagery v-if="viewMode == 0">
          <vc-provider-imagery-openstreetmap></vc-provider-imagery-openstreetmap>
        </vc-layer-imagery>

        <vc-handler-draw-polyline
          :clampToGround="clampToGround"
          ref="handlerLine"
          @activeEvt="activeEvt"
          @movingEvt="movingEvt"
          @drawEvt="drawEvt"
          :editable="editable"
        ></vc-handler-draw-polyline>
        <vc-measure-distance
          :clampToGround="clampToGround"
          ref="measureDistance"
          @activeEvt="activeEvt"
          @measureEvt="measureEvt"
          @movingEvt="movingEvt"
          :removeLastPosition="removeLastPosition"
        ></vc-measure-distance>
        <vc-measure-area
          ref="measureArea"
          @activeEvt="activeEvt"
          @measureEvt="measureEvt"
          @movingEvt="movingEvt"
          :clampToGround="clampToGround"
          :removeLastPosition="removeLastPosition"
        ></vc-measure-area>
        <vc-measure-height
          ref="measureHeight"
          @activeEvt="activeEvt"
          @measureEvt="measureEvt"
          @movingEvt="movingEvt"
        ></vc-measure-height>
      </vc-viewer>

      <AnnotatorCesiumSaveDialog
        :saveModal="saveModal"
        :loading="saveModalLoading"
        :editingMode="editingMode"
        :eName="clickedData.name"
        :eDescription="clickedData.description"
        @save="saveCesiumAnnotationDialog"
        @cancel="cancelCesiumAnnotation"
        @edit="editCesiumAnnotationHandler"
      />
      <AnnotatorRemoveDialog
        :removeModal="removeModal"
        :loading="removeModalLoading"
        @cancel="
          removeModal = false;
          showDraggable = true;
        "
        @remove="removeCesiumAnnotation"
      />
      <div
        v-drag
        class="draggableComp"
        :style="{ top: draggableDialogTop, left: draggableDialogLeft }"
        v-if="showDraggable"
      >
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ clickedData.name }}
              <v-btn
                fab
                small
                @click="
                  showDraggable = false;
                  editingMode = true;
                  saveModal = true;
                "
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </span>
          </v-card-title>
          <v-card-subtitle>
            {{ clickedData.description }}
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <div style="textAlign:center;" v-if="editableIconShow">
                <v-card-subtitle>
                  Click on any node(red dots) of drawing to start editing. Click
                  anywhere else to end editing
                </v-card-subtitle>
              </div>
              <v-simple-table v-else>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Process</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Edit</td>
                      <td>
                        <v-btn
                          class="mr-1"
                          fab
                          small
                          @click="
                            editableIconShow = true;
                            showDraw = true;
                            $refs.drawManager.editGraphic(clickedData.mid);
                          "
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td>Remove</td>
                      <td>
                        <v-btn
                          class="mr-1"
                          fab
                          small
                          @click="
                            showDraggable = false;
                            removeModal = true;
                          "
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!-- <v-row>

              <v-col cols="12">
                Are you Sure, you want to remove this observation?
              </v-col>

            </v-row> -->
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="showDraggable = false"
              v-if="!editableIconShow"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>

      <v-sheet
        class="mx-auto"
        elevation="8"
        style="position: absolute;zIndex: 100;bottom: 0;left:0;right:0;backgroundColor:#1e1e1e47;"
        :style="[
          show2DImages ? { visibility: 'visible' } : { visibility: 'hidden' }
        ]"
      >
        <div
          style="display: flex; alignItems: center;flexFlow: row-reverse;paddingTop: 10px;"
        >
          <v-btn dark text @click="show2DImages = false">
            Close
          </v-btn>
        </div>
        <div
          class="collectedDataResult"
          style="marginTop:30px;paddingBottom:30px"
          v-if="imagesData2d.length < 1"
        >
          Please Upload Some Images!
        </div>

        <v-slide-group
          v-model="sliderModel"
          class="pa-4 pt-0"
          center-active
          show-arrows
          v-else
        >
          <v-slide-item
            v-for="(image, index) in imagesData2d"
            :key="image.id"
            style="width:100px;height:100px;marginLeft:15px;overflow:revert"
            class="d-flex child-flex leaflet-container"
            v-slot="{ active, toggle }"
          >
            <div
              :id="'map' + index + 'test'"
              style="width:100px;zIndex:1;height:100px;boxSizing: content-box;"
              :style="[
                active
                  ? {
                      border: '5px solid rgb(255, 102, 0)',
                      borderRadius: '10px'
                    }
                  : { border: 'none' }
              ]"
              @click="
                () => {
                  toggle();
                  zoomToCamera(image);
                }
              "
            >
              <div
                class="pricedrop-tag"
                v-if="image.loadComplete && image.annotation_count > 0"
              >
                <div class="pricedrop-ribbon urgent">
                  {{ image.annotation_count }} Annotation{{
                    image.annotation_count > 1 ? "s" : ""
                  }}
                </div>
              </div>
              <div
                style="position: absolute;
                      bottom: 5px;
                      left: 5px;
                      z-index: 1000;"
              >
                <v-btn
                  fab
                  x-small
                  @click.stop="() => bigAnnotatorOpenHandler(index)"
                >
                  <v-icon dark color="white">mdi-pencil</v-icon>
                </v-btn>
              </div>
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import * as L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import Vue from "vue";
import VueCesium from "vue-cesium";
import lang from "vue-cesium/lang/en-us";
import cesiumDraw from "cesium-draw";
import "cesium-draw/dist/theme/default.css";
import AnnotatorCesiumSaveDialog from "./AnnotatorCesiumSaveDialog.vue";
import AnnotatorRemoveDialog from "./AnnotatorRemoveDialog.vue";

import { mapState } from "vuex";
// eslint-disable-next-line no-unused-vars
const THREE = window.THREE;
Vue.use(VueCesium, {
  lang: lang,
  cesiumPath:
    process.env.NODE_ENV === "production"
      ? `${process.env.VUE_APP_PUBLIC_PATH}Cesium/Cesium.js`
      : `/Cesium/Cesium.js`,
  accessToken: process.env.VUE_APP_CESIUM_ACCESS_TOKEN
});

export default {
  name: "AssetCesiumViewer",
  props: ["inspectionID", "imagesData2dProp", "cesiumCamera"],
  components: {
    cesiumDraw,
    AnnotatorCesiumSaveDialog,
    AnnotatorRemoveDialog
  },
  data() {
    return {
      editingMode: false,
      showMeasure: false,
      showControls: false,
      imagesData2d: [],
      sliderModel: null,
      show2DImages: false,
      showDraw: false,
      saveModal: false,
      removeModal: false,
      saveModalLoading: false,
      removeModalLoading: false,
      removeLastPosition: true,
      editableIconShow: false,
      cesiumInstance: {},
      polylineDrawing: false,
      clampToGround: false,
      editable: false,
      viewerInfoBox: true,
      navigationControlOptions: {
        enableCompass: true,
        enableZoomControl: {
          defaultResetView: {
            lng: 36,
            lat: 50,
            height: 1000,
            heading: 360,
            pitch: -90,
            roll: 0
          }
        },
        enableDistanceLegend: true,
        enableLocationBar: false,
        enableCompassOuterRing: true,
        enablePrintView: false,
        enableMyLocation: false
      },

      mapboxMapId: "mapbox.satellite",
      mapboxAccessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,

      //   baseLayer: "mapbox_satellite",
      baseLayer: "osm",

      terrainProvider: 0, // 0 - Ellipsoid, 1- Cesium World Terrain
      viewMode: 1, // 0 - Earth, 1- Model

      projectLayers: [],

      maximumLevel: 24,

      orthoLayerUrl: "",
      orthoLayerOpacity: 100,
      orthoLayerOpacityVal: 1,
      showOrthoLayer: true,

      dtmLayerUrl: "",
      dtmLayerOpacity: 100,
      dtmLayerOpacityVal: 1,
      showDtmLayer: false,

      dsmLayerUrl: "",
      dsmLayerOpacity: 100,
      dsmLayerOpacityVal: 1,
      showDsmLayer: false,

      pointCloudlLayerUrl: "",
      pointCloudShading: {
        maximumAttenuation: undefined,
        baseResolution: undefined,
        geometricErrorScale: 1.0,
        attenuation: true,
        eyeDomeLighting: true,
        normalShading: false,
        eyeDomeLightingStrength: 1,
        eyeDomeLightingRadius: 1
      },
      showPointCloudLayer: true,
      pointCloudOffset: 0,
      pointCloudTileset: {},
      tiledModelLayerUrl: "",
      showTiledModelLayer: false,
      tiledModelOffset: 0,
      tiledModelTileset: {},
      showDraggable: false,
      draggableDialogTop: "0px",
      draggableDialogLeft: "0px",
      clickedData: {},

      cameraLabelPosition: {
        lng: 0,
        lat: 0,
        height: 0
      },
      cameraLabelContent: "",
      cameraLabelShow: false,

      showCameras: true,

      selectedCameraFeature: [],

      filterImagesPointFeature: [],
      filteredImages: [],
      foundCesiumCamera: []
      // imageID: undefined,
      // imageURL: undefined,
      // imageIndex: undefined,
      // arrayLength: undefined,
      // firstTime: true
    };
  },

  watch: {
    dsmLayerOpacity() {
      this.dsmLayerOpacityVal = this.dsmLayerOpacity / 100;
    },
    dtmLayerOpacity() {
      this.dtmLayerOpacityVal = this.dtmLayerOpacity / 100;
    },
    orthoLayerOpacity() {
      this.orthoLayerOpacityVal = this.orthoLayerOpacity / 100;
    },
    pointCloudOffset() {
      this.updatePointCloudOffset();
    },
    tiledModelOffset() {
      this.updateTiledModelOffset();
    },
    terrainProvider() {
      const { Cesium, viewer } = this.cesiumInstance;

      if (this.terrainProvider === 0) {
        viewer.terrainProvider = new Cesium.EllipsoidTerrainProvider();
      } else {
        viewer.terrainProvider = new Cesium.createWorldTerrain({
          requestVertexNormals: true
        });
      }
    },
    viewMode() {
      const { viewer } = this.cesiumInstance;

      if (this.viewMode === 0) {
        // Earth
        viewer.scene.globe.show = true;
      } else {
        // Model
        viewer.scene.globe.show = false;
      }
    },
    selectedCamera() {
      const image = this.selectedCamera;
      const { Cesium, viewer } = this.cesiumInstance;

      const lng = image.geometry.coordinates[0];
      const lat = image.geometry.coordinates[1];
      const height = image.geometry.coordinates[2];
      const photo_id = image.properties.photo_id;
      const w = image.properties.width;
      const h = image.properties.height;

      this.selectedCameraFeature = [];
      this.selectedCameraFeature.push({
        lng: lng,
        lat: lat,
        height: height,
        photo_id: photo_id,
        w: w,
        h: h
      });

      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(lng, lat, height + 200)
      });
    }
  },

  computed: {
    ...mapState({
      flightCameras: state => state.cameras.flightCameras,
      // selectedCamera: state => state.cameras.selectedCamera
      isFilterImagesOnClick: state => state.cameras.isFilterImagesOnClick
    })
  },
  mounted() {
    console.log("cesium camera is ", this.cesiumCamera);

    this.imagesData2d = this.imagesData2dProp;

    let pointsArr = [];

    for (let [ind, i] of this.imagesData2d.entries()) {
      let obj = this.getImagePropertiesNewer(i.file_name);
      if (obj) {
        pointsArr.push(obj);
      }

      this.getImageWidthHeight(i.thumbnail_url, () => {
        let ratio = i.width / i.height;
        let mapContainer = document.getElementById("map0test");
        this.loadCamera(
          Math.round(mapContainer.clientWidth),
          Math.round(mapContainer.clientWidth / ratio),
          i.thumbnail_url,
          i.id,
          ind
        );
      });
    }
    this.foundCesiumCamera = pointsArr;
  },

  methods: {
    // changeURLImage(type) {
    //   let currentIndex = this.imageIndex;

    //   if (type == "next") {
    //     currentIndex += 1;
    //   } else if (type == "previous") {
    //     currentIndex -= 1;
    //   }

    //   let image = this.imagesData2d[currentIndex];

    //   this.imageName = image.file_name;
    //   this.imageID = image.id;
    //   this.imageURL = image.image_url;
    //   this.imageIndex = currentIndex;
    //   this.$refs.testingSlot[currentIndex].toggle();
    // },
    // loadedCompleteHandler() {
    //   if (this.firstTime) {
    //     this.$refs.testingSlot[0].toggle();
    //     this.firstTime = false;
    //   }
    // },
    // changeSliderImage(ind) {
    //   let currentIndex = ind;

    //   let image = this.imagesData2d[currentIndex];

    //   this.imageName = image.file_name;
    //   this.imageID = image.id;
    //   this.imageURL = image.image_url;
    //   this.imageIndex = currentIndex;
    // },
    cancelCesiumAnnotation() {
      this.saveModal = false;
      if (this.editingMode) {
        this.showDraggable = true;
      } else {
        this.$refs.drawManager.removeAnnotationFromComp();
      }
      this.editingMode = false;
    },
    async editCesiumAnnotationHandler(props) {
      this.saveModalLoading = true;
      let json = this.getRequiredGeoJson(props.name, props.description);
      let uploadObj = new FormData();
      uploadObj.set("geojson", JSON.stringify(json.features[0]));
      uploadObj.set("inspection_id", this.$route.params.inspectionID);
      uploadObj.set("annotation_id", this.clickedData.pk);
      try {
        let res = await this.$http.put(
          `/edit-model-annotations/${this.clickedData.pk}`,
          uploadObj,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );
        console.log("response of  edit is ", res.data);
        this.saveModalLoading = false;
        this.saveModal = false;
        this.$store.commit("snackbarActivator", {
          open: true,
          success: true,
          message: "Annotation successfully edited"
        });
      } catch (err) {
        console.log("something went wrong");
      }
    },
    async createdGEOJSONHandler(json) {
      console.log("json is ", json);
      // json.features[0].properties.inspection_id = this.$route.params.inspectionID;
      // if (json.features[0].geometry.type == "POLYGON") {
      //   json.features[0].geometry.coordinates[0].push(
      //     json.features[0].geometry.coordinates[0][0]
      //   );
      // }
      // console.log("JSON is ", json.features[0]);
      // let uploadObj = new FormData();
      // uploadObj.set("geojson", JSON.stringify(json.features[0]));
      // try {
      //   let res = await this.$http.post("/create-model-annotation", uploadObj, {
      //     headers: {
      //       Authorization: "token " + localStorage.getItem("auth_token")
      //     }
      //   });
      //   console.log("response is ", res);
      // } catch (err) {
      //   console.log("something went wrong");
      // }
      return;
    },

    zoomToCamera(image) {
      const { Cesium, viewer } = this.cesiumInstance;
      console.log("viewer is ", viewer);
      const image_name = image.file_name;
      console.log("image_name", image_name);

      let image_properties = this.getImagePropertiesNewer(image_name);
      console.log("image_properties", image_properties);

      if (image_properties.X && image_properties.Y) {
        let { Omega, Phi, Kappa } = image_properties;

        Kappa = Number(Kappa);
        Omega = Number(Omega);
        Phi = Number(Phi);

        this.selectedCameraFeature = [];
        this.selectedCameraFeature.push({
          lng: Number(image_properties.X),
          lat: Number(image_properties.Y),
          height: Number(image_properties.Z),
          photo_id: image_properties.PhotoID
        });

        let testObj = Cesium.Cartesian3.fromDegrees(
          Number(image_properties.X),
          Number(image_properties.Y),
          Number(image_properties.Z)
        );

        console.log("measurements are ", testObj);

        let a = new THREE.Euler(
          (Omega * Math.PI) / 180,
          (Phi * Math.PI) / 180,
          (Kappa * Math.PI) / 180,
          "XYZ"
        );
        let b = new THREE.Vector3(0, 0.05, -1);
        b.applyEuler(a);
        b.x = b.x + testObj.x;
        b.y = b.y + testObj.y;
        b.z = b.z + testObj.z;

        let posVec = new THREE.Vector3(testObj.x, testObj.y, testObj.z);
        let V = new THREE.Vector3().subVectors(b, posVec);
        let dir = V.normalize();
        let yaw, pitch;
        console.log("V is ", V);
        if (dir.x === 0 && dir.y === 0) {
          pitch = (Math.PI / 2) * Math.sign(dir.z);
        } else {
          yaw = Math.atan2(dir.y, dir.x) - Math.PI / 2;
          pitch = Math.atan2(dir.z, Math.sqrt(dir.x * dir.x + dir.y * dir.y));
        }
        console.log("new yaw is ", yaw);
        console.log("new pitch is ", pitch);
        viewer.camera.setView({
          destination: Cesium.Cartesian3.fromDegrees(
            Number(image_properties.X),
            Number(image_properties.Y),
            Number(image_properties.Z)
          ),
          orientation: {
            heading: yaw * -1,
            pitch
            // roll : matrixEulerTest.y
          }
        });
      }
    },

    // zoomToCamera(image) {
    //   const { Cesium, viewer } = this.cesiumInstance;

    //   const image_name = image.file_name;
    //   console.log("image_name", image_name);

    //   let image_properties = this.getImagePropertiesNewer(image_name);
    //   console.log("image_properties", image_properties);

    //   if (image_properties.X && image_properties.Y) {
    //     let {
    //       r11,
    //       r12,
    //       r13,
    //       r21,
    //       r22,
    //       r23,
    //       r31,
    //       r32,
    //       r33,
    //       Kappa
    //     } = image_properties;
    //     let kappa = Number(Kappa);
    //     this.selectedCameraFeature = [];
    //     this.selectedCameraFeature.push({
    //       lng: Number(image_properties.X),
    //       lat: Number(image_properties.Y),
    //       height: Number(image_properties.Z),
    //       photo_id: image_properties.PhotoID
    //     });
    //     let testObj = Cesium.Cartesian3.fromDegrees(
    //       image_properties.X,
    //       image_properties.Y,
    //       image_properties.Z
    //     );
    //     console.log("measurements are ", testObj);
    //     kappa = 90 + kappa;
    //     kappa *= Math.PI / 180;
    //     console.log("kappa is ", kappa);
    //     const m = new THREE.Matrix4();
    //     m.set(
    //       Number(r11),
    //       Number(r21),
    //       Number(r31),
    //       testObj.x,
    //       Number(r12),
    //       Number(r22),
    //       Number(r32),
    //       testObj.y,
    //       Number(r13),
    //       Number(r23),
    //       Number(r33),
    //       testObj.z,
    //       0,
    //       0,
    //       0,
    //       1
    //     );
    //     let matrixEulerTest = new THREE.Euler();
    //     matrixEulerTest.setFromRotationMatrix(m, "ZXY");
    //     console.log("matirx is ", m);
    //     console.log("rotation from matrix is ", matrixEulerTest);

    //     viewer.camera.setView({
    //       destination: Cesium.Cartesian3.fromDegrees(
    //         image_properties.X,
    //         image_properties.Y,
    //         image_properties.Z
    //       ),
    //       orientation: {
    //         heading: matrixEulerTest.z * -1,
    //         // heading : matrixEulerTest.z
    //         // heading : 1.57 + matrixEulerTest.z,
    //         // heading : kappa,
    //         // heading : 0.785398,
    //         pitch: matrixEulerTest.x - Math.PI / 2
    //         // roll : matrixEulerTest.y
    //       }
    //     });
    //   }
    // },

    // zoomToCamera(image) {
    //   const { Cesium, viewer } = this.cesiumInstance;

    //   const image_name = image.file_name;
    //   console.log("image_name", image_name);

    //   let image_properties = this.getImageProperties(image_name);
    //   console.log("image_properties", image_properties);

    //   console.log("new image properties are ",this.getImagePropertiesNewer(image_name))

    //   if (image_properties.lng && image_properties.lat) {
    //     this.selectedCameraFeature = [];
    //     this.selectedCameraFeature.push({
    //       lng: image_properties.lng,
    //       lat: image_properties.lat,
    //       height: image_properties.height,
    //       photo_id: image_properties.photo_id
    //     });
    //     let testObj = Cesium.Cartesian3.fromDegrees(
    //         image_properties.lng,
    //         image_properties.lat,
    //         image_properties.height
    //       );
    //     console.log("measurements are ",testObj)

    //     // viewer.camera.pitch = 0;
    //     //  viewer.camera.flyTo({
    //     //   destination: Cesium.Cartesian3.fromDegrees(
    //     //     image_properties.lng,
    //     //     image_properties.lat,
    //     //     image_properties.height + 200
    //     //   )
    //     // });
    //     // viewer.camera.setView({
    //     //     position : new Cesium.Cartesian3(testObj.x, testObj.y, testObj.z),
    //     //     // heading : 2.9600678864211987,
    //     //     // pitch : 0,
    //     //     // roll : -0.01383036006142486
    //     //     });
    //     viewer.camera.setView({
    //       destination : Cesium.Cartesian3.fromDegrees(
    //         image_properties.lng,
    //         image_properties.lat,
    //         image_properties.height
    //       ),
    //       orientation:{
    //         heading : 2.9600678864211987,
    //         pitch : -1.5707963267948966,
    //         roll : 0.01949890418768477
    //       }
    //     });
    //   }

    //   // this.$store.commit("setSelectedCamera", image);
    // },
    getImagePropertiesNewer(iamge_name) {
      return this.cesiumCamera.find(item => iamge_name.includes(item.PhotoID));
    },
    bigAnnotatorOpenHandler(ind) {
      let image = this.imagesData2d[ind];

      let obj = {
        imageName: image.file_name,
        imageID: image.id,
        imageURL: image.image_url,
        imageIndex: ind
      };

      this.$emit("bigAnnotatorOpener", obj);
    },

    getImageWidthHeight(url, callback) {
      var img = new Image();
      img.src = url;
      img.onload = function() {
        callback();
      };
    },

    loadCamera(width, height, imageURL, id, ind) {
      // let duplicateObj = { ...this.collectedData[ind] };
      // duplicateObj.existingObservations = L.layerGroup();

      // this.collectedData.splice(ind, 1, duplicateObj);

      this.imagesData2d[ind].existingObservations = L.layerGroup();
      this.imagesData2d[ind].existingObservations.clearLayers();

      if (this.imagesData2d[ind].mainMap) {
        this.imagesData2d[ind].mainMap.remove();
      }

      this.imagesData2d[ind].algoSelection = false;

      let mainMap = L.map(`map${ind}test`, {
        zoom: 0,
        center: [0, 0],
        zoomControl: false,
        attributionControl: false,
        crs: L.CRS.Simple,
        minZoom: 1,
        maxZoom: 4,
        scrollWheelZoom: false
      });
      mainMap.dragging.disable();
      mainMap.doubleClickZoom.disable();

      // duplicateObj = { ...this.imagesData2d[ind] };
      // this.imagesData2d[ind].mainMap = mainMap;

      // duplicateObj.mainMap = mainMap;
      // this.imagesData2d.splice(ind, 1, duplicateObj);
      this.imagesData2d[ind].mainMap = mainMap;

      this.imagesData2d[ind].mainMap.createPane("existingObservation");

      this.imagesData2d[ind].existingObservations.addTo(
        this.imagesData2d[ind].mainMap
      );

      let w = width,
        h = height;

      let southWest = this.imagesData2d[ind].mainMap.unproject(
        [0, h],
        this.imagesData2d[ind].mainMap.getMaxZoom() - 1
      );
      let northEast = this.imagesData2d[ind].mainMap.unproject(
        [w, 0],
        this.imagesData2d[ind].mainMap.getMaxZoom() - 1
      );
      let bounds = new L.LatLngBounds(southWest, northEast);
      // let bounds = new L.LatLngBounds([0, 0], [h / 10, w / 10]);
      let overlay_layer = L.imageOverlay(imageURL, bounds, {
        interactive: true
      });
      this.imagesData2d[ind].mainMap.overlay_layer = overlay_layer;
      this.imagesData2d[ind].mainMap.overlay_layer.addTo(
        this.imagesData2d[ind].mainMap
      );
      let otherObj = { ...this.imagesData2d[ind] };
      otherObj.loadComplete = true;
      this.imagesData2d.splice(ind, 1, otherObj);
      this.imagesData2d[ind].mainMap.overlay_layer.on("load", () => {
        // this.overlay = false;
        this.loadImageObservations(id, ind);
      });
      this.imagesData2d[ind].mainMap.overlay_layer.on("click", () => {
        // this.overlay = false;
        // this.bigAnnotatorOpenHandler(ind);
        console.log("I am clicked");
      });
      this.imagesData2d[ind].mainMap.setMaxBounds(bounds);
      this.imagesData2d[ind].mainMap.fitBounds(bounds);
      this.imagesData2d[ind].mainMap.invalidateSize();
    },

    loadImageObservations(id, ind, editAnnotation) {
      this.$http
        .get(`/get-annotations/${id}`, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        })
        .then(
          response => {
            let inspection_geo = { ...response.data };

            this.imagesData2d[ind].newObservation = L.layerGroup();
            if (this.imagesData2d[ind].existingObservations) {
              this.imagesData2d[ind].existingObservations.clearLayers();
            }

            if (this.imagesData2d[ind].newObservation) {
              this.imagesData2d[ind].mainMap.removeLayer(
                this.imagesData2d[ind].newObservation
              );
            }

            let needFor = inspection_geo.features;
            let otherCoordinates = [],
              num = 0,
              todo,
              test;
            let overlayTest = this.imagesData2d[ind].mainMap.overlay_layer
              ._image;
            let yR = overlayTest.clientHeight / this.imagesData2d[ind].height;
            let xR = overlayTest.clientWidth / this.imagesData2d[ind].width;
            for (let i of needFor) {
              if (i.geometry.type == "Point") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                test = this.imagesData2d[ind].mainMap.unproject([
                  -todo[1],
                  -todo[0]
                ]);
                otherCoordinates.push(test.lat * xR);
                otherCoordinates.push(test.lng * yR);
                inspection_geo.features[
                  num
                ].geometry.coordinates = otherCoordinates;
              } else if (i.geometry.type == "LineString") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                for (let j of todo) {
                  test = this.imagesData2d[ind].mainMap.unproject([
                    -j[1],
                    -j[0]
                  ]);

                  otherCoordinates.push([test.lat * xR, test.lng * yR]);
                }
                inspection_geo.features[
                  num
                ].geometry.coordinates = otherCoordinates;
              } else if (i.geometry.type == "Polygon") {
                todo = i.geometry.coordinates;
                otherCoordinates = [];
                let subNum = 0;
                for (let j of todo) {
                  for (let k of j) {
                    test = this.imagesData2d[ind].mainMap.unproject([
                      -k[1],
                      -k[0]
                    ]);
                    otherCoordinates.push([test.lat * xR, test.lng * yR]);
                  }
                  inspection_geo.features[num].geometry.coordinates[
                    subNum
                  ] = otherCoordinates;
                  subNum++;
                }
              }
              num++;
            }

            if (inspection_geo.features.length > 0) {
              let geo = L.geoJSON(inspection_geo, {
                pane: "existingObservation",
                pmIgnore: false,
                onEachFeature: (feature, layer) => {
                  if (layer.feature.properties.drawn_by == "Machine") {
                    layer.setStyle({ color: "#e21e1e" });
                    // layer.bindTooltip(
                    //   `${layer.feature.properties.confidence} %`,
                    //   { permanent: true, offset: [0, 0] }
                    // );
                  }
                }
              });

              this.imagesData2d[ind].existingObservations.addLayer(geo);
            }
            if (editAnnotation) {
              let otherObj = { ...this.imagesData2d[ind] };
              otherObj.annotation_count = response.data.features.length;
              this.imagesData2d.splice(ind, 1, otherObj);
            }
          },
          error => {
            console.log("error", error);
            throw error;
          }
        )
        .catch(error => {
          console.log("cerror", error);
          throw error;
        });
    },
    getRequiredGeoJson(name, description) {
      let json = this.$refs.drawManager.saveGEOJSONHandler(name, description);
      json.features[0].properties.inspection_id = this.$route.params.inspectionID;
      if (json.features[0].geometry.type == "POLYGON") {
        json.features[0].geometry.coordinates[0].push(
          json.features[0].geometry.coordinates[0][0]
        );
      }
      return json;
    },
    async saveCesiumAnnotationDialog(props) {
      this.saveModalLoading = true;
      let json = this.getRequiredGeoJson(props.name, props.description);
      let uploadObj = new FormData();
      uploadObj.set("geojson", JSON.stringify(json.features[0]));
      try {
        let res = await this.$http.post("/create-model-annotation", uploadObj, {
          headers: {
            Authorization: "token " + localStorage.getItem("auth_token")
          }
        });
        this.$refs.drawManager.savePKId(res.data.id);
        this.saveModalLoading = false;
        this.saveModal = false;
      } catch (err) {
        console.log("something went wrong");
      }
    },
    elementEditedHandler() {
      this.editableIconShow = false;
      console.log("element editing is ended");
    },

    async removeCesiumAnnotation() {
      this.removeModalLoading = true;
      this.$refs.drawManager.deleteGraphic(this.clickedData.mid);
      try {
        await this.$http.delete(
          `/delete-model-annotations/${this.clickedData.pk}`,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );

        this.removeModalLoading = false;
        this.removeModal = false;
      } catch (err) {
        console.log("something went wrong");
      }
    },

    viewerReady(cesiumInstance) {
      const { viewer, Cesium } = cesiumInstance;
      this.cesiumInstance = cesiumInstance;
      console.log("I am inside ready check the viewer ", viewer);
      this.$refs.drawManager.init(viewer);
      // window.cesiumViewer = viewer;

      // var res = new Cesium.GeoJsonDataSource.load('/polylinetest2.geojson');
      // res.then((dataSource)=>{
      //   viewer.dataSources.add(dataSource);
      // })

      this.viewerInfoBox = false;

      let scene = viewer.scene;
      this.mouseMoveHundler(Cesium, viewer, scene);
      this.mouseClickHundler(Cesium, viewer, scene);

      // remove default imagery layer
      viewer.scene.imageryLayers.removeAll();

      viewer.scene.globe.show = false;
      viewer.scene.backgroundColor = Cesium.Color.clone(
        Cesium.Color.DARKGREY
      ).withAlpha(0.0);

      this.getProcessedData();
    },

    mouseMoveHundler(Cesium, viewer, scene) {
      let mouse_move_hundler = new Cesium.ScreenSpaceEventHandler(scene.canvas);
      mouse_move_hundler.setInputAction(movement => {
        let foundPosition = false;

        if (scene.mode !== Cesium.SceneMode.MORPHING) {
          let pickedObject = scene.pick(movement.endPosition);

          if (
            pickedObject &&
            pickedObject.id &&
            typeof pickedObject.id === "string" &&
            scene.pickPositionSupported &&
            Cesium.defined(pickedObject) &&
            pickedObject.id.includes("cameraEntity_")
          ) {
            let cartesian = viewer.scene.pickPosition(movement.endPosition);

            if (Cesium.defined(cartesian)) {
              this.cameraLabelPosition = cartesian;
              this.cameraLabelContent = pickedObject.id.replace(
                "cameraEntity_",
                ""
              );

              let image_properties = this.getImageProperties(pickedObject.id);

              this.selectedCameraFeature = [];
              this.selectedCameraFeature.push({
                lng: image_properties.lng,
                lat: image_properties.lat,
                height: image_properties.height,
                photo_id: image_properties.photo_id
              });

              this.cameraLabelShow = true;

              foundPosition = true;
            }
          }
        }

        if (!foundPosition) {
          this.cameraLabelShow = false;
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    },

    filterImagesByCoordinates(lng, lat, alt) {
      console.log("click", lng, lat, alt);
      this.filterImagesPointFeature = [];
      this.filterImagesPointFeature.push({
        lng: lng,
        lat: lat,
        alt: alt + 0.5
      });

      // const flight_id = this.flight_id;
      // this.$store.dispatch("filterFlightImagesByCoordinates", {
      //   flight_id,
      //   lng,
      //   lat,
      // });
    },

    mouseClickHundler(Cesium, viewer, scene) {
      let mouse_click_hundler = new Cesium.ScreenSpaceEventHandler(
        scene.canvas
      );
      mouse_click_hundler.setInputAction(movement => {
        if (scene.mode !== Cesium.SceneMode.MORPHING) {
          let ray = viewer.camera.getPickRay(movement.position);
          let positionCartesian3 = viewer.scene.globe.pick(ray, viewer.scene);
          let positionCartographic = Cesium.Cartographic.fromCartesian(
            positionCartesian3
          );
          let lng = Cesium.Math.toDegrees(positionCartographic.longitude);
          let lat = Cesium.Math.toDegrees(positionCartographic.latitude);
          let alt = positionCartographic.height;

          if (this.isFilterImagesOnClick) {
            this.filterImagesByCoordinates(lng, lat, alt);
          }

          let pickedObject = scene.pick(movement.position);

          if (
            pickedObject &&
            pickedObject.id &&
            typeof pickedObject.id === "string" &&
            scene.pickPositionSupported &&
            Cesium.defined(pickedObject) &&
            pickedObject.id.includes("cameraEntity_")
          ) {
            let cartesian = viewer.scene.pickPosition(movement.position);

            if (Cesium.defined(cartesian)) {
              let image_properties = this.getImageProperties(pickedObject.id);

              viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(
                  image_properties.lng,
                  image_properties.lat,
                  image_properties.height + 200
                )
                //   orientation: {
                //     heading: image_properties.yaw,
                //     pitch: image_properties.pitch,
                //     roll: image_properties.roll,
                //   },
              });

              this.$store.commit("setSidebarPanelIndex", 2);
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },

    getImageProperties(iamge_name) {
      let photo_id,
        lng,
        lat,
        height,
        yaw,
        pitch,
        roll = null;
      console.log("flightCameras", this.flightCameras);
      this.flightCameras.features.forEach(item => {
        if (iamge_name.includes(item.properties.photo_id)) {
          lng = item.geometry.coordinates[0];
          lat = item.geometry.coordinates[1];
          height = item.geometry.coordinates[2];
          photo_id = item.properties.photo_id;
          yaw = item.properties.yaw;
          pitch = item.properties.pitch;
          roll = item.properties.roll;
        }
      });

      return {
        photo_id: photo_id,
        lng: lng,
        lat: lat,
        height: height,
        yaw: yaw,
        pitch: pitch,
        roll: roll
      };
    },

    toggle(type) {
      // console.log(type)
      this.$refs[type].measuring = !this.$refs[type].measuring;
      // this.$refs[type].drawing = !this.$refs[type].drawing;
    },
    activeEvt(_) {
      this[_.type] = _.isActive;
    },
    movingEvt() {
      // console.log("positon is ",position);
      // this.tooltip.showAt(windowPosition, '<p>left click to draw, right click end.</p>')
    },
    measureEvt(result) {
      console.log(result);
      // const { viewer} = this.cesiumInstance;
      // console.log("viewer entities are",viewer.entities)
    },
    drawEvt(result) {
      if (result.finished) {
        console.log("result is ", result);
        console.log(this.$refs.handlerLine);
        // this.$refs.handlerLine.LEFT_CLICK(()=>{
        //   console.log("I was clicked")
        // })
      }
    },
    async getProcessedData() {
      try {
        let res = await this.$http.get(
          `/inspection/${this.inspectionID}/processed-data${
            this.$store.state.isShared ? "?uuid=" + this.$route.query.uuid : ""
          }`,
          {
            ...(localStorage.getItem("auth_token") && {
              headers: {
                Authorization: "token " + localStorage.getItem("auth_token")
              }
            })
          }
        );

        this.projectLayers = res.data;

        this.loadLayers();
      } catch (err) {
        console.log("something went wrong ", err);
      }
    },

    loadLayers() {
      const { Cesium, viewer } = this.cesiumInstance;
      this.projectLayers.forEach(layer => {
        const processed_type = layer.processed_type;

        if (processed_type === "ortho") {
          this.orthoLayerUrl = layer.tiles_path.replace(
            "https://aer-2-dev_s3_amazonaws_com/",
            "https://dev-api.aerinspect.com/get-tile/"
          );
        }

        if (processed_type === "dtm") {
          this.dtmLayerUrl = layer.tiles_path.replace(
            "https://aer-2-dev_s3_amazonaws_com/",
            "https://dev-api.aerinspect.com/get-tile/"
          );
        }

        if (processed_type === "dsm") {
          this.dsmLayerUrl = layer.tiles_path.replace(
            "https://aer-2-dev_s3_amazonaws_com/",
            "https://dev-api.aerinspect.com/get-tile/"
          );
        }

        if (processed_type === "point_cloud") {
          this.pointCloudlLayerUrl = `${layer.tiles_path.replace(
            "https://aer-2-dev_s3_amazonaws_com/",
            "https://dev-api.aerinspect.com/get-tile/"
          )}/${layer.file_name.replace(".las", "")}/tileset.json`;
        }

        if (processed_type === "3d") {
          this.tiledModelLayerUrl = `https://dev-api.aerinspect.com/get-tile/${layer.tiles_path_without_host}/tileset.json`;
          this.showTiledModelLayer = true;
        }

        if (processed_type in ["ortho", "dtm", "dsm"]) {
          console.log("I cam here in processed type for dtm,dsm see");
          const min_x = layer.min_x;
          const min_y = layer.min_y;
          const max_x = layer.max_x;
          const max_y = layer.max_y;

          const lng = (min_x + max_x) / 2;
          const lat = (min_y + max_y) / 2;
          console.log(lng, lat);
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(lng, lat, 100)
          });

          this.navigationControlOptions.enableZoomControl.defaultResetView.lng = lng;
          this.navigationControlOptions.enableZoomControl.defaultResetView.lat = lat;
        }

        if (processed_type === "cameras") {
          const camerasUrl = layer.file_url;
          this.$store.dispatch("loadFlightCameras", camerasUrl);
        }

        // if (processed_type === "cameras_agisoft") {
        //   const camera_geojson = layer.cameras_agisoft.camera_geojson;
        //   this.$store.dispatch("loadFlightCamerasAgisoft", camera_geojson);
        // }

        // if (processed_type === "footprints") {
        //   this.footprintsLayerUrl = layer.file_url;
        // }
      });

      // var res = new Cesium.GeoJsonDataSource.load('/polyline_result.geojson');
      // res.then((dataSource)=>{
      //   viewer.dataSources.add(dataSource);
      // })
      this.loadModelGEOJSON();
    },

    pointCloudReady(tileset) {
      this.pointCloudTileset = tileset;

      const { Cesium, viewer } = this.cesiumInstance;
      viewer.zoomTo(
        tileset,
        new Cesium.HeadingPitchRange(
          0.0,
          -0.5,
          tileset.boundingSphere.radius * 2.0
        )
      );
    },
    async loadModelGEOJSON() {
      try {
        let obj = {
          type: "FeatureCollection",
          name: "graphic",
          crs: {
            type: "name",
            properties: {
              name: "urn:ogc:def:crs:OGC:1.3:CRS84"
            }
          },
          features: []
        };
        let res = await this.$http.get(
          `/get-model-annotations/${this.$route.params.inspectionID}`,
          {
            headers: {
              Authorization: "token " + localStorage.getItem("auth_token")
            }
          }
        );

        for (let i of res.data.features) {
          i.properties.mtype = i.properties.props.mtype;
          i.properties.originalName = i.properties.props.originalName;
          obj.features.push(i);
        }
        this.$refs.drawManager.importFromComp(obj);
      } catch (err) {
        console.log("something went wrong", err);
      }
    },
    elementClickHandler(obj) {
      if (!this.$store.state.isShared) {
        this.draggableDialogTop = `${Math.round(obj.yPos)}px`;
        this.draggableDialogLeft = `${Math.round(obj.xPos)}px`;
        this.showDraggable = true;
        this.clickedData = obj;
      }
    },
    increasePointCloudOffset() {
      this.pointCloudOffset++;
    },
    decreasePointCloudOffset() {
      this.pointCloudOffset--;
    },

    updatePointCloudOffset() {
      const { Cesium } = this.cesiumInstance;
      let boundingSphere = this.pointCloudTileset.boundingSphere;
      console.log(boundingSphere, Cesium);

      var cartographic = Cesium.Cartographic.fromCartesian(
        boundingSphere.center
      );
      var surface = Cesium.Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        0.0
      );
      var offset = Cesium.Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        this.pointCloudOffset
      );
      var translation = Cesium.Cartesian3.subtract(
        offset,
        surface,
        new Cesium.Cartesian3()
      );
      this.pointCloudTileset.modelMatrix = Cesium.Matrix4.fromTranslation(
        translation
      );
      //   this.updateLayerOffset();
    },

    tiledModelReady(tileset) {
      this.tiledModelTileset = tileset;
      const { viewer, Cesium } = this.cesiumInstance;

      // viewer.zoomTo(
      //   tileset,
      //   Cesium.HeadingPitchRange(0.5, -0.5, tileset.boundingSphere.radius * 4.0)
      // );

      viewer.camera.flyToBoundingSphere(
        tileset.root.contentBoundingVolume.boundingSphere,
        { duration: 0.1 }
      );

      const cartographic = Cesium.Cartographic.fromCartesian(
        tileset.root.contentBoundingVolume.boundingSphere.center
      );
      const lat = Cesium.Math.toDegrees(cartographic.latitude);
      const lng = Cesium.Math.toDegrees(cartographic.longitude);
      this.navigationControlOptions.enableZoomControl.defaultResetView.lng = lng;
      this.navigationControlOptions.enableZoomControl.defaultResetView.lat = lat;
      this.navigationControlOptions.enableZoomControl.defaultResetView.height =
        cartographic.height + 100;
    },
    increaseTiledModelOffset() {
      this.tiledModelOffset++;
    },
    decreaseTiledModelOffset() {
      this.tiledModelOffset--;
    },

    updateTiledModelOffset() {
      const { Cesium } = this.cesiumInstance;
      let boundingSphere = this.tiledModelTileset.boundingSphere;
      console.log(Cesium);
      var cartographic = Cesium.Cartographic.fromCartesian(
        boundingSphere.center
      );
      var surface = Cesium.Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        0.0
      );
      var offset = Cesium.Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        this.tiledModelOffset
      );
      var translation = Cesium.Cartesian3.subtract(
        offset,
        surface,
        new Cesium.Cartesian3()
      );
      this.tiledModelTileset.modelMatrix = Cesium.Matrix4.fromTranslation(
        translation
      );
      // this.updateLayerOffset();
    }
  }
};
</script>

<style scoped>
.pricedrop-tag {
  position: absolute;
  left: -9px;
  top: 12%;
  z-index: 445;
}

.urgent {
  margin-bottom: 10px;
  background: #d54b4f;
}

.urgent:before {
  border-left: 10px solid #d54b4f;
}

.pricedrop-ribbon {
  position: relative;
  z-index: 2;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 12px;
  padding: 3px 0px 4px 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}

.pricedrop-ribbon:before {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  border-top: 9px solid transparent;
  border-bottom: 10px solid transparent;
}

.urgent:after {
  border-top: 5px solid #993538;
}

.pricedrop-ribbon:after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  border-left: 5px solid transparent;
}

.leaflet-container {
  background: #10181b;
  outline: 0;
}

.draggableComp {
  width: 400px;
  position: absolute;
  z-index: 1000;
}

.viewer {
  height: 100% !important;
}
.theme--dark.v-icon.mdi-plus {
  color: black;
}
.theme--dark.v-icon.mdi-minus {
  color: black;
}
>>> .theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgb(53 52 52 / 70%);
}

>>> .theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: black;
}

.v-navigation-drawer--is-mouseover {
  width: 170px !important;
}

.sectionInsideDrag {
  width: 400px;
  height: 85px;
  color: #000;
  background: #fff;
  z-index: 10;
  display: flex;
  flex-flow: column;
}

.headerSectionInsideDrag {
  height: 32px !important;
  line-height: 32px !important;
  border-bottom: 1px solid #dcdfe6;
  padding: 0 5px;
  border-radius: 4px;
}

.headerSectionInsideDrag span {
  margin: 0 5px;
  color: #000;
  font-size: 12px;
}

.closebtn {
  text-align: center;
  display: inline-block !important;
  font-size: 12px;
  cursor: pointer;
  float: right;
}

.closebtn:hover {
  color: red;
}

.graphic-draw-main {
  height: 52px;
  padding: 0 5px;
  vertical-align: top;
  color: #000;
  border-radius: 4px;
  font-size: 12px;
}

.graphic-draw-main ul {
  cursor: default;
  border-radius: 4px;
  padding: 0;
  overflow: hidden;
  height: 100%;
  margin: 0;
}

.graphic-draw-main ul li {
  cursor: pointer;
  float: left;
  padding: 0 0 0;
  width: 65px;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.graphic-draw-main ul li i {
  display: block;
  height: 16px;
  width: 16px;
  background-size: contain;
  margin: 0 auto;
  margin-top: 8px;
}

.graphic-draw-main ul li:hover svg,
.graphic-draw-main ul li:hover span {
  color: #409eff;
}

.icon-class {
  color: #000;
}

.graphic-draw-main ul li span {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  text-align: center;
  color: #000;
  line-height: 22px;
}

.layer-control > hr {
  border-color: rgba(0, 0, 0, 0.12);
  margin-top: 4px;
}

.layer-control span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
</style>

<template>
  <div>
    <v-dialog
      v-model="modal"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card :style="{ ...(isTransparent && { background: '#1e1e1e47' }) }">
        <v-toolbar dark dense color="secondary">
          <v-toolbar-title class="mr-5">
            {{ imageName }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="
                modal = false;
                firstTime = true;
              "
            >
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <AnnotatorDialogMainComp
          :imageID="imageID"
          :imageURL="imageURL"
          :imageIndex="imageIndex"
          :arrayLength="arrayLength"
          :isTransparent="isTransparent"
          mapheight="90vh"
          defaultWidth="100%"
          @imageChangeClicked="type => $emit('imageChangeClicked', type)"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AnnotatorDialogMainComp from "./AnnotatorDialogMainComp.vue";

export default {
  name: "AnnotatorDialog",
  props: [
    "imageName",
    "imageID",
    "imageURL",
    "imageIndex",
    "arrayLength",
    "dialogOpen",
    "isTransparent"
  ],
  components: {
    AnnotatorDialogMainComp
  },
  data() {
    return {
      modal: false
    };
  },
  watch: {
    dialogOpen(value) {
      if (value) {
        this.modal = value;
      }
    },
    modal(value) {
      console.log("Modal value is ", value);
      if (!value) {
        this.$emit("dialogClose", { id: this.imageID, index: this.imageIndex });
      }
    }
  }
};
</script>
